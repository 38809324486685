import React, { Component } from 'react';
import './BottomNav.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faCommentDots, faUser, faBookmark, faQuestionCircle, faTasks } from '@fortawesome/free-solid-svg-icons'


class BottomNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isSiteOwner,
      isActive,
      setActiveProfileTab,
      pushHistory
    } = this.props;

    return (
      <div className="bottom-nav">
        <div
          className={isActive('explore') ? 'active' : ''}
          onClick={() => pushHistory('/explore')}
        >
          <span>
            <div><FontAwesomeIcon icon={faCompass} /></div>
            <br />
            <span className={isActive('explore') ? '' : 'nav-text'}>Explore</span>
          </span>
        </div>

        <div
          className={isActive('messages') ? 'active' : ''}
          onClick={() => {
            pushHistory('/myprofile');
            setActiveProfileTab(4);
          }}
        >
          <span>
            <div><FontAwesomeIcon icon={faCommentDots} /></div>
            <br />
            <span className={isActive('messages') ? '' : 'nav-text'}>Messages</span>
          </span>
        </div>

        <div
          className={isActive('myprofile') ? 'active' : ''}
          onClick={() => {
            pushHistory('/myprofile');
            setActiveProfileTab(0);
          }}
        >
          <span>
            <div><FontAwesomeIcon icon={faUser} /></div>
            <br />
            <span className={isActive('myprofile') ? '' : 'nav-text'}>Profile</span>
          </span>
        </div>

        <div
          className={isActive('bookmarks') ? 'active' : ''}
          onClick={() => {
            pushHistory('/myprofile');
            setActiveProfileTab(1);
          }}
        >
          <span>
            <div><FontAwesomeIcon icon={faBookmark} /></div>
            <br />
            <span className={isActive('bookmarks') ? '' : 'nav-text'}>Bookmarks</span>
          </span>
        </div>

        <div
          className={isActive('help') ? 'active' : ''}
          onClick={() => pushHistory('/help')}
        >
          <span>
            <div><FontAwesomeIcon icon={faQuestionCircle} /></div>
            <br />
            <span className={isActive('help') ? '' : 'nav-text'}>Help</span>
          </span>
        </div>

        { isSiteOwner &&
          <div
            className={isActive('manage') ? 'active' : ''}
            onClick={() => pushHistory('/manage')}
          >
            <span>
              <div><FontAwesomeIcon icon={faTasks} /></div>
              <br />
              <span className={isActive('manage') ? '' : 'nav-text'}>Manage</span>
            </span>
          </div>
        }
      </div>
    );
  }
}

export default BottomNav;
