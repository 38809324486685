import React from 'react';
import config from '../../constant/config';
import checkStatus from '../common/checkStatus';
import SessionHeader from '../common/SessionHeader';
import CreatePlanSession from '../common/CreatePlanSessionContainer';

const fetchUsers = async (hubId) => {
  const res = await fetch(`${config.apiURL}/hub/${hubId}/users`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status === 200) {
    const body = await res.json();
    const { users } = body;
    return users;
  }
};

class EditSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      session: null,
      hubName: '',
      categoryDescription: '',
      users: [],
    };
  }

  componentWillMount() {
    this.getSession();
  }

  async getSession() {
    const { sessionId } = this.props.match.params;
    try {
      const res = await fetch(`${config.apiURL}/session/${sessionId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const session = await res.json();
        const users = await fetchUsers(session.hubId);
        await this.setState({
          session,
          hubName: session.hub.name,
          categoryDescription: 'Session',
          users,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <div>
        {this.state.session &&
        <div>
          <SessionHeader
            session={this.state.session}
            hubName={this.state.hubName}
            categoryDescription={this.state.categoryDescription}
            loggedIn={this.props.user}
            edit
          />
          <CreatePlanSession
            edit
            session={this.state.session}
            hubId={this.state.session.hubId}
            modules={[]}
            users={this.state.users}
            approved
          />
        </div>
        }
      </div>
    );
  }
}

export default checkStatus(EditSession);
