import React from 'react';
import { Row, Col, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import './UserGuide.css';
import checkStatus from '../common/checkStatus';
import config from '../../constant/config';
import { parseEmbedYouTubeUrl, parseEmbedVimeoUrl } from '../../lib/video';

import { Cookies } from "react-cookie-consent";

const fetchVideo = async () => {
  const res = await fetch(`${config.apiURL}/userguide/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status === 200) {
    const body = await res.json();
    return body;
  }
  return null;
};

class UserGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSiteOwner: props.user ? (props.user.isSiteOwner || false) : false,
      editing: false,
      videoEmbedLink: '',
      video: '',
    };
  }

  componentWillMount() {
    this.getVideoDetails();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== !!this.props.user) {
      this.setState({ isSiteOwner: newProps.user ? (newProps.user.isSiteOwner || false) : false });
    }
  }

  async getVideoDetails() {
    if (Cookies.get('actifyCookieConsent') === 'true') {
      const video = await fetchVideo();
      let embedLink = '';
      try {
        const videoURL = video.embedURL;
        if (video.sourceId === config.videoSource.YOUTUBE) {
          const youtubeSrc = parseEmbedYouTubeUrl(videoURL);
          embedLink =
            (<iframe
              title={youtubeSrc}
              className="video-wrapper"
              src={youtubeSrc}
              frameBorder="0"
              allowFullScreen
            />);
        } else if (video.sourceId === config.videoSource.VIMEO) {
          const videoId = await parseEmbedVimeoUrl(videoURL); // eslint-disable-line
          embedLink = <iframe title={videoId} className="video-wrapper" src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" allowFullScreen />;
        } else {
          throw new Error('unsupported video format');
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({ video: embedLink });
    } else {
      this.setState({ video: (<p>This video will store cookies while you watch it. <br/> You need to allow cookies for the video to play.</p>) })
    }
  }

  async saveVideoDetails() {
    try {
      const res = await fetch(`${config.apiURL}/userguide`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          embedURL: this.state.videoEmbedLink,
        }),
      });
      if (res.status === 200) {
        this.getVideoDetails();
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleButtonClick() {
    if (this.state.editing === false) {
      this.setState({ editing: true });
    } else {
      this.saveVideoDetails();
      this.setState({ editing: false });
    }
  }

  render() {
    return (
      <Row className="user-guide-container">
        <Col mdOffset={3} md={6} >
          <h1>User Guide</h1>
          {this.state.editing &&
            <FormGroup className="form" controlId="formVideo">
              <ControlLabel>Video Embed Link:</ControlLabel>
              <FormControl
                autoFocus
                className="form-upload"
                type="text"
                value={this.state.videoEmbedLink}
                onChange={e => this.setState({ videoEmbedLink: e.target.value })}
              />
            </FormGroup>
          }
          {!this.state.editing &&
            <div className="video-container">
              {this.state.video}
            </div>}
          <div className="button-container">
            {this.state.isSiteOwner && <Button className="edit-button" onClick={() => this.handleButtonClick()}>{this.state.editing ? 'Save' : 'Edit'}</Button>}
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  sideBarExpanded: state.sideBarExpanded,
});

export default connect(mapStateToProps)(checkStatus(UserGuide));
