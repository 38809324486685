import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TopNav from './TopNav';
import { addHub, toggleSideBar, setUser, setQuizResults, setNotifications, toggleLogin, setActiveProfileTab, setActiveRole } from '../../store/actions';

const mapStateToProps = state => ({
  user: state.user,
  notifications: state.notifications,
  actualNotifications: state.actualNotifications,
  sideBarExpanded: state.sideBarExpanded,
  activeRole: state.activeRole
});

const mapDispatchToProps = dispatch => ({
  onMenuPress: () => {
    dispatch(toggleSideBar());
  },
  onLogout: () => {
    dispatch(setUser(null));
    dispatch(setQuizResults([]));
    dispatch(setNotifications(null));
    dispatch(toggleLogin(false));
  },
  setNotifications: (notifications) => {
    dispatch(setNotifications(notifications));
  },
  setUser: (user) => {
    dispatch(setUser(user));
  },
  setActiveProfileTab: (activeTab) => {
    dispatch(setActiveProfileTab(activeTab));
  },
  setActiveRole: (role) => {
    dispatch(setActiveRole(role));
  },
  addHub: (hub) => {
    dispatch(addHub(hub));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNav));
