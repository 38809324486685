// import React from 'react';
// import { withRouter } from 'react-router-dom';
// import GA_TRACKING_ID from '../constant/config';

// class GoogleAnalytics extends React.Component {
//   componentWillUpdate ({ location, history }) {
//     const gtag = window.gtag;

//     if (location.pathname === this.props.location.pathname) {
//         // don't log identical link clicks (nav links likely)
//         return;
//     }

//     if (history.action === 'PUSH' &&
//         typeof(gtag) === 'function') {
//         gtag('config', GA_TRACKING_ID, {
//             'page_title': document.title,
//             'page_location': window.location.href,
//             'page_path': location.pathname
//             });
//         gtag('config',  'G-9NX219DSVB', {
//             'page_title': document.title,
//             'page_location': window.location.href,
//             'page_path': location.pathname
//             });
//         }
//     }

//     render () {
//         return null;
//     }
// }

// export default withRouter(GoogleAnalytics);
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = ({ trackingID }) => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', trackingID, {
        'page_title': document.title,
        'page_location': window.location.href,
        'page_path': location.pathname + location.search,
      });
    }
  }, [location, trackingID]);

  return null;
};

export default GoogleAnalytics;
