// import ReactGA from 'react-ga';

// export const initGA = (trackingID) => {
//   ReactGA.initialize(trackingID);
// };

// export const PageView = () => {
//   ReactGA.pageview(window.location.pathname +
//                      window.location.search);
// };

// /**
//  * Event - Add custom tracking event.
//  * @param {string} category
//  * @param {string} action
//  * @param {string} label
//  */
// export const Event = (category, action, label) => {
//     ReactGA.event({
//       category,
//       action,
//       label,
//     });
//   };
// tracking.js
export const initGA = (trackingID) => {
  // Assuming googleAnalyticsScript has been imported and executed elsewhere in the app
};

export const PageView = () => {
  window.gtag && window.gtag('event', 'page_view', {
    'page_location': window.location.href,
    'page_path': window.location.pathname + window.location.search
  });
};

export const Event = (eventName, params) => {
  window.gtag && window.gtag('event', eventName, params);
};
