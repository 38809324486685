import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import './Error404.css';

class Error404 extends React.Component {
  render() {
    return (
      <Row className="error-404-container">
        <Col mdOffset={3} md={6} >
          <h3>Not Found</h3>
          <div className="error-message">
            {'It looks like the page you are looking for could not be found.'}<br />
            {'To return home click the button below.'}
          </div>
          <Button className="home-button" onClick={() => this.props.history.push('/home')}>
            Take me home
          </Button>
        </Col>
      </Row>
    );
  }
}

export default withRouter(Error404);
