import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import '../SignUpNew.css';
import config from '../../../../constant/config';

class PageSuggestionPers extends Component {

  addHub(hub) {
    const {
      selectedHubs,
      suggestedHubs,
      setSelectedHubs,
    } = this.props;

    const selectedIds = selectedHubs.map(hub => hub.id);
    const suggestedIds = suggestedHubs.map(hub => hub.id);

    if (!suggestedIds.includes(hub.id)) return;

    if (selectedIds.includes(hub.id)) {
      const index = selectedIds.indexOf(hub.id);
      selectedHubs.splice(index, 1);
      setSelectedHubs(selectedHubs);
    } else {
      setSelectedHubs([...selectedHubs, hub]);
    }
  }

  renderHubs() {
    const {
      suggestedHubs,
      selectedHubs,
    } = this.props;

    const selectedIds = selectedHubs.map(hub => hub.id);

    if (suggestedHubs.length > 0) {
      return suggestedHubs.slice(0, 3).map(hub => (
        <div key={hub.id}>
          <div className="sug-hub-logo sub-hub-logo-pers">
            <img src={`${config.spacesURL}/${hub.logoId}.thumbnail`}  alt={`${hub.name} icon`}  />
            <div className='sug-hub-name'>{hub.name}</div>
          </div>
          <div
            className={selectedIds.includes(hub.id) ? "sug-hub-follow-btn sug-hub-follow-btn-pers-selected" : "sug-hub-follow-btn sug-hub-follow-btn-pers"}
            onClick={() => this.addHub(hub)}
          >
            {!selectedIds.includes(hub.id) ? 'Follow +' : 'Followed ✓'}
          </div>
        </div>
      ));
    }

    return <h3>Unable to find any matching hubs.</h3>
  }

  render() {
    const { handleButtonClick, handleSignup } = this.props;

    return (
      <div className="no-padding">
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              Recommended hubs
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              Thanks for your answers! Based on your answers, we recommend you follow these members of the Actify community:
            </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour suggested-hubs-container">
          {this.renderHubs()}
        </div>

        <div className="navigation-buttons no-colour mt-20">
          <div className="back">
            <Button
              className="navigation-button"
              onClick={() => handleButtonClick(true)}
            >
              ← Back
            </Button>
          </div>
          <div className="next">
            <Button
              className="navigation-button finish-pers"
              onClick={() => handleSignup()}
            >
              Finish! ⚐
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PageSuggestionPers;
