import React, { Component } from 'react';
import { Checkbox } from 'react-bootstrap';
import config from '../../constant/config';
import './UserTile.css';

class UserTile extends Component {
  render() {
    return (
      <div className="user-tile-container">
        <div className="user-tile-top">
          <div
            className="avatar-container"
            style={{ backgroundImage: `url(${config.spacesURL}/${this.props.image}.thumbnail)` }}
            onClick={() => this.props.userClick(this.props.user)}
          />
          {this.props.selectable &&
            <Checkbox
              className="checkbox"
              onChange={() => this.props.onCheckboxClick(this.props.id)}
            />
          }
        </div>
        <div className="user-name-container">
          <div className="user-link" onClick={() => this.props.userClick(this.props.user)}>
            {this.props.hubName}
          </div>
        </div>
      </div>

    );
  }
}

export default UserTile;
