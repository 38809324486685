import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Quizzes from './Quizzes';
import { addQuizResult } from '../../store/actions';

const mapStateToProps = state => ({
  user: state.user,
  quizResults: state.userQuizResults,
});

const mapDispatchToProps = dispatch => ({
  addQuizResult: (result) => {
    dispatch(addQuizResult(result));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quizzes));
