import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { Link } from 'react-router-dom';
import config from '../../constant/config';
import { privacySelector } from '../../lib/utilities';
import './Tile.css';
import fileImage from '../../resources/img/FileImage.svg'

class PlanTile extends Component {
  getSize() {
    if (!this.props.carousel) {
      return 'tile-container';
    }
    return 'tile-container carousel';
  }

  render() {
    return (
      <div className={this.getSize()}>
        <Link to={`/${this.props.hubPathName}/plan/${this.props.id}`} className="tile-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.image})` }} />
        <Link to={`/${this.props.hubPathName}/plan/${this.props.id}`} className="file-background" style={{ backgroundImage: `url(${fileImage}`}}>
          <div className="module-name-container" >
            <Link to={`/${this.props.hubPathName}/plan/${this.props.id}`} className="plan-name">
              <Dotdotdot clamp={2}>
                {privacySelector(this.props.accessId)}
                {this.props.moduleTitle}
              </Dotdotdot>
            </Link>
          </div>
        </Link>
      </div>
    );
  }
}

export default PlanTile;
