import * as types from './action-types';

export const toggleLogin = loggedIn => ({ type: types.TOGGLE_LOGIN, loggedIn });
export const setUser = user => ({ type: types.SET_USER, user });
export const toggleSideBar = () => ({ type: types.TOGGLE_SIDE_BAR });
export const setBookmarks = bookmarks => ({ type: types.SET_BOOKMARKS, bookmarks });
export const addBookmark = bookmark => ({ type: types.ADD_BOOKMARK, bookmark });
export const addQuizResult = result => ({ type: types.ADD_QUIZ_RESULT, result });
export const setQuizResults = results => ({ type: types.SET_QUIZ_RESULTS, results });
export const removeBookmark = moduleId => ({ type: types.REMOVE_BOOKMARK, moduleId });
export const setNotifications = notifications => ({ type: types.SET_NOTIFICATIONS, notifications });
export const setActualNotifications = notifications => ({ type: types.SET_ACTUAL_NOTIFICATIONS, notifications });
export const followHub = hub => ({ type: types.FOLLOW_HUB, hub });
export const unfollowHub = hubId => ({ type: types.UNFOLLOW_HUB, hubId });
export const removeHub = hubId => ({ type: types.REMOVE_HUB, hubId });
export const addHub = hub => ({ type: types.ADD_HUB, hub });
export const setActiveProfileTab = activeTab => ({ type: types.SET_ACTIVE_PROFILE_TAB, activeTab });
export const setActiveRole = activeRole => ({ type: types.SET_ACTIVE_ROLE, activeRole });
export const setConversation =  conversationId =>  ({ type: types.SET_CONVERSATION_ID, conversationId });
