import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import '../SignUpNew.css';

class PageFivePers extends Component {

  onGoalClick(goal) {
    const { chosenPersonalGoals, setChosenPersonalGoals } = this.props;

    if (chosenPersonalGoals.includes(goal)) {
      const index = chosenPersonalGoals.indexOf(goal);
      chosenPersonalGoals.splice(index, 1);
      setChosenPersonalGoals(chosenPersonalGoals);
    } else {
      setChosenPersonalGoals([...chosenPersonalGoals, goal]);
    }
  }

  renderOptions() {
    const { personalGoalsOptions, chosenPersonalGoals } = this.props;

    return personalGoalsOptions.map(goal => (
      <div
        key={goal.id}
        className={chosenPersonalGoals.includes(goal) ? "pers-colour pers-selected" : "pers-colour"}
        onClick={() => this.onGoalClick(goal)}
      >
        <span className="option">{goal.description}</span>
        { chosenPersonalGoals.includes(goal) &&
          <div className="selected-option" />
        }
      </div>
    ));
  }

  render() {
    const {
      handleButtonClick,
      chosenPersonalGoals,
      personalGoalsOptions
    } = this.props;

    const { skipValidation } = this.props;
    const validGoals = chosenPersonalGoals.every(role => personalGoalsOptions.includes(role));
    const valid = validGoals && chosenPersonalGoals.length > 0;

    return (
      <div className="no-padding">
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              What are your physical activity goals?
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              Goals are a great way to progress your physical activity journey. Feel free to choose more than one!
            </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour row">
          <div className="options-container big-options">
            {this.renderOptions()}
          </div>
        </div>

        <div className="navigation-buttons no-colour mt-20">
          <div className="back">
            <Button
              className="navigation-button"
              onClick={() => handleButtonClick(true)}
            >
              ← Back
            </Button>
          </div>
          {(!valid) &&
            <div className="next">
              <Button
                className="navigation-button"
                onClick={() => handleButtonClick()}
              >
                Skip →
              </Button>
            </div>
          }
          {(valid || skipValidation) &&
            <div className="next">
              <Button
                className="navigation-button"
                onClick={() => handleButtonClick()}
              >
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageFivePers;
