import React from 'react';
import { Modal, Row, Col, Checkbox } from 'react-bootstrap';
import config from '../../constant/config';
import Section from '../common/Section';
import CarouselSection from '../common/CarouselSection';
import checkStatus from '../common/checkStatus';
import UserDetails from '../common/UserDetails';
import './Result.css';

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      filteredModules: [],
      modulePriority: {},
      hubs: [],
      filteredHubs: [],
      users: [],
      categories: [],
      categoriesSelected: [],
      targetGroups: [],
      targetGroupsSelected: [],
      activities: [],
      activitiesSelected: [],
      query: this.props.match.params,
      showModal: false,
      modalContent: null,
      showFilter: false,
      isAdmin: false,
      adminHubs: [],
      failMessage: 'Searching...',
      modulesExpanded: false,
      hubsExpanded: false,
      usersExpanded: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
    this.toggleModule = this.toggleModule.bind(this);
    this.toggleHub = this.toggleHub.bind(this);
    this.toggleUser = this.toggleUser.bind(this);
    this.generateSearchResults = this.generateSearchResults.bind(this);

  }

  componentDidMount() {
    const { search } = this.props.match.params;
    this.getSearchResults(search.replace('%23', '#'));
    this.getCategories();
    this.getTargetGroups();
    this.getActivities();
    this.getAdminHubs();
  }

  componentDidUpdate() {
    if (this.props.location.query !== this.state.query) {
      this.getSearchResults(this.props.location.query);
    }
  }

  onUserClick(user) {
    let canInvite = false;
    if (this.state.isAdmin) {
      this.state.adminHubs.forEach((adminHub) => {
        if (!user.hubs.find(h => h.id === adminHub.id)) {
          canInvite = true;
        }
      });
    }
    this.setState({
      showModal: true,
      modalContent: <UserDetails admin={canInvite} adminHubs={this.state.adminHubs} modalUser={user} />,
    });
  }

  async getAdminHubs() {
    const res = await fetch(`${config.apiURL}/user/admin`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 200) {
      const userHubs = await res.json();
      const adminHubs = userHubs.map(userHub => userHub.hub);
      this.setState({
        adminHubs,
        isAdmin: adminHubs.length > 0,
      });
    }
  }

  async getTargetGroups() {
    const res = await fetch(`${config.apiURL}/targetgroup`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 200) {
      const targetGroups = await res.json();
      this.setState({
        targetGroups,
      });
    }
  }

  async getCategories() {
    const res = await fetch(`${config.apiURL}/category`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 200) {
      const categories = await res.json();
      this.setState({
        categories,
      });
    }
  }

  async getActivities() {
    const res = await fetch(`${config.apiURL}/activity`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 200) {
      const activities = await res.json();
      this.setState({
        activities,
      });
    }
  }

  async getSearchResults(query) {
    if (query === 'all') {
      const res = await fetch(`${config.apiURL}/hub/`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const results = await res.json();
        this.setState({
          query,
          hubs: results,
          filteredHubs: results,
        });
      }
    } else if (query) {
      const res = await fetch(`${config.apiURL}/search`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          query,
        }),
      });
      if (res.status === 200) {
        const results = await res.json();
        this.setState({
          query,
          modules: results.modules ? results.modules : [],
          filteredModules: results.modules ? results.modules : [],
          modulePriority: results.modulePriority ? results.modulePriority : {},
          hubs: results.hubs ? results.hubs : [],
          filteredHubs: results.hubs ? results.hubs : [],
          users: results.users ? results.users : [],
          failMessage: 'Sorry, no results found :( Try a different query',
        });
      }
    }
  }

  applyFilters() {
    this.setState({ filteredModules: this.state.modules, filteredHubs: this.state.hubs });
    this.applyGroupFilter();
    this.applyActivityFilter();
    this.applyCategoryFilter();
  }

  applyCategoryFilter() {
    if (this.state.categoriesSelected.length === 0) {
      this.setState({ filteredModules: this.state.filteredModules });
      return;
    }
    const filteredModules = this.state.filteredModules.filter((module) => {
      return this.state.categoriesSelected.includes(module.categoryId);
    });
    this.setState({ filteredModules });
  }

  applyGroupFilter() {
    if (this.state.targetGroupsSelected.length === 0) {
      this.setState({ filteredHubs: this.state.filteredHubs });
      return;
    }
    if (this.state.filteredModules.length > 0) {
      const filteredModules = this.state.filteredModules.filter((module) => {
        if (module.targetGroups !== undefined && module.targetGroups.length > 0) {
          const moduletargetGroupsIds = module.activities.map(group => group.id);
          moduletargetGroupsIds.push(module.hub.targetGroupId);
          const matchedGroups = moduletargetGroupsIds.filter(id => this.state.targetGroupsSelected.includes(id));
          if (matchedGroups.length > 0) {
            return true;
          }
          return false;
        }
        return this.state.targetGroupsSelected.includes(module.hub.targetGroupId);
      });
      this.setState({ filteredModules });
    }
    if (this.state.filteredHubs.length > 0) {
      const filteredHubs = this.state.filteredHubs.filter((hub) => {
        return this.state.targetGroupsSelected.includes(hub.targetGroupId);
      });
      this.setState({ filteredHubs });
    }
  }

  applyActivityFilter() {
    if (this.state.activitiesSelected.length === 0) {
      this.setState({ filteredHubs: this.state.filteredHubs });
      return;
    }
    if (this.state.filteredModules.length > 0) {
      const filteredModules = this.state.filteredModules.filter((module) => {
        if (module.activities !== undefined && module.activities.length > 0) {
          const moduleActivitiesIds = module.activities.map(activity => activity.id);
          moduleActivitiesIds.push(module.hub.activityId);
          const matchedActivities = moduleActivitiesIds.filter(id => this.state.activitiesSelected.includes(id));
          if (matchedActivities.length > 0) {
            return true;
          }
          return false;
        }
        return this.state.activitiesSelected.includes(module.hub.activityId);
      });
      this.setState({ filteredModules });
    }
    if (this.state.filteredHubs.length > 0) {
      const filteredHubs = this.state.filteredHubs.filter((hub) => {
        return this.state.activitiesSelected.includes(hub.activityId);
      });
      this.setState({ filteredHubs });
    }
  }

  async handleCategoryCheckboxClick(e) {
    const index = this.state.categoriesSelected.indexOf(parseInt(e, 10));
    if (index === -1) {
      const categoriesSelected = [...this.state.categoriesSelected, parseInt(e, 10)];
      await this.setState({ categoriesSelected });
    } else {
      const { categoriesSelected } = this.state;
      categoriesSelected.splice(index, 1);
      await this.setState({ categoriesSelected });
    }
    this.applyFilters();
  }

  async handleGroupCheckboxClick(e) {
    const index = this.state.targetGroupsSelected.indexOf(parseInt(e, 10));
    if (index === -1) {
      const targetGroupsSelected = [...this.state.targetGroupsSelected, parseInt(e, 10)];
      await this.setState({ targetGroupsSelected });
    } else {
      const { targetGroupsSelected } = this.state;
      targetGroupsSelected.splice(index, 1);
      await this.setState({ targetGroupsSelected });
    }
    this.applyFilters();
  }

  async handleActivityCheckboxClick(e) {
    const index = this.state.activitiesSelected.indexOf(parseInt(e, 10));
    if (index === -1) {
      const activitiesSelected = [...this.state.activitiesSelected, parseInt(e, 10)];
      await this.setState({ activitiesSelected });
    } else {
      const { activitiesSelected } = this.state;
      activitiesSelected.splice(index, 1);
      await this.setState({ activitiesSelected });
    }
    this.applyFilters();
  }

  closeModal() {
    this.setState({ showModal: false, modalContent: null });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  toggleFilter() {
    this.setState({ showFilter: !this.state.showFilter });
  }

  renderFilters() {
    return [
      <Col md={2} sm={6}>
        <div>
          <h3 className="category-title">Category</h3>
        </div>
        {this.state.categories.map((category) => {
          return (
            <Checkbox key={category.id} id={category.id} onChange={e => this.handleCategoryCheckboxClick(e.target.id)}>{category.description}</Checkbox>
          );
        })}
      </Col>,
      <Col className="target-group-col" md={2} sm={6}>
        <div>
          <h3 className="category-title">Target Group</h3>
        </div>
        {this.state.targetGroups.map((group) => {
          return (
            <Checkbox key={group.id} id={group.id} onChange={e => this.handleGroupCheckboxClick(e.target.id)}>{group.description}</Checkbox>
          );
        })}
      </Col>,
      <Col className="activities-col" md={8}>
        <div>
          <h3 className="category-title">Activities</h3>
        </div>
        <Row>
          {this.state.activities.map((activity) => {
            return (
              <Col sm={3} xs={4}>
                <Checkbox className="activity-check" key={activity.id} id={activity.id} onChange={e => this.handleActivityCheckboxClick(e.target.id)}>{activity.description}</Checkbox>
              </Col>
            );
          })}
        </Row>
      </Col>,
    ];
  }

  toggleModule(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ modulesExpanded: !this.state.modulesExpanded });
  }
  toggleHub(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ hubsExpanded: !this.state.hubsExpanded });
  }

  toggleUser(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ usersExpanded: !this.state.usersExpanded });
  }

  generateSearchResults(e) {
    if (this.state.filteredHubs.length > 0 && this.state.filteredModules.length === 0 && this.state.users.length === 0)
    {
      return (<Section context="hubs" tiles={this.state.filteredHubs} title="Hubs" />)
    }
    else if (this.state.filteredHubs.length === 0 && this.state.filteredModules.length > 0 && this.state.users.length === 0)
    {
      return (<Section context="modules" tiles={this.state.filteredModules} title="Modules" />)

    }
    else if (this.state.filteredHubs.length === 0 && this.state.filteredModules.length === 0 && this.state.users.length > 0)
    {
      return (<Section context="users" tiles={this.state.users} title="Users" userClick={user => this.onUserClick(user)} small />)
    }
    return (
      <div>
        {(this.state.filteredModules.length > 0) && (this.state.modulesExpanded ? <Section context="modules" tiles={this.state.filteredModules} title="Modules" sectionLinkText="Shrink" sectionLink="/result/all" onClick={this.toggleModule} /> : <CarouselSection context="modules" tiles={this.state.filteredModules} title="Modules" sectionLinkText="Expand" sectionLink="/result/all" onClick={this.toggleModule} /> )}
        {(this.state.filteredHubs.length > 0) &&  (this.state.hubsExpanded ? <Section context="hubs" tiles={this.state.filteredHubs} title="Hubs" sectionLinkText="Shrink" sectionLink="/result/all" onClick={this.toggleHub} /> : <CarouselSection context="hubs" tiles={this.state.filteredHubs} title="Hubs" sectionLinkText="Expand" sectionLink="/result/all" onClick={this.toggleHub} />)}
        {(this.state.users.length > 0) &&  (this.state.usersExpanded ? <Section context="users" tiles={this.state.users} title="Users" userClick={user => this.onUserClick(user)} small sectionLinkText="Shrink" sectionLink="/result/all" onClick={this.toggleUser} /> : <CarouselSection context="users" tiles={this.state.users} title="Users" userClick={user => this.onUserClick(user)} small  sectionLinkText="Expand" sectionLink="/result/all" onClick={this.toggleUser} />)}
      </div>
    );
  }
  render() {
    const resultCount = this.state.filteredHubs.length + this.state.filteredModules.length + this.state.users.length;
    return (
      <div className="results-container">
        <div className="heading-container">
          <div className="header-div">
            <div className="text-container">
              {resultCount === 0 ? <h3>{this.state.failMessage}</h3> : this.state.query === 'all' ? <h3>{`${resultCount} total hub${resultCount === 1 ? '' : 's'}`}</h3> : <h3>{`${resultCount} results for "${this.state.query}"`}</h3>}
            </div>
            <div className="filter-toggle-container" onClick={e => this.toggleFilter(e)}>
              <i className={this.state.showFilter ? 'fa fa-minus fa-2x' : 'fa fa-plus fa-2x'} aria-hidden="true"><p className="filter-text">Filter</p></i>
            </div>
          </div>
        </div>
        <div className={this.state.showFilter ? 'filters-container expanded' : 'filters-container hid'}>
          <Row className="filters-row">
            {this.renderFilters()}
          </Row>
        </div>
        <div className="display-results-container">
          {this.generateSearchResults()}
        </div>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {this.state.modalContent}
        </Modal>
      </div>
    );
  }
}

export default checkStatus(Result);
