import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import ManageFeaturedHubs from '../common/ManageFeaturedHubs';
import ManageFeaturedModules from '../common/ManageFeaturedModules';
import ManageCustom from '../common/ManageCustom';
import ManageHubs from '../common/ManageHubs';
import ManageUsers from '../common/ManageUsers';
import ManageFaq from '../common/ManageFaq';
import checkStatus from '../common/checkStatus';
import SiteAnalytics from '../common/SiteAnalytics';
import config from '../../constant/config';
import './ManageSite.css';

const fetchHubs = async () => {
  const res = await fetch(`${config.apiURL}/hub/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchFeaturedHubs = async () => {
  const res = await fetch(`${config.apiURL}/hub/featured`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchModules = async () => {
  const res = await fetch(`${config.apiURL}/module/public`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchFeaturedModules = async () => {
  const res = await fetch(`${config.apiURL}/module/featured`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchCustomTitle = async () => {
  const res = await fetch(`${config.apiURL}/custom`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchCustomModules = async () => {
  const res = await fetch(`${config.apiURL}/module/custom`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchUsers = async () => {
  const res = await fetch(`${config.apiURL}/user/all`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchFaq = async () => {
  const res = await fetch(`${config.apiURL}/faq`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

class ManageSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hubs: [],
      users: [],
      featuredHubs: [],
      hubOptions: null,
      modules: [],
      featuredModules: [],
      nonFeaturedModules: [],
      customModules: [],
      nonCustomModules: [],
      faq: [],
      customTitle: '',
      error: null,
      activeTab: props.activeManageSiteTab || 0,
    };
  }

  componentWillMount() {
    this.getHubData();
    this.getModuleData();
    this.getFaq();
    this.getUsers();
  }

  async getHubData() {
    const hubs = await fetchHubs();
    const featuredHubs = await fetchFeaturedHubs();
    const featuredHubsIds = featuredHubs.map(hub => hub.id);
    const nonFeaturedHubs = hubs.filter(hub => featuredHubsIds.indexOf(hub.id) === -1);
    const hubOptions = nonFeaturedHubs.map(hub =>
      <option key={hub.id} value={hub.id}>{hub.name}</option>);

    this.setState({
      hubs,
      hubOptions,
      featuredHubs,
    });
  }

  async getModuleData() {
    const modules = await fetchModules();
    const featuredModules = await fetchFeaturedModules();
    const featuredModulesIds = featuredModules.map(featured => featured.moduleId);
    const nonFeaturedModules = modules.filter(module => featuredModulesIds.indexOf(module.id) === -1);
    const customTitle = await fetchCustomTitle();
    const customModules = await fetchCustomModules();
    const customModulesIds = customModules.map(custom => custom.moduleId);
    const nonCustomModules = modules.filter(module => customModulesIds.indexOf(module.id) === -1);

    this.setState({
      modules,
      featuredModules,
      nonFeaturedModules,
      customModules,
      nonCustomModules,
      customTitle: customTitle.title,
    });
  }

  async getUsers() {
    const users = await fetchUsers();
    this.setState({ users, error: null });
  }

  async getFaq() {
    const faq = await fetchFaq();
    this.setState({ faq });
  }

  async removeFeaturedHub(hub) {
    try {
      const res = await fetch(`${config.apiURL}/hub/featured`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: hub.id,
        }),
      });
      if (res.status === 200) {
        this.getHubData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async addFeaturedHub(hubId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/featured`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: hubId,
        }),
      });
      if (res.status === 200) {
        this.getHubData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async removeModule(moduleId, moduleType) {
    try {
      const res = await fetch(`${config.apiURL}/module/${moduleType}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: moduleId,
        }),
      });
      if (res.status === 200) {
        this.getModuleData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async addModules(moduleIds, moduleType) {
    moduleIds.forEach(async (moduleId) => {
      try {
        const res = await fetch(`${config.apiURL}/module/${moduleType}`, {
          credentials: 'include',
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            id: moduleId,
          }),
        });
        if (res.status === 200) {
          this.getModuleData();
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  async handleSearch(query, moduleType) {
    if (query) {
      const res = await fetch(`${config.apiURL}/search/module/${moduleType}`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          query,
        }),
      });
      if (res.status === 200) {
        const results = await res.json();
        this.setState({
          modules: results,
        });
      }
    }
  }

  async removeUser(userId) {
    const res = await fetch(`${config.apiURL}/user/`, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id: userId,
      }),
    });
    if (res.status === 200) {
      this.getUsers();
    }
    if (res.status === 400) {
      const body = await res.json();
      const uniqueOwnerHub = body.uniqueOwnerHub.hub;
      if (body.message === 'unique_owner') {
        this.setState({ error: <p>User is owner of <Link to={`/${uniqueOwnerHub.pathName}`}>{uniqueOwnerHub.name}</Link>, please delete Hub before removing user</p> });
      }
    }
  }

  async removeFaq(id) {
    const res = await fetch(`${config.apiURL}/faq/${id}`, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 200) {
      this.getFaq();
    }
  }

  async editFaq(id, question, answer, type) {
    const res = await fetch(`${config.apiURL}/faq/${id}`, {
      credentials: 'include',
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        question,
        answer,
        type,
      }),
    });
    if (res.status === 200) {
      this.getFaq();
    }
  }

  async addFaq(question, answer, type) {
    const res = await fetch(`${config.apiURL}/faq`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        question,
        answer,
        type,
      }),
    });
    if (res.status === 200) {
      this.getFaq();
    }
  }

  async handleUpdateTitle(title) {
    this.setState({ customTitle: title });
    try {
      const res = await fetch(`${config.apiURL}/custom`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          title,
        }),
      });
      if (res.status === 200) {
        this.getHubData();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async exportData(type) {
    var currentdate = new Date();
    var datetime = ('0' + currentdate.getDate()).slice(-2)
      + ('0' + (currentdate.getMonth()+1)).slice(-2)
      + currentdate.getFullYear() + "-"
      + currentdate.getHours()
      + currentdate.getMinutes()
    try {
      const res = await fetch(`${config.apiURL}/module/export`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const data = await res.json();
        let dataFiltered = data.map(m => {
          m.access = m.access.level;
          m.activities = m.activities.map(a => a.description);
          m.targetGroups = m.targetGroups.map(t => t.description);
          m.category = m.category.description;
          m.hub = m.hub.name;
          m.createdBy = `${m.createdBy.firstName} ${m.createdBy.surname}`;
          m.hashtags = [...m.textContent.matchAll(/#\w+/g), ...m.title.matchAll(/#\w+/g), ...m.description.matchAll(/#\w+/g)].map(f => f[0]);
          delete m.textContent;
          return m;
        });
        let csv = '';
        let header = Object.keys(dataFiltered[0]).join(',');
        let values = dataFiltered.map(o => Object.values(o).map(v => `"${v}"`).join(',')).join('\n');
        csv += header + '\n' + values;
        const blob = new Blob([csv], {
          type: "data:text/csv;charset=utf-8,",
        });
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "module_export_" + datetime + ".csv";
        a.click();
        a.remove();
      }
    } catch (err) {
      console.log(err);
    }
  }

  updateTab(key) {
    this.setState({ activeTab: key });
  }

  render() {
    return (
      <div className="manage-site">
        <Tabs activeKey={this.state.activeTab} onSelect={key => this.updateTab(key)} id="controlled-tab-example">
          <Tab eventKey={0} title="Site Analytics">
            <h3>Site Analytics</h3>
            <hr />
            <SiteAnalytics />
          </Tab>

          <Tab eventKey={1} title="Hubs">
            <h3>Hubs</h3>
            <hr />
            <ManageFeaturedHubs
              featured={this.state.featuredHubs}
              hubs={this.state.hubs}
              hubOptions={this.state.hubOptions}
              removeFeaturedClick={hub => this.removeFeaturedHub(hub)}
              addFeaturedClick={hub => this.addFeaturedHub(hub)}
            />
            <ManageHubs />
          </Tab>

          <Tab eventKey={2} title="Modules">
            <h3>
              Modules
              { ' ' }
              <div className="export-data" onClick={() => this.exportData('module')}>
                (export data)
              </div>
            </h3>
            <hr />
            <ManageFeaturedModules
              featured={this.state.featuredModules}
              modules={this.state.modules}
              nonFeaturedModules={this.state.nonFeaturedModules}
              handleModuleSearch={query => this.handleSearch(query, 'featured')}
              removeFeaturedClick={moduleId => this.removeModule(moduleId, 'featured')}
              addFeaturedClick={moduleIds => this.addModules(moduleIds, 'featured')}
            />
            <ManageCustom
              title={this.state.customTitle}
              custom={this.state.customModules}
              modules={this.state.modules}
              nonCustomModules={this.state.nonCustomModules}
              handleModuleSearch={query => this.handleSearch(query, 'custom')}
              removeCustomClick={moduleId => this.removeModule(moduleId, 'custom')}
              addCustomClick={moduleIds => this.addModules(moduleIds, 'custom')}
              updateCustomTitle={title => this.handleUpdateTitle(title)}
            />
          </Tab>
          <Tab eventKey={3} title="FAQ">
            <h3>Frequently Asked Questions</h3>
            <ManageFaq
              faq={this.state.faq}
              editClick={(id, question, answer, type) => this.editFaq(id, question, answer, type)}
              addClick={(question, answer, type) => this.addFaq(question, answer, type)}
              removeClick={id => this.removeFaq(id)}
            />
          </Tab>

          <Tab eventKey={4} title="Users">
            <h3>Users</h3>
            <hr />
            <ManageUsers
              users={this.state.users}
              removeUser={userId => this.removeUser(userId)}
              error={this.state.error}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default checkStatus(ManageSite);
