import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import getStarted from '../../resources/img/lets_get_started.svg'
import explore from '../../resources/img/explore_icon.svg'
// import walkthroughIcon from '../../resources/img/walkthrough.png'

class UserWalkthroughPageSeven extends Component {

render() {
    return (
        <div className = 'walkthrough'>
            <div className='top-section'>
                <div style={{width:'55%', textAlign: 'center', margin: '0 auto'}}>
                    <p className='heading'> Let's get started! </p>
                </div>
                <div>
                    <p className='subheading'>
                        We're excited to welcome you to the Actify platform. Feel free to view this walkthrough
                        in your profile page any time. Ready to get started?
                    </p>
                    <img src={getStarted} alt="Let's get started" width='65%' style={{marginTop: '2.5em'}}/>
                </div>
            </div>
            <Button className='walkthrough-button' onClick={() => this.props.history.push('/explore') } style={{marginBottom: "2.5em"}}>
                Explore <img src={explore} alt="Explore Icon" width='25px' style={{marginLeft: '5px' }}/>
            </Button>
        </div>
    );
  }
}

export default withRouter(UserWalkthroughPageSeven);
