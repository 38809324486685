import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import { autoRehydrate, persistStore } from 'redux-persist';
import localForage from 'localforage';
import Logger from 'redux-logger';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './index.css';
import App from './App';
import reducers, { initialState } from './store/reducers';

const middlewares = [];
if (process.env.NODE_ENV === 'development') {
  // Redux will log all changes into console
  middlewares.push(Logger);
}

const store = createStore(
  reducers, initialState,
  compose(
    autoRehydrate(),
    applyMiddleware(...middlewares),
  ),
);

const configureStore = () => (new Promise((resolve, reject) => {
  try {
    persistStore(store, {
      storage: localForage,
    }, () => {
      store.subscribe(async () => {
        // ensure persist here

      });
      resolve(store);
    });
  } catch (err) {
    reject(err);
  }
}));

const init = async () => {
  const configuredStore = await configureStore();
  ReactDOM.render(
    <Provider store={configuredStore}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
};

init();
//registerServiceWorker();
