import React, { Component } from 'react';
import { FormControl, FormGroup, ControlLabel, Button, HelpBlock } from 'react-bootstrap';
import config from '../../../constant/config';
import './CreateDragQuestion.css';


class CreateDragQuestion extends Component {
  constructor(props) {
    super(props);
    let initialState = {
      validQuestionText: true,
      questionText: '',
      questionWords: [],
      editableWordCountTooHigh: false,
      editableWordCountTooSmall: false,
      editableWords: [],
      activePage: 1,
    };
    if (props.questionToEditContent){
      initialState.questionText = props.questionToEditContent.text || props.questionToEditContent.questionText;
      initialState.editableWords = props.questionToEditContent.editableWords;
    }

    this.state = initialState;
  }

  async onClickNext() {
    const { questionText } = this.state;
    if (this.state.activePage === 1) {
      if (!this.validPageOne()) {
        return;
      }
      this.setState({
        activePage: 2,
      });
    } else {
      if (!this.validPageTwo()) {
        return;
      }
      const question = {
        questionText,
        editableWords: this.state.editableWords,
        questionType: config.questionTypes.DRAG,
      };
      if (this.props.editIndex == null) {
        this.props.addQuestion(question);
      }else{
        this.props.editQuestion(this.props.editIndex, question);
      }

    }
  }

  onWordClick(index) {
    const { editableWords } = this.state;
    const alreadySelectedIndex = editableWords.findIndex(i => i === index);
    if (alreadySelectedIndex !== -1) {
      editableWords.splice(alreadySelectedIndex, 1);
    } else {
      editableWords.push(index);
    }
    this.setState({
      editableWords,
      editableWordCountTooHigh: false,
      editableWordCountTooSmall: false,
    });
  }

  validPageOne() {
    const splitAndTrim = this.state.questionText.trim().split(' ').filter(w => !!w && w !== ' ');
    const validatedQuestionText = splitAndTrim.join(' ');
    const validQuestionText = splitAndTrim.length > 1;
    this.setState({
      validQuestionText,
      questionText: validatedQuestionText,
      questionWords: splitAndTrim,
    });
    return validQuestionText;
  }

  validPageTwo() {
    const wordCount = this.state.questionText.split(' ').filter(w => !!w).length;
    const editableWordsCount = this.state.editableWords.length;
    const editableWordCountTooSmall = !(editableWordsCount > 0);
    const editableWordCountTooHigh = editableWordsCount >= wordCount;
    this.setState({
      editableWordCountTooHigh,
      editableWordCountTooSmall,
    });
    return !(editableWordCountTooHigh || editableWordCountTooSmall);
  }

  renderWords(word, index) {
    if (this.state.editableWords.includes(index)) {
      return (
        <div key={index} className="question-word-space" />
      );
    }
    return (
      <div key={index} className="question-word" onClick={() => this.onWordClick(index)}>{word}</div>
    );
  }

  renderPageOne() {
    return (
      <div className="page-1-container">
        <FormGroup className="form" controlId="title" validationState={!this.state.validQuestionText ? 'error' : null}>
          <ControlLabel>Question Text</ControlLabel>
          <FormControl
            className="form-input"
            componentClass="textarea"
            value={this.state.questionText}
            onChange={e => this.setState({ questionText: e.target.value })}
          />
          {!this.state.validQuestionText && <HelpBlock>The question text must contain at least two words</HelpBlock>}
        </FormGroup>
      </div>);
  }

  renderPageTwo() {
    const { editableWordCountTooHigh, editableWordCountTooSmall } = this.state;
    return (
      <div className="page-2-container">
        <p className="instruction-text">Select word(s) below which will appear blank to user:</p>
        <FormGroup validationState={(editableWordCountTooHigh || editableWordCountTooSmall) ? 'error' : null}>
          <div className="words-container">
            {this.state.questionWords.map((word, index) => this.renderWords(word, index))}
          </div>
          {editableWordCountTooSmall && <HelpBlock>At least one blank word is required</HelpBlock>}
          <p className="instruction-text">Draggable words:</p>
          <div className="words-container">
            {this.state.editableWords.map(index => <div key={index} className="draggable" onClick={() => this.onWordClick(index)}>{this.state.questionWords[index]}</div>)}
          </div>
          {editableWordCountTooHigh && <HelpBlock>Too many blank words</HelpBlock>}
        </FormGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="create-drag-question">
        {this.state.activePage === 1 &&
          this.renderPageOne()
        }
        {this.state.activePage === 2 &&
          this.renderPageTwo()
        }
        <div className="modal-controls">
          <Button className="modal-control modal-button" id="done" onClick={e => this.onClickNext(e)}>{this.state.activePage === 1 ? 'Next' : 'Done'}</Button>
        </div>
      </div>
    );
  }
}

export default CreateDragQuestion;
