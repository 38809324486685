import { connect } from 'react-redux';
import Quiz from './Quiz';
import { addQuizResult, setNotifications, setQuizResults } from '../../../store/actions';

const mapStateToProps = state => ({
  user: state.user,
  quizResults: state.userQuizResults,
});

const mapDispatchToProps = dispatch => ({
  addQuizResult: (result) => {
    dispatch(addQuizResult(result));
  },
  setNotifications: (notifications) => {
    dispatch(setNotifications(notifications));
  },
  setQuizResults: (results) => {
    dispatch(setQuizResults(results));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
