import React, { Component } from 'react';
import { Row, Col, Button, FormGroup, ControlLabel, FormControl, HelpBlock, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { passwordValid } from '../../lib/validation';
import config from '../../constant/config';
import './ResetPassword.css';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      passwordHelpText: '',
      validPassword: true,
      confirmPassword: '',
      validPasswordConfirmation: true,
      feedback: '',
      showFeedback: false,
      showResetConfirmed: false,
    };
  }

  async onButtonClick() {
    try {
      if (this.state.showResetConfirmed) {
        this.props.history.push('/home');
        return;
      }
      const { newPassword, confirmPassword } = this.state;
      const validPassword = passwordValid(newPassword).isValid;
      const passwordMatch = (newPassword === confirmPassword);
      if (!validPassword || !passwordMatch) {
        this.setState({
          passwordHelpText: validPassword.errorMessage,
        });
        return;
      }
      const { userId, token } = this.props.match.params;
      const res = await fetch(`${config.apiURL}/user/reset/${userId}/${token}`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          newPassword,
          confirmPassword,
        }),
      });
      if (res.status === 499) {
        this.setState({
          showFeedback: true,
          feedback: 'The reset token has expired. Please use the forgotten password link during sign in.',
          newPassword: '',
          confirmPassword: '',
        });
        return;
      }
      if (res.status !== 200) {
        throw new Error();
      }
      this.setState({ showResetConfirmed: true, feedback: '', showFeedback: false });
    } catch (err) {
      this.setState({
        showFeedback: true,
        feedback: 'Unable to update password. Please try again later.',
        newPassword: '',
        confirmPassword: '',
      });
    }
  }

  render() {
    const validPassword = passwordValid(this.state.newPassword);
    const passwordHelpText = validPassword.errorMessage;
    const passwordMatch = (this.state.newPassword === this.state.confirmPassword);
    const passwordMatchText = !passwordMatch ? 'Passwords must match' : null;
    return (
      <Row className="ResetPassword">
        <Col mdOffset={4} md={4} >
          <h3>Password Reset</h3>
          {this.state.showResetConfirmed ?
            <div className="confirmation-text">
              Your password has been updated successfully. You can now log in using your new password.
            </div>
            :
            <div>
              <div className="instruction-text">
                Enter your new password for your Actify account.
              </div>
              <div className="form-container">
                <FormGroup className="form" controlId="formPassword" validationState={(!validPassword.isValid || !this.state.validPassword) ? 'error' : null}>
                  <ControlLabel>
                    Create a new password
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="password-tooltip">
                          <div className="password-tooltip">
                            <p><strong>Passwords must include:</strong></p>
                            <li>At least 8 characters</li>
                            <li>1 upper-case character</li>
                            <li>1 lower-case character</li>
                            <li>1 number</li>
                            <li>1 special character</li>
                          </div>
                        </Tooltip>
                      }
                    >
                      <i className="fa fa-info-circle password-info" />
                    </OverlayTrigger>
                  </ControlLabel>
                  <FormControl
                    className="form-input"
                    type="password"
                    value={this.state.newPassword}
                    onChange={e => this.setState({
                      newPassword: e.target.value,
                      validPassword: true,
                      passwordHelpText: '',
                      feedback: '',
                    })}
                  />
                  {(!validPassword.isValid || !this.state.validPassword) && <HelpBlock>{this.state.passwordHelpText || passwordHelpText}</HelpBlock>}
                </FormGroup>
                <FormGroup
                  className="form"
                  controlId="formPasswordConfirmation"
                  validationState={((!passwordMatch && this.state.confirmPassword.length > 0) || !this.state.validPasswordConfirmation) ? 'error' : null}
                >
                  <ControlLabel>Confirm your password</ControlLabel>
                  <FormControl
                    className="form-input"
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={e => this.setState({ confirmPassword: e.target.value, validPasswordConfirmation: true, feedback: '' })}
                  />
                  {((!passwordMatch && this.state.confirmPassword.length > 0) || !this.state.validPasswordConfirmation) && <HelpBlock>{passwordMatchText || 'Password confirmation required'}</HelpBlock>}
                  {this.state.showFeedback && <HelpBlock>{this.state.feedback}</HelpBlock>}
                </FormGroup>
              </div>
            </div>}
          <Button className="action-button" onClick={() => this.onButtonClick()}>
            {this.state.showResetConfirmed ? 'Take me home' : 'Change my password'}
          </Button>
        </Col>
      </Row>
    );
  }
}

export default ResetPassword;
