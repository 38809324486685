/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import CarouselSection from '../../common/CarouselSection';
import config from '../../../constant/config';
import './ProfileTab.css';
import infoIcon from '../../../resources/img/info-icon.png';
import walkthroughIcon from '../../../resources/img/walkthrough.png'
import InfoBox from '../InfoBox';
import { Modal } from 'react-bootstrap';
import UserWalkthrough from '../../common/UserWalkthrough.js';

class ProfileTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendedModules: [],
      showingInfo : false,
      showModal: localStorage.getItem("modal-show-again") !== 'true',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.getRecommendedModules();
  }

  async getRecommendedModules() {
    try {
      const res = await fetch(`${config.apiURL}/module/recommended`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const recommendedModules = await res.json();
        this.setState({ recommendedModules });

      }
    } catch (err) {
      console.log(err);
    }
  }


  openModal(id) {
    this.setState({ showModal: true });
  }

  closeModal(e, reloadWindow) {
    this.setState({ showModal: false });
    if (reloadWindow) {
      this.props.history.push('/');
    }
  }


  render() {

    const { recommendedModules, showModal } = this.state;
    const { following, hubs } = this.props.user;

    const handleEnter = () => {
      this.setState({showingInfo : true})
    };

    const handleLeave = () => {
      this.setState({showingInfo : false})
    };

    {console.log(showModal)}

    return (
      <div className="profile-tab-container">

        {/* Modal button to be removed */}

        <div>
          <span className="top-title">
            Recommended Modules
            <img onMouseOver={()=>handleEnter()} onMouseOut={()=>handleLeave()} className='profile-info-icon' src={infoIcon} alt='help icon' />
          </span>
          { this.state.showingInfo
          ?
          <div className='info-box'>
            <InfoBox
              headerStyle={{fontSize: '18px'}}
              bodyStyle={{fontSize: '15px'}}
              imgStyle={{width: '350px'}}
              helpHeader="What is a recommended module and what makes this recommended?"
              helpText="These modules are recommended to you based on your profile and interests."
            />
          </div>
          :
          null }
          <CarouselSection
            id="recommendedModules"
            context="modules"
            tiles={recommendedModules}
            infinite={true}
            extraClasses="remove-padding"
            noTitle
          />
        </div>

        { hubs.length > 0 &&
          <div className="my-hubs">
            <span className="title">My Hubs ({hubs.length} hub{hubs.length > 1 ? 's': ''})</span>
            <CarouselSection
              id="myHubs"
              context="hubs"
              tiles={hubs}
              infinite={true}
              extraClasses="remove-padding"
              noTitle
              expanded
            />
          </div>
        }

        <br/><br/>

        <div>
          <span className="title">Followed Hubs ({following.length} hub{following.length > 1 ? 's': ''})</span>
          <CarouselSection
            id="followingHubs"
            context="hubs"
            tiles={following}
            infinite={true}
            extraClasses="remove-padding"
            noTitle
            expanded
          />
          <div className='walkthrough-container'>
            <img onClick={() => this.openModal()} className="walkthrough-button" src={walkthroughIcon} alt='walkthrough icon' />
          </div>
        </div>
        <Modal show={showModal} onHide={this.closeModal} backdrop="static">
          <Modal.Header closeButton />
          {<UserWalkthrough handleModalClose={this.closeModal} />}
        </Modal>
      </div>

    );
  }
}

export default ProfileTab;
