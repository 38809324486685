import React, { Component } from 'react';
import {
  Button,
} from 'react-bootstrap';

import '../SignUpNew.css';

class PageFivePrac extends Component {

  onGoalClick(goal) {
    const { chosenGoals, setChosenGoals } = this.props;

    if (chosenGoals.includes(goal)) {
      const index = chosenGoals.indexOf(goal);
      chosenGoals.splice(index, 1);
      setChosenGoals(chosenGoals);
    } else {
      setChosenGoals([...chosenGoals, goal]);
    }
  }

  renderOptions() {
    const { goalOptions, chosenGoals } = this.props;

    return goalOptions.map(goal => (
      <div
        key={goal.id}
        className={chosenGoals.includes(goal) ? "prac-colour prac-selected" : "prac-colour"}
        onClick={() => this.onGoalClick(goal)}
      >
        <span style={{textAlign:"left"}} className="option">{goal.description}</span>
        { chosenGoals.includes(goal) &&
          <div className="selected-option" />
        }
      </div>
    ));
  }

  render() {
    const {
      chosenGoals,
      goalOptions,
      handleButtonClick,
    } = this.props;

    const { skipValidation } = this.props;
    const validGoals = chosenGoals.every(role => goalOptions.includes(role));
    const valid = validGoals && chosenGoals.length > 0;

    return (
        <div className="no-padding">
          <div className="title-section">
            <div>
              <p className="hlayout-title">
                What are you hoping to help others achieve?
              </p>
            </div>

            <div>
              <p className="hlayout-copy">
                Goals are a great place to start! You can check off more than one goal.
              </p>
            </div>
          </div>

          <div className="hlayout-bottom no-colour">
            <div className="options-container big-options">
              {this.renderOptions()}
            </div>
          </div>

          <div className="navigation-buttons no-colour">
            <div className="back">
              <Button className="navigation-button" onClick={() => handleButtonClick(true)}>
                ← Back
              </Button>
            </div>
            {(valid || skipValidation) &&
              <div className="next">
                <Button className="navigation-button" onClick={() => handleButtonClick()}>
                  Next →
                </Button>
              </div>
            }
          </div>
        </div>
    );
  }
}

export default PageFivePrac;
