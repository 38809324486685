import React from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import Section from '../common/Section';
import checkStatus from '../common/checkStatus';
import config from '../../constant/config';
import auth from '../../lib/auth';
import PlanHeader from '../common/PlanHeader';
import './Plan.css';
import { parseContent } from '../../lib/utilities';
import parse from 'html-react-parser';

class Plan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: [],
      hubName: '',
      categoryDescription: '',
      actionButton: null,
      showModal: false,
      deleted: false,
      hubAccess: ''
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.getPlan();
  }

  onEditPlan() {
    const { pathname } = this.props.match.params;
    this.props.history.push(`/${pathname}/plan/${this.state.plan.id}/edit`);
  }

  async onDeletePlan() {
    const { planId } = this.props.match.params;
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.state.plan.hub.id}/plan/${planId}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
       this.setState({ deleted: true });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getPlan() {
    const { planId, pathname } = this.props.match.params;
    try {
      const res = await fetch(`${config.apiURL}/hub/plan/${pathname}/${planId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const plan = await res.json();
        const isAdmin = await auth.isAuthenticatedHubAdmin(plan.hub.id);
        const actionButton = this.renderActionButton(isAdmin);
        await this.setState({
          plan,
          hubName: plan.hub.name,
          hubAccess: plan.userAccessLevel || 1,
          categoryDescription: 'Folder',
          actionButton,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  openAsset(asset) {
    window.open(`${config.spacesURL}/${asset}`);
  }

  rednerModalContent() {
    if (!this.state.deleted) {
      return (
        <div>
          <div>
            <h3>Delete Plan</h3>
          </div>
          <div>
            <p>Are you sure you wish to continue?</p>
          </div>
          <div className="button-container">
            <Button onClick={() => this.onDeletePlan()}>Yes</Button>
            <Button onClick={() => this.closeModal()}>No</Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <h3>Delete Plan</h3>
        </div>
        <div>
          <p>Plan deleted successfully</p>
        </div>
        <div className="button-container">
          <Button
            id="ok-button"
            onClick={() => {
              const { pathname } = this.props.match.params;
              this.props.history.push(`/${pathname}`);
            }}
          >Ok
          </Button>
        </div>
      </div>
    );
  }

  renderActionButton(isAdmin) {
    if (this.props.user && isAdmin) {
      return (
        <div className="action-button-container-top">
          <Button className="action-button normal" onClick={e => this.onEditPlan(e)}>Edit</Button>
          <Button className="action-button warning" onClick={e => this.openModal(e)}>Delete</Button>
        </div>
      );
    }
    return null;
  }

  renderTargetGroups() {
    if (this.state.plan.targetGroups && this.state.plan.targetGroups.length > 0) {
      return this.state.plan.targetGroups.map(group => <p className="list-text" key={group.id}>{group.description} </p>);
    }
    return (
      <p className="list-text">{this.state.plan.hub.targetGroups.description} </p>
    );
  }

  renderActivities() {
    if (this.state.plan.activities && this.state.plan.activities.length > 0) {
      return this.state.plan.activities.map(activity => <p className="list-text" key={activity.id}>{activity.description} </p>);
    }
    return (
      <p  className="list-text">{console.log(this.state.plan.hub)}{this.state.plan.hub.activities.description} </p>
    );
  }

  render() {
    return (
      <div className="plan-page-container">
        <PlanHeader
          plan={this.state.plan}
          hubName={this.state.hubName}
          categoryDescription={this.state.categoryDescription}
          actionButton={this.state.actionButton}
          loggedIn={this.props.user}
        />
        <div className="content-container">
          <Row>
            <Col md={9} className="plan-content-container">
              <Row className="text-content-row" >
                <div className="plan-content">
                  {parse(parseContent(this.state.plan.textContent))}
                </div>
              </Row>
              <Row className="modules-row">
                {this.state.plan.modules &&
                  <Section
                    context="modules"
                    tiles={this.state.plan.modules.filter(mod => mod.accessId <= this.state.hubAccess).map(mod => Object.assign({}, mod, { hub: this.state.plan.hub }))}
                    title="Folder Modules"
                  />
                }
              </Row>
              {console.log(this.state)}
            </Col>
            <Col md={3} className="plan-content-sidebar">
              <div className="sidebar-content-container">
                {this.state.plan.hub &&
                  <div className="sub-category-container">
                    <h4>Target Group(s)</h4>
                    {this.renderTargetGroups()}
                  </div>
                }
                {this.state.plan.hub &&
                  <div className="sub-category-container">
                    <h4>Activities</h4>
                    <div>
                      {this.renderActivities()}
                    </div>
                  </div>
                }
              </div>
            </Col>
          </Row>
        </div>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          <div className="plan-modal-container">
            {this.rednerModalContent()}
          </div>
        </Modal>
      </div>
    );
  }
}

export default checkStatus(Plan);
