import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { Link } from 'react-router-dom';
import config from '../../constant/config';
import { privacySelector } from '../../lib/utilities';
import './Tile.css';
import { AiFillCheckCircle } from 'react-icons/ai';
import { IconContext } from "react-icons";


class Tile extends Component {
  getSize() {
    if (!this.props.carousel) {
      return 'tile-container';
    }
    return 'tile-container carousel';
  }

  render() {
    return (
      <Link to={`/module/${this.props.id}`} className={this.getSize()}>
        <div to={`/module/${this.props.id}`} className="tile-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.image}.thumbnail)` }} />
        <div className="module-name-container">
          <div to={`/module/${this.props.id}`} className="module-name">
            <Dotdotdot clamp={2}>
              {privacySelector(this.props.accessId)}
              {this.props.moduleTitle}
            </Dotdotdot>
          </div>
        </div>
        <div className="hub-name-container">
          <div className="tile-hub-image" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.hubImage}.thumbnail)` }} />
          <h4 className="hub-name"><Link to={`/${this.props.hubPathName}`} >{this.props.hubName}</Link></h4>
        </div>
        <div className="hub-name-container">
          <div className='fill-check'>
            {(() => {
              if (this.props.category === "Information") {
                return (
                  <IconContext.Provider value={{ style: { verticalAlign: '-40%', color: ' #418E9E', height: "20px", width: "20px" } }}>
                    <AiFillCheckCircle />
                  </IconContext.Provider>
                )
              } else if (this.props.category === "Activity") {
                return (
                  <IconContext.Provider value={{ style: { verticalAlign: '-40%', color: ' #FA6400', height: "20px", width: "20px" } }}>
                    <AiFillCheckCircle />
                  </IconContext.Provider>
                )
              }
                return (
                  <IconContext.Provider value={{ style: { verticalAlign: '-40%', color: ' #FAC832', height: "20px", width: "20px" } }}>
                    <AiFillCheckCircle />
                  </IconContext.Provider>
                )
            })()}
          </div>
          <h4 className="module-type">
            {this.props.category}
          </h4>
        </div>

      </Link>

    );
  }
}

export default Tile;
