import React, { Component } from 'react';
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from 'react-bootstrap';
import config from '../../constant/config';
import './MessageUser.css';
import logo from '../../resources/img/actify_logo.svg';

class MessageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validContent: true,
      messageContent: '',
      messageSent: false,
    };
  }

  async onClickSendMessage() {
    const res = await fetch(`${config.apiURL}/message/${this.props.recipient.id}/`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        content: this.state.messageContent,
      }),
    });
    if (res.status === 200) {
      this.setState({
        messageSent: true,
      });
    }
  }

  render() {
    return (
      <div className="message-user">
        <div>
          <img src={logo} width={"200"} alt={"actify-logo"} height={"400"} style={{margin: "-100px 0px -150px 30px"}}/>
        </div>
        <div className="avatar-container">
          <div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.recipient.avatarId})` }} />
        </div>
        <div className="user-info-container">
          <h3>{`${this.props.recipient.firstName} ${this.props.recipient.surname}`}</h3>
          {this.state.messageSent ?
            <div>Message Sent</div> :
            <div className="send-message-content">
              <FormGroup className="form" controlId="textContent" validationState={!this.state.validContent ? 'error' : null}>
                <ControlLabel className="text-area">Message Content</ControlLabel>
                <FormControl
                  className="form-text-area"
                  componentClass="textarea"
                  value={this.state.messageContent}
                  onChange={e => this.setState({ messageContent: e.target.value })}
                />
                {!this.state.validContent && <HelpBlock>Please enter your message</HelpBlock>}
              </FormGroup>
            </div>
          }
          {!this.state.messageSent && <div><Button onClick={() => this.onClickSendMessage()}>{this.state.sendMessage ? 'Send' : 'Message User'}</Button></div>}
        </div>
      </div>
    );
  }
}

export default MessageUser;
