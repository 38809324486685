import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import config from '../../constant/config';
import './SiteAnalytics.css';

const fetchSiteData = async () => {
  const res = await fetch(`${config.apiURL}/analytics/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

class SiteAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteData: null,
    };
  }

  componentWillMount() {
    this.getSiteData();
  }

  async getSiteData() {
    try {
      const siteData = await fetchSiteData();
      this.setState({
        siteData,
      });
    } catch (err) {
      console.error(err.message);
    }
  }

  render() {
    const { siteData } = this.state;
    if (!siteData) {
      return null;
    }
    return (
      <div className="analytics-container">
        <Row className="block-container">
          <Col sm={4} lg={2}>
            <div className="hub-totals totals">
              <div className="total-count">{siteData.hubs}</div>
              <span className="total-title">HUBS</span>
            </div>
          </Col>
          <Col sm={4} lg={2}>
            <div className="module-totals totals">
              <div className="total-count">{siteData.modules}</div>
              <span className="total-title">MODULES</span>
            </div>
          </Col>
          <Col sm={4} lg={2}>
            <div className="user-totals totals">
              <div className="total-count">{siteData.users}</div>
              <span className="total-title">USERS</span>
            </div>
          </Col>
          <Col sm={4} lg={2}>
            <div className="plan-totals totals">
              <div className="total-count">{siteData.plans}</div>
              <span className="total-title">PLANS</span>
            </div>
          </Col>
          <Col sm={4} lg={2}>
            <div className="session-totals totals">
              <div className="total-count">{siteData.sessions}</div>
              <span className="total-title">SESSIONS</span>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SiteAnalytics;
