import React from 'react';
import { Link } from 'react-router-dom';
import { dateCompare } from '../../lib/utilities';
import './ManageFeaturedModules.css';

class SelectFeaturedFolders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFolders: [],
      showModal: false,
    };
  }

  renderTableRows() {
    let ids;
    try
    {
      ids = this.props.featured.map(x => x.id);
    }
    catch (err)
    {
      console.log(err);
      return <p>Hub has no folders! Create a folder to feature it.</p>
    }

    return this.props.folders.sort(dateCompare).map(folder => (
      <tr key={folder.id}>
        <td><Link to={`/${this.props.hub.pathName}/plan/${folder.id}`}>{folder.title}</Link></td>
        <td>{folder.description}</td>
        <td>
          <div className="warning-button-container">
            <input type="checkbox" defaultChecked={ids.includes(folder.id)} onChange={(e) => this.props.handleChange(folder)} />
          </div>
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <div className="manage-featured-container">
        <h4>Featured Folders</h4>
        <table className="modules-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th className="delete-heading">Set Featured</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>

        </table>
      </div>
    );
  }
}

export default SelectFeaturedFolders;
