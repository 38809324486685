import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from '../../constant/config';
import './HubTile.css';

class HubTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      followers: 0,
      fetchingFollowers: true,
      showFollowers: ''
    };
  }

  componentDidMount() {
    this.getFollowers(this.props.id);
  }

  handleClick(id) {
    this.props.addHub(id, !this.state.checked);
    this.setState({ checked: !this.state.checked });
  }

  async getFollowers(hubId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubId}/followers`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const hub = await res.json();
        await this.setState({
          followers: hub.followerCount,
          showFollowers: hub.showFollowers,
          fetchingFollowers: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    if (this.props.selectable) {
      return (
        <div className={!this.props.small ? "hub-tile-outer-container carousel" : "hub-tile-outer-container"}>
          <div className={"hub-tile-container"}>
            <div className="select-container">
              <div className="overflow" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.image}.thumbnail)` }} />
              <div className="checkbox" onClick={() => this.handleClick(this.props.id)}>
                {this.state.checked && <i className="fa fa-check fa" aria-hidden="true" />}
              </div>
            </div>
            <div className="hub-name-container">
              <Link className="hub-link" to={`/${this.props.hubPathName}`}> {this.props.hubName}</Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={!this.props.small ? "hub-tile-outer-container carousel" : "hub-tile-outer-container"}>
        <Link to={`/${this.props.hubPathName}`} className="hub-tile-container">
          <span class={!this.props.small ? "tooltiptext carousel" : "tooltiptext hidden"}>{this.props.hubName}</span>
          <div className="tile-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.image}.thumbnail)` }} />
          <div className="hub-name-container">
            <h3>{this.props.hubName}</h3>
            {this.state.showFollowers==1 && <p>{this.state.fetchingFollowers ? `...` : `${this.state.followers} Followers`}</p>}
          </div>
        </Link>
      </div>
    );
  }
}

export default HubTile;
