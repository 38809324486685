import React from 'react';
import { Modal } from 'react-bootstrap';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import config from '../../constant/config';
import SessionDetails from './SessionDetailsContainer';
import './MySessions.css';
import infoIcon from '../../resources/img/info-icon.png';
import InfoBox from './InfoBox';

const fetchSessions = async () => {
  const res = await fetch(`${config.apiURL}/user/sessions`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

BigCalendar.momentLocalizer(moment);

class MySessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      selectedSession: null,
      showModal: false,
      showingInfo : false,
    };
  }

  componentWillMount() {
    this.getSessions();
  }

  onSessionClick(e) {
    this.setState({
      selectedSession: e,
      showModal: true,
    });
  }

  async getSessions() {
    const sessionsData = await fetchSessions();
    this.setState({
      sessions: sessionsData.map(ses => (Object.assign({}, ses, { title: ses.plan.title }))),
    });
  }

  render() {
    const handleEnter = () => {
      this.setState({showingInfo : true})
    };

    const handleLeave = () => {
      this.setState({showingInfo : false})
    };

    return (
      <div className="my-sessions-container">
        <span className="calendar-title">
          Session Calendar
          <img onMouseOver={()=>handleEnter()} onMouseOut={()=>handleLeave()} className='info-icon' src={infoIcon} alt='help icon' />
        </span>
        { this.state.showingInfo
          ?
          <div className='session-info-box'>
            <InfoBox
              headerStyle={{fontSize: '18px'}}
              bodyStyle={{fontSize: '15px', top: '75px'}}
              imgStyle={{width: '350px'}}
              helpHeader="What is a session calendar?"
              helpText="Hub owners and admins can assign users a set of modules to be completed on or by a specific date."
            />
          </div>
          :
          null }
        <BigCalendar
          className="sessions-calendar"
          {...this.props}
          events={this.state.sessions}
          titleAccessor="title"
          startAccessor="dueDate"
          endAccessor="dueDate"
          views={['month']}
          step={60}
          defaultDate={new Date()}
          onSelectEvent={e => this.onSessionClick(e)}
        />
        <Modal className="session-details-modal" show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton />
          {this.state.selectedSession && <SessionDetails session={this.state.selectedSession} user={this.props.user} closeModal={() => this.setState({ showModal: false })} />}
        </Modal>
      </div>
    );
  }
}

export default MySessions;
