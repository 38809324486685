import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import Slider from 'rc-slider';

import sitting from '../../../../resources/img/sitting_man_pers.svg';
import walking from '../../../../resources/img/walking_man_pers.svg';
import running from '../../../../resources/img/running_man_pers.svg';


import '../SignUpNew.css';
import 'rc-slider/assets/index.css';

class PageFourPers extends Component {

  render() {
    const {
      selectedActivityLevel,
      setSelectedActivityLevel,
      activityLevelOptions,
      handleButtonClick
    } = this.props;

    const { skipValidation } = this.props;

    const valid = activityLevelOptions.includes(selectedActivityLevel);

    const onSliderChange = (v) => {
      const level = activityLevelOptions[v - 1]
      if (level)
        setSelectedActivityLevel(level);
    };

    const setmarks = {
      1: {
        style: {
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: selectedActivityLevel.id === 1 ? 'bold' : 'normal',
        },
        label: (
          <div>
            <div className="pers-colour-dark-text">1</div>
            <div className="slider-label">{activityLevelOptions[0].level}</div>
            <div style={{textAlign: 'center'}}>
              <img src={sitting} className="sitting-man" alt="sitting icon" />
            </div>
          </div>
        )
      },
      2: {
        style: {
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: selectedActivityLevel.id === 2 ? 'bold' : 'normal'
        },
        label: (
          <div>
            <div className="pers-colour-dark-text">2</div>
            <div className="slider-label">{activityLevelOptions[1].level}</div>
            <div style={{textAlign: 'center'}}>
              <img src={walking} className="walking-man" alt="walking icon" />
            </div>
          </div>
        )
      },
      3: {
        style: {
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: selectedActivityLevel.id === 3 ? 'bold' : 'normal'
        },
        label: (
          <div>
            <div className="pers-colour-dark-text">3</div>
            <div className="slider-label">{activityLevelOptions[2].level}</div>
            <div style={{textAlign: 'center'}}>
              <img src={running} className="running-man" alt="running icon" />
            </div>
          </div>
        )
      }
    };

    return (
      <div className="no-padding" style={{ padding: "0 2em" }}>
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              What is your current physical activity level?
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              Tell us a bit about your current levels of physical activity, so we can show you the most useful and relevant content.
            </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour row">
          <div className="col-md-2 no-padding" />

          <div className="col-md-8 mb-20 mt-20">
            <Slider
              min={1}
              max={3}
              value={activityLevelOptions.indexOf(selectedActivityLevel) + 1}
              onChange={(v) => onSliderChange(v)}
              marks={setmarks}
              step={1}
              included={false}
              handleStyle={{
                borderColor: 'white',
                height: 35,
                width: 35,
                marginTop: -8,
                backgroundColor: '#81C3D0'
              }}
              railStyle={{ backgroundColor: '#E6F8FB', height: 20 }}
              dots={false}
            />
          </div>

          <div className="col-md-2 no-padding" />
        </div>

        <br /><br /><br /><br /><br />

        <div className="navigation-buttons no-colour mt-20">
          <div className="back">
            <Button
              className="navigation-button"
              onClick={() => handleButtonClick(true)}
            >
              ← Back
            </Button>
          </div>
          {(valid || skipValidation) &&
            <div className="next">
              <Button
                className="navigation-button"
                onClick={() => handleButtonClick()}
              >
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageFourPers;
