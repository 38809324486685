import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import termsAndConditions from '../../../resources/Actify_TermsandConditions';
import dataCollectionPolicy from '../../../resources/Actify_DataCollectionPolicy';
import logo from '../../../resources/img/actify_logo.svg';
import config from '../../../constant/config';
import './SignUpNew.css';

import {
  getGenderOptions,
  getTypes,
  getRoles,
  getActivities,
  getGoals,
  getTargetGroups,
  getActivityLevels,
  getMotivations,
  getConfidenceLevels,
  getMovementLevels,
  fetchSuggestedHubs,
  counties
} from './SignUpFetchUtil';

import PageOne from './pageOne'; // registration page one
import PageTwo from './pageTwo'; // personal details
import PageThree from './pageThree'; // choose account type
// practitioner_pages
import PageFourPrac from './practitioner_pages/pageFour';
import PageFivePrac from './practitioner_pages/pageFive';
import PageSixPrac from './practitioner_pages/pageSix';
import PageSevenPrac from './practitioner_pages/pageSeven';
import PageSuggestionPrac from './practitioner_pages/pageSuggestion';
// personal_pages
import PageFourPers from './personal_pages/pageFour';
import PageFivePers from './personal_pages/pageFive';
import PageSixPers from './personal_pages/pageSix';
import PageSevenPers from './personal_pages/pageSeven';
import PageEightPers from './personal_pages/pageEight';
import PageTenPers from './personal_pages/pageTen';
import PageElevenPers from './personal_pages/pageEleven';
import PageSuggestionPers from './personal_pages/pageSuggestion';

import PageEleven from './pageEleven';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.signUpModalRef = React.createRef()
    this.state = {
      // retrieved in page one
      forename: '',
      validForename: true,
      surname: '',
      validSurname: true,
      email: '',
      validEmail: true,
      uniqueEmail: true,
      confirmEmail: '',
      password: '',
      validPassword: true,
      acceptedTerms: false,
      // retrieved in page two
      gender: '',
      genderOptions: [],
      validGender: true,
      day: '',
      validDay: true,
      month: '',
      validMonth: true,
      year: '',
      validYear: true,
      location: '',
      locations: [],
      // retrieved in page three
      accountType: '',
      accountTypeOptions: [],
      validAccountType: true,

      // retrieved in page four - practitioner
      chosenRoles: [],
      roleOptions: [],
      validRoles: true,
      // retrieved in page five - practitioner
      chosenGoals: [],
      goalOptions: [],
      validGoals: true,
      // retrieved in page six - practitioner
      selectedActivity: '',
      chosenActivities: [],
      activitiesOptions: [],
      validActivities: true,
      // retrieved in page seven - practitioner
      selectedTargetGroup: '',
      chosenTargetGroups: [],
      targetGroupOptions: [],
      validTargetGroups: true,

      // retrieved in page four - personal
      selectedActivityLevel: '',
      activityLevelOptions: [],
      validActivityLevel: true,
      // retrieved in page five - personal
      chosenPersonalGoals: [],
      personalGoalsOptions: [],
      validPersonalGoals: true,
      // retrieved in page six - personal
      selectedMotivation: '',
      motivationOptions: [],
      validMotivation: true,
      // retrieved in page seven - personal
      selectedTargetGroupPers: '',
      chosenTargetGroupsPers: [],
      // retrieved in page nine - personal
      selectedPersonalActivities: '',
      chosenPersonalActivities: [],
      personalActivitiesOptions: [],
      validPersonalActivities: true,
      // retrieved in page ten - personal
      selectedConfidence: '',
      confidenceOptions: [],
      validConfidence: true,
      // retrieved in page eleven - personal
      selectedMovementLevel: '',
      movementLevelOptions: [],
      validMovementLevel: true,

      // functionality states
      currentPage: 1,
      signUpError: null,
      showTerms: false,
      showDataCollectionPolicy: false,
      completedSignup: false,
      hubInviteToken: this.props.hubInviteToken,
      selectedHubs: [],
      suggestedHubs: [],
      loading: false,

      // for skipping practitioner questionnaire - both
      questionnaireSkipped: false,
    };
  }

  async getSelectOptions() {
    try {
      const genderOptions = await getGenderOptions();
      const accountTypeOptions = await getTypes();
      const roleOptions = await getRoles();
      const targetGroupOptions = await getTargetGroups();
      const activitiesOptions = await getActivities();
      const personalActivitiesOptions = activitiesOptions.filter((a) => a.type === 1 || a.type === 3);
      const goalOptions = await getGoals();
      const personalGoalsOptions = await goalOptions.filter((g) => g.type === 1 || g.type === 3);
      const activityLevelOptions = await getActivityLevels();
      const motivationOptions = await getMotivations();
      const confidenceOptions = await getConfidenceLevels();
      const movementLevelOptions = await getMovementLevels();

      this.setState({
        genderOptions,
        locations: counties,
        accountTypeOptions,
        activitiesOptions: activitiesOptions.filter((a) => a.type === 2 || a.type === 3),
        personalActivitiesOptions,
        roleOptions,
        goalOptions: goalOptions.filter((g) => g.type === 2 || g.type === 3),
        personalGoalsOptions,
        targetGroupOptions,
        activityLevelOptions,
        selectedActivityLevel: activityLevelOptions[1],
        selectedConfidence: confidenceOptions[1],
        selectedMotivation: motivationOptions[1],
        selectedMovementLevel: movementLevelOptions[1],
        motivationOptions,
        confidenceOptions,
        movementLevelOptions,
      });
    } catch (err) {
      this.setState({
        currentPage: null,
        signUpError: 'Unable to create new users at this time, please try again'
      });
    }
  }

  componentDidMount() {
    this.getSelectOptions();
  }

  async handleButtonClick(back = false) {
    const {
      currentPage,
      accountType,
      accountTypeOptions,
      questionnaireSkipped
    } = this.state;
    const pageCount = 22;

    const isPrac = accountType === accountTypeOptions[1];
    const isPers = accountType === accountTypeOptions[0];
    const isBoth = accountType === accountTypeOptions[2];

    this.signUpModalRef.current.scrollIntoView();

    if (currentPage === 1) {
      this.setState({ currentPage: currentPage + 1 });
      return;
    }

    if (back && questionnaireSkipped && currentPage === 22) {
      this.setState({ currentPage: 11, questionnaireSkipped: false });
      return;
    }

    if (back && currentPage !== 1) {
      this.setState({ currentPage: currentPage - 1 });
      return;
    }

    if (currentPage !== pageCount) {
      this.setState({ loading: true })

      if (currentPage + 1 === 11 && isPers)
        await this.getSuggestions();
      if (currentPage + 1 === 8 && isPrac)
        await this.getSuggestions();
      if (currentPage + 1 === 16 && isBoth)
        await this.getSuggestions();

      this.setState({ currentPage: currentPage + 1, loading: false });
      return;
    }
  }

  async skipQuestionnaire(skip = false) {
    if (skip) {
      this.setState({ questionnaireSkipped: true, loading: true });
      await this.getSuggestions();
      this.setState({ currentPage: 22, loading: false });
      return;
    }
  }

  async getSuggestions() {
    console.log(this.state);
    const {
      accountType,
      accountTypeOptions,
      // prac
      chosenActivities,
      chosenGoals,
      chosenTargetGroups,
      // pers
      chosenPersonalActivities,
      chosenPersonalGoals,
      chosenTargetGroupsPers,
      // both
      questionnaireSkipped
    } = this.state;

    const isPrac = accountType === accountTypeOptions[1];
    const isPers = accountType === accountTypeOptions[0];
    const isBoth = accountType === accountTypeOptions[2];

    this.setState({ loading: true });

    let suggestedHubs = [];

    if (isPrac)
      suggestedHubs = await fetchSuggestedHubs(chosenActivities, chosenGoals, chosenTargetGroups);
    if (isPers)
      suggestedHubs = await fetchSuggestedHubs(chosenPersonalActivities, chosenPersonalGoals, chosenTargetGroupsPers);
    if (isBoth && questionnaireSkipped === false) {
      const activites = [...chosenActivities, ...chosenPersonalActivities];
      const goals = [...chosenGoals, ...chosenPersonalGoals];
      const targetGroups = [...chosenTargetGroups, ...chosenTargetGroupsPers]
      suggestedHubs = await fetchSuggestedHubs(activites, goals, targetGroups);
    }
    if (isBoth && questionnaireSkipped === true) {
      suggestedHubs = await fetchSuggestedHubs(chosenPersonalActivities, chosenPersonalGoals, chosenTargetGroupsPers);
    }

    this.setState({ loading: false, suggestedHubs });
  }

  renderPageOne() {
    const {
      currentPage,
      signUpError,
      email,
      validEmail,
      uniqueEmail,
      confirmEmail,
      password,
      validPassword,
      forename,
      validForename,
      surname,
      validSurname,
      passwordHelpText,
      acceptedTerms,
    } = this.state;

    const { loginRedirect } = this.props;

    return (
      <PageOne
        currentPage={currentPage}
        signUpError={signUpError}
        email={email}
        validEmail={validEmail}
        uniqueEmail={uniqueEmail}
        confirmEmail={confirmEmail}
        password={password}
        validForename={validForename}
        validSurname={validSurname}
        forename={forename}
        surname={surname}
        validPassword={validPassword}
        passwordHelpText={passwordHelpText}
        acceptedTerms={acceptedTerms}
        setForename={(v) => this.setState({ forename: v })}
        setValidForename={(v) => this.setState({ validForename: v })}
        setSurname={(v) => this.setState({ surname: v })}
        setValidSurname={(v) => this.setState({ validSurname: v })}
        setEmail={(v) => this.setState({ email: v })}
        setValidEmail={(v) => this.setState({ validEmail: v })}
        setUniqueEmail={(v) => this.setState({ uniqueEmail: v })}
        setConfirmEmail={(v) => this.setState({ confirmEmail: v })}
        setPassword={(v) => this.setState({ password: v })}
        setValidPassword={(v) => this.setState({ validPassword: v })}
        setPasswordHelpText={(v) => this.setState({ passwordHelpText: v })}
        handleButtonClick={() => this.handleButtonClick()}
        setAcceptedTerms={(v) => this.setState({ acceptedTerms: v })}
        setShowTerms={(v) => this.setState({ showTerms: v })}
        login={() => loginRedirect()}
        setShowDataPolicy={(v) => this.setState({ showDataCollectionPolicy: v })}

        skipValidation={false}
      />
    );
  }

  renderPageTwo() {
    const {
      location,
      locations,
      day,
      month,
      year,
      gender,
      genderOptions
    } = this.state;

    return (
      <PageTwo
        location={location}
        locations={locations}
        day={day}
        month={month}
        year={year}
        gender={gender}
        genderOptions={genderOptions}
        setGender={(v) => this.setState({ gender: v })}
        setLocation={(v) => this.setState({ location: v })}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setDay={(v) => this.setState({ day: v })}
        setMonth={(v) => this.setState({ month: v })}
        setYear={(v) => this.setState({ year: v })}
        skipValidation={false}
      />
    );
  }

  renderPageThree() {
    const {
      accountType,
      accountTypeOptions
    } = this.state;

    return (
      <PageThree
        accountType={accountType}
        accountTypeOptions={accountTypeOptions}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setAccountType={(v) => this.setState({ accountType: v })}
      />
    );
  }

  renderPageFourPrac() {
    const { chosenRoles, roleOptions } = this.state;

    return (
      <PageFourPrac
        chosenRoles={chosenRoles}
        roleOptions={roleOptions}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setChosenRoles={(v) => this.setState({ chosenRoles: v })}

        skipValidation={false}
      />
    );
  }

  renderPageFivePrac() {
    const { chosenGoals, goalOptions, validGoals } = this.state;

    return (
      <PageFivePrac
        chosenGoals={chosenGoals}
        goalOptions={goalOptions}
        validGoals={validGoals}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setChosenGoals={(v) => this.setState({ chosenGoals: v })}

        skipValidation={false}
      />
    );
  }

  renderPageSixPrac() {
    const {
      selectedActivity,
      chosenActivities,
      activitiesOptions,
      validActivities
    } = this.state;

    return (
      <PageSixPrac
        selectedActivity={selectedActivity}
        chosenActivities={chosenActivities}
        activitiesOptions={activitiesOptions}
        validActivities={validActivities}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setChosenActivities={(v) => this.setState({ chosenActivities: v })}
        setSelectedActivity={(v) => this.setState({ selectedActivity: v })}

        skipValidation={false}
      />
    );
  }

  renderPageSevenPrac() {
    const {
      selectedTargetGroup,
      chosenTargetGroups,
      targetGroupOptions,
      validTargetGroups,
    } = this.state;

    return (
      <PageSevenPrac
        selectedTargetGroup={selectedTargetGroup}
        chosenTargetGroups={chosenTargetGroups}
        targetGroupOptions={targetGroupOptions}
        validTargetGroups={validTargetGroups}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setChosenTargetGroups={(v) => this.setState({ chosenTargetGroups: v })}
        setSelectedTargetGroup={(v) => this.setState({ selectedTargetGroup: v })}

        skipValidation={false}
      />
    );
  }

  renderPageElevenBoth() {
    return (
      <PageEleven
        handleButtonClick={(v) => this.handleButtonClick(v)}
        skipQuestionnaire = {(v) => this.skipQuestionnaire(v)}

        skipValidation={false}
      />
    );
  }

  renderPageFourPers() {
    const {
      selectedActivityLevel,
      activityLevelOptions,
      validActivityLevel
    } = this.state;

    return (
      <PageFourPers
        selectedActivityLevel={selectedActivityLevel}
        activityLevelOptions={activityLevelOptions}
        validActivityLevel={validActivityLevel}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setSelectedActivityLevel={(v) => this.setState({ selectedActivityLevel: v })}

        skipValidation={false}
      />
    );
  }

  renderPageFivePers() {
    const {
      chosenPersonalGoals,
      personalGoalsOptions,
      validPersonalGoals,
    } = this.state;

    return (
      <PageFivePers
        chosenPersonalGoals={chosenPersonalGoals}
        personalGoalsOptions={personalGoalsOptions}
        validPersonalGoals={validPersonalGoals}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setChosenPersonalGoals={(v) => this.setState({ chosenPersonalGoals: v })}

        skipValidation={false}
      />
    );
  }

  renderPageSixPers() {
    const {
      selectedMotivation,
      motivationOptions,
      validMotivation,
    } = this.state;

    return (
      <PageSixPers
        selectedMotivation={selectedMotivation}
        motivationOptions={motivationOptions}
        validMotivations={validMotivation}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setSelectedMotivation={(v) => this.setState({ selectedMotivation: v })}

        skipValidation={false}
      />
    );
  }

  renderPageSevenPers() {
    const {
      selectedConfidence,
      confidenceOptions,
      validConfidence,
    } = this.state;

    return (
      <PageSevenPers
        selectedConfidence={selectedConfidence}
        confidenceOptions={confidenceOptions}
        validConfidence={validConfidence}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setSelectedConfidence={(v) => this.setState({ selectedConfidence: v })}

        skipValidation={false}
      />
    );
  }

  renderPageEightPers() {
    const {
      selectedMovementLevel,
      movementLevelOptions,
      validMovementLevel,
    } = this.state;

    return (
      <PageEightPers
        selectedMovementLevel={selectedMovementLevel}
        movementLevelOptions={movementLevelOptions}
        validMovementLevel={validMovementLevel}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setSelectedMovementLevel={(v) => this.setState({ selectedMovementLevel: v })}

        skipValidation={false}
      />
    );
  }

  renderPageTenPers() {
    const {
      selectedPersonalActivities,
      chosenPersonalActivities,
      personalActivitiesOptions,
      validPersonalActivities,
    } = this.state;

    return (
      <PageTenPers
        selectedPersonalActivities={selectedPersonalActivities}
        chosenPersonalActivities={chosenPersonalActivities}
        personalActivitiesOptions={personalActivitiesOptions}
        validPersonalActivities={validPersonalActivities}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setChosenPersonalActivities={(v) => this.setState({ chosenPersonalActivities: v })}
        setSelectedPersonalActivities={(v) => this.setState({ selectedPersonalActivities: v })}

        skipValidation={false}
      />
    );
  }

  renderPageElevenPers() {
    const {
      selectedTargetGroupPers,
      chosenTargetGroupsPers,
      targetGroupOptions,
      validTargetGroups,
    } = this.state;

    return (
      <PageElevenPers
        selectedTargetGroup={selectedTargetGroupPers}
        chosenTargetGroups={chosenTargetGroupsPers}
        targetGroupOptions={targetGroupOptions}
        validTargetGroups={validTargetGroups}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        setChosenTargetGroups={(v) => this.setState({ chosenTargetGroupsPers: v })}
        setSelectedTargetGroup={(v) => this.setState({ selectedTargetGroupPers: v })}

        skipValidation={false}
      />
    );
  }

  renderPageSuggestionPers() {
    const {
      suggestedHubs,
      selectedHubs
    } = this.state;

    return (
      <PageSuggestionPers
        suggestedHubs={suggestedHubs}
        selectedHubs={selectedHubs}
        setSelectedHubs={(v) => this.setState({ selectedHubs: v })}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        handleSignup={() => this.handleSignup()}
      />
    );
  }

  renderPageSuggestionPrac() {
    const {
      suggestedHubs,
      selectedHubs
    } = this.state;

    return (
      <PageSuggestionPrac
        suggestedHubs={suggestedHubs}
        selectedHubs={selectedHubs}
        setSelectedHubs={(v) => this.setState({ selectedHubs: v })}
        handleButtonClick={(v) => this.handleButtonClick(v)}
        handleSignup={() => this.handleSignup()}
      />
    );
  }

  async handleSignup() {
    const {
      forename,
      surname,
      email,
      password,
      acceptedTerms,
      gender,
      day,
      month,
      year,
      location,
      accountType,
      // practitioner
      chosenRoles,
      chosenGoals,
      chosenActivities,
      chosenTargetGroups,
      // personal
      selectedActivityLevel,
      chosenPersonalGoals,
      selectedMotivation,
      chosenTargetGroupsPers,
      chosenPersonalActivities,
      selectedConfidence,
      selectedMovementLevel,
      // extra
      hubInviteToken,
      selectedHubs,
    } = this.state;

    const payload = {
      forename,
      surname,
      email,
      password,
      acceptedTerms,
      gender,
      day,
      month,
      year,
      location,
      accountType,
      // practitioner
      chosenRoles,
      chosenGoals,
      chosenActivities,
      chosenTargetGroups,
      // personal
      selectedActivityLevel,
      chosenPersonalGoals,
      selectedMotivation,
      chosenTargetGroupsPers,
      chosenPersonalActivities,
      selectedConfidence,
      selectedMovementLevel,
      // extra
      hubInviteToken,
      selectedHubs,
    };

    try {
      const res = await fetch(`${config.apiURL}/user/newsignup`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (res.status === 200) {
        const body = await res.json();
        this.props.setUser(body);
        this.props.handleModalClose({}, this.state.completedSignup);
      } else {
        throw new Error('Signup Failed');
      }
    } catch (err) {
      console.log(err)
      this.setState({ currentPage: null, signUpError: 'Sign up unsuccessful, please try again' });
    }
  }

  calculateSignupProgress() {
    const { currentPage, accountType, accountTypeOptions, questionnaireSkipped } = this.state;

    const section = 100 / 4; // percent each section takes up
    const nPracSections = 4;
    const nPersSections = 7;

    const isPrac = accountType === accountTypeOptions[1];
    const isPers = accountType === accountTypeOptions[0];
    const isBoth = accountType === accountTypeOptions[2];

    if (currentPage === 1) {
      return 0;
    }
    if (currentPage === 2) {
      return section;
    }
    if (currentPage === 3) {
      return section * 2;
    }
    if (currentPage >= 4 && currentPage <= 10 && (isBoth || isPers)) {
      return (section * 2) + section / (nPersSections / (currentPage - 3));
    }
    if (currentPage >= 4 && currentPage <= 7 && isPrac) {
      return (section * 2) + section / (nPracSections / (currentPage - 3));
    }
    if (currentPage >= 4 && currentPage <= 14 && isBoth) {
      return (section * 2) + section / ((nPracSections+nPersSections) / (currentPage - 3));
    }
    if (currentPage === 15 && isBoth && !questionnaireSkipped) {
      return section * 3;
    }
    return 100;
  }

  render() {
    const {
      currentPage,
      showTerms,
      accountType,
      accountTypeOptions,
      questionnaireSkipped,
      showDataCollectionPolicy
    } = this.state;

    const isPrac = accountType === accountTypeOptions[1];
    const isPers = accountType === accountTypeOptions[0];
    const isBoth = accountType === accountTypeOptions[2];

    let active = 1;
    active = currentPage === 3 ? 2 : active;
    active = (currentPage >= 4 && currentPage <= 12) ? 3 : active;
    active = (currentPage >= 13 && currentPage <= 20) ? 3 : active;
    active = (currentPage === 11 && isBoth) ? 3 : active;
    active = (currentPage === 11 && isPers) ? 4 : active;
    active = (currentPage === 22 && isBoth) || (currentPage === 8 && isPrac) ? 4 : active;

    return (
      <div className="new-sign-up">
        <div className="sign-up-container container-fluid" ref={this.signUpModalRef}>
          {showTerms &&
            <div className="terms-container">
              <div className="modal-header-container">
                <img
                  alt="actify"
                  className="img-fluid tc-image"
                  src={logo}
                />
                <h3>Terms and Conditions of Use</h3>
              </div>
              <div className="terms-content-container">
                {termsAndConditions}
              </div>
              <div className="navigation-buttons no-colour">
                <div className="next">
                  <Button
                    className="navigation-button back-terms"
                    onClick={() => this.setState({ showTerms: false })}
                  >
                    ← Back
                  </Button>
                </div>
              </div>
            </div>
          }

          {showDataCollectionPolicy &&
            <div className="terms-container">
              <div className="modal-header-container">
                <img
                  alt="actify"
                  className="img-fluid tc-image"
                  src={logo}
                />
                <h3>Actify Privacy Policy</h3>
              </div>
              <div className="terms-content-container">
                {dataCollectionPolicy}
              </div>
              <div className="navigation-buttons no-colour">
                <div className="next">
                  <Button
                    className="navigation-button back-terms"
                    onClick={() => this.setState({ showDataCollectionPolicy: false })}
                  >
                    ← Back
                  </Button>
                </div>
              </div>
            </div>
          }

          {currentPage !== 1 &&
            <div>
              <br />
              <br />
              <br />
              <div className="progress-container">
                <hr />
                <hr className="highlighted-progress" style={{ width: `${this.calculateSignupProgress()}%` }} />
                <div className="running_man_progress" style={{ width: `${this.calculateSignupProgress() - 6}%` }} />
                <div className="flag_progress" />
                <div className="sections_progress">
                  <span className={active === 1 ? "selected" : ""}>Personal details</span>
                  <span className={active === 2 ? "selected" : ""}>What do you want to do?</span>
                  <span className={active === 3 ? "selected" : ""}>{isPrac || (isBoth && currentPage > 10) ? 'Helping others' : 'Physical activity'}</span>
                  <span className={active === 4 ? "selected" : ""}>Personalised hubs</span>
                </div>
              </div>
            </div>
          }

          {currentPage === 1 && !showTerms && !showDataCollectionPolicy && this.renderPageOne()}
          {currentPage === 2 && !showTerms && !showDataCollectionPolicy && this.renderPageTwo()}
          {currentPage === 3 && !showTerms && this.renderPageThree()}

          {/* practitioner */}
          {((currentPage === 12 && isBoth) || (currentPage === 4 && isPrac)) && !showTerms && this.renderPageFourPrac()}
          {((currentPage === 13 && isBoth) || (currentPage === 5 && isPrac)) && !showTerms && this.renderPageFivePrac()}
          {((currentPage === 14 && isBoth) || (currentPage === 6 && isPrac)) && !showTerms && this.renderPageSixPrac()}
          {((currentPage === 15 && isBoth) || (currentPage === 7 && isPrac)) && !showTerms && this.renderPageSevenPrac()}
          {((currentPage === 16 && isBoth && !questionnaireSkipped) || (currentPage === 8 && isPrac)) && !showTerms && this.renderPageSuggestionPrac()}

          {/* personal */}
          {((currentPage === 4 && isBoth) || (currentPage === 4 && isPers)) && !showTerms && this.renderPageFourPers()}
          {((currentPage === 5 && isBoth) || (currentPage === 5 && isPers)) && !showTerms && this.renderPageFivePers()}
          {((currentPage === 6 && isBoth) || (currentPage === 6 && isPers)) && !showTerms && this.renderPageSixPers()}
          {((currentPage === 7 && isBoth) || (currentPage === 7 && isPers)) && !showTerms && this.renderPageSevenPers()}
          {((currentPage === 8 && isBoth) || (currentPage === 8 && isPers)) && !showTerms && this.renderPageEightPers()}
          {((currentPage === 9 && isBoth) || (currentPage === 9 && isPers)) && !showTerms && this.renderPageTenPers()}
          {((currentPage === 10 && isBoth) || (currentPage === 10 && isPers)) && !showTerms && this.renderPageElevenPers()}
          {(currentPage === 11 && isPers) && !showTerms && this.renderPageSuggestionPers()}
          {(currentPage === 11 && isBoth) && !showTerms && this.renderPageElevenBoth()}
          {(currentPage === 22 && isBoth && questionnaireSkipped) && !showTerms && this.renderPageSuggestionPers()}
        </div>
      </div>
    );
  }
}

export default SignUp;
