import * as types from './action-types';

export const initialState = {
  loggedIn: false,
  user: null,
  userQuizResults: [],
  notifications: 0,
  actualNotifications: {},
  activeProfileTab: 0,
  sideBarExpanded: false,
  activeRole: 0, // 1 for practitioner 0 for personal
  conversationToShow: null
};

function activaiReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_LOGIN:
      return Object.assign({}, state, { loggedIn: action.loggedIn });
    case types.SET_USER:
      return Object.assign({}, state, {
        user: action.user,
      });
    case types.TOGGLE_SIDE_BAR:
      return Object.assign({}, state, {
        sideBarExpanded: !state.sideBarExpanded,
      });
    case types.SET_BOOKMARKS:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { bookmarks: action.bookmarks }),
      });
    case types.REMOVE_BOOKMARK:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { bookmarks: state.user.bookmarks.filter(bookmark => bookmark.id !== action.moduleId) }),
      });
    case types.ADD_BOOKMARK:
      state.user.bookmarks.unshift(action.bookmark);
      return state;
    case types.SET_QUIZ_RESULTS:
      return Object.assign({}, state, {
        userQuizResults: action.results,
      });
    case types.ADD_QUIZ_RESULT: {
      const currentResIndex = state.userQuizResults.findIndex(res => res.moduleId === action.result.moduleId);
      const newState = state;
      if (currentResIndex > -1) {
        newState.userQuizResults[currentResIndex] = action.result;
      } else {
        newState.userQuizResults.unshift(action.result);
      }
      return newState;
    }
    case types.SET_NOTIFICATIONS: {
      return Object.assign({}, state, {
        notifications: action.notifications,
      });
    }
    case types.SET_ACTUAL_NOTIFICATIONS: {
      return Object.assign({}, state, {
        actualNotifications: action.notifications,
      });
    }
    case types.FOLLOW_HUB: {
      let newFollowingList = state.user.following;
      const alreadyFollowing = newFollowingList.find(hub => hub.id === action.hub.id);
      if (!alreadyFollowing) {
        newFollowingList = [...newFollowingList, action.hub];
      }
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { following: newFollowingList }),
      });
    }
    case types.UNFOLLOW_HUB: {
      const newFollowingList = state.user.following;
      const hubIndex = newFollowingList.findIndex(hub => hub.id === action.hubId);
      if (hubIndex > -1) {
        newFollowingList.splice(hubIndex, 1);
      }
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { following: newFollowingList }),
      });
    }
    case types.REMOVE_HUB: {
      const newHubList = state.user.hubs;
      const hubIndex = newHubList.findIndex(hub => hub.id === action.hubId);
      if (hubIndex > -1) {
        newHubList.splice(hubIndex, 1);
      }
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { hubs: newHubList }),
      });
    }
    case types.ADD_HUB: {
      const newHubList = [...state.user.hubs, action.hub];
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { hubs: newHubList }),
      });
    }
    case types.SET_ACTIVE_PROFILE_TAB: {
      return Object.assign({}, state, { activeProfileTab: action.activeTab });
    }
    case types.SET_ACTIVE_ROLE: {
      return Object.assign({}, state, { activeRole: action.activeRole });
    }
    case types.SET_CONVERSATION_ID: {
      return Object.assign({}, state, { conversationToShow: action.conversationId });
    }
    default:
      return state;
  }
}

export default activaiReducer;
