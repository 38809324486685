import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import checkStatus from '../common/checkStatus';
import MySessions from '../common/MySessionsContainer';
import Messages from '../common/MessageTabContainer';

import './MyProfile.css';
import Quizzes from '../common/QuizzesContainer';
import Profile from '../common/profile/ProfileTab';
import Settings from '../common/profile/SettingsTab';
import Notifications from '../common/profile/NotificationsTab';
import Bookmarks from '../common/profile/BookmarksTab';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faCommentDots, faUser, faCog, faBell, faCalendarAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons'


class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeProfileTab || 0,
      conversationId: props.conversationToShow || null,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.activeProfileTab !== undefined && newProps.activeProfileTab !== this.state.activeTab) {
      this.setState({
        activeTab: newProps.activeProfileTab,
      });
    }

    if (newProps.conversationToShow !== this.state.conversationId) {
      this.setState({
        conversationId: newProps.conversationToShow,
      });
    }
  }

  updateTab(key) {
    this.setState({ activeTab: key });
    this.props.setActiveProfileTab(key);
    this.props.setConversation(null);
  }

  render() {
    const { setActiveProfileTab, addHub, setConversation } = this.props;
    return (
      <div className="myprofile-container">
        <Tabs
          activeKey={this.state.activeTab}
          onSelect={key => this.updateTab(key)}
          id="controlled-tab-example"
        >
          <Tab
            eventKey={0}
            title={<span><FontAwesomeIcon icon={faUser}/> Profile</span>}
            tabClassName="tab-container"
          >
            <Profile user={this.props.user} />
          </Tab>

          <Tab
            eventKey={1}
            title={<span><FontAwesomeIcon icon={faBookmark}/> Bookmarks</span>}
            tabClassName="tab-container"
          >
            <Bookmarks />
          </Tab>

          { this.props.user && ((this.props.user.hubs.length > 0) || this.props.user.isSiteOwner) &&
            <Tab
              eventKey={2}
              title={<span><FontAwesomeIcon icon={faCalendarAlt}/> Sessions</span>}
              tabClassName="tab-container"
            >
              <MySessions />
            </Tab>
          }

          <Tab
            eventKey={3}
            title={<span><FontAwesomeIcon icon={faBell}/> Notifications</span>}
            tabClassName="tab-container"
          >
            <Notifications addHub={(hub) => addHub(hub)} changeTab={(n) => setActiveProfileTab(n)} user={this.props.user} showConversation={(n) => setConversation(n)}/>
          </Tab>

          <Tab
            eventKey={4}
            title={<span><FontAwesomeIcon icon={faCommentDots}/> Messages </span>}
            tabClassName="tab-container"
          >
            <Messages showConversation={(n) => setConversation(n)}/>
          </Tab>

          <Tab
            eventKey={5}
            title={<span><FontAwesomeIcon icon={faFileAlt}/> Quizzes</span>}
            tabClassName="tab-container"
          >
            <Quizzes />
          </Tab>

          <Tab
            eventKey={6}
            title={<span><FontAwesomeIcon icon={faCog}/> Settings</span>}
            tabClassName="tab-container"
          >
            <Settings user={this.props.user} updateUser={(user) => this.props.onGetUser(user)} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default checkStatus(MyProfile, true);
