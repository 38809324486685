import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommentSection from './CommentSection';

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentSection));
