export function emailValid(email) {
  if (email !== '') {
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i; //eslint-disable-line
    const valid = emailRegEx.test(email);
    return valid;
  }
  return true;
}

export function passwordValid(password) {
  const containsLowercaseRegex = /^(?=.*[a-z])/;
  const containsUppercaseRegex = /^(?=.*[A-Z])/;
  const containsNumericRegex = /^(?=.*[0-9])/;
  const containsSpecialRegex = /^(?=.*[!@#\$%\^&\*])/;//eslint-disable-line
  const atLeastEightCharsRegex = /^(?=.{8,})/;

  if (password !== '') {
    if (!containsLowercaseRegex.test(password)) {
      return { isValid: false, errorMessage: 'Must contain 1 lower case character' };
    }
    if (!containsUppercaseRegex.test(password)) {
      return { isValid: false, errorMessage: 'Must contain 1 upper case character' };
    }
    if (!containsNumericRegex.test(password)) {
      return { isValid: false, errorMessage: 'Must contain 1 number' };
    }
    if (!containsSpecialRegex.test(password)) {
      return { isValid: false, errorMessage: 'Must contain 1 special character' };
    }
    if (!atLeastEightCharsRegex.test(password)) {
      return { isValid: false, errorMessage: 'Must be at least 8 characters' };
    }
    return { isValid: true, errorMessage: null };
  }
  return { isValid: true, errorMessage: null };
}

export function postcodeValid(postcode) {
  const postcodeRegex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})/;//eslint-disable-line
  if (postcode !== '') {
    return postcodeRegex.test(postcode);
  }
  return true;
}

export function urlValid(url) {
  const expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;//eslint-disable-line
  if (url !== '') {
    return expression.test(url);
  }
  return true;
}

export function phonenumberValid(number) {
  const phonenumberRegex = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;//eslint-disable-line
  if (number !== '') {
    return phonenumberRegex.test(number);
  }
  return true;
}
