import React from 'react';
import { Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import config from '../../constant/config';
import './PlanHeader.css';

class PlanHeader extends React.Component {
  render() {
    if (!this.props.plan.hub) {
      return null;
    }

    return (
      <div className="module-header-container">
        {config.showPlanBannerImage && <div className="banner-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.plan.logoId})` }} />}
        <div className="header-container" style={{ marginBottom: this.props.edit ? 10 : 50 }}>
          <div className="header-image-container">
            <Link className="hub-link" to={`/${this.props.plan.hub.pathName}`}>
              <Image className="module-logo" src={`${config.spacesURL}/${this.props.plan.hub.logoId}`} />
            </Link>
          </div>
          <div className="module-details-container">
            <Row>
              <h3>{this.props.plan.title}</h3>
              <h4>{`${this.props.hubName} | ${this.props.categoryDescription}`}</h4>
              <p>{this.props.plan.description}</p>
            </Row>
          </div>
          <div className="social-container">
            {this.props.loggedIn && this.props.actionButton}
          </div>
        </div>
      </div>
    );
  }
}

export default PlanHeader;
