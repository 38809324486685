

import React, { Component } from 'react';
import {
  Button,
  FormControl
} from 'react-bootstrap';

import '../SignUpNew.css';

class PageTenPers extends Component {

  addActivity(id) {
    const {
      chosenPersonalActivities,
      setChosenPersonalActivities,
      personalActivitiesOptions
    } = this.props;

    const activity = personalActivitiesOptions.filter(v => `${v.id}` === id);

    if (activity && activity.length > 0) {
      if (!chosenPersonalActivities.includes(activity[0])) {
        setChosenPersonalActivities([...chosenPersonalActivities, activity[0]]);
      }
    }
  }

  removeActivity(activity) {
    const {
      chosenPersonalActivities,
      setChosenPersonalActivities
    } = this.props;

    if (chosenPersonalActivities.includes(activity)) {
      const index = chosenPersonalActivities.indexOf(activity);
      chosenPersonalActivities.splice(index, 1);
      setChosenPersonalActivities(chosenPersonalActivities);
    }
  }

  renderActivites() {
    const { chosenPersonalActivities } = this.props;

    return chosenPersonalActivities.map(act => (
      <div
        key={act.id}
        className="type-ahead-option pers-colour-dark"
        onClick={() => this.removeActivity(act)}
      >
        <p>{act.description}</p>
        <div
          className="type-ahead-delete"
          onClick={() => this.removeActivity(act)}
        >
          ✕
        </div>
      </div>
    ));
  }

  render() {
    const {
      personalActivitiesOptions,
      handleButtonClick,
      chosenPersonalActivities,
    } = this.props;

    const { skipValidation } = this.props;

    const valid = chosenPersonalActivities.length > 0;

    return (
      <div className="no-padding">
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              What kind of physical activities do you enjoy most?
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              This will help us tailor your recommended content and connect you with the most relevant Hubs. Choose as many as you like!            </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour row">
          <div className="col-md-2 no-padding" />

          <div className="col-md-8 mb-20 mt-20 no-padding">
            <FormControl
              className="pers-colour type-ahead"
              componentClass="select"
              onChange={e => this.addActivity(e.target.value)}
            >
              <option value="select">Select activities</option>
              {personalActivitiesOptions.map(activity => (
                <option key={activity.id} value={activity.id}>{activity.description}</option>
              ))}
            </FormControl>

            <div className="options-container">
              {this.renderActivites()}
            </div>
          </div>

          <div className="col-md-2 no-padding" />
        </div>

        <div className="navigation-buttons no-colour mt-20">
          <div className="back">
            <Button
              className="navigation-button"
              onClick={() => handleButtonClick(true)}
            >
              ← Back
            </Button>
          </div>
          {(valid || skipValidation) &&
            <div className="next">
              <Button
                className="navigation-button"
                onClick={() => handleButtonClick()}
              >
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageTenPers;
