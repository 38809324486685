import React from 'react';
import { Button } from 'react-bootstrap';
import config from '../../constant/config';
import moment from 'moment';
import auth from '../../lib/auth';
import './Comment.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'

const getUser = async (id) => {
  try {
    const res = await fetch(`${config.apiURL}/user/${id}/`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      },
    });
    const body = await res.json();
    return body;
  } catch (err) {
    console.error(err);
  }
};

const getLikes = async (id) => {
  try {
    const res = await fetch(`${config.apiURL}/comment/like/${id}/`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      },
    });
    const body = await res.json();
    return body;
  } catch (err) {
    console.error(err);
  }
};

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentId: this.props.comment.id,
      commentUser: this.props.comment.user,
      commentContent: this.props.comment.content,
      commentReplies: this.props.comment.replies,
      commentCreatedAt: this.props.comment.createdAt,
      commentLikes: this.props.comment.likes,
      moduleId: this.props.comment.moduleId,
      feedback: '',
      reply: '',
      repliesExpanded: false,
      visible: true,
      userLikes: this.props.likes,
      error: false,
      loading: false,
      showReply: false,
      isAuthenticated: false,
      isAuthenticatedHubOwner: false,
      isAuthenticatedHubAdmin: false,
    };
  }

  componentWillMount() {
    this.getReplyDetails();
    this.getAuthenticated();
  }

  async getAuthenticated() {
    this.setState({
      loading: true,
    });

    this.setState({
      isAuthenticated: await auth.isAuthenticated(),
      isAuthenticatedHubOwner: await auth.isAuthenticatedHubAdmin(this.props.hubId),
      isAuthenticatedHubAdmin: await auth.isAuthenticatedHubOwner(this.props.hubId),
    });

    this.setState({
      loading: false,
    });
  }

  async getReplyDetails() {
    const { commentReplies } = this.state;

    this.setState({
      loading: true,
    });

    for (let i = 0; i < commentReplies.length; i++) {
      commentReplies[i].user = await getUser(commentReplies[i].userId);
      const likeDetails = await getLikes(commentReplies[i].id);
      commentReplies[i].likes = likeDetails.likes;
      commentReplies[i].likesDetails = likeDetails.likesDetails;
    }

    this.setState({
      loading: false,
    });
  }

  async publishReply() {
    const { moduleId, commentId, reply, commentReplies } = this.state;

    this.setState({
      feedback: 'Posting reply...',
      error: false,
    });

    const threadId = commentId;

    try {
      const res = await fetch(`${config.apiURL}/comment/${moduleId}`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          content: reply,
          threadId: threadId,
        }),
      });
      if (res.status === 200) {
        const reply = await res.json();
        this.setState({
          feedback: 'Comment posted',
          reply: '',
          commentReplies: [...commentReplies, reply],
        });

      } else {
        console.log(res.json());
      }
    } catch (err) {
      console.error(err);
      this.setState({
        feedback: 'Error posting comment',
        error: true,
      });
    }
  }

  async deleteComment(id, isReply) {
    const { deleteComment } = this.props;
    const { commentReplies } = this.state;

    // find index of the reply
    let index = -1;
    if (isReply) {
      for (let i = 0; i < commentReplies.length; i++) {
        if (id === commentReplies[i].id) {
          index = i;
          break;
        }
      }
    }
    
    try {
      const res = await fetch(`${config.apiURL}/comment/${id}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        if (!isReply || index === -1) {
          deleteComment(id, false);
          this.setState({
            feedback: 'Comment deleted',
            visible: false,
          });
        } else {
          const updatedCommentReplies = commentReplies.filter(
            (reply) => reply.id !== id
          );
          deleteComment(id, true);
          this.setState({
            feedback: 'Comment deleted',
            visible: true,
            commentReplies: updatedCommentReplies,
          });
        }
      }
    } catch (err) {
      console.error(err);
      this.setState({
        feedback: 'Error deleting comment',
        error: true,
      });
    }
  }

  async handleLike(id, isReply) {
    // user not logged in
    if (!this.props.user) {
      return;
    }

    const { userLikes, commentReplies } = this.state;

    // find index of the reply
    let index = -1;
    if (isReply) {
      for (let i = 0; i < commentReplies.length; i++) {
        if (id === commentReplies[i].id) {
          index = i;
          break;
        }
      }
    }

    if (userLikes.includes(id)) {
      try {
        const res = await fetch(`${config.apiURL}/comment/like/${id}`, {
          credentials: 'include',
          method: 'DELETE',
          headers: { 'content-type': 'application/json' },
        });
        if (res.status === 200) {
          const removed = userLikes.filter((value, i, a) => value !== id)
          if (!isReply || index === -1) {
            this.setState({
              userLikes: removed,
              commentLikes: this.state.commentLikes - 1,
            });
          } else {
            commentReplies[index].likes = commentReplies[index].likes - 1;
            this.setState({
              userLikes: removed,
              commentReplies,
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const res = await fetch(`${config.apiURL}/comment/like/${id}`, {
          credentials: 'include',
          method: 'POST',
          headers: { 'content-type': 'application/json' },
        });
        if (res.status === 200) {
          userLikes.push(id)
          if (!isReply || index === -1) {
            this.setState({
              userLikes: userLikes,
              commentLikes: this.state.commentLikes + 1,
            });
          } else {
            commentReplies[index].likes = commentReplies[index].likes + 1;
            this.setState({
              userLikes: userLikes,
              commentReplies,
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  visibility() {
    if (this.state.visible) {
      return '';
    }
    return 'hide';
  }

  likedComment(id) {
    const { userLikes } = this.state;
    if (userLikes.includes(id)) {
      return 'liked';
    }
  }

  canDeleteComment(userId) {
    const {
      isAuthenticated,
      isAuthenticatedHubAdmin,
      isAuthenticatedHubOwner
    } = this.state;

    if (isAuthenticated && this.props.user) {
      return this.props.user.id === userId || isAuthenticatedHubAdmin || isAuthenticatedHubOwner;
    }
    return false;
  }

  openReply() {
    const { showReply } = this.state;
    this.setState({
      repliesExpanded: true,
      showReply: !showReply,
    });
  }

  renderComment(id, user, createdAt, likes, content, isReply) {
    if (id && user && createdAt && content) {
      return (
        <div className="comment" key={id}>
          <div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${user.avatarId}.thumbnail)` }} />
          <div className='comment-middle'>
            <span className='name'>{user.firstName} {user.surname}</span>
            <span className='time'> {moment(createdAt).fromNow()}</span>
            {this.canDeleteComment(user.id) &&
              <Button className="delete" onClick={() => this.deleteComment(id, true)}>
                Delete
              </Button>
            }
            <p>{content}</p>
          </div>
          <div className="comment-likes">
            <div className={this.props.user ? 'likes-section' : 'likes-section disabled-likes'} onClick={() => this.handleLike(id, isReply)}>
              <div className={'like-button ' + this.likedComment(id)}>
                <FontAwesomeIcon icon={faThumbsUp} className='icon' aria-hidden="true" />
              </div>
              <div className="like-count">
                <p>{likes}</p>
              </div>
            </div>
          </div>

        </div>
      );
    }
    return <div key={Math.random()} />
  }

  renderReply(reply) {
    const { id, user, createdAt, likes, content } = reply;
    return this.renderComment(id, user, createdAt, likes, content, true);
  }

  renderReplies(replies) {
    const { repliesExpanded } = this.state;
    const expanded = repliesExpanded ? `replies expanded` : `replies`;

    return (
      <div className={expanded + " " + this.visibility()}>
        <Button className="expand" onClick={() => this.openReply()}>
          Reply
        </Button>
        {!repliesExpanded && replies.length > 0 &&
          <Button className="expand" onClick={() => this.setState({ repliesExpanded: true })}>
            View Replies ({replies.length})
          </Button>
        }
        {repliesExpanded && replies.length > 0 &&
          <Button className="expand" onClick={() => this.setState({ repliesExpanded: false })}>
            Hide replies
          </Button>
        }
        {replies.length > 0 && replies.map(reply => this.renderReply(reply))}
      </div>
    );
  }

  render() {
    const {
      commentId,
      commentUser,
      commentContent,
      commentCreatedAt,
      commentLikes,
      commentReplies,
      reply,
      showReply
    } = this.state;

    let user;
    if (Array.isArray(commentUser)) {
      user = commentUser[0];
    } else {
      user = commentUser;
    }

    return (
      <div className="comment-container">
        {this.renderComment(commentId, user, commentCreatedAt, commentLikes, commentContent, false)}

        {this.renderReplies(commentReplies)}

        {this.props.user && showReply &&
          <div className="replies expanded">
            <div className="user-comment-reply">
              <input
                className={'comment-box'}
                value={reply}
                onChange={(e) => this.setState({ reply: e.target.value })}
                placeholder="Post a reply..."
              />
              {reply.length >= 4 &&
                <Button
                  className="comment-submit"
                  onMouseDown={() => this.publishReply()}
                >
                  Reply
                </Button>
              }
            </div>
          </div>
        }
        {!this.props.user && showReply &&
          <div className="replies expanded">
            <span className="login-prompt">
              Login to reply to this comment!
            </span>
          </div>
        }
      </div>
    );
  }
}

export default Comment;
