import React from 'react';
import { Image } from 'react-bootstrap';
import iconPrivate from '../resources/img/privacy-private.icon.png';
import iconHidden from '../resources/img/privacy-hidden.icon.png';
import "./privacy-icon.css";

import { parseEmbedYouTubeUrl } from './video';

export function privacySelector(id, big=false) {
  let styleClass = 'privacy-icon';

  if (big) {
    styleClass = 'privacy-icon-big';
  }

  if (id === 1) {
    return;
  } else if (id === 2) {
    return <Image className={styleClass} src={iconPrivate} alt="Private Module Padlock Icon" />;
  } else if (id === 3) {
    return <Image className={styleClass} src={iconHidden} alt="Hidden Module Padlock Icon" />;
  }
}

export function dateCompare(el1, el2)
{
  if (el1.createdAt > el2.createdAt)
  {
    return -1;
  }
  else if (el1.createdAt < el2.createdAt)
  {
    return 1;
  }
  return 0;
}

export function featuredDateCompare(el1, el2)
{
  if (el1.featuredAt > el2.featuredAt)
  {
    return 1;
  }
  else if (el1.featuredAt < el2.featuredAt)
  {
    return -1;
  }
  return 0;
}


export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function parseContent(content) {
  let updatedContent = "";
  if (content) {
    const splitContent = content.split('\n');
    for (let i = 0; i < splitContent.length; i += 1) {
      const yt_re = /(?:https?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
      const yt_match = splitContent[i].match(yt_re);
      const vimeo_re = /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;  // eslint-disable-line
      const vimeo_match = splitContent[i].match(vimeo_re);
      if (yt_match) {
        const youtubeSrc = parseEmbedYouTubeUrl(yt_match[0]);
        if (youtubeSrc) {
          updatedContent = updatedContent.concat(`<p><div><iframe title="YouTube video player" class="video-wrapper" src=${youtubeSrc} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div></p>`);
        }
      } else if (vimeo_match && vimeo_match.length >= 2) {
        const vimeo_id = vimeo_match[1];
        updatedContent = updatedContent.concat(`<p><div><iframe title=${vimeo_id} class="video-wrapper" src="https://player.vimeo.com/video/${vimeo_id}" frameborder="0" allowFullScreen></iframe></div></p>`);
      } else {
        updatedContent = updatedContent.concat(splitContent[i]);
      }
    }
    return updatedContent;
  }
  return updatedContent;
}
