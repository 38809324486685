import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import recommendations from '../../resources/img/recommendations.svg'
import recommendations2 from '../../resources/img/recommendations2.svg'

class UserWalkthroughPageThree extends Component {

render() {
    const { nextPage, previousPage, existingUserSelected } = this.props;

    return (
        <div className = 'walkthrough'>
            <div className='top-section'>
                <div style={{width:'55%', textAlign: 'center', margin: '0 auto'}}>
                    <p className='heading'> Recommendations</p>
                </div>
                { existingUserSelected === true ?
                    <div>
                        <p className='subheading'>
                            In your profile settings, you can choose your profile type and update personal details. You can also fill out our physical
                            activity questionnaire to get personalised recommendations modules that match your goals.
                        </p>
                        <img src={recommendations2} className="walkthrough-img" alt="Recommendations"/>
                    </div>
                :
                    <div>
                        <p className='subheading'>
                            We match you to personalised modules to help you achieve your goals. You can find your personal recommendations
                            inside your profile here and update your profile anytime.
                        </p>
                        <img src={recommendations} alt="Recommendations" width='65%' className="walkthrough-img"/>
                    </div>
                }
            </div>

            <div className='navigation'>
                <Button className='back-button' onClick={() => previousPage()}>
                    ← Back
                </Button>

                <Button className='next-button' onClick={() => nextPage()}>
                    Next →
                </Button>
            </div>
        </div>
    );
  }
}

export default withRouter(UserWalkthroughPageThree);
