import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import MessageUser from './MessageUser';
import Section from './Section';
import config from '../../constant/config';
import './UserDetails.css';
import logo from '../../resources/img/actify_logo.svg';

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessage: props.sendMessage || false,
      inviteUser: false,
      selectedHubs: [],
      inviteFeedbackMessage: null,
    };
    this.handleHubSelection = this.handleHubSelection.bind(this);
  }

  onClickSendMessage() {
    this.setState({
      sendMessage: true,
    });
  }

  handleInviteClick() {
    this.setState({ inviteUser: true });
  }

  async handleInviteSubmit() {
    const { selectedHubs } = this.state;
    if (selectedHubs.length > 0) {
      const errors = [];
      for (let i = 0; i < selectedHubs.length; i += 1) {
        try {
          const res = await fetch(`${config.apiURL}/hub/${selectedHubs[i]}/invite`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              inviteeId: this.props.modalUser.id,
            }),
          });
          if (res.status !== 200) {
            throw new Error(selectedHubs[i]);
          }
        } catch (err) {
          errors.push(err);
        }
        if (errors.length > 0) {
          console.log(errors);
        }
        this.setState({
          inviteFeedbackMessage: 'Invite(s) Successfully Sent',
        });
      }
    }
  }

  handleHubSelection(hubId, shouldAdd) {
    if (shouldAdd) {
      const selectedHubs = [...this.state.selectedHubs, hubId];
      this.setState({ selectedHubs });
    } else {
      const { selectedHubs } = this.state;
      const index = selectedHubs.indexOf(hubId);
      selectedHubs.splice(index, 1);
      this.setState({ selectedHubs });
    }
  }

  render() {
    if (this.state.inviteUser) {
      if (this.state.inviteFeedbackMessage) {
        return (
          <div className="invite-feedback-container">
            <p>{this.state.inviteFeedbackMessage}</p>
          </div>
        );
      }
      return (
        <div className="invite-modal-form-container">
          <div className="heading-container">
            <h4>Invite User</h4>
            <p>{`Select which hubs to invite ${this.props.modalUser.firstName} ${this.props.modalUser.surname} to`}</p>
          </div>
          <Section
            selectable
            context="hubs"
            tiles={this.props.adminHubs}
            addHub={this.handleHubSelection}
          />
          <div className="button-container">
            <Button className="modal-button" onClick={() => this.handleInviteSubmit()}>
              Invite
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="user-modal-container">
        {!this.state.sendMessage &&
          <div>
            <div>
              <img src={logo} width={"200"} height={"400"} alt="avatar" style={{margin: "-100px 0px -150px 30px"}}/>
            </div>
            <div className="avatar-container">
              <div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.modalUser.avatarId})` }} />
            </div>
            <div className="user-info-container">
              <h3>{`${this.props.modalUser.firstName} ${this.props.modalUser.surname}`}</h3>
              <div><Button onClick={() => this.onClickSendMessage()}>Message User</Button></div>
              {this.props.admin &&
                <Button onClick={() => this.handleInviteClick()}>
                  Invite User
                </Button>
              }
            </div>
          </div>
        }
        {this.state.sendMessage &&
          <MessageUser recipient={this.props.modalUser} />
        }
      </div>
    );
  }
}

export default UserDetails;
