import React, { Component } from 'react';
import {
  Button,
  FormControl
} from 'react-bootstrap';

import '../SignUpNew.css';

class PageSixPrac extends Component {

  addActivity(id) {
    const {
      chosenActivities,
      setChosenActivities,
      activitiesOptions
    } = this.props;

    const activity = activitiesOptions.filter(v => `${v.id}` === id);

    if (activity && activity.length > 0) {
      if (!chosenActivities.includes(activity[0])) {
        setChosenActivities([...chosenActivities, activity[0]]);
      }
    }
  }

  removeActivity(activity) {
    const {
      chosenActivities,
      setChosenActivities
    } = this.props;

    if (chosenActivities.includes(activity)) {
      const index = chosenActivities.indexOf(activity);
      chosenActivities.splice(index, 1);
      setChosenActivities(chosenActivities);
    }
  }

  renderActivites() {
    const { chosenActivities } = this.props;

    return chosenActivities.map(act => (
      <div
        key={act.id}
        className="type-ahead-option prac-colour-dark"
        onClick={() => this.removeActivity(act)}
      >
        <p>{act.description}</p>
        <div
          className="type-ahead-delete"
          onClick={() => this.removeActivity(act)}
        >
          ✕
        </div>
      </div>
    ));
  }

  render() {
    const {
      activitiesOptions,
      chosenActivities,
      handleButtonClick,
    } = this.props;

    const { skipValidation } = this.props;

    const valid = chosenActivities.length > 0;

    return (
      <div className="no-padding">
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              Which physical activities are your main interest?
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              There are a lot of activities you may be focusing on so feel free to choose more than one!
            </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour row">
          <div className="col-md-2 no-padding" />

          <div className="col-md-8 mb-20 mt-20 no-padding">
            <FormControl
              className="prac-colour type-ahead"
              componentClass="select"
              onChange={e => this.addActivity(e.target.value)}
            >
              <option value="select">Select activities</option>
              {activitiesOptions.map(activity => (
                <option key={activity.id} value={activity.id}>{activity.description}</option>
              ))}
            </FormControl>

            <div className="options-container">
              {this.renderActivites()}
            </div>
          </div>

          <div className="col-md-2 no-padding" />
        </div>

        <div className="navigation-buttons no-colour">
          <div className="back">
            <Button className="navigation-button" onClick={() => handleButtonClick(true)}>
              ← Back
            </Button>
          </div>
          {(valid || skipValidation) &&
            <div className="next">
              <Button className="navigation-button" onClick={() => handleButtonClick()}>
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageSixPrac;
