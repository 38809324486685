import React from 'react';
import {
  Button, Modal, FormGroup, ControlLabel, FormControl, HelpBlock,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import config from '../../constant/config';
import './ManageCustom.css';

class ManageCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModules: [],
      title: '',
      showModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal(context) {
    this.setState({ modalContext: context, showModal: true });
  }

  renderTableRows() {
    return this.props.custom.map(module => (
      <tr key={module.id}>
        <td><Link to={`/module/${module.id}`} >{module.title}</Link></td>
        <td>{module.category.description}</td>
        <td>{module.hub.name}</td>
        <td>{module.hub.organisation}</td>
        <td>
          <div className="warning-button-container">
            <Button className="warning-button" onClick={() => this.props.removeCustomClick(module.id)}>Remove</Button>
          </div>
        </td>

      </tr>
    ));
  }

  render() {
    return (
      <div className="manage-featured-container">
        <h4 className="custom-heading">{this.props.title}</h4> <i className="fa fa-pencil" aria-hidden="true" onClick={() => this.openModal('title')} />
        <table className="modules-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Category</th>
              <th>Hub</th>
              <th>Organisation</th>
              <th className="delete-heading">{`Remove from ${this.props.title}`}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>

        </table>
        { (this.props.nonCustomModules && this.props.nonCustomModules.length > 0) &&
          <div className="add-button-container">
            <Button
              className="add-button"
              onClick={() => this.openModal('modules')}
              disabled={this.props.custom.length >= config.customModuleLimit}
            >
              {`Add New ${this.props.title}`}
            </Button>
          </div>
        }
        { (this.props.custom && this.props.custom.length >= config.customModuleLimit) &&
          <p className="limit-text">{`You have reached the limit for ${this.props.title} Modules`}</p>
        }
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {this.state.modalContext === 'modules' &&
            <div className="featured-module-modal">
              <div className="heading-container">
                <h4>{`Manage ${this.props.title} Modules`}</h4>
              </div>
              <div className="typeahead-container">
                <AsyncTypeahead
                  autoFocus
                  multiple
                  labelKey={option => `${option.title}: ${option.hub.name}`}
                  filterBy={(option, text) => (`${option.title}: ${option.hub.name}`.toLowerCase().includes(text.toLowerCase()))}
                  onSearch={query => this.props.handleModuleSearch(query)}
                  searchText="Searching..."
                  emptyLabel="No Modules"
                  minLength={2}
                  options={this.props.modules.filter(m => !this.state.selectedModules.find(s => s.id === m.id))}
                  onChange={selection => this.setState({ selectedModules: selection })}
                  useCache={false}
                  renderMenuItemChildren={this.renderMenuItemChildren}
                />
              </div>
              <div className="button-container">
                <Button
                  className="add-button"
                  onClick={() => this.props.addCustomClick(this.state.selectedModules.map(m => m.id)) && this.setState({ showModal: false })}
                  disabled={this.props.custom.length + this.state.selectedModules.length > config.featuredModuleLimit}
                >
                  Add
                </Button>
                { this.props.custom.length + this.state.selectedModules.length > config.customModuleLimit && <HelpBlock className="limit-text">Your selection will exceed the module limit</HelpBlock>}
              </div>
            </div>
          }
          {this.state.modalContext === 'title' &&
            <div className="featured-module-modal">
              <div className="heading-container">
                <h4>Edit Custom Category Title</h4>
              </div>
              <FormGroup className="form" controlId="title" >
                <ControlLabel>Category Title:</ControlLabel>
                <FormControl
                  autoFocus
                  className="form-input"
                  type="text"
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
              </FormGroup>
              <div className="button-container">
                <Button className="add-button" onClick={() => this.props.updateCustomTitle(this.state.title) && this.closeModal()} >
                  Save
                </Button>
              </div>

            </div>
          }
        </Modal>
      </div>
    );
  }
}

export default ManageCustom;
