import React from 'react';
import SignUpNew from '../common/signup/SignUpNewContainer';
import Login from '../common/LoginContainer';
import checkStatus from '../common/checkStatus';
import './Home.css';

import { Modal, Button } from 'react-bootstrap';
import { Cookies } from "react-cookie-consent";
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';

import exploreHubsPers from '../../resources/img/explore_hubs_pers.svg';
import connectPers from '../../resources/img/connect_pers.svg';
import browseContentPers from '../../resources/img/browse_content_pers.svg';

import createHubPrac from '../../resources/img/create_hub_prac.svg';
import connectPrac from '../../resources/img/connect_prac.svg';
import publishModulesPrac from '../../resources/img/publish_modules_prac.svg';

import carouselPrac1 from '../../resources/img/Carosel HO 1.svg';
import carouselPrac2 from '../../resources/img/Carosel HO 2.svg';
import carouselPrac3 from '../../resources/img/Carosel HO 3.svg';
import carouselPrac4 from '../../resources/img/Carosel HO 4.svg';

import carouselPers1 from '../../resources/img/Carosel P 1.svg';
import carouselPers2 from '../../resources/img/Carosel P 2.svg';
import carouselPers3 from '../../resources/img/Carosel P 3.svg';
import carouselPers4 from '../../resources/img/Carosel P 4.svg';


class Home extends React.Component {
  constructor(props) {
    super(props);
    let showModal = false;
    let modalContent = '';

    this.hubInviteToken = new URLSearchParams(this.props.location.search).get("invite_token");
    this.closeModal = this.closeModal.bind(this);
    this.renderLogin = this.renderLogin.bind(this);

    if (this.hubInviteToken && !this.props.user) {
      showModal = true;
      modalContent = <SignUpNew hubInviteToken={this.hubInviteToken} loginRedirect={() => this.setState({ modalContent: this.renderLogin() })} setUser={this.props.setUser} handleModalClose={this.closeModal} />
    }

    this.state = {
      featuredModules: [],
      featuredHubs: [],
      recommendedModules: [],
      showModal,
      modalContent,
      videoLink: null,
      choice: null,
      screenWidth: window.innerWidth,
    };
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentWillMount() {
    this.setVideoEmbebedHtml();
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener('resize', this.handleResize);
  };


  onClickSignUp = () => {
    this.setState({
      showModal: true,
      modalContent: <SignUpNew hubInviteToken={this.hubInviteToken} loginRedirect={() => this.setState({ modalContent: this.renderLogin() })} setUser={this.props.setUser} handleModalClose={this.closeModal} />,
    });
  }

  renderLogin() {
    return <Login signupRedirect={() => this.onClickSignUp()} handleModalClose={this.closeModal} />;
  }

  closeModal() {
    this.setState({ showModal: false, modalContent: null });
  }

  async setVideoEmbebedHtml() {
    try {
      let video = null;
      if (Cookies.get('actifyCookieConsent') === 'true') {
        // embed link for home page video
        video = <iframe title="Actify 2.0 Video" className="video-wrapper" src={`https://player.vimeo.com/video/861908812?h=00d6a6880e&autoplay=1&loop=1&muted=1&texttrack=en-x-autogen`} allow="autoplay" frameBorder="0" allowFullScreen />;
      } else {
        video = <div style={{textAlign: 'center', marginTop: '20px'}}>This video will store cookies while you watch it. <br /> You need to allow cookies for the video to play.</div>;
      }
      this.setState({ 'videoLink': video });
    } catch (error) {
      console.log(error);
      this.setState({ 'videoLink': null });
    }
  }

  renderCarousel(images) {
    return <div>
      <Carousel
        additionalTransfrom={0}
        arrows
        centerMode={false}
        className=""
        containerClass="home-carousel"
        draggable
        focusOnSelect={false}
        itemClass="home-carousel-item"
        keyBoardControl
        minimumTouchDrag={80}
        partialVisible
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 3,
            partialVisibilityGutter: 30
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 40
          },
          tablet: {
            breakpoint: {
              max: 1023,
              min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            style={{
              width: '95%',
            }}
            alt={`Carousel item ${index}`}
          />
        ))}
      </Carousel>
    </div>
  }

  scrollDown = () => {
    if (this.helpingOthersSection) {
      this.helpingOthersSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  render() {
    const {
      videoLink,
      showModal,
      modalContent,
      choice,
    } = this.state;

    const imagesPers = [carouselPers1, carouselPers2, carouselPers3, carouselPers4];
    const imagesPrac = [carouselPrac1, carouselPrac2, carouselPrac3, carouselPrac4];

    const howToUseText =
    this.state.screenWidth < 769
      ? "How does it work?"
      : "How would you like to use Actify?";

    const isMobile = this.state.screenWidth < 769;

    return (
      <div>
        {console.log(this.state.screenWidth)}
        <div className="home-container">
          <div className="what-we-do">
            <div className="text-container">
              <span className="title-text">
                Helping more people become more active, more often, with Actify’s free online platform
              </span>
              <span className="main-text">
                Organise your teams, share resources with users and members, and connect with Scotland’s physical activity community – all in one place.
              </span>
              <Button
                className="blue-button"
                onClick={this.onClickSignUp}
              >
                <span>Get Started</span>
              </Button>
            </div>
            <div className="video-container">
              <div class="video">
                {videoLink}
              </div>
            </div>
          </div>
          <div className="support-container">
            <div>Supported by</div>
            <div className="supported-scotland" />
          </div>

          <div className="how-to-use-container">
            <span className="how-to-use-title">{howToUseText}</span>
            <span className="text">
              Toggle between the two profile types and scroll <br />
              below to see how you can use Actify.
            </span>

            <div className="how-use">
              <div className="choices">
                <div className='helping-others-section' ref={(el) => (this.helpingOthersSection = el)}
                  onClick={() => {
                    this.setState({ choice: 'helpOthers' });
                    this.scrollDown();
                  }}>
                  <div className={choice === 'helpOthers' ? "helping-others selected" : "helping-others"} />
                  <div className="pill-select">
                    <span className={choice === 'helpOthers' ? "prac-choice-selected" : "prac-choice"}>
                      Help others
                    </span>
                  </div>
                </div>
                <div className='personal-use-section'
                  onClick={() => {
                    this.setState({ choice: 'personalUse' });
                    this.scrollDown();
                  }}>
                  <div className={choice === 'personalUse' ? "running-treadmill selected" : "running-treadmill"} />
                  <div className="pill-select">
                    <span className={choice === 'personalUse' ? "pers-choice-selected" : "pers-choice"}>
                      Help myself
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* help others content */}
            {choice === 'helpOthers' &&
              <div className='how-to-use-content-prac'>
                <span className="how-to-use-title" >How can I use the Actify platform to help others?</span>
                <div className='carousel-container'>
                  {this.renderCarousel(imagesPrac)}
                </div>
                <div className='how-to-use-content'>
                  <span className="how-to-use-title">What can I do on Actify platform?</span>
                  <span className="text"> Share, learn and connect on the first platform designed specifically for the physical activity community</span>
                  <div className='icons-container'>
                    <img src={createHubPrac} alt="create hub icon" />
                    <img src={publishModulesPrac} alt="publish modules icon" />
                    <img src={connectPrac} alt="connect icon" />
                  </div>
                  {!isMobile && <span className='text'>Have questions about how you can use Actify to help others acheived their goals? Head to  <Link style={{ textDecoration: 'underline' }} to={`/about`} onClick={() => window.scrollTo(0, 0)}>About</Link> to hear from our users.</span>}
                  <div className='sign-up-button-container'>
                    <Button className="blue-button" onClick={this.onClickSignUp}>
                      <span>Get Started</span>
                    </Button>
                  </div>
                  {isMobile && <span className='text' style={{fontSize: '1em'}}>Have questions about how you can use Actify to help others acheived their goals? Head to  <Link style={{ textDecoration: 'underline' }} to={`/about`} onClick={() => window.scrollTo(0, 0)}>About</Link> to hear from our users.</span>}
                  {!isMobile && <div className="div-back-to-top">
                    <Button
                      className="blue-button"
                      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    >
                      Back to top ↑
                    </Button>
                  </div>}
                </div>
              </div>}

            {/* personal use content */}
            {choice === 'personalUse' &&
              <div className="how-to-use-content-pers">
                <span className="how-to-use-title">How can I use the Actify platform to improve my health and fitness?</span>
                <br></br>
                <div className='carousel-container'>
                  {this.renderCarousel(imagesPers)}
                </div>
                <span className="how-to-use-title">What can I do on Actify platform?</span>
                <span className="text"> Share, learn and connect on the first platform designed specifically for the physical activity community</span>
                <div className='icons-container'>
                  <img src={exploreHubsPers} alt="explore hubs icon" />
                  <img src={browseContentPers} alt="browse content icon" />
                  <img src={connectPers} alt="connect icon" />
                </div>

                {!isMobile && <span className='text'>Have a question about how to use Actify to learn about physical activity? Head to our <Link style={{ textDecoration: 'underline' }} to={`/faq`} onClick={() => window.scrollTo(0, 0)}>FAQs</Link>.</span>}
                <div className='sign-up-button-container'>
                  <Button className="blue-button" onClick={this.onClickSignUp}>
                    <span>Get Started</span>
                  </Button>
                </div>
                {isMobile && <span className='text' style={{fontSize: '1em'}}>Have a question about how to use Actify to learn about physical activity? Head to our <Link style={{ textDecoration: 'underline' }} to={`/faq`} onClick={() => window.scrollTo(0, 0)}>FAQs</Link>.</span>}
                {!isMobile && <div className="div-back-to-top">
                    <Button
                      className="blue-button"
                      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    >
                      Back to top ↑
                    </Button>
                  </div>}
              </div>}
            {choice !== null && (isMobile) &&
              <div className="div-back-to-top">
                <Button
                  className="blue-button"
                  onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                >
                  Back to top ↑
                </Button>
              </div>}

              {choice === null &&
              <div style={{height: '150px'}}/>}
            <div>
            </div>

          </div>

          <Modal show={showModal} onHide={this.closeModal} backdrop="static">
            <Modal.Header closeButton />
            {modalContent}
          </Modal>
        </div>
      </div>
    );
  }
}

export default checkStatus(Home);
