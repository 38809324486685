import React, { Component } from 'react';
import update from 'immutability-helper';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Word from './Word';
import WordSpace from './WordSpace';
import './DragQuestion.css';

class DragQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      words: props.questionWords.map((word, index) => (
        {
          text: props.editableWords.includes(index) ? null : word,
          textIndex: index,
          originalOrder: index,
          dragTarget: props.editableWords.includes(index),
        }
      )),
      droppedWords: [],
    };
  }

  onChangeAnswer() {
    const { words } = this.state;
    const answer = words.sort((a, b) => (a.originalOrder - b.originalOrder));
    const answerText = answer.map(a => a.text).join(' ');
    this.props.onChangeAnswer(answerText);
  }

  getStyle() {
    if (this.props.answer) {
      return {
        color: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        borderColor: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        border: '1px',
        borderStyle: 'solid',
      };
    }
    return null;
  }

  isDropped(word) {
    return this.state.droppedWords.indexOf(word) > -1;
  }

  async handleDrop(index, item) {
    const { textIndex, word } = item;
    const currentValue = this.state.words[index].text ? this.state.words[index].textIndex : null;
    const { droppedWords } = this.state;
    if (currentValue) {
      const currentPos = droppedWords.indexOf(currentValue);
      droppedWords.splice(currentPos, 1);
    }
    if (!droppedWords.includes(textIndex)) {
      droppedWords.push(textIndex);
    }
    const inUseIndex = this.state.words.findIndex(w => (w.textIndex === textIndex && w.text === word));
    let newWords;

    if (inUseIndex > -1 && inUseIndex !== index) {
      newWords = {
        [index]: {
          text: {
            $set: word,
          },
          textIndex: {
            $set: textIndex,
          },
        },
        [inUseIndex]: {
          text: {
            $set: null,
          },
          textIndex: {
            $set: null,
          },
        },
      };
    } else {
      newWords = {
        [index]: {
          text: {
            $set: word,
          },
          textIndex: {
            $set: textIndex,
          },
        },
      };
    }

    await this.setState(Object.assign({}, update(this.state, {
      words: newWords,
    }), { droppedWords }));
    this.onChangeAnswer();
  }

  render() {
    const { words } = this.state;
    return (
      <div className="checkbox-question-container" style={this.getStyle()}>
        <h3 className="question-text-container">
          <ol start={this.props.questionNumber}>
            <li>Drag the words into their correct position</li>
          </ol>
        </h3>
        {this.props.answer &&
          <div className="icon-container">
            {this.props.answer.isCorrect ?
              <i className="fa fa-check fa-2x" aria-hidden="true" /> :
              <i className="fa fa-times fa-2x" aria-hidden="true" />
            }
          </div>
        }
        <div className="answers-container">
          {words.map((word, index) => {
            if (word.dragTarget) {
              if (this.props.disabled) {
                const answerWords = this.props.answer.answerValue.split(' ');
                const correctAnswerWords = this.props.answer.correctAnswer.split(' ');
                if (answerWords[word.originalOrder] === correctAnswerWords[word.originalOrder]) {
                  return (
                    <div key={`correct-${word}-${index}`}className="answered-drag correct">{answerWords[index]}</div>
                  );
                }
                return (
                  <div key={`incorrect-${word}-${index}`} className="answered-drag incorrect">{answerWords[index]}</div>
                );
              }
              return (
                <WordSpace
                  key={`space-${word}-${index}`}
                  position={word.order}
                  lastDroppedItem={word.text}
                  onDrop={item => this.handleDrop(index, item)}
                />
              );
            }
            return (
              <div key={`order-${word}-${index}`} className="word-container">{word.text}</div>
            );
          })
          }
          <p className="instruction-text">{this.props.answer ? 'Correct Answer:' : 'Draggable word:'}</p>
          {!this.props.answer &&
            <div className="words-container">
              {this.props.questionWords.map((word, index) => {
                if (this.props.editableWords.includes(index)) {
                  return (
                    <Word
                      key={`word-${word}-${index}`}
                      textIndex={index}
                      word={word}
                      isDropped={this.isDropped(index)}
                    />);
                }
                return null;
              })}
            </div>}
          {this.props.answer &&
            <p className="correct-answer-text">{this.props.answer.correctAnswer}</p>}
        </div>
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(DragQuestion);
