/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button } from 'react-bootstrap';
import Section from '../Section';
import config from '../../../constant/config';
import './BookmarksTab.css';
import infoIcon from '../../../resources/img/info-icon.png';
import InfoBox from '../InfoBox';

class BookmarksTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookmarks: [],
      showingInfo : false,
    };
  }

  componentWillMount() {
    this.getBookmarks();
  }

  onClickMoudle(id) {
    this.props.history.push(`/module/${id}`);
  }

  async getBookmarks() {
    const res = await fetch(`${config.apiURL}/user/bookmarks`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    const bookmarks = await res.json();
    this.setState({ bookmarks });
  }

  render() {
    const handleEnter = () => {
      this.setState({showingInfo : true});
    };

    const handleLeave = () => {
      this.setState({showingInfo : false});
    };

    return (
      <div className="profile-tab-container">
        <div>
          <span className="title">
            Bookmarked Modules
            <img onMouseOver={()=>handleEnter()} onMouseOut={()=>handleLeave()} className='info-icon' src={infoIcon} alt='help icon' />
          </span>
          { this.state.showingInfo
          ?
          <div className='bookmark-info-box'>
            <InfoBox
              headerStyle={{fontSize: '18px'}}
              bodyStyle={{fontSize: '15px'}}
              imgStyle={{width: '360px'}}
              helpHeader="What is a bookmarked module and how to create a bookmark?"
              helpText="See something you like? Bookmark it and come back to it later. You can bookmark modules from any Hub and see them all on a single page."
            />
          </div>
          :
          null }
        </div>
        <Section
          context="modules"
          tiles={this.state.bookmarks}
          emptyText={
            <div className="empty-text">
              <div>It looks like you haven't bookmarked any Modules yet.</div>
              <div>
                <span>Press the</span><Button className="bookmark-button">Bookmark</Button><span>button on any Module page to save it to this section.</span>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default BookmarksTab;
