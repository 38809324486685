import React, { Component } from 'react';
import {
  Button,
  FormControl
} from 'react-bootstrap';

import '../SignUpNew.css';

class PageSevenPrac extends Component {

  addTargetGroup(id) {
    const {
      chosenTargetGroups,
      setChosenTargetGroups,
      targetGroupOptions
    } = this.props;

    const targetGroup = targetGroupOptions.filter(v => `${v.id}` === id);

    if (targetGroup && targetGroup.length > 0) {
      if (!chosenTargetGroups.includes(targetGroup[0])) {
        setChosenTargetGroups([...chosenTargetGroups, targetGroup[0]]);
      }
    }
  }

  removeTargetGroup(group) {
    const {
      chosenTargetGroups,
      setChosenTargetGroups
    } = this.props;

    if (chosenTargetGroups.includes(group)) {
      const index = chosenTargetGroups.indexOf(group);
      chosenTargetGroups.splice(index, 1);
      setChosenTargetGroups(chosenTargetGroups);
    }
  }

  renderTargetGroups() {
    const { chosenTargetGroups } = this.props;

    return chosenTargetGroups.map(group => (
      <div
        key={group.id}
        className="type-ahead-option prac-colour-dark"
        onClick={() => this.removeTargetGroup(group)}
      >
        <p>{group.description}</p>
        <div
          className="type-ahead-delete"
          onClick={() => this.removeTargetGroup(group)}
        >
          ✕
        </div>
      </div>
    ));
  }

  render() {
    const {
      targetGroupOptions,
      chosenTargetGroups,
      handleButtonClick,
    } = this.props;

    const { skipValidation } = this.props;

    const valid = chosenTargetGroups.length > 0;

    return (
      <div className="no-padding">
        <div className="title-section">
          <div>
            <p className="hlayout-title">
             Do you work with any of the following people?
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              We’d like to hear more about who you’re looking to help. You can select more than one group.
            </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour row">
          <div className="col-md-2 no-padding" />

          <div className="col-md-8 mb-20 mt-20 no-padding">
            <FormControl
              className="prac-colour type-ahead"
              componentClass="select"
              onChange={e => this.addTargetGroup(e.target.value)}
            >
              <option value="select">Select target groups</option>
              {targetGroupOptions.map(group => (
                <option key={group.id} value={group.id}>{group.description}</option>
              ))}
            </FormControl>

            <div className="options-container">
              {this.renderTargetGroups()}
            </div>
          </div>

          <div className="col-md-2 no-padding" />
        </div>

        <div className="navigation-buttons no-colour">
          <div className="back">
            <Button className="navigation-button" onClick={() => handleButtonClick(true)}>
              ← Back
            </Button>
          </div>
          {(valid || skipValidation) &&
            <div className="next">
              <Button className="navigation-button" onClick={() => handleButtonClick()}>
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageSevenPrac;
