import React, { Component } from 'react';
import { connect } from 'react-redux';
import checkStatus from '../common/checkStatus';
import './About.css';

import arrowsImage from '../../resources/img/about_page/arrows.svg';
import groupImage from '../../resources/img/about_page/group-image.svg';
import screenshot from '../../resources/img/about_page/screenshot.svg';
import thriveLogo from '../../resources/img/about_page/thrive-logo.svg';
import aptLogo from '../../resources/img/about_page/apt-logo.svg';

class About extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="about-container" >
        <div className="title-header">
          <div style={{margin: '0px 10px'}}>
            <h2>Hey! We're Actify</h2>
          </div>
        </div>
        <div className="our-story">
          <div className='middle-text'>
            <h1 className='header'>Our Story</h1>
            <p> Actify was launched in 2018 to help sport, physical activity and fitness organisations maximise their use of digital technology and provide the best support and guidance possible to their audiences.</p>
            <p> Since then we’ve built a brilliant team that brings together experience in sport, education, dance, play, digital technology and content production. We’ve experienced first hand the challenges the sector faces, which is why we’re well placed to develop great solutions.</p>
            <p>We work closely with the Scottish Government and over 150 organisations from around the country working in sport, leisure, health, education, active travel, play and research. We have helped the thousands of staff, volunteers and participants to:</p>
          <div className='learn-share-connect'>
            <img
              src={arrowsImage}
              alt="arrows"
              style={{ marginRight: "5px"}}
            />
            <div>
            <span className='learn-share-connect-text'>
              <span style={{color: '#FA6400', fontWeight: 'bold'}}>Learn</span> what will help them achieve the best outcomes <br></br>
              <span style={{color: '#FA6400', fontWeight: 'bold'}}>Share</span> what they know works and what they need<br></br>
              <span style={{color: '#FA6400', fontWeight: 'bold'}}>Connect</span> with others who can help </span>
            </div>
          </div>
          <p><br/>We take our evaluation pretty seriously and gather a range of data and evidence from everything we do. This approach has enabled us to continually evolve and develop or work to meet the needs and interests of our users.</p>
          </div>
        </div>

        <div className='group-image-div'>
          <div className='group-image'>
          <img
            className='group-image'
            src={groupImage}
            alt="groupImage"
          />
          </div>
        </div>

        <div className='our-vision-mission'>
            <div >
              <div>
                <h1 className='header'>Our Vision</h1>
                <p>We have a thriving and empowered sport and physical activity workforce that is building a more active Scotland.</p>
              </div>
              <div>
                <h1 className='header'>Mission Statement</h1>
                <p>To provide an innovative digital platform and training services, that enables the sport and physical activity workforce to learn, share and connect. </p>
              </div>
            </div>
        </div>

        <div className="how-can-we-help">
          <div className='middle-text'>
            <h1 className='header'>How Can We Help?</h1>

            <h2 className='header'>What’s the problem?</h2>
            <p>People and organisations delivering physical activities have loads of brilliant experience and resources but there was no digital platform specifically designed to support this work.</p>

            <h2 className='header'>How can Actify help?</h2>
            <p>Actify is a free tool to help the sector share learning and resources and to connect with their staff, volunteers and participants. Actify’s support packages help people to build the knowledge, skills and confidence that will enable them to continue to make the best use of digital.</p>
            <p>Anyone can sign up for an Actify account to access and connect with a huge variety of content to help them stay active or increase their activity levels. Organisations can create a hub which is branded, has links to their website and social media pages and can be organised through featuring modules or creating folders.</p>
            <p>Actify offers people options to interact with content (bookmark, follow, like, comment), for hub owners to have control over their content (edit, visibility settings, turning on/off comments) and the option to start conversations.</p>

            <h2 className='header'>What difference do we make?</h2>
            <p>Actify is trusted by 24,000+ users and 180+ Sport and Physical Activity organisations nationwide, who have all used the platform differently to meet their specific needs.</p>

            <h3 className='header'>Reaching more people easily:</h3>
            <p className='quote' style={{fontWeight: 'bold'}}>“The Cycling UK in Scotland team have started to use Actify to showcase some of our work across Scotland. Supported by the Scottish Government it enables us to quickly and easily put together engaging case studies and stories from our projects.”
            </p>
           <p className='quote'>Susanne Forup <br></br>Head of Development Scotland for Cycling UK</p>

            <h3 className='header'>Managing Workload:</h3>
            <p className='quote' style={{fontWeight: 'bold'}}>“It was only recently I discovered I could actually use the platform to help me manage mv workload by creating my own modules.”
            </p>
            <p className='quote'>Actify Hub Owner, 2023</p>
          </div>
        </div>

        <div className='group-image-div-video'>
          <div className='video'>
            <iframe title="Actify About Us Video" className="video-wrapper" src={`https://player.vimeo.com/video/917430325?h=b064df4f00&badge=0&autopause=0&player_id=0&app_id=58479`} frameBorder="0" allowFullScreen />
          </div>
        </div>

        <div className='find-out-more'>
          <h3 className='header' style={{ fontSize: '16px' }}>Find out more about our delivery programmes:</h3>
          <div className='logos'>
            <a href="https://actify.org.uk/apt">
              <img
                src={aptLogo}
                alt="aptLogo"
                style={{ minWidth: '30%', height: 'auto' }}
              />
            </a>
            <a href="https://actify.org.uk/thrive">
              <img
                src={thriveLogo}
                alt="thriveLogo"
                style={{ minWidth: '30%', height: 'auto' }}
              />
            </a>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  sideBarExpanded: state.sideBarExpanded,
});

export default connect(mapStateToProps)(checkStatus(About));
