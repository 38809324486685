import React, { Component, Fragment } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import config from './constant/config';
import { setNotifications, setActualNotifications, setUser } from './store/actions';
import TopNav from './components/common/TopNavContainer';
import SideBar from './components/common/SideBarContainer';
import NavWrapper from './components/common/NavWrapper';
import Home from './components/views/HomeContainer';
import FaqPage from './components/views/Faq';
import Explore from './components/views/ExploreContainer';
import Module from './components/views/ModuleContainer';
import EditModule from './components/views/EditModuleContainer';
import UserGuide from './components/views/UserGuide';
import Contact from './components/views/Contact';
import HubHome from './components/views/HubHomeContainer';
import MyProfile from './components/views/MyProfileContainer';
import Result from './components/views/Result';
import SessionFeedback from './components/views/SessionFeedbackContainer';
import ManageSite from './components/views/ManageSite';
import About from './components/views/About';
import Support from './components/views/Support';
import Plan from './components/views/PlanContainer';
import { PageView, initGA } from './components/Tracking';
import EditPlan from './components/views/EditPlan';
import EditSession from './components/views/EditSessionContainer';
import ResetPassword from './components/views/ResetPassword';
import CookieConsent from "react-cookie-consent";
import GoogleAnalytics from './components/GoogleAnalytics';
import googleAnalyticsScript from './components/common/GoogleAnalyticsScript';
import './App.css';
import { NavbarCustom } from './components/common/Navbar';
import cookie from './resources/img/cookie.svg';

const PrivateRoute = ({ component: Comp, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={props => authenticated // eslint-disable-line
      ? <Comp {...props} />
      : <Redirect to={{ pathname: '/home', state: { from: props.location, login: true } }} />}
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // reactGaInitialised: false,
    };
  }

  componentDidMount() {
    googleAnalyticsScript(config.GA_TRACKING_ID);
    this.pollNotifications();
    // initGA(config.GA_TRACKING_ID);
    PageView();
  }

  async pollNotifications() {
    setTimeout(() => this.pollNotifications(), config.pollNotificationsMs);
    if (!this.props.user) {
      return;
    }
    try {
      const res = await fetch(`${config.apiURL}/user/notifications`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const notifications = await res.json();
        this.props.setNotifications(notifications.count);
        this.props.setActualNotifications(notifications.notifications)
      }
    } catch (err) { console.log(err) }
  }

  renderCookieBanner() {
    return (
      <CookieConsent
        enableDeclineButton
        flipButtons
        buttonText="Allow all"
        declineButtonText="Decline"
        cookieName="actifyCookieConsent"
        className='cookie'
        style={this.props.user ? {animationName: "slide-up", animationDuration: "3.5s", background: "#5CC5D2", zIndex: 2001, padding: "18px 0 18px 0"} : {animationName: "slide-up", animationDuration: "3.5s", background: "#5CC5D2", zIndex: 1001, padding: "18px 0 18px 0"}}
        contentClasses='contentWrapper'
        buttonWrapperClasses='cookieWrapper'
        buttonClasses='cookieButton'
        declineButtonClasses='cookieButton'
        buttonStyle={{ backgroundColor: '#fa6400'}}
        declineButtonStyle={{ backgroundColor: '#B4B4B4', color: '#FFFFFF'}}
        disableButtonStyles={true}
        onAccept={() => window.location.reload(false)}
      >
        <img
          alt="cookie"
          src={cookie}
          className='cookieImage'
        />
        <div className='cookieText'>
          <p className='cookieLargeText'>This website uses cookies</p>
          <p className='cookieSmallText'>Actify uses cookies to enhance the user experience and personalize content for you. </p>
          <p className='cookieSmallText'>Check out &nbsp;
            <a href="https://drive.google.com/file/d/1VuYoLYLKaHjtZ8YDMTomAwWSemSE-shi/view?usp=sharing" target='_blank' rel="noopener noreferrer" style={{ color: '#FAC832'}}>Privacy Policy</a> {" & "}
            <a href="https://drive.google.com/open?id=1wtyvkJ1A3PzZjejDyzxcBAy4p8bRASDA" target='_blank' rel="noopener noreferrer" style={{ color: '#FAC832' }}>Cookie Policy</a>
          </p>
        </div>
      </CookieConsent>
    );
  }

  renderMainView() {
    if (this.state.isLoading) {
      return (
        <div className="loading-screen">
          <div className="loading-text">Loading...</div>
        </div>
      );
    }
    return (
      <SideBar>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/explore" component={Explore} />
          <Route path="/about" component={About} />
          <Route path="/support" component={Support} />
          <Route path="/userguide" component={UserGuide} />
          <Route path="/contact" component={Contact} />
          <Route path="/result/:search" component={Result} />
          <Route path="/manage" component={ManageSite} />
          <PrivateRoute
            path="/manage"
            authenticated={this.props.user}
            component={ManageSite}
          />
          <PrivateRoute
            path="/myprofile"
            authenticated={this.props.user}
            component={MyProfile}
          />
          <Route path="/module/:moduleId" component={Module} />
          <Route path="/edit/module/:moduleId" component={EditModule} />
          <Route path="/session/:sessionId/feedback" component={SessionFeedback} />
          <Route path="/session/:sessionId/edit" component={EditSession} />
          <Route path="/reset/:userId/:token" component={ResetPassword} />
          <Route path="/:pathname/plan/:planId/edit" component={EditPlan} />
          <Route path="/:pathname/plan/:planId" component={Plan} />
          <Route path="/:pathname" component={HubHome} />
        </Switch>
      </SideBar>
    );
  }

  render() {
    return (
      <BrowserRouter>
        <Fragment>
          <Switch>
            <Redirect exact path="/" to="/home" />
            <NavWrapper>
              <TopNav />
              {this.renderMainView()}
              {this.renderCookieBanner()}
            </NavWrapper>
          </Switch>
          <GoogleAnalytics trackingID={config.GA_TRACKING_ID}/>
        </Fragment>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setUser: (user) => {
    dispatch(setUser(user));
  },
  setNotifications: (notifications) => {
    dispatch(setNotifications(notifications));
  },
  setActualNotifications: (notifications) => {
    dispatch(setActualNotifications(notifications));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
