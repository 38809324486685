import React, { Component } from 'react';
import {
  ControlLabel,
  FormControl,
  Button,
} from 'react-bootstrap';
import moment from 'moment';
import config from '../../../constant/config';

import './SignUpNew.css';

class PageTwo extends Component {
  renderDays() {
    const { monthProp } = this.props;
    const month = config.monthData.find(m => m.number === parseInt(monthProp, 10)) || {};
    const daysCount = month.days || 31;
    const days = [];
    for (let i = 1; i <= daysCount; i += 1) {
      days.push(<option key={i} value={i}>{i}</option>);
    }
    return days;
  }

  renderYears() {
    const years = [];
    const currentYear = moment().year();
    for (let i = currentYear; i > currentYear - config.yearLimit; i -= 1) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  }

  renderMonths() {
    const monthsList = config.monthData.map(month => (
      <option key={month.short} value={month.number}>{month.name}</option>
    ));
    return monthsList;
  }

  renderGender() {
    const { genderOptions } = this.props;
    const genders = genderOptions.map(gender => (
      <option key={gender.key} value={gender.key}>{gender.gender}</option>
    ));
    return genders;
  }

  render() {
    const {
      location,
      locations,
      setLocation,
      handleButtonClick,
      day,
      setDay,
      month,
      setMonth,
      year,
      setYear,
      gender,
      genderOptions,
      setGender,
    } = this.props;

    const { skipValidation } = this.props;

    const validDay = day.length > 0 && day.length < 255;
    const validMonth = month.length > 0;
    const validYear = year.length > 0 && year.length < 255;

    const validGender = !!genderOptions.find(g => g.gender === gender);
    const validLocation = config.councilAreas.includes(location);

    const valid = validDay && validMonth && validYear && validGender && validLocation;

    return (
      <div className="no-padding">
        <div className="title-section row" style={{margin: "2em 3em"}}>

          <div className="col-md-12">
            <p className="hlayout-title">
              Why does Actify need to collect this information about me?
            </p>
          </div>

          <div className="col-md-12">
            <p className="hlayout-copy">
              Actify will use this data to inform service updates and changes making sure they are inclusive
              and relevant for users, as well as to capture the equality and diversity progress of
              the Scottish PA and sport sector. Your data will not be visible on your profile.
            </p>
          </div>
        </div>

        <div className="row hlayout-bottom">
          <div className="col-md-3 no-padding" />

          <div className="col-md-6 mb-20 mt-20 no-padding">
            <div className="modal-form-container">
              <div className="input-g">
                <ControlLabel className="input-label about-user">
                  How do you identify?
                </ControlLabel>
                <FormControl
                  className="form-input blue-input"
                  componentClass="select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="select">Select one</option>
                  {this.renderGender()}
                </FormControl>
              </div>

              <div className="input-g">
                <ControlLabel className="input-label about-user">
                  What's your birthday?
                </ControlLabel>
                <div className='birthday-input'>
                  <FormControl
                    className="form-input blue-input day"
                    componentClass="select"
                    value={day}
                    onChange={e => setDay(e.target.value)}
                  >
                    <option value="select">Day</option>
                    {this.renderDays()}
                  </FormControl>

                  <FormControl
                    className="form-input blue-input month"
                    componentClass="select"
                    value={month}
                    onChange={e => setMonth(e.target.value)}
                  >
                    <option value="select">Month</option>
                    {this.renderMonths()}
                  </FormControl>

                  <FormControl
                    className="form-input blue-input year"
                    componentClass="select"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                  >
                    <option value="select">Year</option>
                    {this.renderYears()}
                  </FormControl>

                </div>

              </div>

              <div className="input-g">
                <ControlLabel className="input-label about-user">
                  Where do you live?
                </ControlLabel>
                <FormControl
                  className="form-input blue-input"
                  componentClass="select"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="select">Location</option>
                  {locations}
                </FormControl>
              </div>
            </div>
          </div>

          <div className="col-md-3 no-padding" />
        </div>

        <div className="navigation-buttons">
          <div className="back">
            <Button className="navigation-button" onClick={() => handleButtonClick(true)}>
              ← Back
            </Button>
          </div>
          {(valid || skipValidation) &&
            <div className="next">
              <Button className="navigation-button" onClick={() => handleButtonClick()}>
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageTwo;
