import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import config from '../../constant/config';
import SessionFeedback from '../views/SessionFeedback';
import './SessionDetails.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faBan } from '@fortawesome/free-solid-svg-icons'


class SessionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedback: null,
      accessLevel: config.hubAccessLevels.PUBLIC_USER,
      showModal: false,
    };
  }

  componentWillMount() {
    this.getSessionFeedback();
    this.getHubAccessLevel();
  }

  async onViewPlan(e) {
    e.preventDefault();
    const res = await fetch(`${config.apiURL}/hub/${this.props.session.plan.hubId}/pathname`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 200) {
      const hub = await res.json();
      this.props.history.push(`${hub.pathName}/plan/${this.props.session.plan.id}`);
    }
  }

  onLeaveFeedback() {
    this.setState({
      showModal: true,
    });
    // this.props.history.push(`/session/${this.props.session.id}/feedback`);
  }
  onEditSession(e) {
    this.props.history.push(`/session/${this.props.session.id}/edit`);
  }

  async onDeleteSession() {
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.props.session.plan.hubId}/session/${this.props.session.id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status !== 200) {
        throw new Error('unable to delete session');
      }
      window.location.reload();
    } catch (err) {
      console.error(err.message);
    }
  }

  async getHubAccessLevel() {
    if (this.props.user.isSiteOwner) {
      this.setState({
        accessLevel: config.hubAccessLevels.SITE_OWNER,
      });
      return;
    }
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.props.session.hubId}/access`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status !== 200) {
        throw new Error('unable to get access level for session hub');
      }
      const accessLevel = await res.json();
      this.setState({
        accessLevel,
      });
    } catch (err) {
      this.setState({
        accessLevel: config.hubAccessLevels.PUBLIC_USER,
      });
    }
  }

  async getSessionFeedback() {
    try {
      const res = await fetch(`${config.apiURL}/session/${this.props.session.id}/feedback`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const feedback = await res.json();
        this.setState({ feedback });
      }
    } catch (err) {
      this.setState({ feedback: null });
    }

  }

  render() {
    const { title } = this.props.session;
    const sessionDate = moment(this.props.session.dueDate).format('Do MMMM YYYY');
    return (
      <div className="session-details-container">
        <Modal className="session-feedback-modal" show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Header closeButton />
          <SessionFeedback session_id={this.props.session.id} user={this.props.user} title={title} closeModal={() => this.setState({ showModal: false })} />
        </Modal>
        {!this.state.confirmDelete &&
          <div>
            <div className="session-plan-title">{title}</div>
            <div className="session-date">{sessionDate}</div>
            <div className="session-address">
              <div>{this.props.session.address1} {this.props.session.address2}</div>
              <div>{this.props.session.postcode}</div>
            </div>
            <div className="button-container">
              <Button className="activai-button" onClick={e => this.onViewPlan(e)}>VIEW FOLDER</Button>
              {(!this.state.feedback || this.state.accessLevel >= config.hubAccessLevels.HUB_ADMIN) &&
                <Button className="activai-button" onClick={e => this.onLeaveFeedback(e)}>
                  {this.state.feedback === null ? 'GIVE FEEDBACK' : 'REVIEW FEEDBACK'}
                </Button>
              }
            </div>

            {this.state.accessLevel >= config.hubAccessLevels.HUB_ADMIN &&
              <div className="button-container">
                <Button className="activai-button" onClick={e => this.onEditSession(e)}>EDIT SESSION <FontAwesomeIcon icon={faPen} /></Button>
                <Button className="activai-button delete-button" onClick={() => this.setState({ confirmDelete: true })}>DELETE <FontAwesomeIcon icon={faBan} /></Button>
              </div>
            }
          </div>
        }
        {this.state.confirmDelete &&
          <div className="confirm-delete-container">
            <div className="confirm-delete-title">Delete Session</div>
            <div className="confirm-delete-text">Are you sure you wish to delte this session?</div>
            <div className="button-container">
              <Button className="activai-button" onClick={() => this.setState({ confirmDelete: false })}>CANCEL</Button>
              <Button className="activai-button delete-button" onClick={e => this.onDeleteSession(e)}>DELETE</Button>
            </div>
          </div>
        }
        {!this.state.confirmDelete && this.props.session.additionalInfo &&
          <div className="additional-information-container">
            <div className="additional-information-title">
              Additional information
            </div>
            <div className="additional-information">{this.props.session.additionalInfo}</div>
          </div>
        }

      </div>
    );
  }
}

export default withRouter(SessionDetails);
