import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import navigation from '../../resources/img/navigation.svg'
import explore from '../../resources/img/explore_image.svg'

class UserWalkthroughPageFour extends Component {

render() {
    const { nextPage, previousPage, existingUserSelected } = this.props;

    return (
        <div className = 'walkthrough'>
        { existingUserSelected === true ?
            <div className='top-section'>
                <div style={{width:'55%', textAlign: 'center', margin: '0 auto'}}>
                    <p className='heading'> Navigation</p>
                </div>
                <div>
                    <p className='subheading'>
                        You may have noticed that your profile navigation bar looks slightly different. You can access this here...
                    </p>
                    <img src={navigation} alt="Navigation" className="walkthrough-img"/>
                </div>
            </div>
        :
            <div className='top-section'>
                <div style={{width:'55%', textAlign: 'center', margin: '0 auto'}}>
                    <p className='heading'> Explore</p>
                </div>
                <div>
                    <p className='subheading'>
                        You'll find new themes on the Actify Explore page every month. We highlight a handful of modules and Hubs that offer content
                        relating to the monthly theme, which you will find here. If you're looking for a specific Hub, you can use the search bar
                        here to find them! Try searching by popular hashtags or keywords to find information you're interested in.
                    </p>
                    <img src={explore} alt="Explore" className="walkthrough-img"/>
                </div>
            </div>
        }
            <div className='navigation'>
                <Button className='back-button' onClick={() => previousPage()}>
                    ← Back
                </Button>

                <Button className='next-button' onClick={() => nextPage()}>
                    Next →
                </Button>
            </div>
        </div>
    );
  }
}

export default withRouter(UserWalkthroughPageFour);
