import { connect } from 'react-redux';
import Module from './Module';
import { addBookmark, removeBookmark } from '../../store/actions';

const mapStateToProps = state => ({
  user: state.user,
  results: state.userQuizResults,
});

const mapDispatchToProps = dispatch => ({
  removeBookmark: (moduleId) => {
    dispatch(removeBookmark(moduleId));
  },
  addBookmark: (bookmark) => {
    dispatch(addBookmark(bookmark));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Module);
