import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Navbar, Nav, NavItem, NavDropdown, Image, FormGroup, FormControl, Modal, MenuItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import icon from '../../resources/img/actify_icon.svg';
import logo from '../../resources/img/actify_logo_small.png';
import SignUpNew from '../common/signup/SignUpNewContainer';
import Login from './LoginContainer';
import CreateHub from './CreateHub';
import config from '../../constant/config';
import './TopNav.css';
import { NavLinksMobile } from "./NavLinksMobile";
import { Logo } from "./NavbarLogo";

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faBell, faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons'

class TopNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalContext: null,
      showSearch: false,
      searchBarValue: "",
      screenWidth: window.innerWidth,
      isOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.hubInviteToken = new URLSearchParams(this.props.location.search).get("invite_token");
    this.hubInviteId = new URLSearchParams(this.props.location.search).get("inviteId");
  }

  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    const { activeRole } = this.props;
    document.documentElement.setAttribute('data-theme', activeRole ? 'personal' : '');
    window.addEventListener('resize', this.handleResize);

    // if there is a hub invite id in the link, add user to the hub if logged in or prompt user to log in
    if (this.hubInviteId) {
      if (this.props.user) {
        this.onHubInvitationId(this.hubInviteId, true);
      } else {
        this.openModal(2);
      }
    }
  }

  componentWillUnmount() {
    // Clean up the event listener on component unmount
    window.removeEventListener('resize', this.handleResize);
  }

  onClickNotification(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push('/myprofile');
    this.props.setActiveProfileTab(3);
  }

  onClickAvatar(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push('/myprofile');
    this.props.setActiveProfileTab(0);
  }

  onClickCreate(e) {
    e.preventDefault();
    this.props.history.push('/myprofile');
    this.props.setActiveProfileTab(2);
  }

  closeModal(e, reloadWindow) {
    if (reloadWindow) {
      if (this.hubInviteId) {
        this.onHubInvitationId(this.hubInviteId, true);
      }
      this.props.history.push('/myprofile');
      this.props.setActiveProfileTab(0);
    }
    this.setState({ showModal: false, modalContext: null });
  }

  onClickLink(e, link) {
    e.preventDefault();
    const lowerLink = link.toLowerCase();
    this.props.history.push(`/${lowerLink}`);
  }

  openModal(id) {
    this.setState({ showModal: true, modalContext: id });
  }

  handleMenuClick(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.onMenuPress();
  }

  async handleSearchIconClick(e) {
    e.preventDefault();
    await this.setState({
      showSearch: !this.state.showSearch,
    });
    ReactDOM.findDOMNode(this.searchBar).focus();
  }

  async handleLogout() {
    try {
      await fetch(`${config.apiURL}/user/logout`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      });
      await this.props.onLogout();
      this.props.history.push('/');
    } catch (err) {
      console.log(err);
    }
  }

  async onHubInvitationId(id, accepted) {
    try {
      const res = await fetch(`${config.apiURL}/user/hubinvites`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id,
          accepted,
        }),
      });
      if (res.status === 200) {
        const body = await res.json();
        if (body.accepted && body.hub) {
          this.props.addHub(body.hub);
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleSearch(e) {
    const { searchBarValue } = this.state;
    const searchQuery = searchBarValue.toLowerCase();
    this.props.history.push({ pathname: `/result/${searchQuery}`, query: searchQuery });
  }

  checkNotifications() {
    if (this.props.notifications > 0) {
      return true;
    }
    return false;
  }

  renderHubInvites(hubInvites) {
    if (hubInvites.length > 0) {
      return hubInvites.map(invite => (
        <div>
          <div className="notification-item">
            <span className="notification-item-text">You have a hub invite to <b>{invite.hub.name}</b>!</span>
            {' '}
            <span className="small-timestamp">{moment(invite.createdAt).fromNow()}</span>
          </div>
        </div>
      ));
    }
    return;
  }

  renderPendingReviews(pendings) {
    if (pendings.length > 0) {
      return pendings.map(pending => (
        <div>
          <div className="notification-item">
            <span className="notification-item-text">
              Pending quiz review for <b>{pending.firstName} {pending.surname}</b>.
              {' '}
              <span className="small-timestamp">{moment(pending.createdAt).fromNow()}</span>
            </span>
          </div>
        </div>
      ));
    }
    return;
  }

  renderCompletedReviews(completed) {
    let filtered = [];
    for (let i = 0; i < completed.length; i++) {
      if (completed[i].module) {
        filtered.push(completed[i])
      }
    }
    if (filtered.length > 0) {
      return filtered.map(complete => (
        <div>
          <div className="notification-item">
            <span className="notification-item-text">
              Quiz to review: <b>{complete.module && complete.module.title}</b>.
              {' '}
              <span className="small-timestamp">{moment(complete.createdAt).fromNow()}</span>
            </span>
          </div>
        </div>
      ));
    }
    return;
  }

  renderComments(comments) {
    if (comments.length > 0) {
      return comments.map(comment => (
        <div>
          <div className="notification-item">
            <span className="notification-item-text">
              Someone has {comment.isReply ? 'has replied to your comment.' : 'has left a comment on your module.'}
              {' '}
              <span className="small-timestamp">{moment(comment.createdAt).fromNow()}</span>
            </span>
          </div>
        </div>
      ));
    }
    return;
  }

  renderApproved(hubs) {
    if (hubs.length > 0) {
      return hubs.map(hub => (
        <div>
          <div className="notification-item">
            <span className="notification-item-text">
              Your hub <b>{hub.hub.name}</b> has been approved!
              {' '}
              <span className="small-timestamp">{moment(hub.createdAt).fromNow()}</span>
            </span>
          </div>
        </div>
      ));
    }
    return;
  }

  renderMessages(messages) {
    if (messages.length > 0) {
      return messages.map(msg => (
        <div>
          <div className="notification-item">
            <span className="notification-item-text">
              <b>{msg.sender.firstName}</b> has sent you a message!
              {' '}
              <span className="small-timestamp">{moment(msg.createdAt).fromNow()}</span>
            </span>
          </div>
        </div>
      ));
    }
    return;
  }

  renderNotifications() {
    // console.log(this.props.actualNotifications)
    if (this.props.actualNotifications.length > 0) {
      console.log(this.props.actualNotifications)
      const actNotif = this.props.actualNotifications;
      if (actNotif.length === 0) {
        return (
          <span className="notification-item-text">You have no notifications!</span>
        );
      }

      const combinedNotifications = [
        ...actNotif.hubInvites,
        ...actNotif.pendingReview,
        ...actNotif.completedReviews,
        ...actNotif.comments,
        ...actNotif.approvedHubs,
        ...actNotif.messages,
      ];

      // console.log(combinedNotifications);
      combinedNotifications.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return (
        <div className="notification-list">
          {combinedNotifications.map(notification => {
            // Render each notification based on its type
            if (actNotif.hubInvites.includes(notification)) {
              return this.renderHubInvites([notification]);
            } else if (actNotif.pendingReview.includes(notification)) {
              return this.renderPendingReviews([notification]);
            } else if (actNotif.completedReviews.includes(notification)) {
              return this.renderCompletedReviews([notification]);
            } else if (actNotif.comments.includes(notification)) {
              return this.renderComments([notification]);
            } else if (actNotif.approvedHubs.includes(notification)) {
              return this.renderApproved([notification]);
            } else if (actNotif.messages.includes(notification)) {
              return this.renderMessages([notification]);
            } else {
              return null; // Unknown notification type
            }
          })}
        </div>
      );
    }

  }

  isItemActive(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { showModal } = this.state;
    switch (item.toLowerCase()) {
      case 'home':
        return pathname === '/home' && !showModal;
      case 'explore':
        return pathname === '/explore' && !showModal;
      case 'about':
        return pathname === '/about' && !showModal;
      case 'support':
        return pathname === '/support' && !showModal;
      case 'contact':
        return pathname === '/contact' && !showModal;
      case 'faq':
        return pathname === '/faq' && !showModal;
      default:
        return false;
    }
  }

  renderSignInDesktop() {
    const {
      searchBarValue
    } = this.state;

    return (
      <div className="top-nav-signed-out">
        <Navbar.Header>
          <div className="logo-container">
            <Link to="/home"><Image className="nav-logo" src={logo} /></Link>
          </div>


          <Navbar.Form className="search-bar-form" style={{ flexShrink: 1 }}>
            <FormGroup>
              <FormControl
                className="nav-search"
                type="text"
                placeholder="Search..."
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSearch();
                  }
                }}
                value={searchBarValue}
                onChange={(e) => this.setState({ searchBarValue: e.target.value })}
                ref={(r) => { this.searchBar = r; }}
              />
              <div className="search-button" onClick={() => this.handleSearch()}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </FormGroup>
          </Navbar.Form>
        </Navbar.Header>

        <div className="top-nav-links">
          <span
            className={this.isItemActive('home') ? 'selected' : ''}
            onClick={() => {
              this.props.history.push('/home');
              window.scrollTo(0, 0);
            }}
          >
            Home
          </span>
          <span
            className={this.isItemActive('explore') ? 'selected' : ''}
            onClick={() => {
              this.props.history.push('/explore');
              window.scrollTo(0, 0);
            }}
          >
            <FontAwesomeIcon icon={faCompass} />
            {' '}
            Explore
          </span>
          <span
            className={this.isItemActive('about') ? 'selected' : ''}
            onClick={() => {
              this.props.history.push('/about');
              window.scrollTo(0, 0);
            }}
          >
            About
          </span>
          <span
            className={this.isItemActive('support') ? 'selected' : ''}
            onClick={() => {
              this.props.history.push('/support');
              window.scrollTo(0, 0);
            }}
          >
            Support
          </span>
          <span
            className={this.isItemActive('faq') ? 'selected' : ''}
            onClick={() => {
              this.props.history.push('/faq');
              window.scrollTo(0, 0);
            }}
          >
            FAQs
          </span>
          <span
            className={this.isItemActive('contact') ? 'selected' : ''}
            onClick={() => {
              this.props.history.push('/contact');
              window.scrollTo(0, 0);
            }}
          >
            Contact Us
          </span>
        </div>

        <div className="top-nav-buttons">
          <div
            className="top-nav-signin-btn"
            onClick={() => this.openModal(2)}
          >
            <span>Login</span>
          </div>
          <div
            className="top-nav-register-btn"
            onClick={() => this.openModal(1)}
          >
            <span>Get Started</span>
          </div>
        </div>
      </div>
    )
  }

  renderSignInMobile() {
    const {
      searchBarValue
    } = this.state;

    return (
      <div className="top-nav-signed-out-mobile">

        <div className="Navbar-container-signIn" style={(this.state.isOpen)
          ? { paddingTop: 30, paddingBottom: 246, paddingRight: 30, paddingLeft: 30, backgroundColor: "var(--color-background-darker-role)" }
          : { paddingLeft: 30, paddingRight: 10, paddingTop: 0, paddingBottom: 0 }}
        >
          <div className="left-section-nav">
            <Link to="/home"><Image className="nav-logo" src={logo} /></Link>
          </div>
          <div className="middle-section-nav" >


          </div>
          <div className="right-section-nav" style={{ display: "flex" }}>
            <Navbar.Form className="search-bar-form">
              <FormGroup>
                <FormControl
                  className="nav-search-signIn"
                  type="text"
                  placeholder="Search..."
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.handleSearch();
                    }
                  }}
                  value={searchBarValue}
                  onChange={(e) => this.setState({ searchBarValue: e.target.value })}
                  ref={(r) => { this.searchBar = r; }}
                />
                <div className="search-button" onClick={() => this.handleSearch()}>
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              </FormGroup>
            </Navbar.Form>

            <NavLinksMobile toggle={this.toggleMenu} state={this.state.isOpen} color={"#14b4c8"} buttons={true} />
          </div>
        </div>


      </div>
    )
  }

  renderSignIn() {
    const {
      modalContext,
      showModal
    } = this.state;

    const { setUser } = this.props;
    const isDesktop = this.state.screenWidth > 956;

    return (
      <Navbar className="top-nav mobile" collapseOnSelect fixedTop style={{ paddingLeft: 0 }}>
        {isDesktop ? this.renderSignInDesktop() : this.renderSignInMobile()}
        <Modal show={showModal} onHide={this.closeModal} backdrop="static">
          <Modal.Header closeButton />
          {modalContext === 1 && <SignUpNew hubInviteToken={this.hubInviteToken} loginRedirect={() => this.setState({ modalContext: 2 })} setUser={setUser} handleModalClose={this.closeModal} />}
          {modalContext === 2 && <Login signupRedirect={() => this.setState({ modalContext: 1 })} handleModalClose={this.closeModal} />}
          {modalContext === 3 && <CreateHub handleModalClose={this.closeModal} />}
        </Modal>
      </Navbar>
    )
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };
renderRightNav(){
  const {
    searchBarValue,
    modalContext,
    showModal
  } = this.state;

  const { user, setUser, activeRole, setActiveRole } = this.props;
  const isMobile = this.state.screenWidth < 1023;

  if (!user.accountType) {
    this.handleLogout();
  } else if (user.accountType.id === 1) {
    setActiveRole(true);
  } else if (user.accountType.id === 2) {
    setActiveRole(false);
  }

  if (this.state.showSearch) {
    return null;
  }

  return(
<Nav pullRight className="nav-loggedin">
              {!activeRole &&
                <NavItem
                  eventKey={4}
                  onSelect={() => this.openModal(3)}
                  className="btn create-hub-btn"
                >
                  <span>Create</span>
                  {' Hub '}
                  +
                </NavItem>
              }
              <NavDropdown
                id="user-drop-down"
                className='notifications-drop-down'
                title={
                  <div>
                    <span>
                      <FontAwesomeIcon
                        icon={faBell}
                        size="2x"
                        aria-hidden="true"
                        className="bell-icon"
                      />
                    </span>
                    {this.checkNotifications() && <div className="notification" />}
                  </div>
                }>
                <div className="notification-container">
                  <span className="notification-header">Notifications</span>
                  <hr />
                  {this.renderNotifications()}
                </div>
                <MenuItem onClick={e => this.onClickNotification(e)}>
                  <div className="notification-button-view">
                    View notifications
                  </div>
                </MenuItem>
              </NavDropdown>
              <NavDropdown
                id="user-drop-down"
                title={
                  <div className="top-nav-user">
                    <div
                      className="avatar-image"
                      style={{ backgroundImage: `url(${config.spacesURL}/${user.avatarId}.thumbnail)` }}
                    />
                    <span>
                      <span id="nav-user-text">
                        {!activeRole ? 'Helping others' : 'Personal use'}
                        {' '}
                      </span>
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </div>
                }>
                <MenuItem onClick={e => this.onClickAvatar(e)}>Profile</MenuItem>
                <hr />
                <MenuItem onClick={e => this.onClickLink(e, 'help')}>Help</MenuItem>
                <hr />

                {user.accountType.id === 3 &&
                  <MenuItem
                    className="switch"
                    onClick={() => {
                      setActiveRole(!activeRole);
                      document.documentElement.setAttribute('data-theme', !activeRole ? 'personal' : '');
                    }}
                  >
                    {!activeRole ? 'Switch to personal use' : 'Switch to helping others'}
                  </MenuItem>
                }

                <hr />
                <MenuItem onClick={() => this.handleLogout()}>Logout</MenuItem>
              </NavDropdown>
            </Nav>
  )
}

  renderLoggedIn() {

    const {
      searchBarValue,
      modalContext,
      showModal
    } = this.state;
  
    const { user, setUser, activeRole, setActiveRole } = this.props;
    const isMobile = this.state.screenWidth < 784;
    const isTablet = this.state.screenWidth >= 784 && this.state.screenWidth < 1024;
    const isDesktop =  this.state.screenWidth >= 1024;
  
    if (!user.accountType) {
      this.handleLogout();
    } else if (user.accountType.id === 1) {
      setActiveRole(true);
    } else if (user.accountType.id === 2) {
      setActiveRole(false);
    }
  
    if (this.state.showSearch) {
      return null;
    }

    return (

      <Navbar className="top-nav" collapseOnSelect>
        { (isMobile || isTablet) && 
          <div className="Navbar-container" style={(this.state.isOpen)
            ? { paddingTop: 30, paddingBottom: 246, paddingRight: 30, paddingLeft: 30, backgroundColor: "var(--color-background-darker-role)" }
            : { paddingLeft: 30, paddingRight: 30, paddingTop: 0, paddingBottom: 0 }}
          >
            <div className='left-mid-right-nav-container'>
            <div className="left-section-nav">
              <Logo />
            </div>
            <div className="middle-section-nav" >
              {isTablet && <div className="navbar-search-container">
        <FormGroup>
            <FormControl
              className="nav-search"
              type="text"
              placeholder="Search..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.handleSearch();
                }
              }}
              value={searchBarValue}
              onChange={(e) => this.setState({ searchBarValue: e.target.value })}
              ref={(r) => { this.searchBar = r; }}
            />
            <div className="search-button" onClick={() => this.handleSearch()}>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </FormGroup>
        </div>}
              {this.renderRightNav()}
            </div>
            <div className="right-section-nav">
              <NavLinksMobile toggle={this.toggleMenu} state={this.state.isOpen} />
            </div>
          </div>
          </div>
        }
       {isMobile && <div className="navbar-search-container">
        <FormGroup>
            <FormControl
              className="nav-search"
              type="text"
              placeholder="Search..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.handleSearch();
                }
              }}
              value={searchBarValue}
              onChange={(e) => this.setState({ searchBarValue: e.target.value })}
              ref={(r) => { this.searchBar = r; }}
            />
            <div className="search-button" onClick={() => this.handleSearch()}>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </FormGroup>
        </div>
        }

        {isDesktop && <Navbar.Header>
          <div className="logo-container">
            <Link to="/home"><Image className="nav-icon" src={icon} /></Link>
          </div>
        </Navbar.Header>}

        {isDesktop && <Navbar.Form className="search-bar-form">
          <FormGroup>
            <FormControl
              className="nav-search"
              type="text"
              placeholder="Search..."
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.handleSearch();
                }
              }}
              value={searchBarValue}
              onChange={(e) => this.setState({ searchBarValue: e.target.value })}
              ref={(r) => { this.searchBar = r; }}
            />
            <div className="search-button" onClick={() => this.handleSearch()}>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </FormGroup>
        </Navbar.Form>}


        {isDesktop && <div className="footer-links">
          <span className="hover-blue" onClick={() => { this.props.history.push('/home'); window.scrollTo(0, 0); }}>Home</span>
          <span className="hover-blue" onClick={() => { this.props.history.push('/explore'); window.scrollTo(0, 0); }}> <FontAwesomeIcon icon={faCompass} />
            {' '} Explore</span>
          <span className="hover-blue" onClick={() => { this.props.history.push('/about'); window.scrollTo(0, 0); }}>About</span>
          <span className="hover-blue" onClick={() => { this.props.history.push('/support'); window.scrollTo(0, 0); }}>Support</span>
          <span className="hover-blue" onClick={() => { this.props.history.push('/faq'); window.scrollTo(0, 0); }}>FAQs</span>
          <span className="hover-blue" onClick={() => { this.props.history.push('/contact'); window.scrollTo(0, 0); }}>Contact Us</span>
        </div>}

        {isDesktop && this.renderRightNav()}

        <Modal show={showModal} onHide={this.closeModal} backdrop="static">
          <Modal.Header closeButton />
          {modalContext === 1 && <SignUpNew hubInviteToken={this.hubInviteToken} loginRedirect={() => this.setState({ modalContext: 2 })} setUser={setUser} handleModalClose={this.closeModal} />}
          {modalContext === 2 && <Login signupRedirect={() => this.setState({ modalContext: 1 })} handleModalClose={this.closeModal} />}
          {modalContext === 3 && <CreateHub handleModalClose={this.closeModal} />}
        </Modal>
      </Navbar>
    );
  }

  render() {
    const { user } = this.props;
    return user ? this.renderLoggedIn() : this.renderSignIn();
  }
}

export default TopNav;
