import React from 'react';
import config from '../../../constant/config';
import './SettingsTab.css';
import { emailValid, passwordValid } from '../../../lib/validation';

import {
  FormGroup,
  ControlLabel,
  FormControl,
  Checkbox,
  Button,
  Modal,
  Col,
  Row
} from 'react-bootstrap';

import {
  getRoles,
  getActivities,
  getGoals,
  getTargetGroups,
  getActivityLevels,
  getMotivations,
  getConfidenceLevels,
  getMovementLevels,
  counties
} from '../signup/SignUpFetchUtil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import personIcon from '../../../resources/img/person_icon.svg';
import mailIcon from '../../../resources/img/mail_icon.svg';
import lockIcon from '../../../resources/img/lock_icon.svg';
import locationIcon from '../../../resources/img/location_icon.svg';


class SettingsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // main details
      firstname: this.props.user.firstName,
      surname: this.props.user.surname,
      email: this.props.user.emailAddress,
      location: this.props.user.location,
      locations: [],
      acceptEmailNotifications: this.props.user.acceptEmailNotifications,
      basicDetailsUpdateMessage: '',
      avatar: this.props.user.avatarId,
      avatarData: null,
      avatarFileType: '',
      avatarFileName: '',
      // security
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
      passwordUpdateMessage: '',
      // helping others
      pracRoles: this.props.user.roles,
      selectedPracRole: null,
      roleOptions: [],
      pracGoals: this.props.user.goals.filter(g => g.type === 2 || g.type === 3),
      selectedPracGoal: null,
      goalOptions: [],
      pracActivites: this.props.user.interests.filter(a => a.type === 2 || a.type === 3),
      selectedPracActivity: null,
      activityOptions: [],
      targetGroup: this.props.user.targetGroups,
      selectedTargetGroup: null,
      targetGroupOptions: [],
      helpingOthersMessage: '',
      // personal use
      persGoals: this.props.user.goals.filter(g => g.type === 1 || g.type === 3),
      selectedPersGoal: null,
      persActivites: this.props.user.interests.filter(a => a.type === 1 || a.type === 3),
      persActivityLevel: this.props.user.activityLevelId,
      activityLevelOptions: [],
      persMotivationLevel: this.props.user.motivationLevelId,
      motivationOptions: [],
      persConfidenceLevel: this.props.user.confidenceLevelId,
      confidenceLevelOptions: [],
      persMovementLevel: this.props.user.movementId,
      movementLevelOptions: [],
      persTargetGroup:this.props.user.targetGroupsPers,
      selectedPersTargetGroup: null,
      personalUseMessage: '',
      // other
      showModal: false,
      modalContent: null,
      isLoadingAvatar: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async getSelectOptions() {
    try {
      const roleOptions = await getRoles();
      const targetGroupOptions = await getTargetGroups();
      const activityOptions = await getActivities();
      const goalOptions = await getGoals();
      const activityLevelOptions = await getActivityLevels();
      const motivationOptions = await getMotivations();
      const confidenceLevelOptions = await getConfidenceLevels();
      const movementLevelOptions = await getMovementLevels();

      this.setState({
        locations: counties,
        roleOptions,
        goalOptions,
        activityOptions,
        targetGroupOptions,
        activityLevelOptions,
        movementLevelOptions,
        confidenceLevelOptions,
        motivationOptions,
      });
    } catch (err) {
      this.setState({
        currentPage: null,
        signUpError: 'Unable to create new users at this time, please try again'
      });
    }
  }

  componentDidMount() {
    this.getSelectOptions();
  }


  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  async onChangeFile(e) {
    e.preventDefault();
    const selectedFiles = this.profileAvatarFileInput.files;
    const file = selectedFiles[0];
    this.setState({
      isLoadingAvatar: true,
    });
    const maxSizeInBytes = 1024 * 1024; // 1 MB
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit. Please select a smaller file.');
      this.setState({
        isLoadingAvatar: false,
      });
      return;
    }
    await this.readFile(file);
  }

  async readFile(file) {
    const reader = new FileReader();
    reader.onloadend = async () => {
      await this.setState({
        avatarData: reader.result,
        avatarFileType: file.type,
        avatarFileName: file.name,
      });
      this.updateProfilePicture();
    };
    reader.readAsArrayBuffer(file);
  }

  async updateProfilePicture() {
    const {
      avatarData,
      avatarFileType,
      avatarFileName
    } = this.state;

    try {
      if (avatarData) {
        const payload = new Buffer(avatarData, 'binary').toString('binary');
        const avatarResponse = await fetch(`${config.apiURL}/asset/`, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            title: avatarFileName,
            data: payload,
            fileType: avatarFileType,
          }),
        });
        const avatar = await avatarResponse.text();
        this.setState({ avatar, isLoadingAvatar: false, });
        const res = await fetch(`${config.apiURL}/user/picture`, {
          credentials: 'include',
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ avatarId: avatar }),
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  async isUniqueEmail() {
    const { email } = this.state;
    if (email.length > 0 && emailValid(email)) {
      const res = await fetch(`${config.apiURL}/user/email`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ email: email }),
      });
      if (res.status === 200) {
        const emailTaken = await res.json();
        if (emailTaken && !(this.props.user.emailAddress.toLowerCase() === email.toLowerCase())) {
          return false;
        } else {
          return true;
        }
      }
    }
    return true;
  }

  validateBasicInformation() {
    const {
      firstname,
      surname,
      email
    } = this.state;

    let basicInfoHelpText = [];

    if (firstname === '') {
      basicInfoHelpText.push('First name is required.')
    }

    if (surname === '') {
      basicInfoHelpText.push('Second name is required.')
    }

    if (surname === '') {
      basicInfoHelpText.push('Second name is required.')
    }

    if (!emailValid(email)) {
      basicInfoHelpText.push('Email format is invalid.')
    }

    if (!this.isUniqueEmail()) {
      basicInfoHelpText.push('Email has already been taken.')
    }

    this.setState({ basicDetailsUpdateMessage: basicInfoHelpText.join('\n') });

    return !(basicInfoHelpText.length > 0);
  }

  async updateBasicDetails() {
    const {
      firstname,
      surname,
      email,
      acceptEmailNotifications,
      location
    } = this.state;

    if (this.validateBasicInformation()) {
      try {
        const res = await fetch(`${config.apiURL}/user/updatebasicinfo`, {
          credentials: 'include',
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            firstname,
            surname,
            email,
            acceptEmailNotifications,
            location
          }),
        });
        if (res.status !== 200) {
          throw new Error('Error has occurred unable to update your information.');
        } else {
          this.setState({ basicDetailsUpdateMessage: 'Details have been updated!' });
        }
      } catch (err) {
        this.setState({ basicDetailsUpdateMessage: err.message });
      }
    }
  }

  validateNewPassword() {
    const {
      password,
      newPassword,
      newPasswordConfirm
    } = this.state;

    let passwordHelpText = [];

    if (password === '') {
      passwordHelpText.push('Existing password is required');
    }
    if (newPassword === '') {
      passwordHelpText.push('New password is required');
    }
    if (newPasswordConfirm === '') {
      passwordHelpText.push('Please confirm new password');
    }
    if (newPassword !== newPasswordConfirm) {
      passwordHelpText.push('Passwords must match');
    }
    const validPassword = passwordValid(newPassword);
    if (!validPassword.isValid) {
      passwordHelpText.push(validPassword.errorMessage);
    }
    this.setState({ passwordUpdateMessage: passwordHelpText.join('\n') });

    if (passwordHelpText.length > 0 || !validPassword.isValid) {
      return false;
    }
    return true;
  }

  async updatePassword() {
    if (this.validateNewPassword()) {
      const {
        password,
        newPassword,
        newPasswordConfirm
      } = this.state;

      if (newPassword !== newPasswordConfirm) {
        this.setState({ passwordUpdateMessage: 'New passwords do not match.' });
        return;
      }

      try {
        const res = await fetch(`${config.apiURL}/user/updatepassword`, {
          credentials: 'include',
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            existingPassword: password,
            newPassword: newPassword,
            userId: this.props.user.id,
          }),
        });
        if (res.status !== 200) {
          throw new Error('Unable to update password, Please check password is valid');
        } else {
          this.setState({ passwordUpdateMessage: 'Password Updated Successfully' });
        }
      } catch (err) {
        this.setState({ passwordUpdateMessage: err.message });
      }
    }
  }

  async updatePracOptions(pracRoles, pracGoals, pracActivites, targetGroup) {
    // api call to update prac option related items
    try {
      const res = await fetch(`${config.apiURL}/user/updatePracOptions`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          pracRoles,
          pracGoals,
          pracActivites,
          targetGroup
        }),
      });
      if (res.status !== 200) {
        throw new Error('Something went wrong when updating profile.');
      } else {
        this.setState({
          pracRoles,
          pracGoals: pracGoals.filter(g => g.type === 2 || g.type === 3),
          pracActivites: pracActivites.filter(g => g.type === 2 || g.type === 3),
          targetGroup,
          helpingOthersMessage: 'Updated successfully!'
        });
      }
    } catch (err) {
      this.setState({ helpingOthersMessage: err.message });
    }
  }

  async updatePersOptions(persGoals, persActivites, persTargetGroup) {
    // api call to update pers option related items
    try {
      const res = await fetch(`${config.apiURL}/user/updatePersOptions`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          persGoals,
          persActivites,
          persTargetGroup,
        }),
      });
      if (res.status !== 200) {
        throw new Error('Something went wrong when updating profile.');
      } else {
        this.setState({
          persGoals: persGoals.filter(g => g.type === 1 || g.type === 3),
          persActivites: persActivites.filter(g => g.type === 1 || g.type === 3),
          persTargetGroup,
          personalUseMessage: 'Updated successfully!'
        });
      }
    } catch (err) {
      this.setState({ personalUseMessage: err.message });
    }
  }

  async addPracOption(option, type) {
    const {
      pracRoles,
      pracGoals,
      pracActivites,
      targetGroup,
      persGoals
    } = this.state;

    if (!option) {
      this.setState({ helpingOthersMessage: 'Make sure to select an option' });
      return;
    }

    if (type === 'pracRole')
      pracRoles.push(option);

    if (type === 'pracGoal')
      pracGoals.push(option);

    if (type === 'pracActivity')
      pracActivites.push(option);

    if (type === 'targetGroup')
      targetGroup.push(option);

    await this.updatePracOptions(pracRoles, [...pracGoals, ...persGoals], pracActivites, targetGroup);
  }

  async addPersOption(option, type) {
    const {
      persGoals,
      pracGoals,
      persActivites,
      persTargetGroup,
    } = this.state;

    if (!option) {
      this.setState({ personalUseMessage: 'Make sure to select an option' });
      return;
    }

    if (type === 'persGoals')
      persGoals.push(option);

    if (type === 'persActivites')
      persActivites.push(option);

    if (type === 'persTargetGroup')
      persTargetGroup.push(option);

    await this.updatePersOptions([...persGoals, ...pracGoals], persActivites, persTargetGroup);
  }

  async deletePracOption(id, type) {
    const {
      pracRoles,
      pracGoals,
      pracActivites,
      targetGroup,
      persGoals
    } = this.state;

    let index;

    if (type === 'pracRole') {
      index = pracRoles.findIndex(x => x.id === id);
      if (index !== -1)
        pracRoles.splice(index, 1);
    }

    if (type === 'pracGoal') {
      index = pracGoals.findIndex(x => x.id === id);
      if (index !== -1)
        pracGoals.splice(index, 1);
    }

    if (type === 'pracActivity') {
      index = pracActivites.findIndex(x => x.id === id);
      if (index !== -1)
        pracActivites.splice(index, 1);
    }

    if (type === 'targetGroup') {
      index = targetGroup.findIndex(x => x.id === id);
      if (index !== -1)
        targetGroup.splice(index, 1);
    }

    await this.updatePracOptions(pracRoles, [...persGoals, ...pracGoals], pracActivites, targetGroup);
  }

  async deletePersOption(id, type) {
    const {
      persGoals,
      persActivites,
      pracGoals,
      persTargetGroup,
    } = this.state;

    let index;

    if (type === 'persGoals') {
      index = persGoals.findIndex(x => x.id === id);
      if (index !== -1)
        persGoals.splice(index, 1);
    }

    if (type === 'persActivites') {
      index = persActivites.findIndex(x => x.id === id);
      if (index !== -1)
        persActivites.splice(index, 1);
    }

    if (type === 'persTargetGroup') {
      index = persTargetGroup.findIndex(x => x.id === id);
      if (index !== -1)
        persTargetGroup.splice(index, 1);
    }

    await this.updatePersOptions([...persGoals, ...pracGoals], persActivites, persTargetGroup);
  }

  async savePersLevels() {
    const {
      persActivityLevel,
      persMovementLevel,
      persConfidenceLevel,
      persMotivationLevel
    } = this.state;

    const validValues = [1, 2, 3]

    if (!validValues.includes(persActivityLevel)) {
      this.setState({ personalUseMessage: 'Activity level is invalid.' });
      return;
    }

    if (!validValues.includes(persMovementLevel)) {
      this.setState({ personalUseMessage: 'Movement level is invalid.' });
      return;
    }

    if (!validValues.includes(persConfidenceLevel)) {
      this.setState({ personalUseMessage: 'Confidence level is invalid.' });
      return;
    }

    if (!validValues.includes(persMotivationLevel)) {
      this.setState({ personalUseMessage: 'Motivation level is invalid.' });
      return;
    }

    try {
      const res = await fetch(`${config.apiURL}/user/updatePersLevels`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          persActivityLevel,
          persMovementLevel,
          persConfidenceLevel,
          persMotivationLevel,
        }),
      });
      if (res.status !== 200) {
        throw new Error('Something went wrong when updating profile.');
      } else {
        this.setState({ personalUseMessage: 'Updated successfully!' });
      }
    } catch (err) {
      this.setState({ personalUseMessage: err.message });
    }
  }

  async upgradeAccount() {
    try {
      const res = await fetch(`${config.apiURL}/user/changeaccounttype`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ type: 3 }),
      });
      if (res.status === 200) {
        const user = await res.json();
        this.props.updateUser(user);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  renderPracOptions(options, type) {
    if (options.length > 0) {
      return options.map((op, i) => (
        <div key={i} className='option-container'>
          {op.description}
          <div className='delete-icon' onClick={() => this.deletePracOption(op.id, type)}>
            —
          </div>
        </div>
      ));
    }
    return <div>You haven't selected anything here.</div>
  }

  renderPersOptions(options, type) {
    if (options.length > 0) {
      return options.map((op, i) => (
        <div key={i} className='option-container'>
          {op.description}
          <div className='delete-icon' onClick={() => this.deletePersOption(op.id, type)}>
            —
          </div>
        </div>
      ));
    }
    return <div>You haven't selected anything here.</div>
  }

  renderPracOptionsAges(options) {
    if (options && options.length > 0) {
      return options.map((op, i) => (
        <div key={i} className='option-container'>
          {op.lower}-{op.upper}
          <div className='delete-icon' onClick={() => this.deletePracOption(op.id, 'targetAge')}>
            —
          </div>
        </div>
      ));
    }
    return <div>You haven't selected anything here.</div>
  }

  renderLevelOptions(options, selected, callback) {
    if (options && options.length > 0) {
      return options.map((op, i) => (
        <div
          key={i}
          className='option-container'
          style={{ cursor: 'pointer', paddingRight: selected === op.id ? '2.25em' : '0.5em' }}
          onClick={() => callback(op.id)}
        >
          {op.level}
          {selected === op.id &&
            <div className='delete-icon'>
              ✓
            </div>
          }
        </div>
      ));
    }
    return <div>Issue loading options...</div>
  }

  render() {
    const { user } = this.props;
    const {
      avatar,
      firstname,
      surname,
      email,
      location,
      locations,
      acceptEmailNotifications,
      basicDetailsUpdateMessage,
      password,
      newPassword,
      newPasswordConfirm,
      passwordUpdateMessage,
      pracActivites,
      selectedPracActivity,
      activityOptions,
      targetGroup,
      selectedTargetGroup,
      targetGroupOptions,
      activityLevelOptions,
      pracGoals,
      selectedPracGoal,
      goalOptions,
      pracRoles,
      selectedPracRole,
      roleOptions,
      movementLevelOptions,
      confidenceLevelOptions,
      helpingOthersMessage,
      persGoals,
      selectedPersGoal,
      selectedPersActivity,
      persActivites,
      personalUseMessage,
      persActivityLevel,
      persMotivationLevel,
      persConfidenceLevel,
      motivationOptions,
      persMovementLevel,
      persTargetGroup,
      selectedPersTargetGroup,
      showModal,
      modalContent
    } = this.state;

    return (
      <div className="settings-tab-container">
        <div className="settings-security">
          <div className="settings">
            <span className="title">Settings</span>
            <div className="settings-area">
            <div
              className="avatar-image"
              style={!this.state.isLoadingAvatar ? { backgroundImage: `url(${config.spacesURL}/${this.state.avatar})` } : {}}
              onClick={() => this.profileAvatarFileInput.click()}
            >
              <div className="edit-pencil">
                <FontAwesomeIcon icon={faPen} />
              </div>
            </div>
              <div style={{ width: '100%' }}>
                <div className="input-icon-group">
                  <FormGroup className="form">
                    <FormControl
                      autoFocus
                      className="form-input text-input"
                      placeholder="Firstname"
                      type="text"
                      name="firstname"
                      value={firstname}
                      onChange={(e) => {
                        this.setState({ firstname: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <img
                    class="input-icon"
                    src={personIcon}
                    alt="person icon"
                  />
                </div>

                <div className="input-icon-group">
                  <FormGroup className="form">
                    <FormControl
                      autoFocus
                      className="form-input text-input"
                      placeholder="Surname"
                      type="text"
                      name="surname"
                      value={surname}
                      onChange={(e) => {
                        this.setState({ surname: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <img
                    class="input-icon"
                    src={personIcon}
                    alt="person icon"
                  />
                </div>

                <div className="input-icon-group">
                  <FormGroup className="form">
                    <FormControl
                      autoFocus
                      className="form-input text-input"
                      placeholder="Email address"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => {
                        this.setState({ email: e.target.value })
                      }}
                    />
                  </FormGroup>
                  <img
                    class="input-icon"
                    src={mailIcon}
                    alt="mail icon"
                  />
                </div>

                <div className="input-icon-group">
                  <FormControl
                    className="form-input text-input"
                    componentClass="select"
                    placeholder="Location"
                    value={location}
                    onChange={(e) => this.setState({ location: e.target.value })}
                  >
                    {locations}
                  </FormControl>
                  <img
                    class="input-icon"
                    src={locationIcon}
                    alt="pin point location icon"
                    style={{ marginTop: '8px' }}
                  />
                </div>

                <FormGroup className="form" controlId="formTerms" style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    id="email-notifications"
                    onClick={e => this.setState({ acceptEmailNotifications: e.target.checked })}
                    checked={acceptEmailNotifications}
                    style={{ display: 'inline-block' }}
                  />
                  <ControlLabel style={{ display: 'inline' }}>
                    I would like to receive email notifications from Actify
                  </ControlLabel>
                </FormGroup>

                {basicDetailsUpdateMessage &&
                  <ul>
                    {basicDetailsUpdateMessage.split('\n').map(t => <li>{t}</li>)}
                  </ul>
                }

                <Button className="settings-btn" onClick={() => this.updateBasicDetails()}>
                  Save details
                </Button>
              </div>
            </div>
          </div>

          <div className="security">
            <span className="title">Security</span>
            <div className="input-icon-group">
              <FormGroup className="form">
                <FormControl
                  autoFocus
                  className="form-input text-input"
                  placeholder="Enter old password"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => {
                    this.setState({ password: e.target.value })
                  }}
                />
              </FormGroup>
              <img
                class="input-icon"
                src={lockIcon}
                alt="mail icon"
              />
            </div>

            <div className="input-icon-group">
              <FormGroup className="form">
                <FormControl
                  autoFocus
                  className="form-input text-input"
                  placeholder="Enter new password"
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => {
                    this.setState({ newPassword: e.target.value })
                  }}
                />
              </FormGroup>
              <img
                class="input-icon"
                src={lockIcon}
                alt="mail icon"
              />
            </div>

            <div className="input-icon-group">
              <FormGroup className="form">
                <FormControl
                  autoFocus
                  className="form-input text-input"
                  placeholder="Confirm new password"
                  type="password"
                  name="newPassworConfirm"
                  value={newPasswordConfirm}
                  onChange={(e) => {
                    this.setState({ newPasswordConfirm: e.target.value })
                    this.validateNewPassword()
                    if (newPassword === e.target.value) {
                      this.setState({ passwordUpdateMessage: '' })
                    }
                  }}
                />
              </FormGroup>
              <img
                class="input-icon"
                src={lockIcon}
                alt="mail icon"
              />
            </div>

            {passwordUpdateMessage &&
              <ul>
                {passwordUpdateMessage.split('\n').map(t => <li>{t}</li>)}
              </ul>
            }

            <Button className="settings-btn" onClick={() => this.updatePassword()}>
              Change password
            </Button>
          </div>
        </div>

        { /* Helping others */}
        {(user.accountType.id === 2 || user.accountType.id === 3) &&
          <>
            <hr />
            <div className="extra-settings">
              <span className="title">
                Helping others
                {helpingOthersMessage &&
                  <span className='settings-feedback'>{helpingOthersMessage}</span>
                }
              </span>

              <div className="extra-settings-options">

                <Col md={3} mdOffset={1}>
                  <Row>
                    <div style={{ marginTop: '50px', width: '290px' }}>
                      <span className="title sub">Your role</span>
                      <div className="selected-options">
                        {this.renderPracOptions(pracRoles, 'pracRole')}
                      </div>
                      <FormControl
                        className="form-input text-input choose-option"
                        componentClass="select"
                        placeholder="Role"
                        value={selectedPracRole ? selectedPracRole.description : 'none'}
                        onChange={(e) => {
                          this.addPracOption(roleOptions.find(op => op.description === e.target.value), 'pracRole');
                        }}
                      >
                        <option value="none" selected disabled hidden>Select a Role</option>
                        {roleOptions.filter(op => !pracRoles.find(op2 => op.description === op2.description)).map(op => (
                          <option key={op.id} value={op.description}>
                            {op.description}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </Row>

                  <Row>
                    <div style={{ marginTop: '50px', width: '290px' }}>
                      <span className="title sub">Physical activites of focus</span>
                      <div className="selected-options">
                        {this.renderPracOptions(pracActivites, 'pracActivity')}
                      </div>
                      <FormControl
                        className="form-input text-input choose-option"
                        componentClass="select"
                        placeholder="Activity"
                        value={selectedPracActivity ? selectedPracActivity.description : 'none'}
                        onChange={(e) => {
                          this.addPracOption(activityOptions.find(op => op.description === e.target.value), 'pracActivity');
                        }}
                      >
                        <option value="none" selected disabled hidden>Select an Activity</option>
                        {activityOptions.filter(op => !pracActivites.find(op2 => op.description === op2.description) && (op.type === 2 || op.type === 3)).map(op => (
                          <option key={op.id} value={op.description}>
                            {op.description}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </Row>
                </Col>

                <Col md={3} mdOffset={1}>
                  <Row>
                    <div style={{ marginTop: '50px', width: '290px' }}>
                      <span className="title sub">Goals</span>
                      <div className="selected-options">
                        {this.renderPracOptions(pracGoals, 'pracGoal')}
                      </div>
                      <FormControl
                        className="form-input text-input choose-option"
                        componentClass="select"
                        placeholder="Goal"
                        value={selectedPracGoal ? selectedPracGoal.description : 'none'}
                        onChange={(e) => {
                          this.addPracOption(goalOptions.find(op => op.description === e.target.value), 'pracGoal');
                        }}
                      >
                        <option value="none" selected disabled hidden>Select a Goal</option>
                        {goalOptions.filter(op => !pracGoals.find(op2 => op.description === op2.description) && (op.type === 2 || op.type === 3)).map(op => (
                          <option key={op.id} value={op.description}>
                            {op.description}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </Row>

                  <Row>
                    <div style={{ marginTop: '50px', width: '290px' }}>
                      <span className="title sub">Target audience</span>
                      <div className="selected-options">
                        {this.renderPracOptions(targetGroup, 'targetGroup')}
                      </div>
                      <FormControl
                        className="form-input text-input choose-option"
                        componentClass="select"
                        placeholder="Activity"
                        value={selectedTargetGroup ? selectedTargetGroup.description : 'none'}
                        onChange={(e) => {
                          this.addPracOption(targetGroupOptions.find(op => op.description === e.target.value), 'targetGroup');
                        }}
                      >
                        <option value="none" selected disabled hidden>Select a Target Group</option>
                        {targetGroupOptions.filter(op => !targetGroup.find(op2 => op.description === op2.description)).map(op => (
                          <option key={op.id} value={op.description}>
                            {op.description}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </Row>

                </Col>

                <Col md={3} mdOffset={1}>

                </Col>
              </div>
            </div>
          </>
        }

        { /* Personal use */}

        {(user.accountType.id === 1 || user.accountType.id === 3) &&
          <>
            <hr />
            <div className="extra-settings">
              <span className="title">
                Personal use
                {personalUseMessage &&
                  <span className='settings-feedback'>{personalUseMessage}</span>
                }
              </span>
              <div className="extra-settings-options">
                <Col md={3} mdOffset={1}>
                  <Row style={{ marginTop: '50px', width: '290px' }}>
                    <div>
                      <span className="title sub">Goals</span>
                      <div className="selected-options">
                        {this.renderPersOptions(persGoals, 'persGoals')}
                      </div>
                      <FormControl
                        className="form-input text-input choose-option"
                        componentClass="select"
                        placeholder="Role"
                        value={selectedPersGoal ? selectedPersGoal.description : 'none'}
                        onChange={(e) => {
                          this.addPersOption(goalOptions.find(op => op.description === e.target.value), 'persGoals');
                        }}
                      >
                        <option value="none" selected disabled hidden>Select a Goal</option>
                        {goalOptions.filter(op => !persGoals.find(op2 => op.description === op2.description) && (op.type === 1 || op.type === 3)).map(op => (
                          <option key={op.id} value={op.description}>
                            {op.description}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </Row>

                  <Row style={{ marginTop: '50px', width: '290px' }}>
                    <div>
                      <span className="title sub">Physical activity level</span>
                      <div className="selected-options">
                        {this.renderLevelOptions(activityLevelOptions, persActivityLevel, (id) => this.setState({ persActivityLevel: id }))}
                      </div>
                      <Button className="settings-btn" style={{ width: '100%' }} onClick={() => this.savePersLevels()}>
                        Save Activity Level
                      </Button>
                    </div>
                  </Row>

                  <Row style={{ marginTop: '50px', width: '290px' }}>
                    <div>
                      <span className="title sub">Motivation level</span>
                      <div className="selected-options">
                        {this.renderLevelOptions(motivationOptions, persMotivationLevel, (id) => this.setState({ persMotivationLevel: id }))}
                      </div>
                      <Button className="settings-btn" style={{ width: '100%' }} onClick={() => this.savePersLevels()}>
                        Save Motivation Level
                      </Button>
                    </div>
                  </Row>

                  <Row>
                    <div style={{ marginTop: '50px', width: '290px' }}>
                      <span className="title sub">Target group</span>
                      <div className="selected-options">
                        {this.renderPersOptions(persTargetGroup, 'persTargetGroup')}
                      </div>
                      <FormControl
                        className="form-input text-input choose-option"
                        componentClass="select"
                        placeholder="Activity"
                        value={selectedPersTargetGroup ? selectedPersTargetGroup.description : 'none'}
                        onChange={(e) => {
                          this.addPersOption(targetGroupOptions.find(op => op.description === e.target.value), 'persTargetGroup');
                        }}
                      >
                        <option value="none" selected disabled hidden>Select a Target Group</option>
                        {targetGroupOptions.filter(op => !persTargetGroup.find(op2 => op.description === op2.description)).map(op => (
                          <option key={op.id} value={op.description}>
                            {op.description}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </Row>


                </Col>

                <Col md={3} mdOffset={1}>

                <Row style={{ marginTop: '50px', width: '290px' }}>
                    <div>
                      <span className="title sub">Physical activites</span>
                      <div className="selected-options">
                        {this.renderPersOptions(persActivites, 'persActivites')}
                      </div>
                      <FormControl
                        className="form-input text-input choose-option"
                        componentClass="select"
                        placeholder="Role"
                        value={selectedPersActivity ? selectedPersActivity.description : 'none'}
                        onChange={(e) => {
                          this.addPersOption(activityOptions.find(op => op.description === e.target.value), 'persActivites');
                        }}
                      >
                        <option value="none" selected disabled hidden>Select an Activity</option>
                        {activityOptions.filter(op => !persActivites.find(op2 => op.description === op2.description) && (op.type === 1 || op.type === 3)).map(op => (
                          <option key={op.id} value={op.description}>
                            {op.description}
                          </option>
                        ))}
                      </FormControl>
                    </div>
                  </Row>

                <Row style={{ marginTop: '50px', width: '290px' }}>
                    <div>
                      <span className="title sub">Movement level</span>
                      <div className="selected-options">
                        {this.renderLevelOptions(movementLevelOptions, persMovementLevel, (id) => this.setState({ persMovementLevel: id }))}
                      </div>
                      <Button className="settings-btn" style={{ width: '100%' }} onClick={() => this.savePersLevels()}>
                        Save Movement Level
                      </Button>
                    </div>
                  </Row>


                  <Row style={{ marginTop: '50px', width: '290px' }}>
                    <div>
                      <span className="title sub">Confidence level</span>
                      <div className="selected-options">
                        {this.renderLevelOptions(confidenceLevelOptions, persConfidenceLevel, (id) => this.setState({ persConfidenceLevel: id }))}
                      </div>
                      <Button className="settings-btn" style={{ width: '100%' }} onClick={() => this.savePersLevels()}>
                        Save Confidence Level
                      </Button>
                    </div>
                  </Row>
                </Col>
              </div>
            </div>
          </>
        }

        {(user.accountType.id === 1) &&
          <>
            <hr />
            <div>
              <span className="title">
                Helping others
              </span>
              <Button className="settings-btn" onClick={() => this.upgradeAccount()}>
                Create a helping others profile
              </Button>
            </div>
          </>
        }

        {(user.accountType.id === 2) &&
          <>
            <hr />
            <div>
              <span className="title">Personal use</span>
              <Button className="settings-btn" onClick={() => this.upgradeAccount()}>
                Create a personal use profile
              </Button>
            </div>
          </>
        }

        {/* Upload avatar file functinality */}
        <div style={{display: 'none'}}>
          <input
            ref={(f) => { this.profileAvatarFileInput = f; }}
            className="form-input logo"
            type="file"
            onChange={e => this.onChangeFile(e)}
            accept="image/jpeg,image/png"
          />
        </div>

        <Modal show={showModal} onHide={this.closeModal} backdrop="static">
          <Modal.Header closeButton />
          {modalContent}
        </Modal>
      </div>
    );
  }
}

export default SettingsTab;
