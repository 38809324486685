import config from '../constant/config';

export function parseEmbedYouTubeUrl(url) {
  const re = /(?:https?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig; //eslint-disable-line
  return url.replace(re, 'https://www.youtube.com/embed/$1');
}

export function parseEmbedVimeoUrl(url) {
  const re = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/; //eslint-disable-line
  if (!url.match(re)) {
    return;
  }
  const id = url.match(re)[3];
  return id;
}

export async function getVimeoEmbedForUrl(url) {
  const res = await fetch(`${config.vimeoAPIURL}/?url=${url}`, {
    method: 'GET',
  });
  if (res.status !== 200) {
    return new Error(`vimeo request returned ${res.status}`);
  }
  const body = await res.json();
  return body;
}
