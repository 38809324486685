module.exports = {
  apiURL: process.env.REACT_APP_ACTIVAI_API_URL || 'http://localhost',
  GA_TRACKING_ID: process.env.REACT_APP_GOOGLE_TRACKING_ID || 'G-9NX219DSVB',
  spacesURL: process.env.REACT_APP_SPACES_URL || 'https://actify-assets.fra1.cdn.digitaloceanspaces.com',
  pollNotificationsMs: 60000,
  pollCommentsMs: 20000,
  yearLimit: 100,
  moduleFileLimit: 10,
  moduleVideoLimit: 2,
  featuredHubLimit: 8,
  featuredModuleLimit: 8,
  customModuleLimit: 8,
  questionAnswerLimit: 10,
  acceptedImageTypes: ['image/PNG', 'image/png', 'image/jpeg', 'image/jpg', 'image/JPEG'],
  acceptedPdfTypes: ['application/pdf', 'application/zip'],
  hubAccessLevels: {
    PUBLIC_USER: 0,
    HUB_FOLLOWER: 1,
    HUB_MEMBER: 2,
    HUB_ADMIN: 3,
    HUB_OWNER: 4,
    SITE_OWNER: 5,
  },
  vimeoAPIURL: 'https://vimeo.com/api/oembed.json',
  videoSource: {
    YOUTUBE: 1,
    VIMEO: 2,
  },
  pubnubApiKeys: {
    PUBLISH: process.env.REACT_APP_PUBNUB_PUBLISH_KEY || 'INSERT_PUBLISH_KEY_HERE',
    SUBSCRIBE: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || 'INSERT_SUBSCRIBE_KEY_HERE'
  },
  monthData: [
    {
      name: 'January',
      short: 'Jan',
      number: 1,
      days: 31,
    },
    {
      name: 'February',
      short: 'Feb',
      number: 2,
      days: 28,
    },
    {
      name: 'March',
      short: 'Mar',
      number: 3,
      days: 31,
    },
    {
      name: 'April',
      short: 'Apr',
      number: 4,
      days: 30,
    },
    {
      name: 'May',
      short: 'May',
      number: 5,
      days: 31,
    },
    {
      name: 'June',
      short: 'Jun',
      number: 6,
      days: 30,
    },
    {
      name: 'July',
      short: 'Jul',
      number: 7,
      days: 31,
    },
    {
      name: 'August',
      short: 'Aug',
      number: 8,
      days: 31,
    },
    {
      name: 'September',
      short: 'Sep',
      number: 9,
      days: 30,
    },
    {
      name: 'October',
      short: 'Oct',
      number: 10,
      days: 31,
    },
    {
      name: 'November',
      short: 'Nov',
      number: 11,
      days: 30,
    },
    {
      name: 'December',
      short: 'Dec',
      number: 12,
      days: 31,
    },
  ],
  ratingTypes: {
    POSITIVE: 1,
    NEGATIVE: 2,
  },
  councilAreas: [
    'Aberdeen City',
    'Aberdeenshire',
    'Angus',
    'Antrim and Newtownabbey',
    'Ards and North Down',
    'Argyll and Bute',
    'Armagh, Banbridge and Craigavon',
    'Bedfordshire',
    'Belfast',
    'Berkshire',
    'Blaenau Gwent',
    'Bridgend',
    'Buckinghamshire',
    'Caerphilly',
    'Cambridgeshire',
    'Cardiff',
    'Carmarthenshire',
    'Causeway Coast and Glens',
    'Ceredigion',
    'Cheshire',
    'City of Bristol',
    'Clackmannanshire',
    'Conwy',
    'Cornwall',
    'Cumbria',
    'Denbighshire',
    'Derbyshire',
    'Derry and Strabane',
    'Devon',
    'Dorset',
    'Dumfries and Galloway',
    'Dundee City',
    'Durham (County Durham)',
    'East Ayrshire',
    'East Dunbartonshire',
    'East Lothian',
    'East Renfrewshire',
    'East Sussex',
    'Edinburgh',
    'Essex',
    'Falkirk',
    'Fermanagh and Omagh',
    'Fife',
    'Flintshire',
    'Glasgow City',
    'Gloucestershire',
    'Greater Manchester',
    'Gwynedd',
    'Hampshire',
    'Herefordshire',
    'Hertfordshire',
    'Highland',
    'Inverclyde',
    'Isle of Anglesey',
    'Isle of Wight',
    'Kent',
    'Lancashire',
    'Leicestershire',
    'Lincolnshire',
    'Lisburn and Castlereagh',
    'London',
    'Merseyside',
    'Merthyr Tydfil',
    'Mid and East Antrim',
    'Midlothian',
    'Mid-Ulster',
    'Monmouthshire',
    'Moray',
    'Neath Port Talbot',
    'Newport',
    'Newry, Mourne and Down',
    'Norfolk',
    'North Ayrshire',
    'North Lanarkshire',
    'North Yorkshire',
    'Northamptonshire',
    'Northumberland',
    'Nottinghamshire',
    'Orkney Islands',
    'Oxfordshire',
    'Pembrokeshire',
    'Perth and Kinross',
    'Powys',
    'Renfrewshire',
    'Rhondda Cynon Taff',
    'Rutland',
    'Scottish Borders',
    'Shetland Islands',
    'Shropshire',
    'Somerset',
    'South Ayrshire',
    'South Lanarkshire',
    'South Yorkshire',
    'Staffordshire',
    'Stirling',
    'Suffolk',
    'Surrey',
    'Swansea',
    'Torfaen',
    'Tyne and Wear',
    'Vale of Glamorgan',
    'Warwickshire',
    'West Dunbartonshire',
    'West Lothian',
    'West Midlands',
    'West Sussex',
    'West Yorkshire',
    'Western Isles',
    'Wiltshire',
    'Worcestershire',
    'Wrexham',
    'Yorkshire, East Riding',
    'Scotland-wide'
  ],
  questionTypes: {
    CHECKBOX: 1,
    RADIO: 2,
    FILL: 3,
    DRAG: 4,
    FREE: 5,
  },
  showModuleBannerImage: false,
  showPlanBannerImage: false,
};
