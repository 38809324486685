import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './RemoveUser.css';

class RemoveUser extends Component {
  render() {
    const { removeUser, closeModal } = this.props;
    return (
      <div className="remove-user-modal-container">
        <div>
          <div className="text-container">
            <p>
              Are you sure you wish to remove this user?
            </p>
          </div>
          <div className="button-container">
            <Button onClick={() => removeUser()}>Yes</Button>
            <Button onClick={() => closeModal()}>No</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default RemoveUser;
