import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { setNotifications, setQuizResults, setUser, toggleLogin } from '../../store/actions';
import config from '../../constant/config';
import Auth from '../../lib/auth';
import './checkStatus.css';

const mapStateToProps = state => ({
  loggedIn: state.loggedIn,
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(setUser(null));
    dispatch(setQuizResults([]));
    dispatch(setNotifications(null));
    dispatch(toggleLogin(false));
  },
  onGetUser: (user) => {
    dispatch(setUser(user));
  },
});

const checkStatus = (InputComponent, updateUser = false) => {
  class CheckStatus extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
      this.checkStatus = this.checkStatus.bind(this);
      this.updateUser = this.updateUser.bind(this);
      this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
      if (updateUser) {
        this.updateUser();
      } else {
        this.checkStatus();
      }
    }

    closeModal() {
      this.props.history.push('/home');
      this.setState({ showModal: false, loading: false });
    }

    async updateUser() {
      try {
        const res = await fetch(`${config.apiURL}/user`, {
          method: 'GET',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
        });
        if (res.status === 200) {
          const user = await res.json();
          this.props.onGetUser(user);
        } else if (this.props.loggedIn) {
          this.props.onLogout();
          this.setState({ showModal: true });
        }
        this.setState({ loading: false });
      } catch (err) {
        console.log(err);
      }
    }

    async checkStatus() {
      try {
        if (this.props.loggedIn) {
          const sessionValid = await Auth.isAuthenticated();
          if (!sessionValid) {
            this.props.onLogout();
            this.setState({ showModal: true });
            return;
          }
        }
        this.setState({ loading: false });
      } catch (err) {}
    }


    render() {
      if (this.state.loading) {
        return (
          <div>
            <Modal className="logged-out-modal" show={this.state.showModal} onHide={this.closeModal}>
              <Modal.Header closeButton />
              <div className="logged-out">
                <p>Your session has expired. Please log in again to continue using Actify</p>
                <div className="modal-button-container">
                  <Button className="modal-button" onClick={() => this.closeModal()}>
                    Close
                  </Button>
                </div>
              </div>
            </Modal>
          </div>);
      }
      return <InputComponent {...this.props} />;
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(CheckStatus);
};

export default checkStatus;
