import React, { Component } from 'react';
import { FormControl, FormGroup, ControlLabel, Button, HelpBlock, Checkbox } from 'react-bootstrap';
import config from '../../../constant/config';
import './CreateCheckboxQuestion.css';
import CheckboxQuestion from './CheckboxQuestion';

class CreateCheckboxQuestion extends Component {
  constructor(props) {
    super(props);
    let initialState = {
      validQuestionText: true,
      questionText: '',
      validPossibleAnswers: true,
      possibleAnswers: [],
      validAnswerText: true,
      newAnswer: '',
      validCorrectAnswers: true,
      correctAnswers: [],
      activePage: 1,
    }

    if (props.questionToEditContent) {
      initialState.questionType = props.questionToEditContent.questionType;
      initialState.questionText = props.questionToEditContent.text || props.questionToEditContent.questionText;
      initialState.possibleAnswers = props.questionToEditContent.possibleAnswers;
    }

    this.state = initialState;
    this.selectCorrectAnswer = this.selectCorrectAnswer.bind(this);
  }

  async onClickNext() {
    if (this.state.activePage === 1) {
      if (!this.validPageOne()) {
        return;
      }
      this.setState({
        activePage: 2,
      });
    } else {
      if (!this.validPageTwo()) {
        return;
      }
      const { questionText, possibleAnswers, correctAnswers } = this.state;
      const question = {
        questionText,
        questionType: config.questionTypes.CHECKBOX,
        possibleAnswers,
        correctAnswers: correctAnswers.map(ans => parseInt(ans, 10)),
      };
      if (this.props.editIndex == null) {
        this.props.addQuestion(question);
      }else{
        this.props.editQuestion(this.props.editIndex, question);
      }
    }
  }

  addAnswer(e) {
    e.preventDefault();
    const { newAnswer } = this.state;
    const trimmedAnswer = newAnswer.trim().split(' ').filter(w => !!w && w !== ' ').join(' ');
    if (trimmedAnswer === '') {
      this.setState({
        validAnswerText: false,
      });
      return;
    }
    this.setState({
      newAnswer: '',
      possibleAnswers: [...this.state.possibleAnswers, { answerText: trimmedAnswer, id: this.state.possibleAnswers.length }],
      validPossibleAnswers: true,
    });
  }

  deleteAnswer(id) {
    const updatedAnswers = this.state.possibleAnswers.filter(answer => answer.id !== id);
    this.setState({
      possibleAnswers: updatedAnswers,
      validPossibleAnswers: true,
    });
  }

  selectCorrectAnswer(correctAnswers) {
    this.setState({ correctAnswers, validCorrectAnswers: true });
  }

  validPageOne() {
    const { questionText, possibleAnswers } = this.state;
    const trimmedQuestionText = questionText.trim().split().filter(w => !!w && w !== ' ').join(' ');
    const validQuestionText = !!trimmedQuestionText;
    const validPossibleAnswers = possibleAnswers.length > 1; // multiple choice requires two or more answers
    this.setState({
      validQuestionText,
      validPossibleAnswers,
      questionText: trimmedQuestionText,
    });
    return (validQuestionText && validPossibleAnswers);
  }

  validPageTwo() {
    const { correctAnswers } = this.state;
    const validCorrectAnswers = correctAnswers.length > 0;
    this.setState({
      validCorrectAnswers,
    });
    return validCorrectAnswers;
  }

  renderPageOne() {
    return (
    <div className="page-1-container">
      <FormGroup className="form" controlId="title" validationState={!this.state.validQuestionText ? 'error' : null}>
        <ControlLabel>Question Text</ControlLabel>
        <FormControl
          className="form-input"
          componentClass="textarea"
          value={this.state.questionText}
          onChange={e => this.setState({ questionText: e.target.value, validQuestionText: true })}
        />
        {!this.state.validQuestionText && <HelpBlock>Must enter question text</HelpBlock>}
      </FormGroup>
      {this.state.possibleAnswers.length > 0 &&
        <FormGroup className="form" controlId="formRadio">
          <ControlLabel>Possible Answers</ControlLabel>
          {this.state.possibleAnswers.map(answer =>
            (<div style={{display: 'flex', gap: '20px'}}>
              <Checkbox disabled key={`answer-${answer.id}`} id={answer.id}>{answer.answerText}</Checkbox>
              <i className="clickable fa fa-times" aria-hidden="true" onClick={() => this.deleteAnswer(answer.id)} style={{color: 'red'}}/>
            </div>
            )) // eslint-disable-line
          }
        </FormGroup>
      }
      <FormGroup className="form" controlId="answer-input" validationState={!this.state.validAnswerText || !this.state.validPossibleAnswers ? 'error' : null}>
        <ControlLabel className="inline-label">Add answer</ControlLabel>
        <FormControl
          className="form-input answer-text"
          type="text"
          value={this.state.newAnswer}
          onChange={e => this.setState({ newAnswer: e.target.value, validAnswerText: true })}
        />
        <Button
          className="add-answer-button"
          onClick={e => this.addAnswer(e)}
          disabled={this.state.possibleAnswers.length >= config.questionAnswerLimit}
        >
          Add
        </Button>
        {!this.state.validAnswerText && <HelpBlock>Answer text can not be empty</HelpBlock>}
        {!this.state.validPossibleAnswers && <HelpBlock>There must be at least two answer choices</HelpBlock>}
      </FormGroup>
    </div>);
  }

  renderPageTwo() {
    return (
      <div className="page-2-container">
        <FormGroup validationState={!this.state.validCorrectAnswers ? 'error' : null}>
          <CheckboxQuestion
            questionText={this.state.questionText}
            answerLabel="Mark the correct answers from the options below"
            possibleAnswers={this.state.possibleAnswers}
            onClickAnswer={this.selectCorrectAnswer}
          />
          {!this.state.validCorrectAnswers && <HelpBlock>Please select at least one correct answer</HelpBlock>}
        </FormGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="create-checkbox-question">
        {this.state.activePage === 1 &&
          this.renderPageOne()
        }
        {this.state.activePage === 2 &&
          this.renderPageTwo()
        }
        <div className="modal-controls">
          <Button
            className="modal-control modal-button"
            id="done"
            onClick={e => this.onClickNext(e)}
          >
            {this.state.activePage === 1 ? 'Next -->' : 'Done -->'}
          </Button>
        </div>
      </div>
    );
  }
}

export default CreateCheckboxQuestion;
