/* eslint-disable import/prefer-default-export */
import React, {useState} from "react";
import { Modal } from "react-bootstrap";
import { NavMenuToggle } from "./NavMenuToggle";
import './NavLinksMobile.css';
import SignUpNew from '../common/signup/SignUpNewContainer';
import Login from './LoginContainer';

export function NavLinksMobile({toggle, state, color, buttons}){
  if (!toggle || !state) {
    [state, toggle] = useState(false);
  }

  const [showModal, setShowModal] = useState(false);
  const [modalContext, setModalContext] = useState(null);

  // Function to open the modal and set the context
  const openModal = (context) => {
    setShowModal(true);
    setModalContext(context);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setModalContext(null);
  };

  // const { setUser } = this.props;

  return(
    <div className="nav-links-container">
      <NavMenuToggle isOpen={state} toggle={() => toggle(!state)} svg_color={color} />
      {state && (
        <ul className="nav-links-wrapper">
          <li className="nav-links-item">
            <a className="nav-link" href="/explore">Explore</a>
          </li>
          <li className="nav-links-item">
            <a className="nav-link" href="/about">About</a>
          </li>
          <li className="nav-links-item">
            <a className="nav-link" href="/support">Support</a>
          </li>
          <li className="nav-links-item">
            <a className="nav-link" href="/faq">FAQs</a>
          </li>
          <li className="nav-links-item">
            <a className="nav-link" href="/contact">Contact Us</a>
          </li>
          {buttons &&
          <li className="nav-links-item">
            <div className="top-nav-register-btn" onClick={() => openModal(1)}>
              <span>Get Started</span>
            </div>
          </li>}

          {buttons &&
          <li className="nav-links-item">
            <div className="top-nav-signin-btn" onClick={() => openModal(2)}>
              <span>Login</span>
            </div>
          </li>}

        </ul>
      )}

      <Modal show={showModal} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton />
        {modalContext === 1 && <SignUpNew loginRedirect={() => setModalContext(2)} handleModalClose={closeModal} />}
        {modalContext === 2 && <Login signupRedirect={() => setModalContext(1)} handleModalClose={closeModal} />}
      </Modal>
    </div>

  )
}
