import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel, Button, HelpBlock } from 'react-bootstrap';
import CreateCheckboxQuestion from './CreateCheckboxQuestion';
import CreateRadioQuestion from './CreateRadioQuestion';
import CreateFillBlankQuestion from './CreateFillBlankQuestion';
import CreateDragQuestion from './CreateDragQuestion';
import config from '../../../constant/config';
import './CreateQuizQuestion.css';

class QuizBuilder extends Component {
  constructor(props) {
    super(props);
    let initialState = {
      questionTypes: props.questionTypes || [],
      questionType: 1,
      freeTextQuestion: '',
      validQuestionText: true,
      questionToEdit: props.questionToEdit,
    };

    if (props.questionToEditContent) {
      initialState.questionType = props.questionToEditContent.questionType;
      initialState.freeTextQuestion = props.questionToEditContent.text || props.questionToEditContent.questionText;
    }

    this.state = initialState;

  }

  async onClickDone() {
    const { freeTextQuestion } = this.state;
    const trimmedQuestionText = freeTextQuestion.trim().split(' ').filter(w => !!w && w !== ' ').join(' ');
    const validQuestionText = !!trimmedQuestionText;
    this.setState({
      validQuestionText,
      freeTextQuestion: trimmedQuestionText,
    });
    if (!validQuestionText) {
      return;
    }
    const question = {
      questionText: trimmedQuestionText,
      questionType: config.questionTypes.FREE,
    };
    if (this.state.questionToEdit == null ) {
      this.props.addQuestion(question);
    }else{
      this.props.editQuestion(this.state.questionToEdit, question);
    }

  }

  changeQuestionType(e) {
    e.preventDefault();
    this.setState({ questionType: parseInt(e.target.value, 10) });
  }

  render() {
    return (
      <div className="create-quiz-question">
        {this.state.questionToEdit !== null ? (
          <h2 className="modal-title">Edit Question {this.state.questionToEdit+1}</h2>
        ) : (
          <h2 className="modal-title">Add New Question</h2>
        )}
        <FormGroup className="form" controlId="formCategory">
          <ControlLabel className="inline-label-question-type">Question Type:</ControlLabel>
          <FormControl
            className="multi-select"
            componentClass="select"
            placeholder="Select Category"
            value={this.state.questionType}
            onChange={e => this.changeQuestionType(e)}
          >
            {this.state.questionTypes}
          </FormControl>
        </FormGroup>
        {this.state.questionType === config.questionTypes.CHECKBOX &&
          <CreateCheckboxQuestion editIndex={this.state.questionToEdit} addQuestion={this.props.addQuestion} editQuestion={this.props.editQuestion} questionToEditContent={this.props.questionToEditContent}/>
        }
        {this.state.questionType === config.questionTypes.RADIO &&
          <CreateRadioQuestion editIndex={this.state.questionToEdit} addQuestion={this.props.addQuestion} editQuestion={this.props.editQuestion} questionToEditContent={this.props.questionToEditContent}/>
        }
        {this.state.questionType === config.questionTypes.FILL &&
          <CreateFillBlankQuestion editIndex={this.state.questionToEdit} addQuestion={this.props.addQuestion} editQuestion={this.props.editQuestion}  questionToEditContent={this.props.questionToEditContent}/>
        }
        {this.state.questionType === config.questionTypes.DRAG &&
          <CreateDragQuestion editIndex={this.state.questionToEdit} addQuestion={this.props.addQuestion} editQuestion={this.props.editQuestion}  questionToEditContent={this.props.questionToEditContent}/>
        }
        {this.state.questionType === config.questionTypes.FREE &&
          <div className="create-free-text">
            <FormGroup className="form" controlId="title" validationState={!this.state.validQuestionText ? 'error' : null}>
              <ControlLabel>Question Text</ControlLabel>
              <FormControl
                className="form-input"
                componentClass="textarea"
                value={this.state.freeTextQuestion}
                onChange={e => this.setState({ freeTextQuestion: e.target.value, validQuestionText: true })}
              />
              {!this.state.validQuestionText && <HelpBlock>Question text can not be empty</HelpBlock>}
            </FormGroup>
            <div className="modal-controls">
              <Button className="modal-control modal-button" id="done" onClick={e => this.onClickDone(e)}>Done</Button>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default QuizBuilder;
