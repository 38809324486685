import React, { Component } from 'react';
import { Row, Col, Image, Checkbox, Radio } from 'react-bootstrap';
import config from '../../constant/config';
import './TableSelectList.css';

class TableSelectList extends Component {
  render() {
    const { rows } = this.props;
    return (
      <div className="table-select-list">
        <div className="module-select-heading">{this.props.heading}</div>
        <div className="modules-container">
          {rows.map(row => (
            <Row key={row.id} className="module-row">
              <hr />
              <div className="module-row-lft">
                <Col md={4}>
                  <div className="module-image-container">
                    <Image className="module-image" src={`${config.spacesURL}/${row.logoId}`} />
                  </div>
                </Col>
                <Col className="module-title-container" md={4}>
                  <div className="module-title">
                    {row.title}
                  </div>
                </Col>
                <Col className="module-creator-container" md={4}>
                  <div className="module-creator">{`Created by ${row.createdBy}`}</div>
                </Col>
              </div>
              <div className="module-row-rt">
                <Col className="module-checkbox-container" md={12}>
                  {this.props.type === 'checkbox' && <Checkbox className="module-checkbox" id={row.id} onChange={e => this.props.onRowSelect(e.target.id)} checked={row.isSelected} /> }
                  {this.props.type === 'radio' && <Radio className="module-checkbox" name="table-group" id={row.id} checked={row.isSelected} onChange={e => this.props.onRowSelect(e.target.id)} /> }
                </Col>
              </div>
            </Row>))}
        </div>
      </div>
    );
  }
}

export default TableSelectList;
