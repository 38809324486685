import React, { Component } from 'react';
import {
  Button,
} from 'react-bootstrap';

import pracIcon from '../../../resources/img/practitioner_icon.svg';
import persIcon from '../../../resources/img/personal_icon.svg';
import bothIcon from '../../../resources/img/both_icon.svg';
import tick from '../../../resources/img/tick.svg';

import './SignUpNew.css';

class PageThree extends Component {

  render() {
    const {
      accountType,
      accountTypeOptions,
      setAccountType,
      handleButtonClick,
    } = this.props;

    const valid = accountTypeOptions.includes(accountType);

    return (
        <div className="no-padding">
          <div className="title-section">
            {/* <div>
              <p className="hlayout-title">
               Please choose from the options below
              </p>
            </div> */}

            <div>
              <p className="hlayout-copy">
              Please choose from the options below.
              You'll then be asked to answer a few questions so that we can show you the most relevant content.
              It'll take less than 5 minutes. You can change your answers later on under <span style={{fontWeight: 'bolder'}}>My profile</span> if you need to.
              </p>
            </div>
          </div>

          <div className="hlayout-bottom no-colour row">
            <div className="col-md-2 no-padding"></div>

            <div className="col-md-8 mb-20 mt-20 no-padding">
              <div
                className={accountTypeOptions[1] === accountType ? "account-option-box prac-colour row prac-selected" : "account-option-box prac-colour row"}
                onClick={() => setAccountType(accountTypeOptions[1])}
                style={{marginBottom: '20px'}}
              >
                <div className="col-md-2 center">
                  <img
                    src={pracIcon}
                    alt="practitioner icon"
                  />
                </div>
                <div className="col-md-8">
                 I’m interested in using Actify Platform to <span style={{fontWeight: 'bolder'}}>Help Others</span> achieve their goals.
                </div>
                <div className="col-md-2 center">
                  { accountTypeOptions[1] === accountType &&
                    <img src={tick} alt="tick icon" />
                  }
                </div>
              </div>

              <div
                className={accountTypeOptions[0] === accountType ? "account-option-box pers-colour row pers-selected" : "account-option-box pers-colour row"}
                onClick={() => setAccountType(accountTypeOptions[0])}
                style={{marginBottom: '20px'}}
              >
                <div className="col-md-2 center">
                  <img
                    src={persIcon}
                    alt="personal icon"
                  />
                </div>
                <div className="col-md-8">
                I want to use Actify to <span style={{fontWeight: 'bolder'}}>Help Myself</span> learn more about being active and improving my fitness/skills.
                </div>
                <div className="col-md-2 center">
                  { accountTypeOptions[0] === accountType &&
                    <img src={tick} alt="tick icon" />
                  }
                </div>
              </div>

              <div
                className={accountTypeOptions[2] === accountType ? "account-option-box mix-colour row mix-selected" : "account-option-box mix-colour row"}
                onClick={() => setAccountType(accountTypeOptions[2])}
              >
                <div className="col-md-2 center">
                  <img
                    src={bothIcon}
                    alt="both icon"
                  />
                </div>
                <div className="col-md-8">
                I want to use Actify to <span style={{fontWeight: 'bolder'}}>Help Others</span> to acheive their goals as well as to <span style={{fontWeight: 'bolder'}}>Help Myself</span>.
                </div>
                <div className="col-md-2 center">
                  { accountTypeOptions[2] === accountType &&
                    <img src={tick} alt="tick icon" />
                  }
                </div>
              </div>
            </div>

            <div className="col-md-2 no-padding"></div>
          </div>

          <div className="navigation-buttons no-colour">
            <div className="back">
              <Button className="navigation-button" onClick={() => handleButtonClick(true)}>
                ← Back
              </Button>
            </div>
            {valid &&
              <div className="next">
                <Button className="navigation-button" onClick={() => handleButtonClick()}>
                  Next →
                </Button>
              </div>
            }
          </div>
        </div>
    );
  }
}

export default PageThree;
