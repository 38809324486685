import React from 'react';
import config from '../../../constant/config';

const getGenderOptions = async () => {
  const res = await fetch(`${config.apiURL}/gender`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch genders');
  }
  const genders = await res.json();
  return genders;
};

const getTypes = async () => {
  const res = await fetch(`${config.apiURL}/userType`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch types');
  }
  const types = await res.json();
  return types;
};

const getRoles = async () => {
  const res = await fetch(`${config.apiURL}/role`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch roles');
  }
  const roles = await res.json();
  return roles;
};

const getGoals = async () => {
  const res = await fetch(`${config.apiURL}/goal`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch goals');
  }
  const goals = await res.json();
  return goals;
};

const getActivities = async () => {
  const res = await fetch(`${config.apiURL}/activity`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch activities');
  }
  const activites = await res.json();
  return activites;
};

const getTargetGroups = async () => {
  const res = await fetch(`${config.apiURL}/targetGroup`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch target groups');
  }
  const targetGroups = await res.json();
  return targetGroups;
};

const getTargetAges = async () => {
  const res = await fetch(`${config.apiURL}/targetAge`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch target ages');
  }
  const targetAges = await res.json();
  return targetAges;
};

const getActivityLevels = async () => {
  const res = await fetch(`${config.apiURL}/activityLevel`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch activity levels');
  }
  const activityLevels = await res.json();
  return activityLevels;
};

const getMotivations = async () => {
  const res = await fetch(`${config.apiURL}/motivation`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch activity levels');
  }
  const motivations = await res.json();
  return motivations;
};

const getConfidenceLevels = async () => {
  const res = await fetch(`${config.apiURL}/confidenceLevel`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch confidence levels');
  }
  const confidenceLevels = await res.json();
  return confidenceLevels;
};

const getMovementLevels = async () => {
  const res = await fetch(`${config.apiURL}/movement`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch confidence levels');
  }
  const movementLevels = await res.json();
  return movementLevels;
};

const getStrengthBalanceLevels = async () => {
  const res = await fetch(`${config.apiURL}/strengthBalance`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch confidence levels');
  }
  const strengthBalanceLevels = await res.json();
  return strengthBalanceLevels;
};

const counties = config.councilAreas.map((county) => (
  <option key={county} value={county}>
    {county}
  </option>
));

const fetchSuggestedHubs = async (interests, goals, targetGroups) => {
  const interestIds = interests.map(v => v.id);
  const goalIds = goals.map(v => v.id);
  const targetGroupIds = targetGroups.map(v => v.id);

  const res = await fetch(`${config.apiURL}/hub/suggested/`, {
    credentials: 'include',
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      interestIds,
      goalIds,
      targetGroupIds,
    }),
  });
  const suggestedHubs = await res.json();
  if (res.status === 200) {
    return suggestedHubs;
  }
  return [];
};

export {
  getGenderOptions,
  getTypes,
  getRoles,
  getActivities,
  getGoals,
  getTargetGroups,
  getTargetAges,
  getActivityLevels,
  getConfidenceLevels,
  getMotivations,
  getMovementLevels,
  getStrengthBalanceLevels,
  fetchSuggestedHubs,
  counties,
};
