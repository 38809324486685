import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setActiveProfileTab } from '../../store/actions';
import SideBar from './SideBar';

const mapStateToProps = state => ({
  user: state.user,
  sideBarExpanded: state.sideBarExpanded,
  activeProfileTab: state.activeProfileTab,
});

const mapDispatchToProps = dispatch => ({
  setActiveProfileTab: (activeTab) => {
    dispatch(setActiveProfileTab(activeTab));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBar));
