import { connect } from 'react-redux';
import { setActiveProfileTab } from '../../store/actions';
import CreatePlanSession from './CreatePlanSession';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setActiveProfileTab: (activeTab) => {
    dispatch(setActiveProfileTab(activeTab));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlanSession);
