import React from 'react';
import Collapsible from 'react-collapsible';
import parse from 'html-react-parser';

import config from '../../constant/config';
import './Faq.css';

import { parseContent } from '../../lib/utilities';



class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: [],
      faqOpened: [],
    };
  }

  componentDidMount() {
    this.getFaq();
  }

  async getFaq() {
    try {
      const res = await fetch(`${config.apiURL}/faq`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        let faq = await res.json();

        // Filtering faq questions
        const questionType = parseInt(this.props.type, 10);
        faq = faq.filter(function (item) {
          return item.type === (questionType);
        });

        const faqOpened = faq.map(faq => false);
        this.setState({ faq, faqOpened });
        console.log(faq)
      }
    } catch (err) {
      console.log(err.message);
    }

  }

  updateFaqOpened(i) {
    let opened = this.state.faqOpened;
    opened[i] = !opened[i];
    this.setState({ faqOpened: opened });
  }

  render() {
    if (this.state.faq.length > 0) {
      return this.state.faq.map((faq, index) => (
        <div className={parseInt(this.props.type, 10) === 1 ? "style1" : "style2"}>
          <Collapsible

            trigger={
              <div style={{ display: "flex" }}>
                <div className="question" style={{ flex: "85%" }}>
                  {faq.question}
                </div>
                <div className={parseInt(this.props.type, 10) === 1 ? "style1" : "style2"}>
                  <div
                    className={this.state.faqOpened[index] ? "faq-caret-icon faq-caret-icon-rotated" : "faq-caret-icon"}
                    style={{ flex: "15%" }}
                  />
                </div>

              </div>
            }
            triggerTagName="div"
            transitionTime={300}
            onOpen={() => this.updateFaqOpened(index)}
            onClose={() => this.updateFaqOpened(index)}
          >
            <div>
              {parse(parseContent(faq.answer))}
            </div>
          </Collapsible>
        </div>
      ));
    }
    return (<p>Currently there is no frequently asked questions.</p>);
  }
}

export default Faq;
