import React from 'react';
import { Image, Row, Col } from 'react-bootstrap';
import config from '../../constant/config';
import './SessionHeader.css';

class SessionHeader extends React.Component {
  render() {
    if (!this.props.session.hub) {
      return null;
    }
    return (
      <div className="module-header-container">
        <Row className="header-container" style={{ marginBottom: this.props.edit ? 10 : 50 }}>
          <Col md={2} className="header-image-container">
            <Image className="module-logo" src={`${config.spacesURL}/${this.props.session.hub.logoId}`} />
          </Col>
          <Col md={8} className="module-details-container">
            <Row>
              <h3>{this.props.session.title}</h3>
              <h4>{`${this.props.hubName} | ${this.props.categoryDescription}`}</h4>
              <p>{this.props.session.additionalInfo}</p>
            </Row>
          </Col>
          <Col md={2} className="social-container">
            {this.props.loggedIn && this.props.actionButton}
          </Col>
        </Row>
      </div>
    );
  }
}

export default SessionHeader;
