import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import Slider from 'rc-slider';

import sad from '../../../../resources/img/sad_icon.svg';
import meh from '../../../../resources/img/meh_icon.svg';
import happy from '../../../../resources/img/happy_icon.svg';

import '../SignUpNew.css';
import 'rc-slider/assets/index.css';


class PageSevenPers extends Component {

  render() {
    const {
      selectedConfidence,
      setSelectedConfidence,
      confidenceOptions,
      handleButtonClick
    } = this.props;

    const { skipValidation } = this.props;

    const valid = confidenceOptions.includes(selectedConfidence);

    const onSliderChange = (v) => {
      const level = confidenceOptions[v - 1]
      if (level)
        setSelectedConfidence(level);
    };

    const setmarks = {
      1: {
        style: {
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: selectedConfidence.id === 1 ? 'bold' : 'normal',
        },
        label: (
          <div>
            <div className="pers-colour-dark-text">1</div>
            <div className="slider-label">{confidenceOptions[0].level}</div>
            <div style={{textAlign: 'center'}}>
              <img src={sad} className="sitting-man" alt="sad icon" />
            </div>
          </div>
        )
      },
      2: {
        style: {
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: selectedConfidence.id === 2 ? 'bold' : 'normal'
        },
        label: (
          <div>
            <div className="pers-colour-dark-text">2</div>
            <div className="slider-label">{confidenceOptions[1].level}</div>
            <div style={{textAlign: 'center'}}>
              <img src={meh} className="walking-man" alt="meh/ok icon" />
            </div>
          </div>
        )
      },
      3: {
        style: {
          fontSize: '16px',
          lineHeight: '19px',
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: selectedConfidence.id === 3 ? 'bold' : 'normal'
        },
        label: (
          <div>
            <div className="pers-colour-dark-text">3</div>
            <div className="slider-label">{confidenceOptions[2].level}</div>
            <div style={{textAlign: 'center'}}>
              <img src={happy} className="running-man" alt="happy icon" />
            </div>
          </div>
        )
      }
    };

    return (
      <div className="no-padding" style={{ padding: "0 2em" }}>
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              How confident do you feel when taking part in physical activity?
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              Your confidence level will also have an impact on the types of organisations and resources that are best suited to you.
            </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour row">
          <div className="col-md-2 no-padding" />

          <div className="col-md-8 mb-20 mt-20">
            <Slider
              min={1}
              max={3}
              value={confidenceOptions.indexOf(selectedConfidence) + 1}
              onChange={(v) => onSliderChange(v)}
              marks={setmarks}
              step={1}
              included={false}
              handleStyle={{
                borderColor: 'white',
                height: 35,
                width: 35,
                marginTop: -8,
                backgroundColor: '#81C3D0'
              }}
              railStyle={{ backgroundColor: '#E6F8FB', height: 20 }}
              dots={false}
            />
          </div>

          <div className="col-md-2 no-padding" />
        </div>

        <br /><br /><br /><br /><br />

        <div className="navigation-buttons no-colour mt-20">
          <div className="back">
            <Button
              className="navigation-button"
              onClick={() => handleButtonClick(true)}
            >
              ← Back
            </Button>
          </div>
          {(valid || skipValidation) &&
            <div className="next">
              <Button
                className="navigation-button"
                onClick={() => handleButtonClick()}
              >
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageSevenPers;
