import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import BottomNav from './BottomNav';
import Footer from './Footer';
import config from '../../constant/config';
import './SideBar.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faBookmark, faCommentDots, faQuestionCircle, faUser, faTasks } from '@fortawesome/free-solid-svg-icons'

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: null,
      showModal: false,
      modalContent: null,
      followingExpanded: false,
      followingExpandable: false,
      followingMoreHubCount: 0,
      myHubsExpanded: false,
      myHubsExpandable: false,
      myHubsMoreHubCount: 0,
      isSiteOwner: props.user ? (props.user.isSiteOwner || false) : false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.hubInviteToken = new URLSearchParams(this.props.location.search).get("invite_token");
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== !!this.props.user) {
      this.setState({ isSiteOwner: newProps.user ? (newProps.user.isSiteOwner || false) : false });
    }
  }

  closeModal() {
    this.setState({ showModal: false, modalContent: null });
  }

  isItemActive(item) {
    const { activeProfileTab, location } = this.props;
    const { pathname } = location;
    const { showModal } = this.state;
    const excludeTabs = [1, 4];
    switch (item.toLowerCase()) {
      case 'home':
        return pathname === '/home' && !showModal;
      case 'bookmarks':
        return pathname === '/myprofile' && !showModal && activeProfileTab === 1;
      case 'messages':
        return pathname === '/myprofile' && !showModal && activeProfileTab === 4;
      case 'login':
        return showModal;
      case 'myprofile':
        return pathname === '/myprofile' && !showModal && !excludeTabs.includes(activeProfileTab);
      default:
    }
    if (pathname === `/${item.toLowerCase()}`) {
      return true && !showModal;
    }
    return false;
  }

  renderHubs() {
    const { user } = this.props;
    const { following } = user;

    let filteredHubs = following.filter(hub => hub && hub.approved)

    if (filteredHubs.length === 0)
      return <span>Hubs you follow will show here!</span>

    if (filteredHubs.length > 5) {
      this.state.followingExpandable = true;  // eslint-disable-line
      this.state.followingMoreHubCount = filteredHubs.length - 5;  // eslint-disable-line
    }

    if (!this.state.followingExpanded && this.state.followingExpandable)
      filteredHubs = filteredHubs.splice(0, 5);

    return filteredHubs.map(hub => (
      <div
        key={hub.pathName}
        className={this.isItemActive(hub.pathName) ? 'selected' : ''}
        onClick={() => {
          this.props.history.push(`/${hub.pathName}`);
          window.location.reload(false);
        }}
      >
        <div
          className="hub-icon"
          style={{ backgroundImage: `url(${config.spacesURL}/${hub.logoId}.thumbnail)` }}
        />
        <span className="hub-name">{hub.name}</span>
      </div>
    ));
  }

  renderMyHubs() {
    let filteredHubs = this.props.user.hubs.filter(this.isApprovedHub);

    if (filteredHubs.length > 5) { // eslint-disable-next-line
      this.state.myHubsExpandable = true; // eslint-disable-next-line
      this.state.myHubsMoreHubCount = filteredHubs.length - 5;
    }

    if (!this.state.myHubsExpanded && this.state.myHubsExpandable) {
      filteredHubs = filteredHubs.splice(0, 5);
    }

    return filteredHubs.map(hub => (
      <div
        key={hub.pathName}
        className={this.isItemActive(hub.pathName) ? 'selected' : ''}
        onClick={() => {
          this.props.history.push(`/${hub.pathName}`);
          window.location.reload(false);
        }}
      >
        <div
          className="hub-icon"
          style={{ backgroundImage: `url(${config.spacesURL}/${hub.logoId}.thumbnail)` }}
        />
        <span className="hub-name">{hub.name}</span>
      </div>
    ));
  }
  isApprovedHub(hub) {
    try {
      if (hub.approved) {
        return true;
      }
    } catch(err) {
      console.log(err)
      return false;
    }

    return false;
  }



  render() {
    const wrapperClass = `${this.props.location.pathname}`;

    const {
      showModal,
      modalContent,
      followingExpandable,
      followingExpanded,
      isSiteOwner
    } = this.state;

    const {
      children,
      user,
      setActiveProfileTab
    } = this.props;

    return (
      <div>
        { user ?
          <div className="side-bar-wrapper">
            <div className="side-bar">
              <div className='actify-logo'
                onClick={() => this.props.history.push('/home') }
              />

              <div className="nav-buttons">
                <div
                  className={this.isItemActive('explore') ? 'selected' : ''}
                  onClick={() => this.props.history.push('/explore')}
                >
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faCompass}
                      aria-hidden="true"
                    />
                  </div>
                  <span>Explore</span>
                </div>
                <div
                  className={this.isItemActive('messages') ? 'selected' : ''}
                  onClick={() => {
                    this.props.history.push('/myprofile');
                    setActiveProfileTab(4);
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faCommentDots}
                      aria-hidden="true"
                    />
                  </div>
                  <span>Messages</span>
                </div>
                <div
                  className={this.isItemActive('myprofile') ? 'selected' : ''}
                  onClick={() => {
                    this.props.history.push('/myprofile');
                    setActiveProfileTab(0);
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faUser}
                      aria-hidden="true"
                    />
                  </div>
                  <span>Profile</span>
                </div>
                <div
                  className={this.isItemActive('bookmarks') ? 'selected' : ''}
                  onClick={() => {
                    this.props.history.push('/myprofile');
                    setActiveProfileTab(1);
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faBookmark}
                      aria-hidden="true"
                    />
                  </div>
                  <span>Bookmarks</span>
                </div>
                <div
                  className={this.isItemActive('help') ? 'selected' : ''}
                  onClick={() => {
                    this.props.history.push('/help');
                    window.location.reload();
                  }}
                >
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      aria-hidden="true"
                    />
                  </div>
                  <span>Help</span>
                </div>
                { user.isSiteOwner &&
                  <div
                    className={this.isItemActive('manage') ? 'selected' : ''}
                    onClick={() => this.props.history.push('/manage')}
                  >
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={faTasks}
                        aria-hidden="true"
                      />
                    </div>
                    <span>Manage</span>
                  </div>
                }
              </div>

              <hr style={{ borderWidth: '3px' }}/>
              {this.props.user.hubs.length > 0 &&
              <div>
                <span className="side-bar-title">My Hubs</span>
                <div className="followed-hubs">
                  {this.renderMyHubs(this.props.user.hubs)}
                  {this.state.myHubsExpandable &&
                    <div
                      className="view-more"
                      onClick={() => this.setState({ myHubsExpanded: !this.state.myHubsExpanded })}
                    >
                      {!this.state.myHubsExpanded ? `Show ${this.state.myHubsMoreHubCount} more hubs` : `Hide ${this.state.myHubsMoreHubCount} hubs`}
                    </div>
                  }
                </div>
                <hr style={{ borderWidth: '3px' }} />
              </div>
            }
              {/* {
                this.hasHubs(this.props.user.hubs) > 0 &&
                <div>
                  <hr className="divider my-hubs-list" />
                  <div className="hub-list my-hubs-list">
                    <span className={(this.state.isExpanded || this.props.sideBarExpanded) ? "muted" : "muted hidden"}>My Hubs</span>
                    {this.renderMyHubs(this.props.user.hubs)}
                    {this.state.myHubsExpandable && !this.state.myHubsExpanded &&
                      <Button className={(this.state.isExpanded || this.props.sideBarExpanded) ? "view-following" : "view-following hidden"} onClick={() => {this.setState({myHubsExpanded: true,});}}>
                        Show {this.state.myHubsMoreHubCount} more hubs
                      </Button>
                    }
                    {this.state.myHubsExpandable && this.state.myHubsExpanded &&
                      <Button className={(this.state.isExpanded || this.props.sideBarExpanded) ? "view-following" : "view-following hidden"} onClick={() => {this.setState({myHubsExpanded: false,})}}>
                        Hide {this.state.myHubsMoreHubCount} hubs
                      </Button>
                    }
                  </div>
                </div>

              } */}
              { user.following.length > 0 &&
                <div>
                  <span className="side-bar-title">Followed Hubs</span>
                  <div className="followed-hubs">
                    { this.renderHubs() }
                    {followingExpandable &&
                      <div
                        className="view-more"
                        onClick={() => this.setState({followingExpanded: !followingExpanded})}
                      >
                        {!followingExpanded ? 'Show all' : 'Show less'}
                      </div>
                    }
                  </div>
                  <hr style={{ borderWidth: '3px' }}/>
                </div>
              }

{/* { this.props.user.following.length > 0 &&
          <div>
            <hr className="divider follow-hubs-list" />
            <div className="hub-list follow-hubs-list">
              <span className={(this.state.isExpanded || this.props.sideBarExpanded) ? "muted" : "muted hidden"}>Following</span>
              {this.renderFollowing(this.props.user.following)}
              {this.state.followingExpandable && !this.state.followingExpanded &&
                <Button className={(this.state.isExpanded || this.props.sideBarExpanded) ? "view-following" : "view-following hidden"} onClick={() => {this.setState({followingExpanded: true,});}}>
                  Show {this.state.followingMoreHubCount} more hubs
                </Button>
              }
              {this.state.followingExpandable && this.state.followingExpanded &&
                <Button className={(this.state.isExpanded || this.props.sideBarExpanded) ? "view-following" : "view-following hidden"} onClick={() => {this.setState({followingExpanded: false,})}}>
                  Hide {this.state.followingMoreHubCount} hubs
                </Button>
              }
            </div>
          </div>
        } */}

              <span className="side-bar-title">Supported by</span>
              <div className="supported-scotland" />
              <span className="side-bar-title" style={{marginBottom: "10px"}}>&copy; Actify {new Date().getFullYear()}</span>
            </div>

            <div id="page-wrapper" className={wrapperClass}>
              {children}
            </div>

            <Footer pushHistory={(pathname) => this.props.history.push(pathname)} />

            <BottomNav
              isActive={(item) => this.isItemActive(item)}
              isSiteOwner={isSiteOwner}
              setActiveProfileTab={(tab) => setActiveProfileTab(tab)}
              pushHistory={(pathname) => this.props.history.push(pathname)}
            />

            <Modal show={showModal} onHide={this.closeModal} backdrop="static">
              <Modal.Header closeButton />
              {modalContent}
            </Modal>
          </div>
          :
          <div>
            <div id="page-wrapper" className={wrapperClass} style={{ paddingLeft: 0, marginTop: '76px'}}>
              {children}
            </div>

            <Footer paddingZero pushHistory={(pathname) => this.props.history.push(pathname)} />

            <Modal show={showModal} onHide={this.closeModal} backdrop="static">
              <Modal.Header closeButton />
              {modalContent}
            </Modal>
          </div>
        }
      </div>
    );
  }
}

export default SideBar;
