import React, { Component } from 'react';
import { Button, HelpBlock, FormGroup } from 'react-bootstrap';
import { shuffle } from '../../../lib/utilities';
import config from '../../../constant/config';
import CheckboxQuestion from './CheckboxQuestion';
import RadioQuestion from './RadioQuestion';
import FreeTextQiestion from './FreeTextQiestion';
import FillQuestion from './FillQuestion';
import DragQuestion from './DragQuestion';
import './Quiz.css';

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      quizResult: null,
      review: false,
      reviewedAnswers: props.review ?
        props.questions.filter(q => q.questionType === config.questionTypes.FREE)
          .map(q => ({ questionId: q.id, correct: null })) :
        [],
      reviewHelpText: null,
    };
    this.onReviewQuestion = this.onReviewQuestion.bind(this);
  }

  componentWillMount() {
    if (this.props.reviewResult) {
      this.setState({
        quizResult: this.props.reviewResult,
        review: true,
      });
      return;
    }
    // updates the answers stored in redux
    this.getQuizAnswers();
    if (this.props.quizResults.length > 0) {
      const quizResult = this.props.quizResults.find(result => result.moduleId === this.props.moduleId);
      if (quizResult) {
        // if review is required check it has been carried out
        if (quizResult.requiresAdminReview) {
          this.getresult();
        }
        this.setState({ quizResult });
      }
    }
  }

  async onClickAnswer(answer, questionId) {
    const questionIds = this.state.answers.map(ans => ans.questionId);
    const newAnswer = { questionId, answer };
    const index = questionIds.indexOf(parseInt(questionId, 10));
    if (index === -1) {
      const answers = [...this.state.answers, newAnswer];
      await this.setState({ answers });
    } else {
      const { answers } = this.state;
      answers.splice(index, 1);
      answers.push(newAnswer);
      await this.setState({ answers });
    }
  }

  async onSubmitQuiz(e) {
    e.preventDefault();
    if (this.state.review) {
      const { reviewedAnswers } = this.state;
      if (reviewedAnswers.find(ans => ans.correct === null)) {
        this.setState({ reviewHelpText: 'Please review all free text questions' });
        return;
      }
      try {
        const res = await fetch(`${config.apiURL}/quiz/review/${this.state.quizResult.moduleId}/${this.props.userId}`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(reviewedAnswers),
        });
        if (res.status !== 200) {
          throw new Error('unable to submit quiz');
        }
        const result = await res.json();
        this.setState({ quizResult: result, review: false, reviewedAnswers: [], reviewHelpText: '' });
        this.getNotifications();
      } catch (err) {
        this.setState({ reviewHelpText: 'Unable to submit quiz' });
      }
    } else {
      const { answers } = this.state;
      if (this.state.answers.length !== this.props.questions.length) {
        this.setState({ reviewHelpText: 'Please answer all the questions' });
        return;
      }
      try {
        const res = await fetch(`${config.apiURL}/quiz/${this.props.moduleId}/answer`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(answers),
        });
        if (res.status !== 200) {
          throw new Error('unable to submit quiz');
        }
        const result = await res.json();
        this.props.addQuizResult(result);
        this.setState({ quizResult: result, reviewHelpText: '' });
      } catch (err) {
        this.setState({ reviewHelpText: 'Unable to submit quiz' });
      }
    }
    // updates the answers stored in redux
    await this.getQuizAnswers();
  }

  onReviewQuestion(questionId, correct) {
    const { reviewedAnswers } = this.state;
    const answerIndex = reviewedAnswers.findIndex(q => q.questionId === questionId);
    if (answerIndex > -1) {
      reviewedAnswers[answerIndex] = { questionId, correct };
    } else {
      reviewedAnswers.push({ questionId, correct });
    }
    this.setState({
      reviewedAnswers,
    });
  }

  async getNotifications() {
    try {
      const res = await fetch(`${config.apiURL}/user/notifications`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const notifications = await res.json();
        this.props.setNotifications(notifications.count);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getresult() {
    const res = await fetch(`${config.apiURL}/quiz/${this.props.moduleId}/answer`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status !== 200) {
      console.error('unable to fetch updated answers');
    }
    const quizResult = await res.json();
    this.setState({
      quizResult,
    });
    this.props.addQuizResult(quizResult);
  }

  async getQuizAnswers() {
    try {
      const res = await fetch(`${config.apiURL}/quiz/answers`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const body = await res.json();
        this.props.setQuizResults(body);
        console.log(body)
      }
    } catch (err) {
      console.error(err);
    }
  }

  renderQuestions() {
    return (
      this.props.questions.map((question, index) => {
        if (question.questionType === config.questionTypes.CHECKBOX) {
          return (
            <CheckboxQuestion
              key={`question-${question.id}`}
              disabled={!!this.state.quizResult}
              questionNumber={index + 1}
              questionText={question.text}
              answerLabel="Select all that apply:"
              possibleAnswers={question.possibleAnswers}
              onClickAnswer={answerId => this.onClickAnswer(answerId, question.id)}
              answer={this.state.quizResult ? this.state.quizResult.userAnswers.find(ans => ans.questionId === question.id) : null}
            />);
        } else if (question.questionType === config.questionTypes.RADIO) {
          return (
            <RadioQuestion
              key={`question-${question.id}`}
              disabled={!!this.state.quizResult}
              questionNumber={index + 1}
              questionText={question.text}
              answerLabel="Select the correct answer below:"
              possibleAnswers={question.possibleAnswers}
              onClickAnswer={answerId => this.onClickAnswer(answerId, question.id)}
              answer={this.state.quizResult ? this.state.quizResult.userAnswers.find(ans => ans.questionId === question.id) : null}
            />);
        } else if (question.questionType === config.questionTypes.FREE) {
          return (
            <FreeTextQiestion
              key={`question-${question.id}`}
              disabled={!!this.state.quizResult}
              review={this.state.review}
              onReviewQuestion={correct => this.onReviewQuestion(question.id, correct)}
              questionNumber={index + 1}
              questionText={question.text}
              answerLabel={this.state.review ? 'Answer' : 'Your answer'}
              onChangeAnswer={answer => this.onClickAnswer(answer, question.id)}
              answer={this.state.quizResult ? this.state.quizResult.userAnswers.find(ans => ans.questionId === question.id) : null}
            />
          );
        } else if (question.questionType === config.questionTypes.FILL) {
          const questionWords = question.text.split(' ');
          return (
            <FillQuestion
              key={`question-${question.id}`}
              disabled={!!this.state.quizResult}
              questionNumber={index + 1}
              questionWords={questionWords}
              editableWords={question.editableWords}
              onChangeAnswer={answer => this.onClickAnswer(answer, question.id)}
              answer={this.state.quizResult ? this.state.quizResult.userAnswers.find(ans => ans.questionId === question.id) : null}
            />
          );
        } else if (question.questionType === config.questionTypes.DRAG) {
          const questionWords = question.text.split(' ');
          const randomEditableWords = shuffle(question.editableWords);
          return (
            <DragQuestion
              key={`question-${question.id}`}
              disabled={!!this.state.quizResult}
              questionNumber={index + 1}
              questionWords={questionWords}
              editableWords={question.editableWords}
              randomEditableWords={randomEditableWords}
              onChangeAnswer={answer => this.onClickAnswer(answer, question.id)}
              answer={this.state.quizResult ? this.state.quizResult.userAnswers.find(ans => ans.questionId === question.id) : null}
            />
          );
        }
        return null;
      })
    );
  }

  renderQuizFeedback() {
    if (this.state.quizResult.requiresAdminReview) {
      return (
        <div className="quiz-feedback-container">
          <p className="quiz-feedback-heading">Quiz Feedback</p>
          <div>Quiz Awaiting Admin Review</div>
          <p>In the mean time you can view your answers below</p>
        </div>

      );
    }
    return (
      <div className="quiz-feedback-container">
        <p className="quiz-feedback-heading">Quiz Feedback</p>
        <div>{`${Math.round((this.state.quizResult.userScore / this.state.quizResult.userAnswers.length) * 100)}%`}</div>
        <div>{`Overall Quiz Score: ${this.state.quizResult.userScore} out of ${this.state.quizResult.userAnswers.length}`}</div>
        <p>See below for detailed results</p>
      </div>

    );
  }

  render() {
    return (
      <div className="quiz">
        {!this.state.review && <h4 className="title">Module Quiz</h4>}
        {this.state.quizResult && !this.state.review && this.renderQuizFeedback()}
        <div className="question-container">
          {this.renderQuestions()}
        </div>
        {this.state.reviewHelpText &&
          <FormGroup validationState="error">
            <HelpBlock>{this.state.reviewHelpText}</HelpBlock>
          </FormGroup>
        }
        {(this.state.quizResult === null || (this.state.quizResult && this.state.review)) &&
          <Button
            id="newQuestionButton"
            className="new-button"
            onClick={e => this.onSubmitQuiz(e)}
          >
            Submit
          </Button>
        }
      </div>
    );
  }
}

export default Quiz;
