import React, { Component } from 'react';
import { FormGroup, ControlLabel, Checkbox } from 'react-bootstrap';
import './CheckboxQuestion.css';

class CheckboxQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckbox: [],
    };
  }

  async onClickAnswer(answerId) {
    const index = this.state.selectedCheckbox.indexOf(parseInt(answerId, 10));
    if (index === -1) {
      const selectedCheckbox = [...this.state.selectedCheckbox, parseInt(answerId, 10)];
      await this.setState({ selectedCheckbox });
    } else {
      const { selectedCheckbox } = this.state;
      selectedCheckbox.splice(index, 1);
      await this.setState({ selectedCheckbox });
    }
    this.props.onClickAnswer(this.state.selectedCheckbox);
  }

  getStyle() {
    if (this.props.answer) {
      return {
        color: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        borderColor: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        border: '1px',
        borderStyle: 'solid',
      };
    }
    return null;
  }

  renderLabel(option) {
    if (!option.id) {
      return (
        option.answerText
      );
    }
    if (!this.props.answer) {
      return (
        option.answerText
      );
    }
    if (this.props.answer.correctAnswer.includes(option.id)) {
      return (
        <p className="correct">{option.answerText}</p>
      );
    }
    if (this.props.answer.answerValue.includes(option.id)) {
      return (
        <p className="incorrect">{option.answerText}</p>
      );
    }
    return (
      option.answerText
    );
  }

  render() {
    return (
      <div className="checkbox-question-container" style={this.getStyle()}>
        <h3 className="question-text-container">
          {this.props.questionNumber ?
            <ol start={this.props.questionNumber}>
              <li>{this.props.questionText}</li>
            </ol> : this.props.questionText}
        </h3>
        { this.props.deletable &&
          <div className="icon-container">
            <span className="edit-quiz" onClick={() => this.props.editQuestion()}>EDIT</span>
            <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.props.removeQuestion()} />
          </div>
        }
        {this.props.answer &&
          <div className="icon-container">
            {this.props.answer.isCorrect ?
              <i className="fa fa-check fa-2x" aria-hidden="true" /> :
              <i className="fa fa-times fa-2x" aria-hidden="true" />
            }
          </div>
        }
        <div className="answers-container">
          <FormGroup className="form" controlId="formRadio" style={{display:"block"}}>
            {this.props.answerLabel && <ControlLabel>{this.props.answerLabel}</ControlLabel>}
            {this.props.possibleAnswers.map((answer, index) => {
              if (answer.id) {
                return (
                  <Checkbox
                    disabled={this.props.disabled}
                    key={`correct-answer-${index}`}
                    id={answer.id}
                    onClick={() => this.onClickAnswer(answer.id)}
                    checked={this.props.answer ? this.props.answer.answerValue.includes(answer.id) : null}
                  >
                    {this.renderLabel(answer)}
                  </Checkbox>);
                }
              return (<Checkbox disabled={this.props.disabled} key={`correct-answer-${index}`} id={index} onClick={() => this.onClickAnswer(index)}>{answer.answerText}</Checkbox>); // eslint-disable-line
            })
          }
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default CheckboxQuestion;
