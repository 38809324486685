import React from 'react';
import { FormGroup, FormControl, Button, HelpBlock } from 'react-bootstrap';
import config from '../../constant/config';
import checkStatus from '../common/checkStatus';
import { emailValid } from '../../lib/validation';
import './Contact.css';
import { Link } from 'react-router-dom';

import personIcon from '../../resources/img/person_icon.svg';
import mailIcon from '../../resources/img/mail_icon.svg';
import subjectIcon from '../../resources/img/subjectIcon.svg';
import emailSuccessIcon from '../../resources/img/email_success.svg';
import emailFailureIcon from '../../resources/img/email_failure.svg';
import runningMan from '../../resources/img/running_man_contact.svg';
import climberIcon from '../../resources/img/climber_icon_contact.svg';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      validName: true,
      email: '',
      validEmail: true,
      subject: '',
      validSubject: true,
      message: '',
      validMessage: true,
      feedback: '',
      success: false,
    };
  }

  async handleButtonClick() {
    if (this.validateForm()) {
      try {
        const res = await fetch(`${config.apiURL}/message/contact`, {
          credentials: 'include',
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            name: this.state.name,
            subject: this.state.subject,
            content: this.state.message,
            email: this.state.email,
          }),
        });
        if (res.status === 200) {
          const feedback = 'Email Sent';
          const success = true;
          this.setState({ feedback, success });
        }
      } catch (err) {
        const feedback = 'Email Not Sent';
        const success = false;
        this.setState({ feedback, success });
        console.log(err);
      }
    }
  }

  validateForm() {
    const validName = this.state.name.length > 0 && this.state.name.length < 255;
    const validEmail =
      emailValid(this.state.email)
      && this.state.email.length > 0
      && this.state.email.length < 255;
    const validSubject = this.state.subject.length > 0 && this.state.subject.length < 255;
    const validMessage = this.state.message.length >= 20 && this.state.message.length <= 500;
    this.setState({
      validName, validEmail, validSubject, validMessage,
    });
    if (!(validName && validEmail && validSubject && validMessage)) {
      return false;
    }
    return true;
  }

  render() {
    const {
      name,
      validName,
      email,
      validEmail,
      subject,
      validSubject,
      message,
      validMessage,
      feedback,
      success
    } = this.state;

    const validEmailSecond = emailValid(email) && email.length < 255;

    return (
      <div style={{display:'block'}}>
        <div className='mobile-contact-img' />
        <div className="contact-form-container">

          <div className="contact-form">
            <span className="contact-form-subtitle">Contact us</span>
            <span className="contact-form-title">Send us a message!</span>
            <span className="contact-form-desc">
              Got a question that hasn't been answered elsewhere on the site (take a peek at our <Link to="/faq"> <u>FAQs</u></Link>, just in case)? <br></br><br></br> Maybe you have an idea that you'd like to share. Or you just want to say hello to the team behind Actify. Pop your details in below and we'll get back to you within 24 hours.
            </span>
            <br />

            {!feedback ?
              <div>
                <div className="contact-form-name-email">
                  <FormGroup className="form" controlId="formName" validationState={!validName ? 'error' : null}>
                    <FormControl
                      className="form-input name"
                      type="text"
                      value={name}
                      onChange={e => this.setState({ name: e.target.value })}
                      placeholder="Full name"
                    />
                    <img
                      class="input-icon"
                      src={personIcon}
                      alt="person icon"
                    />
                    {!validName && <HelpBlock>Please enter your name</HelpBlock>}
                  </FormGroup>
                  <FormGroup className="form" controlId="formEmail" validationState={(!validEmail || !validEmailSecond) ? 'error' : null}>
                    <FormControl
                      className="form-input"
                      type="email"
                      value={email}
                      onChange={e => this.setState({ email: e.target.value })}
                      placeholder="Email address"
                    />
                    <img
                      class="input-icon"
                      style={{ marginTop: '11px' }}
                      src={mailIcon}
                      alt="mail icon"
                    />
                    {(!validEmail || !validEmailSecond) && <HelpBlock>Please enter valid email</HelpBlock>}
                  </FormGroup>
                </div>

                <FormGroup className="form" controlId="formSubject" validationState={!validSubject ? 'error' : null} >
                  <FormControl
                    className="form-input"
                    type="text"
                    value={subject}
                    maxLength={255}
                    onChange={e => this.setState({ subject: e.target.value })}
                    placeholder="Subject"
                  />
                  <img
                    class="input-icon"
                    style={{ marginTop: '5px' }}
                    src={subjectIcon}
                    alt="subject icon"
                  />
                  {!validSubject && <HelpBlock>Please enter valid subject</HelpBlock>}
                </FormGroup>

                <FormGroup className="form" controlId="message" validationState={!validMessage ? 'error' : null}>
                  <div className="character-count">{500 - message.length} / 500</div>
                  <FormControl
                    className="form-input message-text-area"
                    componentClass="textarea"
                    value={message}
                    maxLength={500}
                    onChange={e => this.setState({ message: e.target.value })}
                    placeholder="Type message here..."
                    rows="7"
                  />
                  {!validMessage && <HelpBlock>Please enter valid message between 20 and 500 characters</HelpBlock>}
                </FormGroup>

                <div className="submit-button-container">
                  <Button className="submit-button" onClick={() => this.handleButtonClick()}>
                    Send Message →
                  </Button>
                </div>
              </div>
              :
              <div className={success ? 'form-success' : 'form-failure'}>
                {success ?
                  <div>
                    <div className="feedback-top">
                      <span className="success-text">
                        Thank you!
                        <br />
                        <span className="feedback-subtext">
                          Message successful.
                        </span>
                      </span>
                      <img
                        className="person-icon"
                        src={runningMan}
                        alt="running man"
                      />
                    </div>
                    <div className="feedback-desc">
                      A member of the Actify team will aim to reply to your message within 24 hours.
                    </div>
                    <br /><br /><br />
                  </div>
                  :
                  <div>
                    <div className="feedback-top">
                      <span className="failure-text">
                        Sorry.
                        <br />
                        <span className="feedback-subtext">
                          Message failed.
                        </span>
                      </span>
                      <img
                        className="person-icon"
                        src={climberIcon}
                        alt="climber man"
                      />
                    </div>
                    <div className="feedback-desc">
                      An error occurred while sending your message. <br></br>Apologies for the inconvenience – please try again.
                    </div>
                    <br /><br /><br />
                  </div>
                }


                <div className={success ? 'success' : 'failure'}>
                  {success ?
                    <img
                      class="feedback-icon"
                      src={emailSuccessIcon}
                      alt="email success icon"
                    />
                    :
                    <img
                      class="feedback-icon"
                      src={emailFailureIcon}
                      alt="email failure icon"
                    />
                  }
                </div>
              </div>
            }
          </div>

          <div className="contact-img-container">
            <div className="contact-img" />
          </div>
        </div>
      </div>
    );
  }
}

export default checkStatus(Contact);
