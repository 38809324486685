import React from 'react';
import { Button, FormGroup, FormControl, ControlLabel, HelpBlock} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import config from '../../constant/config';
import { phonenumberValid, postcodeValid, urlValid } from '../../lib/validation';
import './CreateHub.css';
import CyclingManIcon from "../../resources/img/CyclingManIcon.svg";
import tick from "../../resources/img/Vector.svg";

const getActivities = async () => {
  const res = await fetch(`${config.apiURL}/activity/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status !== 200) {
    throw new Error('can not fetch activities');
  }
  const activities = await res.json();
  return activities;
};

const fetchGoals = async () => {
  const res = await fetch(`${config.apiURL}/goal`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch goals');
  }
  const goals = await res.json();
  return goals;
};

const getTargetGroups = async () => {
  const res = await fetch(`${config.apiURL}/targetgroup/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status !== 200) {
    throw new Error('can not fetch age groups');
  }
  const targetGroups = await res.json();
  return targetGroups;
};

const counties = config.councilAreas.map((county) => (
  <option key={county} value={county}>
    {county}
  </option>
));

class CreateHub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      hubName: '',
      hubNameHelpText: '',
      organisation: '',
      organisationHelpText: '',
      phoneNumber: '',
      phoneNumberHelpText: '',
      website: '',
      websiteHelpText: '',
      address1: '',
      address2: '',
      postcode: '',
      addressHelpText: '',
      description: '',
      descriptionHelpText: '',
      // activities: [{ id: 1, description: 'one' }, { id: 2, description: 'two' }],
      selectedActivity: '',
      chosenActivities: [],
      activityOptions: [],
      // activity: '',
      activityHelpText: '',
      selectedGoal: '',
      chosenGoals: [],
      goalOptions: [],
      goalsHelpText: '',
      selectedTargetGroup: '',
      chosenTargetGroups: [],
      targetGroupOptions: [],
      targetGroupHelpText: '',
      logo: null,
      logoHelpText: '',
      logoFileType: '',
      logoFileName: '',
      banner: null,
      bannerHelpText: '',
      bannerFileType: '',
      bannerFileName: '',
      closeCountdown: 50,
      hubLogo: null,
      hubBanner: null,
      target: '',
      targetHelpText: '',
      physicalActivity: '',
      physicalActivityHelpText: '',
      locations: counties,
      location: '',
      locationHelpText: '',
      confidenceLevel: '',
      confidenceLevelHelpText: '',
      // targetAges: [],
      // targetAge: '',
      // targetAgeHelpText: '',
      errorMessage: '',
      isCreatingHub: false,
    };
  }

  componentWillMount() {
    this.getSelectListOptions();
  }


  onChangeFile(e, context) {
    e.preventDefault();
    const selectedFiles = context === 'logo' ? this.hubLogoFileInput.files : this.bannerFileInput.files;
    const file = selectedFiles[0];
    if (!file) {
      return;
    }
    if (context === 'logo') {
      this.setState({
        logoFileName: file.name,
      });
      this.setState({
        hubLogo: URL.createObjectURL(e.target.files[0]),
      });
    } else {
      this.setState({
        bannerFileName: file.name,
      });
      this.setState({
        hubBanner: URL.createObjectURL(e.target.files[0]),
      });
    }

    this.readFile(file, context);
  }

  async onClickButton(e) {
    e.preventDefault();
    if (this.state.currentPage === 1) {
      let pageValid = true;
      let hubNameHelpText = '';
      let organisationHelpText = '';
      let phoneNumberHelpText = '';
      let websiteHelpText = '';
      let addressHelpText = '';

      if (this.state.hubName === '') {
        pageValid = false;
        hubNameHelpText = 'Hub name is required';
      }
      if (this.state.organisation === '') {
        pageValid = false;
        organisationHelpText = 'Organisation name is required';
      }
      if (this.state.phoneNumber === '') {
        pageValid = false;
        phoneNumberHelpText = 'Phone number is required';
      }
      if (!phonenumberValid(this.state.phoneNumber)) {
        pageValid = false;
        phoneNumberHelpText = 'Valid phone number is required';
      }

      if (!urlValid(this.state.website)) {
        pageValid = false;
        websiteHelpText = 'Valid website url is required';
      }

      if (this.state.address1 === '' || this.state.address2 === '') {
        pageValid = false;
        addressHelpText = 'Address is required';
      }

      if (!postcodeValid(this.state.postcode) || this.state.postcode === '') {
        pageValid = false;
        addressHelpText = 'Valid postcode required';
      }
      if (!this.state.hubName === '') {
        const availableName = await this.checkNameAvailable(this.state.hubName);
        if (!availableName) {
          pageValid = false;
          hubNameHelpText = 'Hub name already in use.';
        }
      }
      let descriptionHelpText = '';
      let activityHelpText = '';
      let goalsHelpText = '';
      let targetGroupHelpText = '';
      let logoHelpText = '';
      let bannerHelpText = '';
      let targetHelpText = '';
      let physicalActivityHelpText = '';
      let locationHelpText = '';
      // let targetAgeHelpText = '';
      let confidenceLevelHelpText = '';
      let errorMessage = '';
      if (this.state.description === '') {
        pageValid = false;
        descriptionHelpText = 'Hub description is required';
      }
      if (this.state.chosenActivities.length === 0) {
        pageValid = false;
        activityHelpText = 'At least one activity is required';
      }
      if (this.state.chosenGoals.length === 0) {
        pageValid = false;
        goalsHelpText = 'At least one goal is required';
      }
      if (this.state.chosenTargetGroups.length === 0) {
        pageValid = false;
        targetGroupHelpText = 'At least one hub target group is required';
      }
      if (this.state.logo === null) {
        pageValid = false;
        logoHelpText = 'Hub logo is required';
      }
      if (this.state.banner === null) {
        pageValid = false;
        bannerHelpText = 'Hub banner image is required';
      }
      if (this.state.target === '') {
        pageValid = false;
        targetHelpText = 'Target Skill Level is required';
      }
      if (this.state.physicalActivity === '') {
        pageValid = false;
        physicalActivityHelpText = 'Physicial Activity Level is required';
      }
      if (this.state.location === '') {
        pageValid = false;
        locationHelpText = 'Target audience location is required';
      }
      // if (this.state.targetAge === '') {
      //   pageValid = false;
      //   targetAgeHelpText = 'Target Age group is required';
      // }
       if (this.state.confidenceLevel === '') {
        pageValid = false;
        confidenceLevelHelpText = 'Target Confidence Level is required';
      }

      if (pageValid) {
        this.setState({errorMessage: ''});
        this.onCreateHub();
      } else {
        errorMessage = 'Cannot create a hub. Please check the provided information and try again';
        this.setState({
          hubNameHelpText,
          organisationHelpText,
          phoneNumberHelpText,
          websiteHelpText,
          addressHelpText,
          descriptionHelpText,
          activityHelpText,
          goalsHelpText,
          targetGroupHelpText,
          logoHelpText,
          bannerHelpText,
          targetHelpText,
          physicalActivityHelpText,
          locationHelpText,
          // targetAgeHelpText,
          confidenceLevelHelpText,
          errorMessage,
        });
      }
    }
  }

  async onCreateHub() {
    this.setState({ isCreatingHub: true });
    const logoPayload = new Buffer(this.state.logo, 'binary').toString('binary'); //eslint-disable-line
    const logoRes = await fetch(`${config.apiURL}/asset/`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        title: this.state.logoFileName,
        data: logoPayload,
        fileType: this.state.logoFileType,
      }),
    });
    const logoId = await logoRes.text();
    const bannerPayload = new Buffer(this.state.banner, 'binary').toString('binary'); //eslint-disable-line
    const bannerRes = await fetch(`${config.apiURL}/asset/`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        title: this.state.bannerFileName,
        data: bannerPayload,
        fileType: this.state.bannerFileType,
      }),
    });
    const bannerId = await bannerRes.text();
    const res = await fetch(`${config.apiURL}/hub/`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        name: this.state.hubName,
        organisation: this.state.organisation,
        phoneNumber: this.state.phoneNumber,
        website: this.state.website,
        address1: this.state.address1,
        address2: this.state.address2,
        postcode: this.state.postcode,
        description: this.state.description,
        activityIds: this.state.chosenActivities,
        targetGroupIds: this.state.chosenTargetGroups,
        goalIds: this.state.chosenGoals,
        logoId,
        bannerId,
        target: this.state.target,
        physicalActivity: this.state.physicalActivity,
        location: this.state.location,
        // targetAge: parseInt(this.state.targetAge, 10),
        targetConfidence: this.state.confidenceLevel,
      }),
    });
    if (res.status !== 200) {
      this.setState({ currentPage: null, signUpError: 'Create hub unsuccessful, please try again',  isCreatingHub: false });
      return;
    }
    this.setState({
      currentPage: 2, isCreatingHub: false,
    });
    this.closeCountdown();
  }

  async getSelectListOptions() {
    const activityOptions = await getActivities();
    const targetGroupOptions = await getTargetGroups();
    const goalOptions = await fetchGoals();
    // const targetAges = await getTargetAges();
    this.setState({
      activityOptions,
      targetGroupOptions,
      goalOptions: goalOptions.filter((g) => g.type === 2 || g.type === 3),
      // targetAges,
    });
  }

  async checkNameAvailable(name) {
    const res = await fetch(`${config.apiURL}/hub/available/${name}`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 401) {
      this.props.history.push('/');
    }
    return res.status === 200;
  }

  closeCountdown() {
    const { closeCountdown } = this.state;
    if (closeCountdown > 0) {
      setTimeout(async () => {
        await this.setState({ closeCountdown: this.state.closeCountdown - 1 });
        this.closeCountdown();
      }, 1000);
    } else {
      this.props.handleModalClose();
    }
  }

  browseFiles(context) {
    if (context === 'logo') {
      this.hubLogoFileInput.click();
    } else {
      this.bannerFileInput.click();
    }
  }

  readFile(file, context) {
    const reader = new FileReader();
    const acceptedList = ['image/PNG', 'image/png', 'image/jpeg', 'image/jpg', 'image/JPEG'];
    reader.onloadend = () => {
      if (context === 'logo') {
        if (file.size > 5000000) {
          this.setState({
            logo: null,
            logoHelpText: 'Files must be less than 5mb',
            logoFileType: '',
            logoFileName: '',
          });
          return;
        }
        if (!acceptedList.includes(file.type)) {
          this.setState({
            logo: null,
            logoHelpText: 'Images must be jpeg or png',
            logoFileType: '',
            logoFileName: '',
          });
          return;
        }
        this.setState({
          logo: reader.result,
          logoHelpText: '',
          logoFileType: file.type,
          logoFileName: file.name,
        });
      } else {
        if (file.size > 5000000) {
          this.setState({
            banner: null,
            bannerHelpText: 'Files must be less than 5mb',
            bannerFileType: '',
            bannerFileName: '',
          });
          return;
        }
        if (!acceptedList.includes(file.type)) {
          this.setState({
            banner: null,
            bannerHelpText: 'Images must be jpeg or png',
            bannerFileType: '',
            bannerFileName: '',
          });
          return;
        }
        this.setState({
          banner: reader.result,
          bannerHelpText: '',
          bannerFileType: file.type,
          bannerFileName: file.name,
        });
      }
    };
    reader.readAsArrayBuffer(file);
  }

  async checkHubNameAvailable(e) {
    e.preventDefault();
    const desiredName = this.state.hubName;
    if (desiredName === '') {
      this.setState({
        hubNameHelpText: 'Hub name is required',
      });
      return;
    }
    const available = await this.checkNameAvailable(desiredName);
    this.setState({
      hubNameHelpText: available ? '' : 'Hub name already in use.',
    });
  }

  addGoal() {
    if (this.state.selectedGoal === '') {
      return;
    }
    this.setState({
      chosenGoals: [...this.state.chosenGoals, parseInt(this.state.selectedGoal, 10)],
      selectedGoal: '',
    });
  }

  removeGoalClick(e) {
    const goal = e.target.id;
    this.setState({
      chosenGoals: this.state.chosenGoals.filter(i => i !== parseInt(goal, 10)),
    });
  }

  renderChosenGoals() {
    return this.state.chosenGoals.map((id) => {
      const goal = this.state.goalOptions.find(i => i.id === id);
      return (
        <div key={id} className="selection-container">
          <div className="selection-text">{goal.description}</div>
          <div className="selection-icon">
            <i
              id={id}
              className="fa fa-times fa"
              aria-hidden="true"
              onClick={e => this.removeGoalClick(e)}
            />
          </div>
        </div>
      );
    });
  }

  addActivity() {
    if (this.state.selectedActivity === '') {
      return;
    }
    this.setState({
      chosenActivities: [...this.state.chosenActivities, parseInt(this.state.selectedActivity, 10)],
      selectedActivity: '',
    });
  }

  removeActivityClick(e) {
    const activity = e.target.id;
    this.setState({
      chosenActivities: this.state.chosenActivities.filter(i => i !== parseInt(activity, 10)),
    });
  }

  renderChosenActivities() {
    return this.state.chosenActivities.map((id) => {
      const activity = this.state.activityOptions.find(i => i.id === id);
      return (
        <div key={id} className="selection-container">
          <div className="selection-text">{activity.description}</div>
          <div className="selection-icon">
            <i
              id={id}
              className="fa fa-times fa"
              aria-hidden="true"
              onClick={e => this.removeActivityClick(e)}
            />
          </div>
        </div>
      );
    });
  }

  addTargetGroup() {
    if (this.state.selectedTargetGroup === '') {
      return;
    }
    this.setState({
      chosenTargetGroups: [...this.state.chosenTargetGroups, parseInt(this.state.selectedTargetGroup, 10)],
      selectedTargetGroup: '',
    });
  }

  removeTargetGroupClick(e) {
    const targetGroup = e.target.id;
    this.setState({
      chosenTargetGroups: this.state.chosenTargetGroups.filter(i => i !== parseInt(targetGroup, 10)),
    });
  }

  renderChosenTargetGroups() {
    return this.state.chosenTargetGroups.map((id) => {
      const targetGroup = this.state.targetGroupOptions.find(i => i.id === id);
      return (
        <div key={id} className="selection-container">
          <div className="selection-text">{targetGroup.description}</div>
          <div className="selection-icon">
            <i
              id={id}
              className="fa fa-times fa"
              aria-hidden="true"
              onClick={e => this.removeTargetGroupClick(e)}
            />
          </div>
        </div>
      );
    });
  }

  renderPageOne() {
    const {
      hubNameHelpText,
      organisationHelpText,
      phoneNumberHelpText,
      addressHelpText,
      websiteHelpText,
      descriptionHelpText,
      activityHelpText,
      goalsHelpText,
      // targetAges,
      targetGroupHelpText,
      logoHelpText,
      bannerHelpText,
      targetHelpText,
      physicalActivityHelpText,
      locationHelpText,
      locations,
      confidenceLevelHelpText,
      // targetAgeHelpText
    } = this.state;

    const activities = this.state.activityOptions.filter(i =>
      !this.state.chosenActivities.includes(i.id));
    const targetGroups = this.state.targetGroupOptions.filter(i =>
      !this.state.chosenTargetGroups.includes(i.id));
    const goals = this.state.goalOptions.filter(i =>
      !this.state.chosenGoals.includes(i.id));
    const physicalActivitylist = ["Inactive", "Active", "Very Active"];
    const targetlist = ["Not Skilled", "Somewhat", "Very Skilled"];
    const confidenceLevelList = ["Not Very Confident", "Somewhat Confident", "Very Confident"]

    const Target = ({ active, target, onClick }) => {
      if (active) {
        return (
          <div onClick={onClick} className="targets-selected" >
            {target}
            <img src={tick} alt="" style={{ marginLeft: "20px" }} />
          </div>
        );
      }
      return (
        <div onClick={onClick} className="targets">
          {target}
        </div>
      );
    };

    return (
      <div className="modal-form-container">
        <FormGroup className="form-group" controlId="hub-name" validationState={hubNameHelpText ? 'error' : null}>
          <ControlLabel>Hub Name</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input name"
              type="text"
              placeholder="My Hub"
              value={this.state.hubName}
              onChange={e => this.setState({ hubName: e.target.value })}
              onBlur={e => this.checkHubNameAvailable(e)}
            />
            <FormControl.Feedback />
            {hubNameHelpText && <HelpBlock style={{ marginTop: "-0px", marginLeft: "8px" }}>{hubNameHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="organisation" validationState={organisationHelpText ? 'error' : null}>
          <ControlLabel>Organisation</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input organisation"
              type="text"
              placeholder="My Organisation"
              value={this.state.organisation}
              onChange={e => this.setState({ organisation: e.target.value })}
            />
            <FormControl.Feedback />
            {organisationHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{organisationHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="phone-number" validationState={phoneNumberHelpText ? 'error' : null}>
          <ControlLabel>Phone Number</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input phone-number"
              type="text"
              placeholder="123456789"
              value={this.state.phoneNumber}
              onChange={e => this.setState({ phoneNumber: e.target.value })}
            />
            <FormControl.Feedback />
            {phoneNumberHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{phoneNumberHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="website" validationState={websiteHelpText ? 'error' : null}>
          <ControlLabel>Website</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input website"
              type="text"
              placeholder="www.example.com"
              value={this.state.website}
              onChange={e => this.setState({ website: e.target.value })}
            />
            <FormControl.Feedback />
            {websiteHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{websiteHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="address1" validationState={addressHelpText ? 'error' : null}>
          <ControlLabel>Address</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input address"
              type="text"
              placeholder="Address 1"
              value={this.state.address1}
              onChange={e => this.setState({ address1: e.target.value })}
            />
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="address2">
          <ControlLabel>Address 2</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input address"
              type="text"
              placeholder="Address 2"
              value={this.state.address2}
              onChange={e => this.setState({ address2: e.target.value })}
            />
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="postcode" validationState={addressHelpText ? 'error' : null}>
          <ControlLabel>Post Code</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input postcode"
              type="text"
              placeholder="Postcode"
              value={this.state.postcode}
              onChange={e => this.setState({ postcode: e.target.value })}
            />
            {addressHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{addressHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="hub-description" validationState={descriptionHelpText ? 'error' : null}>
          <ControlLabel className="inline-label">Hub Description</ControlLabel>
          <div className='form-input'>
            <div className="character-count">{300 - this.state.description.length}/300</div>
            <FormControl
              className="form-input description"
              componentClass="textarea"
              placeholder="Description"
              value={this.state.description}
              maxLength={300}
              onChange={e => this.setState({ description: e.target.value })}
            />
            <FormControl.Feedback />
            {descriptionHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{descriptionHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="hub-activity" validationState={activityHelpText ? 'error' : null}>
          <ControlLabel>Activity</ControlLabel>
          <div className='form-input'>
            <div style={{ display: "block", flex: "1" }}>
              <FormControl
                className="form-input multi-select"
                componentClass="select"
                onChange={e => this.setState({ selectedActivity: e.target.value }, () => this.addActivity())}
              >
                <option value="select">Select Activity</option>
                {activities.map(activity => (
                  <option key={activity.id} value={activity.id} color="red">{activity.description}</option>
                ))}
              </FormControl>
              <div className="chosen-goals-container">
                {this.renderChosenActivities()}
              </div>
            </div>
            {activityHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{activityHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form" controlId="formGoals" validationState={goalsHelpText ? 'error' : null}>
          <ControlLabel>Goals</ControlLabel>
          <div className='form-input'>
            <div style={{ display: "block", flex: "1" }}>
              <FormControl
                className="form-input multi-select"
                componentClass="select"
                onChange={e => this.setState({ selectedGoal: e.target.value }, () => this.addGoal())}
              >
                <option value="select">Please enter your goals</option>
                {goals.map(goal => (
                  <option key={goal.id} value={goal.id}>{goal.description}</option>
                ))}
              </FormControl>
              <div className="chosen-goals-container">
                {this.renderChosenGoals()}
              </div>
            </div>
            {(this.state.goalsHelpText) && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>Please enter at least one Group</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="location" validationState={targetGroupHelpText ? 'error' : null}>
          <ControlLabel>Target Group</ControlLabel>
          <div className='form-input'>
            <div style={{ display: "block", flex: "1" }}>
              <FormControl
                className="form-input multi-select"
                componentClass="select"
                onChange={e => this.setState({ selectedTargetGroup: e.target.value }, () => this.addTargetGroup())}
              >
                <option value="select">Select Group</option>
                {targetGroups.map(targetGroup => (
                  <option key={targetGroup.id} value={targetGroup.id}>{targetGroup.description}</option>
                ))}
              </FormControl>
              <div className="chosen-goals-container">
                {this.renderChosenTargetGroups()}
              </div>
            </div>
            {targetGroupHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{targetGroupHelpText}</HelpBlock>}
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="location" validationState={locationHelpText ? 'error' : null}>
          <ControlLabel>Local Authority Areas Covered</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input targetGroup-select"
              componentClass="select"
              onChange={e => this.setState({ location: e.target.value })}>
              <option value="select">Select Location</option>
              {locations}
            </FormControl>
            {locationHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{locationHelpText}</HelpBlock>}
            <FormControl.Feedback />
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="physicalActivity" validationState={physicalActivityHelpText ? 'error' : null}>
          <ControlLabel>Physical Activity Level</ControlLabel>
          <div className='form-input'>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
              {physicalActivitylist.map(t => (
                <Target
                  key={t}
                  target={t}
                  active={t === this.state.physicalActivity}
                  onClick={() => this.setState({ physicalActivity: t })}
                />
              ))}
            </div>
            {physicalActivityHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{physicalActivityHelpText}</HelpBlock>}
            <FormControl.Feedback />
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="target" validationState={targetHelpText ? 'error' : null}>
          <ControlLabel>Target Skill Level</ControlLabel>
          <div className='form-input'>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
              {targetlist.map(t => (
                <Target
                  key={t}
                  target={t}
                  active={t === this.state.target}
                  onClick={() => this.setState({ target: t })}
                />
              ))}
            </div>
            {targetHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{targetHelpText}</HelpBlock>}
            <FormControl.Feedback />
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="target" validationState={confidenceLevelHelpText ? 'error' : null}>
          <ControlLabel>Target Confidence Level</ControlLabel>
          <div className='form-input'>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
              {confidenceLevelList.map(t => (
                <Target
                  key={t}
                  target={t}
                  active={t === this.state.confidenceLevel}
                  onClick={() => this.setState({ confidenceLevel: t })}
                />
              ))}
            </div>
            {confidenceLevelHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{confidenceLevelHelpText}</HelpBlock>}
            <FormControl.Feedback />
          </div>
        </FormGroup>
        {/* <FormGroup className="form-group" controlId="age-group" validationState={targetAgeHelpText ? 'error' : null}>
          <ControlLabel>Target Audience Age Bracket</ControlLabel>
          <div className='form-input'>
            <FormControl
              className="form-input targetGroup-select"
              componentClass="select"
              onChange={e => this.setState({ targetAge: e.target.value })}
            >
              <option value="select">Select Age Group</option>
              {targetAges.map(targetages => (
                <option key={targetages.id} value={targetages.id}>{targetages.lower}{targetages.upper > 100 ? '+' : ` - ${targetages.upper}`}</option>
              ))}
            </FormControl>
            <FormControl.Feedback />
            {targetAgeHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{targetAgeHelpText}</HelpBlock>}
          </div>
        </FormGroup> */}
        <FormGroup className="form-group" controlId="logo" validationState={logoHelpText ? 'error' : null}>
          <ControlLabel>Hub Logo
            <div className="inline">(Recommended Size 250 x 250)</div>
          </ControlLabel>
          <div className='form-input'>
            <div style={{ display: "block", flex: "1" }}>
              {/* <FormControl
                className="form-input file-input-label"
                type="text"
                contentEditable={false}
                disabled
                placeholder='Upload image'
                value={this.state.logoFileName}
              /> */}
              <div className="browse-files-button-container">
                <div style={{ display: "block" }}>
                  <img className='img1' src={this.state.hubLogo} alt="hub-logo" style={{ width: "135px", height: "135px", float: "right" }} />
                  <div className='image-description'>{this.state.logoFileName}</div>
                </div>
                <Button
                  className="browse-files-button"
                  onClick={() => this.browseFiles('logo')}
                >
                  {this.state.logoFileName === '' ? 'Upload' : 'Reupload'}
                </Button>
              </div>
            </div>
            {logoHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{logoHelpText}</HelpBlock>}
            <FormControl.Feedback />
          </div>
        </FormGroup>
        <FormGroup className="form-group" controlId="address2" validationState={bannerHelpText ? 'error' : null}>
          <ControlLabel>Banner Image
            <div className='inline'>(Recommended Size 1350 x 250)</div>
          </ControlLabel>
          <div className='form-input'>
            <div style={{ display: "block", flex: "1" }}>
              {/* <FormControl
                className=" form-input file-input-label"
                type="text"
                disabled
                placeholder='Upload image'
                contentEditable={false}
                value={this.state.bannerFileName}
              /> */}
              <div className="browse-files-button-container">
                <div style={{ display: "block" }}>
                  <img className='img1' src={this.state.hubBanner} alt="hub-banner" style={{ width: "500px", height: "100px", float: "right" }} />
                  <div className='image-description'>{this.state.bannerFileName}</div>
                </div>
                <Button
                  className="browse-files-button"
                  onClick={() => this.browseFiles('banner')}
                >
                  {this.state.bannerFileName === '' ? 'Upload' : 'Reupload'}
                </Button>
              </div>
            </div>
            {bannerHelpText && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{bannerHelpText}</HelpBlock>}
            <FormControl.Feedback />
          </div>
        </FormGroup>
        <div style={{ display: 'none' }}>
          <input
            ref={(f) => { this.hubLogoFileInput = f; }}
            className="form-input logo"
            type="file"
            onChange={e => this.onChangeFile(e, 'logo')}
            accept="image/jpeg"
          />
          <input
            ref={(f) => { this.bannerFileInput = f; }}
            className="form-input logo"
            type="file"
            placeholder="hub logo"
            onChange={e => this.onChangeFile(e, 'banner')}
            accept="image/jpeg"
          />
        </div>
      </div>
    );
  }


  renderSuccess() {
    return (
      <div className="modal-form-container-no-scroll">
        <div className="success-container">
          <img className="success-image" alt="" src={CyclingManIcon} />
          <p className='success-message'>Congratulations! Your new hub has been created and is pending approval. A member of the Actify team will be in touch soon regarding your new hub. </p>
        </div>
      </div>
    );
  }

  renderErrorFeedback() {
    return (
      <div className="feedback-container">
        <p className="sign-up-feedback">{this.state.signUpError}</p>
      </div>
    );
  }

  render() {
    return (
      <div className="CreateHub">
        <div className="modal-header-container">
          <h4 className="modal-title">{this.state.currentPage === 2 ? 'Hub submitted!' : 'Create Hub'}</h4>
        </div>
        {this.state.currentPage === 1 && this.renderPageOne()}
        {this.state.currentPage === 2 && this.renderSuccess()}
        {this.state.signUpError && this.renderErrorFeedback()}
        {this.state.errorMessage && <HelpBlock style={{ marginTop: "0px", marginLeft: "8px" }}>{this.state.errorMessage}</HelpBlock>}
        {this.state.isCreatingHub &&  <HelpBlock style={{ color: "#14b4c8", marginTop: "0px", marginLeft: "8px" }}> Please wait, we are creating your hub...</HelpBlock>}
        {!this.state.signUpError && this.state.currentPage === 1 &&
          <div className="modal-button-container">
            <Button className="modal-button" onClick={e => this.onClickButton(e)}>
              Done
            </Button>
          </div>}
      </div>
    );
  }
}

export default withRouter(CreateHub);
