import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import introductoryImage from '../../resources/img/introductory_image.svg'
import introductoryImage2 from '../../resources/img/introductory_image2.svg'

class UserWalkthroughPageTwo extends Component {


render() {
    const { nextPage, previousPage, existingUserSelected } = this.props;

    return (
        <div className = 'walkthrough'>
            <div className='top-section'>
                <div style={{width:'55%', textAlign: 'center', margin: '0 auto'}}>
                    <p className='heading'> Profile</p>
                </div>
                { existingUserSelected === true ?
                    <div>
                        <p className='subheading'>
                            There are now two different types of profiles on Actify. You can choose between a Helping Others account OR a Personal
                            account OR have both! Below is a brief explanation of each profile to help you decide which one works for you.
                        </p>
                        <img src={introductoryImage} className="walkthrough-img" alt=""/>
                    </div>
                :
                    <div>
                        <p className='subheading'>
                            Within your profile, you can view, edit and update your personal details as well as complete your questionnaire here.
                            And view any quiz results here.
                        </p>
                        <img src={introductoryImage2} className="walkthrough-img" alt=""/>
                    </div>
                }
            </div>

            <div className='navigation'>
                <Button className='back-button' onClick={() => previousPage()}>
                    ← Back
                </Button>

                <Button className='next-button' onClick={() => nextPage()}>
                    Next →
                </Button>
            </div>
        </div>
    );
  }
}

export default withRouter(UserWalkthroughPageTwo);
