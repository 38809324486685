import React from 'react';
import config from '../../constant/config';
import auth from '../../lib/auth';
import PlanHeader from '../common/PlanHeader';
import checkStatus from '../common/checkStatus';
import './EditModule.css';
import CreatePlanSession from '../common/CreatePlanSessionContainer';

class EditPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: null,
      hubName: '',
      categoryDescription: '',
      actionButton: null,
    };
  }

  componentWillMount() {
    this.getPlan();
  }

  async getPlan() {
    const { planId, pathname } = this.props.match.params;
    try {
      const res = await fetch(`${config.apiURL}/hub/plan/${pathname}/${planId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const plan = await res.json();
        const isAdmin = await auth.isAuthenticatedHubAdmin(plan.hub.id);
        const actionButton = this.renderActionButton(isAdmin);
        await this.setState({
          plan,
          hubName: plan.hub.name,
          categoryDescription: 'Plan',
          actionButton,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  renderActionButton() {
    return;
  }

  render() {
    return (
      <div>
        {this.state.plan &&
        <div>
          <PlanHeader
            plan={this.state.plan}
            hubName={this.state.hubName}
            categoryDescription={this.state.categoryDescription}
            actionButton={this.state.actionButton}
            loggedIn={this.props.user}
            edit
          />
          <CreatePlanSession
            edit
            plan={this.state.plan}
            hubId={this.state.plan.hubId}
            modules={this.state.plan.hub.modules}
            approved
          />
        </div>
        }
      </div>
    );
  }
}

export default checkStatus(EditPlan);
