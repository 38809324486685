import React, { Component } from 'react';
import {
  Button,
} from 'react-bootstrap';

import '../SignUpNew.css';

class PageFourPrac extends Component {

  onRoleClick(role) {
    const { chosenRoles, setChosenRoles } = this.props;

    if (chosenRoles.includes(role)) {
      const index = chosenRoles.indexOf(role);
      chosenRoles.splice(index, 1);
      setChosenRoles(chosenRoles);
    } else {
      setChosenRoles([...chosenRoles, role]);
    }
  }

  renderOptions() {
    const { roleOptions, chosenRoles } = this.props;

    return roleOptions.map(role => (
      <div
        key={role.id}
        className={chosenRoles.includes(role) ? "prac-colour prac-selected" : "prac-colour"}
        onClick={() => this.onRoleClick(role)}
      >
        <span className="option">{role.description}</span>
        {chosenRoles.includes(role) &&
          <div className="selected-option" />
        }
      </div>
    ));
  }

  render() {
    const {
      chosenRoles,
      roleOptions,
      handleButtonClick,
    } = this.props;

    const { skipValidation } = this.props;

    const validRoles = chosenRoles.every(role => roleOptions.includes(role));
    const valid = validRoles && chosenRoles.length > 0;

    return (
      <div className="no-padding">
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              What is your role?
            </p>
          </div>

          <div>
            <p className="hlayout-copy">
              Tell us a bit more about what you do so we can show you the most relevant content. Feel free to choose more than one role. </p>
          </div>
        </div>

        <div className="hlayout-bottom no-colour">
          <div className="options-container">
            {this.renderOptions()}
          </div>
        </div>

        <div className="navigation-buttons no-colour">
          <div className="back">
            <Button className="navigation-button" onClick={() => handleButtonClick(true)}>
              ← Back
            </Button>
          </div>
          {(valid || skipValidation) &&
            <div className="next">
              <Button className="navigation-button" onClick={() => handleButtonClick()}>
                Next →
              </Button>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default PageFourPrac;
