import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel, HelpBlock, Checkbox, Button, Radio, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import TableSelectList from './TableSelectList';
import config from '../../constant/config';
import { postcodeValid } from '../../lib/validation';
import publicIcon from "../../resources/img/publicicon1.svg";
import HiddenIcon from "../../resources/img/HiddenIcon.svg";
import eyeVisibleIcon from "../../resources/img/eyeVisibleicon.svg";
import ModuleMembers from './ModuleMembers';
import tick from "../../resources/img/Vector.svg";
import './CreatePlanSession.css';

import RTE from './RTE';

import RichTextEditor, {
  getTextAlignBlockMetadata,
  getTextAlignStyles,
} from 'react-rte';

const fetchPlans = async (hubId) => {
  const res = await fetch(`${config.apiURL}/hub/${hubId}/plans`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchTargetGroups = async () => {
  const res = await fetch(`${config.apiURL}/targetGroup`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch target groups');
  }
  const targetGroups = await res.json();
  return targetGroups;
};

const fetchActivities = async () => {
  const res = await fetch(`${config.apiURL}/activity`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch activities');
  }
  const activities = await res.json();
  return activities;
};

class CreatePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createType: 'Folder',
      createTypeOptions: ['Folder', 'Session'],
      planTitle: '',
      validTitle: true,
      planDescription: '',
      validDescription: true,
      planTextContent: RichTextEditor.createEmptyValue(),
      validPlanTextContent: true,
      planModules: [],
      validModules: true,
      assetId: null,
      sessionDueDate: new Date(),
      validSessionDate: true,
      sessionAddress1: '',
      validAddress1: true,
      sessionAddress2: '',
      sessionPostcode: '',
      validPostcode: true,
      sessionAdditionalInfo: '',
      selectedSessionPlan: null,
      validSessionPlan: true,
      selectedSessionUsers: [],
      validSessionUsers: true,
      activityOptions: [],
      activitiesSelected: [],
      targetGroups: [],
      targetGroupsSelected: [],
      featuredImage: null,
      featuredImage1: null,
      featuredImageFileType: '',
      featuredImageTitle: '',
      validImage: true,
      plans: [],
      published: false,
      selectedActivity: '',
      selectedTarget: '',
      accessId: '',
      validAccessId: true,
      membersOld: [],
      members: [],
      userMember: false,
      hubMembers: [],
      errorMessage: ''
    };
    this.onModuleRowClick = this.onModuleRowClick.bind(this);
    this.onPlanRowClick = this.onPlanRowClick.bind(this);
    this.onUserClick = this.onUserClick.bind(this);
    this.addMember = this.addMember.bind(this);
    this.removeMember = this.removeMember.bind(this);
  }

  async componentWillMount() {
    this.getSubCategories();
    this.getPlans();
    this.prefill();
    this.getHubMembers(this.props.hubId);
    if (this.props.session) {
      this.setState({selectedSessionUsers: this.props.session.users.map(user => user.id)});
    }
    if (this.props.plan) {
      this.getMembers(this.props.plan.id)
    }
  }

  // retrieve members
  async getMembers(planId) {
    const retrieveMembers = [];
    const retrieveMembers2 = [];

    try {
      const res = await fetch(`${config.apiURL}/hub/${planId}/plan/members`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        },
      });
      const body = await res.json();

      body.forEach(async (x) => {
        const res2 = await fetch(`${config.apiURL}/user/${x.userId}`, {
          credentials: 'include',
          method: 'GET',
          headers: {
            'content-type': 'application/json'
          },
        });
        const body2 = await res2.json();
        retrieveMembers.push(body2);
        retrieveMembers2.push(body2);

        if (x.userId === this.props.user.id) {
          this.setState({ userMember: true, });
        }

      });
    } catch (err) {
      console.log(err);
    }

    if (this.state.members.length === 0) {
      this.setState({ members: retrieveMembers, membersOld: retrieveMembers2 });
    }
  }

  async getHubMembers(hubId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const hubInfo = await res.json();
        this.setState({ hubMembers: hubInfo.users });
      }
    } catch (err) {
      console.log(err);
    }
  }

  addMember(user) {
    this.setState({
      members: [...this.state.members, user],
    });
  }

  removeMember(user) {
    const membersList = this.state.members;
    membersList.splice(membersList.indexOf(user), 1);
    this.setState({
      members: membersList,
    });
  }

  componentWillReceiveProps(props) {
    if (props.plan) {
      const { plan } = props;
      this.setState({
        createType: 'Folder',
        planTitle: plan.title,
        planDescription: plan.description,
        planTextContent: RichTextEditor.createValueFromString(plan.textContent, 'html', { customBlockFn: getTextAlignBlockMetadata }),
        planModules: plan.modules.map(mod => mod.id.toString()),
        targetGroupsSelected: plan.targetGroups.map(t => t.id.toString()),
        activitiesSelected: plan.activities.map(a => a.id.toString()),
        assetId: plan.logoId,
        accessId: plan.accessId
      });
    }
    if (props.session) {
      const { session } = props;
      this.setState({
        createType: 'Session',
        sessionDueDate: session.dueDate,
        sessionAddress1: session.address1,
        sessionAddress2: session.address2,
        sessionPostcode: session.postcode,
        sessionAdditionalInfo: session.additionalInfo,
        selectedSessionPlan: session.planId.toString(),
        selectedSessionUsers: session.users.map(user => user.id),
      });
    }
  }

  onChangeFile(e) {
    e.preventDefault();
    const selectedFiles = this.featuredImageFileInput.files;
    this.setState({
      featuredImage1: URL.createObjectURL(e.target.files[0]),
    });
    const file = selectedFiles[0];
    this.readFile(file);

  }

  onPublishClick() {
    if (this.props.edit) {
      return this.state.createType === 'Folder' ? this.updatePlan() : this.updateSession();
    }
    return this.state.createType === 'Folder' ? this.publishPlan() : this.publishSession();
  }

  onSubCategoryCheckboxClick(e) {
    const index = this.state.subCategoriesSelected.indexOf(e);
    if (index === -1) {
      const subCategoriesSelected = [...this.state.subCategoriesSelected, e];
      this.setState({ subCategoriesSelected });
    } else {
      const { subCategoriesSelected } = this.state;
      subCategoriesSelected.splice(index, 1);
      this.setState({ subCategoriesSelected });
    }
  }

  onModuleRowClick(id) {
    const index = this.state.planModules.indexOf(id);
    if (index === -1) {
      const planModules = [...this.state.planModules, id];
      this.setState({ planModules });
    } else {
      const { planModules } = this.state;
      planModules.splice(index, 1);
      this.setState({ planModules });
    }
  }

  onPlanRowClick(id) {
    this.setState({ selectedSessionPlan: id });
  }

  onUserClick(id) {
    const index = this.state.selectedSessionUsers.indexOf(id);
    if (index === -1) {
      const selectedSessionUsers = [...this.state.selectedSessionUsers, id];
      this.setState({ selectedSessionUsers });
    } else {
      const { selectedSessionUsers } = this.state;
      selectedSessionUsers.splice(index, 1);
      this.setState({ selectedSessionUsers });
    }
    console.log(this.state.selectedSessionUsers);
  }

  onChangeTextContent(planTextContent) {
    this.setState({ planTextContent });
    if (this.props.onChange) {
      this.props.onChange(planTextContent.toString('html', { blockStyleFn: getTextAlignStyles }));
    }
  }


  async getSubCategories() {
    const activityOptions = await fetchActivities();
    const targetGroups = await fetchTargetGroups();
    this.setState({ activityOptions, targetGroups });
  }

  async getPlans() {
    const plans = await fetchPlans(this.props.hubId);
    this.setState({
      plans,
    });
  }

  prefill() {
    if (this.props.plan) {
      const { plan } = this.props;
      this.setState({
        createType: 'Folder',
        planTitle: plan.title,
        planDescription: plan.description,
        planTextContent: RichTextEditor.createValueFromString(plan.textContent, 'html', { customBlockFn: getTextAlignBlockMetadata }),
        planModules: plan.modules.map(mod => mod.id.toString()),
        targetGroupsSelected: plan.targetGroups.map(t => t.id.toString()),
        activitiesSelected: plan.activities.map(a => a.id.toString()),
        assetId: plan.logoId,
        accessId: plan.accessId
      });
    }
    if (this.props.session) {
      const { session } = this.props;
      this.setState({
        createType: 'Session',
        sessionDueDate: new Date(session.dueDate),
        sessionAddress1: session.address1,
        sessionAddress2: session.address2,
        sessionPostcode: session.postcode,
        sessionAdditionalInfo: session.additionalInfo,
        selectedSessionPlan: session.planId.toString(),
        selectedSessionUsers: session.users.map(user => user.id),
      });
    }
  }

  async uploadAsset(imageData, imageTitle, imageFileType) {
    const payload = new Buffer(imageData, 'binary').toString('binary');
    const assetRes = await fetch(`${config.apiURL}/asset/`, { // eslint-disable-line
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        data: payload,
        title: imageTitle,
        fileType: imageFileType,
      }),
    });
    const assetId = await assetRes.text();
    return assetId;
  }

  async updatePlan() {
    const {
      planTitle,
      planDescription,
      planTextContent,
      planModules,
      subCategoriesSelected,
      featuredImage,
      featuredImageFileType,
      featuredImageTitle,
      accessId
    } = this.state;
    this.setState({ published: true });
    const validTitle = !!planTitle;
    const validDescription = !!planDescription;
    const validPlanTextContent = planTextContent.toString('markdown').length > 2;
    const validModules = planModules.length > 0;
    if (validTitle && validDescription && validModules && validPlanTextContent) {
      try {
        let { assetId } = this.state;
        if (featuredImage) {
          assetId = await this.uploadAsset(featuredImage, featuredImageTitle, featuredImageFileType);
        }
        const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/plan`, {
          method: 'PUT',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            planId: this.props.plan.id,
            planTitle,
            planDescription,
            planTextContent: planTextContent.toString('html', { blockStyleFn: getTextAlignStyles }),
            planModules,
            subCategoriesSelected,
            accessId,
            logoId: assetId,
            activities: this.state.activitiesSelected.map(i => ({ id: parseInt(i, 10) })),
            targetGroups: this.state.targetGroupsSelected.map(i => ({ id: parseInt(i, 10) })),
            members: this.state.members.filter((el) => el.id !== this.props.user.id),
            membersOld: this.state.membersOld.filter((el) => el.id !== this.props.user.id),
            userMember: this.state.userMember
          }),
        });
        if (res.status !== 200) {
          throw new Error('failed to create folder');
        }
        const updatedPlan = await res.json();
        this.props.history.push(`/${this.props.match.params.pathname}/plan/${updatedPlan.id}`);
      } catch (err) {
        console.error(err.message);
      }
    }
    this.setState({
      validTitle,
      validDescription,
      validPlanTextContent,
      validModules,
      published: false,
      errorMessage: 'Updating folder unsuccessful. Please check the provided information and try again.'
    });
  }

  async publishPlan() {
    const {
      planTitle,
      planDescription,
      planTextContent,
      planModules,
      subCategoriesSelected,
      featuredImage,
      featuredImageFileType,
      featuredImageTitle,
      accessId
    } = this.state;
    this.setState({
      published: true,
    });
    const validTitle = !!planTitle;
    const validDescription = !!planDescription;
    const validPlanTextContent = planTextContent.toString('markdown').length > 2;
    const validModules = planModules.length > 0;
    const validImage = !!featuredImage;
    const validAccessId = accessId !== "";
    if (validTitle && validDescription && validModules && validImage && validPlanTextContent) {
      try {
        const assetId = await this.uploadAsset(featuredImage, featuredImageTitle, featuredImageFileType);
        const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/plan`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            planTitle,
            planDescription,
            planTextContent: planTextContent.toString('html', { blockStyleFn: getTextAlignStyles }),
            planModules,
            subCategoriesSelected,
            accessId,
            logoId: assetId,
            activities: this.state.activitiesSelected.map(i => ({ id: i })),
            targetGroups: this.state.targetGroupsSelected.map(i => ({ id: i })),
            members: this.state.members.filter((el) => el.id !== this.props.user.id),
            membersOld: this.state.membersOld.filter((el) => el.id !== this.props.user.id)
          }),
        });
        if (res.status !== 200) {
          throw new Error('failed to create plan');
        }
        const body = await res.json();
        this.props.history.push(`/${this.props.match.params.pathname}/plan/${body.id}`);
      } catch (err) {
        console.error(err.message);
      }
    }
    this.setState({
      validTitle,
      validDescription,
      validPlanTextContent,
      validModules,
      validImage,
      published: false,
      validAccessId,
      errorMessage: 'Publishing folder. Please check the provided information and try again.'
    });
  }

  async updateSession() {
    const {
      sessionDueDate,
      sessionAddress1,
      sessionAddress2,
      sessionPostcode,
      sessionAdditionalInfo,
      selectedSessionPlan,
      selectedSessionUsers,
      subCategoriesSelected,
    } = this.state;
    const validSessionDate = !!sessionDueDate;
    const validAddress1 = !!sessionAddress1;
    const validPostcode = postcodeValid(sessionPostcode);
    const validSessionPlan = !!selectedSessionPlan;
    const validSessionUsers = selectedSessionUsers.length > 0;
    if (validSessionDate && validAddress1 && validPostcode && validSessionPlan && validSessionUsers) {
      try {
        const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/session/${this.props.session.id}`, {
          method: 'PUT',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            sessionDueDate,
            sessionAddress1,
            sessionAddress2,
            sessionPostcode,
            sessionAdditionalInfo,
            selectedSessionPlan,
            selectedSessionUsers,
            subCategoriesSelected,
          }),
        });
        if (res.status !== 200) {
          throw new Error('failed to create session');
        }
        this.props.history.push('/myprofile');
        this.props.setActiveProfileTab(2);
      } catch (err) {
        console.error(err.message);
      }
    }
    this.setState({
      validSessionDate,
      validAddress1,
      validPostcode,
      validSessionPlan,
      validSessionUsers,
      errorMessage: 'Updating session unsuccessful. Please check the provided information and try again.'
    });
  }

  async publishSession() {
    const {
      sessionDueDate,
      sessionAddress1,
      sessionAddress2,
      sessionPostcode,
      selectedSessionPlan,
      selectedSessionUsers,
      subCategoriesSelected,
      sessionAdditionalInfo,
    } = this.state;
    const validSessionDate = !!sessionDueDate;
    const validAddress1 = !!sessionAddress1;
    const validPostcode = postcodeValid(sessionPostcode);
    const validSessionPlan = !!selectedSessionPlan;
    const validSessionUsers = selectedSessionUsers.length > 0;
    if (validSessionDate && validAddress1 && validPostcode && validSessionPlan && validSessionUsers) {
      try {
        const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/session`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            sessionDueDate,
            sessionAddress1,
            sessionAddress2,
            sessionPostcode,
            sessionAdditionalInfo,
            selectedSessionPlan,
            selectedSessionUsers,
            subCategoriesSelected,
          }),
        });
        if (res.status !== 200) {
          throw new Error('failed to create session');
        }
        this.props.history.push('/myprofile');
        this.props.setActiveProfileTab(2);
      } catch (err) {
        console.error(err.message);
      }
    }
    this.setState({
      validSessionDate,
      validAddress1,
      validPostcode,
      validSessionPlan,
      validSessionUsers,
      errorMessage: 'Publishing session unsuccessful. Please check the provided information and try again.'
    });
  }

  readFile(file) {
    const reader = new FileReader();
    const acceptedList = ['image/PNG', 'image/png', 'image/jpeg', 'image/jpg', 'image/JPEG'];
    reader.onloadend = () => {
      if (file.size > 5000000) {
        this.setState({
          featuredImage: null,
          featuredImageHelpText: 'Files must be less than 5mb',
          featuredImageFileType: '',
          featuredImageTitle: '',
          validImage: false,
        });
        return;
      }
      if (!acceptedList.includes(file.type)) {
        this.setState({
          featuredImage: null,
          featuredImageHelpText: 'Images must be jpeg or png',
          featuredImageFileType: '',
          featuredImageTitle: '',
          validImage: false,
        });
        return;
      }
      this.setState({
        featuredImage: reader.result,
        featuredImageFileType: file.type,
        featuredImageHelpText: '',
        featuredImageTitle: file.name,
        validImage: true,
      });
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  }

  handleActivityCheckboxClick(e) {
    const index = this.state.activitiesSelected.indexOf(e);
    if (index === -1) {
      const activitiesSelected = [...this.state.activitiesSelected, e];
      this.setState({ activitiesSelected });
    } else {
      const { activitiesSelected } = this.state;
      activitiesSelected.splice(index, 1);
      this.setState({ activitiesSelected });
    }
  }

  handleGroupCheckboxClick(e) {
    const index = this.state.targetGroupsSelected.indexOf(e);
    if (index === -1) {
      const targetGroupsSelected = [...this.state.targetGroupsSelected, e];
      this.setState({ targetGroupsSelected });
    } else {
      const { targetGroupsSelected } = this.state;
      targetGroupsSelected.splice(index, 1);
      this.setState({ targetGroupsSelected });
    }
  }

  renderModulesList() {
    const { planModules } = this.state;
    const rows = this.props.modules.map(module => ({
      id: module.id,
      logoId: module.logoId,
      title: module.title,
      createdBy: `${module.createdBy.firstName} ${module.createdBy.surname}`,
      isSelected: planModules.includes(module.id.toString()),
    }));
    return (
      <TableSelectList
        type="checkbox"
        heading="Select Modules"
        rows={rows}
        onRowSelect={this.onModuleRowClick}
      />
    );
  }

  renderPlansList() {
    const { selectedSessionPlan } = this.state;
    const rows = this.state.plans.map(plan => ({
      id: plan.id,
      logoId: plan.logoId,
      title: plan.title,
      createdBy: `${plan.createdBy.firstName} ${plan.createdBy.surname}`,
      isSelected: plan.id.toString() === selectedSessionPlan
    }
    ));
    return (
      <div className="plan-list-container">
        {rows.map(row => (
          <div key={row.id} className="plan">
            <Checkbox name="radioGroup" className="plan-checkbox" id={row.id} checked={row.isSelected} onChange={e => this.onPlanRowClick(e.target.id)} />
            <div className='plan-info-container'>
              <div className={row.isSelected ? 'plan-image-container-selected' : 'plan-image-container'}>
                <Image className="plan-image" src={`${config.spacesURL}/${row.logoId}`} />
              </div>
              <div className="plan-title">{row.title}</div>
              <div className="plan-creator">{`Created by ${row.createdBy}`}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderPlan() {
    return (
      <div className="create-plan">
        {!this.props.modules.length > 0 &&
          <div className="no-plans">
            No folders available to create a session. Create a folder first.
          </div>
        }
        {this.props.modules.length > 0 &&
          <div>
            <FormGroup className="form" controlId="plan-title" validationState={!this.state.validTitle ? 'error' : null}>
              <ControlLabel>Folder Title:</ControlLabel>
              <FormControl
                className="form-input"
                type="text"
                value={this.state.planTitle}
                onChange={e => this.setState({ planTitle: e.target.value })}
              />
              {!this.state.validTitle && <HelpBlock>Must enter a Folder title</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="plan-description" validationState={!this.state.validDescription ? 'error' : null}>
              <ControlLabel>Folder Description:</ControlLabel>
              <FormControl
                className="form-input"
                type="text"
                value={this.state.planDescription}
                onChange={e => this.setState({ planDescription: e.target.value })}
              />
              {!this.state.validDescription && <HelpBlock>Must Enter a Folder Description</HelpBlock>}
            </FormGroup>
            <RTE
              textContent={this.state.planTextContent}
              validTextContent={this.state.validPlanTextContent}
              onChangeTextContent={(v) => this.onChangeTextContent(v)}
            />
            <div className="padding-line" />
            <FormGroup controlId="plan-modules" validationState={!this.state.validModules ? 'error' : null}>
              {!this.state.validModules && <HelpBlock>Must select at least one module</HelpBlock>}
              {this.renderModulesList()}
            </FormGroup>
          </div>
        }
      </div>
    );
  }

  renderSessionAdmins() {
    const { selectedSessionUsers } = this.state;
    const admins = this.props.users.map(user => (
      <div>
        <div className="user-tile-container" key={user.id}>
          <div
            className="avatar-container"
            style={{
              backgroundImage: `url(${config.spacesURL}/${user.avatarId})`,
              cursor: user.id === this.props.user.id ? 'default' : 'pointer',
            }}
            onClick={() => {
              if (user.id === this.props.user.id) {
                return;
              }
              this.onUserClick(user.id);
            }}
          />

          <div
            className="user-link"
            style={{
              cursor: user.id === this.props.user.id ? 'default' : 'pointer',
            }}
            onClick={() => {
              if (user.id === this.props.user.id) {
                return;
              }
              this.onUserClick(user.id);
            }}
          >
            {`${user.firstName} ${user.surname}`}
          </div>

          <Checkbox className="checkbox" disabled={user.id === this.props.user.id} checked={selectedSessionUsers.includes(user.id)} onChange={() => this.onUserClick(user.id)} />

        </div>
        <div className="padding-line" />
      </div>
    ));
    return admins;
  }

  renderSession() {
    return (
      <div className="create-session">
        {!this.state.plans.length > 0 &&
          <div className="no-plans">
            No plans available to create a session. Create a plan first.
          </div>
        }
        {this.state.plans && this.state.plans.length > 0 &&
          <div>
            <FormGroup className="form" controlId="plan-session">
              <ControlLabel>Session due date:</ControlLabel>
              <DatePicker
                className="date-picker"
                value={this.state.sessionDueDate}
                onChange={(date) => { this.setState({ sessionDueDate: date }); }}
                clearIcon={null}
                calendarIcon={<i className="fa fa-calendar fa-2x" aria-hidden="true" />}
              />
              {!this.state.validSessionDate && <HelpBlock>Must enter a due date</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="session-address-1">
              <ControlLabel>Session location:</ControlLabel>
              <FormControl
                className="form-input"
                placeholder="Address line 1"
                type="text"
                value={this.state.sessionAddress1}
                onChange={e => this.setState({ sessionAddress1: e.target.value })}
              />
              {!this.state.validAddress1 && <HelpBlock>Address line 1 is required</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="session-address-2">
              <ControlLabel />
              <FormControl
                className="form-input"
                type="text"
                placeholder="Address line 2"
                value={this.state.sessionAddress2}
                onChange={e => this.setState({ sessionAddress2: e.target.value })}
              />
            </FormGroup>
            <FormGroup className="form" controlId="session-postcode">
              <ControlLabel />
              <FormControl
                className="form-input"
                type="text"
                placeholder="Postcode"
                value={this.state.sessionPostcode}
                onChange={e => this.setState({ sessionPostcode: e.target.value })}
              />
              {!this.state.validPostcode && <HelpBlock>Must Enter a valid postcode</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="session-additional-info">
              <ControlLabel>Additional info:</ControlLabel>
              <FormControl
                className="form-input"
                type="text"
                value={this.state.sessionAdditionalInfo}
                onChange={e => this.setState({ sessionAdditionalInfo: e.target.value })}
              />
            </FormGroup>
            <FormGroup validationState={!this.state.validSessionPlan ? 'error' : null}>
              <div className="session-plans-list">
                <h4 className="users-title">Select Folder for Session:</h4>
                {this.renderPlansList()}
              </div>
              {!this.state.validSessionPlan && <HelpBlock>Must select a folder</HelpBlock>}
            </FormGroup>
            <FormGroup validationState={!this.state.validSessionUsers ? 'error' : null}>
              <div className="users-container">
                <h4 className="users-title">Assign Members:</h4>
                {this.renderSessionAdmins()}
              </div>
              {!this.state.validSessionUsers && <HelpBlock>Must select at least one user</HelpBlock>}
            </FormGroup>
          </div>
        }
      </div>
    );
  }

  renderTargetGroups() {
    const { targetGroupsSelected, targetGroups } = this.state;
    const targetGroupCheckboxes = targetGroups.map(group =>
      <Checkbox key={group.id} id={group.id} checked={targetGroupsSelected.includes(group.id.toString())} onChange={e => this.handleGroupCheckboxClick(e.target.id)}>{group.description}</Checkbox>);
    return targetGroupCheckboxes;
  }

  addActivity() {
    if (this.state.selectedActivity === '') {
      return;
    }
    this.setState({
      activitiesSelected: [...this.state.activitiesSelected, parseInt(this.state.selectedActivity, 10)],
      selectedActivity: '',
    });
  }

  removeActivityClick(e) {
    const activity = e.target.id;
    this.setState({
      activitiesSelected: this.state.activitiesSelected.filter(i => i !== parseInt(activity, 10)),
    });
  }


  renderChosenActivities() {

    if(this.state.activityOptions.length > 0){
      return this.state.activitiesSelected.map((id) => {
        const activity = this.state.activityOptions.find(i => i.id === Number(id));
        return (
          <div key={id} className="selection-container">
            <div className="selection-text">{activity.description}</div>
            <div className="selection-icon">
              <i
                id={id}
                className="fa fa-times-circle fa"
                aria-hidden="true"
                onClick={e => this.removeActivityClick(e)}
              />
            </div>
          </div>
        );
      });
    }

    return (
      <span>loading</span>
    )
  }

  addTarget() {
    if (this.state.selectedTarget === '') {
      return;
    }
    this.setState({
      targetGroupsSelected: [...this.state.targetGroupsSelected, parseInt(this.state.selectedTarget, 10)],
      selectedTarget: '',
    });
  }

  removeTargetClick(e) {
    const target = e.target.id;
    this.setState({
      targetGroupsSelected: this.state.targetGroupsSelected.filter(i => i !== parseInt(target, 10)),
    });
  }

  renderChosenTargets() {
    if(this.state.targetGroups.length > 0){
      return this.state.targetGroupsSelected.map((id) => {
        const target = this.state.targetGroups.find(i => i.id === Number(id));
        return (
          <div key={id} className="selection-container">
            <div className="selection-text">{target.description}</div>
            <div className="selection-icon">
              <i
                id={id}
                className="fa fa-times-circle fa"
                aria-hidden="true"
                onClick={e => this.removeTargetClick(e)}
              />
            </div>
          </div>
        );
      });
    }
    return (
      <span>Loading</span>
    )
  }

  renderVisibility() {
    const visibility = [1, 2, 3];
    const Visibility = ({ active, description, target, onClick, img1 }) => {
      if (active) {
        return (
          <div style={{ flex: ' 1' }}>
            <div onClick={onClick} className="visibility-selected" >
              <img src={img1} style={{ marginRight: "20px" }} alt='img' />
              {target}
              <img src={tick} style={{ marginLeft: "20px" }} alt='img' />
            </div>
            <div className="radio-sub-text">{description}</div>
          </div>
        );
      }
      return (
        <div style={{ flex: ' 1' }}>
          <div onClick={onClick} className="visibilty">
            <img src={img1} style={{ marginRight: "20px" }} alt='img' />
            {target}
          </div>
          <div className="radio-sub-text">{description}</div>
        </div>
      );
    };

    return (
      <div>
        <FormGroup className="form" controlId="formRadio" validationState={!this.state.validAccessId ? 'error' : null}>
          <span className='sub-categories-title-container'>
            <p className='sub-category-title'>Visibility</p>
          </span>
          <span className='sub-categories-container' style={{ flex: '1 1'}}  >
            <div style={{ display: 'flex' }}>
              {visibility.map((t) => {
                if (t === 1) {
                  return (
                    <Visibility
                      key={t}
                      target="Public"
                      active={t === this.state.accessId}
                      description="Visible to anybody"
                      onClick={() => {
                        this.setState({ accessId: t });
                      }}
                      img1={publicIcon}
                    />
                  );
                }
                else if (t === 2) {
                  return (
                    <Visibility
                      key={t}
                      target="Private"
                      active={t === this.state.accessId}
                      description="Only visible to hub members"
                      onClick={() => {
                        this.setState({ accessId: t });
                      }}
                      img1={eyeVisibleIcon}
                    />
                  );
                }
                return (
                  <Visibility
                    key={t}
                    target="Hidden"
                    active={t === this.state.accessId}
                    description="Only visible to those you choose"
                    onClick={() => {
                      this.setState({ accessId: t });
                    }}
                    img1={HiddenIcon}
                  />
                );
              })}
            </div>
            {!this.state.validAccessId && <HelpBlock>Must Select Access Level</HelpBlock>}
          </span>

          <div style={{ display: "None" }}>
            <Radio name="radioGroup" onChange={() => this.setState({ accessId: 1 })} checked={this.state.accessId === 1} disabled={this.state.category === '3'}>
              Public
            </Radio>
            <h6 className="radio-sub-text">Visible to anybody</h6>
            <Radio name="radioGroup" ref={(r) => { this.visibilityPrivate = r; }} checked={this.state.accessId === 2} onChange={() => this.setState({ accessId: 2 })}>
              Private
            </Radio>
            <h6 className="radio-sub-text">Only visible to hub members</h6>
            <Radio name="radioGroup" ref={(r) => { this.visibilityPrivate = r; }} checked={this.state.accessId === 3} onChange={() => this.setState({ accessId: 3 })}>
              Hidden
            </Radio>
            <h6 className="radio-sub-text">Only visible to those you choose</h6>
          </div>
        </FormGroup>
        <div className='member-search'>
          {this.state.accessId === 3 && <p style={{ position: "relative", top: "60%", left: "50%" }}>Use the search below to add users!</p>}
          {this.state.accessId === 3 && <ModuleMembers hubMembers={this.state.hubMembers} members={this.state.members} user={this.props.user} module={this.props.module} addMemberBound={this.addMember} removeMemberBound={this.removeMember} />}
        </div>

      </div>
    );
  }

  render() {
    if (!this.props.approved) {
      return (
        <div className="not-approved">
          <h4>Unapproved Hub</h4>
          A hub must be approved before adding content.
        </div>
      );
    }
    return (
      <div className="create-plan-session-tab-container">
        <h4 className="form-heading">
          {this.props.edit ? `Edit ${this.state.createType}` : 'Create a Folder or Session'}
        </h4>
        <div className="form-container">
          <FormGroup className="form" controlId="plan-session">
            <ControlLabel>Folder or Session: </ControlLabel>
            <FormControl
              componentClass="select"
              disabled={this.props.edit}
              value={this.state.createType}
              onChange={e => this.setState({ createType: e.target.value })}
            >
              {this.state.createTypeOptions.map(type => (<option key={type} value={type}>{type}</option>))}
            </FormControl>
          </FormGroup>
          {this.state.createType === 'Folder' ? this.renderPlan() : this.renderSession()}
        </div>


        {this.state.createType === 'Folder' &&
          <div>
            <div className="padding-line" />
            <FormGroup className="form" controlId="form" validationState={!this.state.validImage ? 'error' : null}>
              <ControlLabel className="featured-title">Folder Thumbnail</ControlLabel>
              <div className='input-image-container'>
                <div className="change-featured-image-button-container">
                  <Button className="change-featured-image-button" onClick={() => this.featuredImageFileInput.click()}>
                    Upload
                  </Button>
                </div>
                <img className='img1' src={this.state.featuredImage1} style={{ width: "300px", height: "150px", float: "right" }} alt="" />
              </div>

              <HelpBlock>{this.state.featuredImageTitle || 'Recommended Size: 200 x 120'}</HelpBlock>
              {!this.state.validImage && <HelpBlock>{this.state.featuredImageHelpText || 'featured Image is required'}</HelpBlock>}
            </FormGroup>

            {this.renderVisibility()}

            <div className="sub-categories">
              <span className="sub-categories-title-container">
                <p className="sub-category-title">Sub Categories</p>
              </span>

              <span className="sub-categories-container">
                <div className="activities-container">
                  <FormGroup className="form" controlId="formCategory">
                    <div>
                      <p className="sub-category-heading">Activities</p>
                      <FormControl
                        className="activity-select"
                        componentClass="select"
                        onChange={e => this.setState({ selectedActivity: e.target.value }, () => this.addActivity())}
                        size="lg"
                      >
                        <option value="select">Select Activity</option>
                        {this.state.activityOptions.map(activity => (
                          <option key={activity.id} value={activity.id}>{activity.description}</option>
                        ))}
                      </FormControl>
                      <div className="chosen-goals-container">
                        {this.renderChosenActivities()}
                      </div>
                    </div>
                  </FormGroup>
                </div>

                <div className="activities-container">

                  <FormGroup className="form" controlId="formCategory">
                    <div>
                      <p className="sub-category-heading">Target Groups</p>
                      <FormControl
                        className="activity-select"
                        componentClass="select"
                        onChange={e => this.setState({ selectedTarget: e.target.value }, () => this.addTarget())}
                      >
                        <option value="select">Select Group</option>
                        {this.state.targetGroups.map(targetGroup => (
                          <option key={targetGroup.id} value={targetGroup.id}>{targetGroup.description}</option>
                        ))}
                      </FormControl>
                      <div className="chosen-goals-container">
                        {this.renderChosenTargets()}
                      </div>

                    </div>
                  </FormGroup>
                </div>
              </span>
            </div>

          </div>}

        <div style={{ display: 'none' }}>
          <input
            ref={(f) => { this.featuredImageFileInput = f; }}
            className="form-input logo"
            type="file"
            onChange={e => this.onChangeFile(e)}
            accept='image/*'
          />
        </div>

        <div style={{ display: "block" }} className="publish-button-container">

          <Button
            className="publish-button"
            onClick={() => this.onPublishClick()}
          >
            {this.props.edit ? 'Update' : 'Publish'}
          </Button>
        </div>
        <div className="publish-feedback">
          {this.state.published && (this.props.edit ? `Updating ${this.state.createType}...` : `Creating ${this.state.createType}...`)}
          {!this.state.published && this.state.errorMessage && <HelpBlock style={{color: "#a94442"}}>{this.state.errorMessage}</HelpBlock>}
        </div>

      </div>
    );
  }
}

export default withRouter(CreatePlan);
