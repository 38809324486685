import React, { Component } from 'react';
import { Col, Row, Button, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';
import config, { featuredModuleLimit } from '../../constant/config';
import { postcodeValid } from '../../lib/validation';
import SelectFeaturedModules from '../common/SelectFeaturedModules';
import SelectFeaturedFolders from './SelectFeaturedFolders';
import tick from "../../resources/img/Vector.svg";
import './SettingsTab.css';


const fetchActivities = async () => {
  const res = await fetch(`${config.apiURL}/activity`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch activities');
  }
  const activities = await res.json();
  return activities;
};

const fetchTargetGroups = async () => {
  const res = await fetch(`${config.apiURL}/targetgroup/`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch target groups');
  }
  const targetGroups = await res.json();
  return targetGroups;
};

const fetchGoals = async () => {
  const res = await fetch(`${config.apiURL}/goal`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch goals');
  }
  const goals = await res.json();
  return goals;
};

const fetchCustomTitle = async () => {
  const res = await fetch(`${config.apiURL}/custom`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchCustomModules = async () => {
  const res = await fetch(`${config.apiURL}/module/custom`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const locations = [
  <option key="na" value="N/A">
    N/A
  </option>,
  ...config.councilAreas.map((county) => (
    <option key={county} value={county}>
      {county}
    </option>
  ))
];

const physicalActivitylist = ["Inactive", "Active", "Very Active"];
const targetlist = ["Not Skilled", "Somewhat", "Very Skilled"];
const confidenceLevelList = ["Not Very Confident", "Somewhat Confident", "Very Confident"]

const Target = ({ active, target, onClick }) => {
  if (active) {
    return (
      <div onClick={onClick} className="targets-selected" >
        {target}
        <img src={tick} alt="" style={{ marginLeft: "20px" }} />
      </div>
    );
  }
  return (
    <div onClick={onClick} className="targets">
      {target}
    </div>
  );
};

class SettingsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      validName: true,
      organisation: '',
      phoneNumber: '',
      validPhoneNumber: true,
      website: '',
      validWebsite: true,
      address1: '',
      validAddress: true,
      address2: '',
      postcode: '',
      description: '',
      validDescription: true,
      location: '',
      logoId: '',
      logoType: '',
      logoTitle: '',
      bannerId: '',
      bannerType: '',
      bannerTitle: '',
      twitter: '',
      validTwitter: true,
      facebook: '',
      validFacebook: true,
      googlePlus: '',
      validGooglePlus: true,
      linkedin: '',
      validLinkedin: true,
      validSocial: true,
      selectedActivity: '',
      chosenActivities: [],
      activities: [],
      selectedTargetGroup: '',
      chosenTargetGroups: [],
      targetGroups: [],
      selectedGoal: '',
      chosenGoals: [],
      goalOptions: [],
      featured: this.props.featured,
      featuredFolders: this.props.featuredFolders,
      fetchModuleData: true,
      targetSkill: '',
      targetPhysicalActivity: '',
      targetConfidence: '',
      showFollowers: '',
      selectedFeaturedModuleLength: this.props.featured.length,
      selectedFeaturedFolderLength: this.props.featuredFolders.length,
    };

    this.handleFeaturedChange = this.handleFeaturedChange.bind(this);
    this.handleFeaturedFolderChange = this.handleFeaturedFolderChange.bind(this);
  }

  componentWillMount() {
    if (!this.props.hub) {
      return;
    }
    const { hub } = this.props;
    this.setState({
      name: hub.name,
      organisation: hub.organisation,
      phoneNumber: hub.phoneNumber,
      website: hub.website,
      address1: hub.address1,
      address2: hub.address2,
      postcode: hub.postcode,
      description: hub.description,
      logoId: hub.logoId,
      bannerId: hub.bannerId,
      twitter: hub.twitter,
      facebook: hub.facebook,
      googlePlus: hub.googlePlus,
      linkedin: hub.linkedin,
      location: hub.location,
      targetSkill: hub.target,
      targetPhysicalActivity: hub.physicalActivity,
      targetConfidence: hub.targetConfidence,
      showFollowers: hub.showFollowers
    });
  }

  componentDidMount() {
    this.getActivities();
    this.getTargetGroups();
    this.getGoals();
    this.getAssetInfo();
  }

  onChangeLogoFile(e) {
    e.preventDefault();
    document.getElementById('logo-preview').src = window.URL.createObjectURL(this.logoFileInput.files[0]);
    document.getElementById('logo-preview').width = 200;
    document.getElementById('logo-preview').height = 200;
    const selectedFiles = this.logoFileInput.files;
    const file = selectedFiles[0];
    this.readLogoFile(file);
  }

  onChangeBannerFile(e) {
    e.preventDefault();
    document.getElementById('banner-preview').src = window.URL.createObjectURL(this.bannerFileInput.files[0]);
    const selectedFiles = this.bannerFileInput.files;
    if (window.innerWidth > 600) {
      document.getElementById('banner-preview').width = 500;
      document.getElementById('banner-preview').height = 250;
    } else {
      document.getElementById('banner-preview').width = 400;
      document.getElementById('banner-preview').height = 250;
    }

    const file = selectedFiles[0];
    this.readBannerFile(file);
  }

  async onSaveChangesClick() {
    if (this.validateForm()) {
      let { logoId } = this.state;
      let { bannerId } = this.state;
      if (typeof (this.state.logoId) !== 'string') {
        try {
          const payload = new Buffer(this.state.logoId, 'binary').toString('binary');// eslint-disable-line
          const assetRes = await fetch(`${config.apiURL}/asset/`, { // eslint-disable-line
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              data: payload,
              title: this.state.logoTitle,
              fileType: this.state.logoType,
            }),
          });
          logoId = await assetRes.text();
        } catch (err) {
          console.error(err);
        }
      }
      if (typeof (this.state.bannerId) !== 'string') {
        try {
          const payload = new Buffer(this.state.bannerId, 'binary').toString('binary');// eslint-disable-line
          const assetRes = await fetch(`${config.apiURL}/asset/`, { // eslint-disable-line
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
              data: payload,
              title: this.state.bannerTitle,
              fileType: this.state.bannerType,
            }),
          });
          bannerId = await assetRes.text();
        } catch (err) {
          console.error(err);
        }
      }
      const editedHub = {
        id: this.props.hub.id,
        name: this.state.name,
        organisation: this.state.organisation,
        phoneNumber: this.state.phoneNumber,
        website: this.state.website,
        address1: this.state.address1,
        address2: this.state.address2,
        postcode: this.state.postcode,
        description: this.state.description,
        location: this.state.location,
        activities: this.state.chosenActivities.map(i => ({ id: i })),
        targetGroups: this.state.chosenTargetGroups.map(i => ({ id: i })),
        goals: this.state.chosenGoals.map(i => ({ id: i })),
        logoId,
        bannerId,
        twitter: this.state.twitter,
        facebook: this.state.facebook,
        googlePlus: this.state.googlePlus,
        linkedin: this.state.linkedin,
        featured: this.state.featured,
        featuredFolders: this.state.featuredFolders,
        targetConfidence: this.state.targetConfidence,
        target: this.state.targetSkill,
        physicalActivity: this.state.targetPhysicalActivity,
        showFollowers: Boolean(this.state.showFollowers)
      };
      this.props.editHub(editedHub);
    }
  }

  async getAssetInfo() {
    const logoRes = await fetch(`${config.apiURL}/asset/${this.state.logoId}/info`, { credentials: 'include' });
    if (logoRes.status !== 200) {
      throw new Error('could not fetch logoId');
    }
    const logo = await logoRes.json();
    const bannerRes = await fetch(`${config.apiURL}/asset/${this.state.bannerId}/info`, { credentials: 'include' });
    if (bannerRes.status !== 200) {
      throw new Error('could not fetch logoId');
    }
    const banner = await bannerRes.json();
    this.setState({
      logoTitle: logo.title,
      logoType: logo.fileType,
      bannerTitle: banner.title,
      bannerType: banner.fileType,
    });
  }

  async getActivities() {
    const activities = await fetchActivities();
    const chosenActivities = this.props.hub.activities.map(activity => (activity.id));
    this.setState({ activities, chosenActivities });
  }

  async getTargetGroups() {
    const targetGroups = await fetchTargetGroups();
    const chosenTargetGroups = this.props.hub.targetGroups.map(tg => (tg.id));
    this.setState({ targetGroups, chosenTargetGroups });
  }

  async getGoals() {
    const goalOptions = await fetchGoals();
    const chosenGoals = this.props.hub.goals.map(goal => (goal.id));
    this.setState({ goalOptions, chosenGoals });
  }

  addActivity() {
    if (this.state.selectedActivity === '') {
      return;
    }
    this.setState({
      chosenActivities: [...this.state.chosenActivities, parseInt(this.state.selectedActivity, 10)],
      selectedActivity: '',
    });
  }

  removeActivityClick(e) {
    const activity = e.target.id;
    this.setState({
      chosenActivities: this.state.chosenActivities.filter(i => i !== parseInt(activity, 10)),
    });
  }

  addTargetGroup() {
    if (this.state.selectedTargetGroup === '') {
      return;
    }
    this.setState({
      chosenTargetGroups: [...this.state.chosenTargetGroups, parseInt(this.state.selectedTargetGroup, 10)],
      selectedTargetGroup: '',
    });
  }

  removeTargetGroupClick(e) {
    const tg = e.target.id;
    this.setState({
      chosenTargetGroups: this.state.chosenTargetGroups.filter(i => i !== parseInt(tg, 10)),
    });
  }

  addGoal() {
    if (this.state.selectedGoal === '') {
      return;
    }
    this.setState({
      chosenGoals: [...this.state.chosenGoals, parseInt(this.state.selectedGoal, 10)],
      selectedGoal: '',
    });
  }

  removeGoalClick(e) {
    const goal = e.target.id;
    this.setState({
      chosenGoals: this.state.chosenGoals.filter(i => i !== parseInt(goal, 10)),
    });
  }

  readBannerFile(file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        bannerId: reader.result,
        bannerType: file.type,
        bannerTitle: file.name,
      });
    };
    reader.readAsArrayBuffer(file);
  }

  readLogoFile(file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        logoId: reader.result,
        logoType: file.type,
        logoTitle: file.name,
      });
    };
    reader.readAsArrayBuffer(file);
  }

  async handleSearch(query, moduleType) {
    if (query) {
      const res = await fetch(`${config.apiURL}/search/hub/${this.props.hub.id}/module/featured`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          query,
        }),
      });
      if (res.status === 200) {
        const results = await res.json();
        this.setState({
          modules: results,
        });
      }
    }
  }

  async getModuleData() {
    if (this.state.fetchModuleData === true) {
      const customTitle = await fetchCustomTitle();
      const customModules = await fetchCustomModules();
      const customModulesIds = customModules.map(custom => custom.moduleId);
      const nonCustomModules = this.props.modules.filter(module => customModulesIds.indexOf(module.id) === -1);

      this.setState({
        customModules,
        nonCustomModules,
        customTitle: customTitle.title,
        fetchModuleData: false,
      });
    }

  }

  handleFollowerChange(state) {
    this.setState({ showFollowers: !state })
  }

  handleFeaturedChange(module) {
    if (this.state.featured.map(x => x.id).includes(module.id)) {
      const index = this.state.featured.findIndex(
        (m) => m.id.toString() === module.id.toString()
      );
      this.state.featured.splice(index, 1);
    } else {
      this.state.featured.push(module);
    }
    this.setState({ selectedFeaturedModuleLength: this.state.featured.length });
  }

  handleFeaturedFolderChange(folder) {
    if (this.state.featuredFolders.map(x => x.id).includes(folder.id)) {
      const index = this.state.featuredFolders.findIndex(
        (f) => f.id.toString() === folder.id.toString()
      );
      this.state.featuredFolders.splice(index, 1);
    } else {
      this.state.featuredFolders.push(folder);
    }
    this.setState({ selectedFeaturedFolderLength: this.state.featuredFolders.length });
  }

  validateForm() {
    const validName = this.state.name.length > 0 && this.state.name.length < 255;
    const validPhoneNumber =
      this.state.phoneNumber.length > 0
      && this.state.phoneNumber.length < 255;
    const validWebsite = this.state.website.length > 0;
    const validAddress1 = this.state.address1.length > 0 && this.state.address1.length < 255;
    const validAddress2 = this.state.address2.length > 0 && this.state.address2.length < 255;
    const validPostcode = this.state.postcode.length > 0 && postcodeValid(this.state.postcode);
    const validAddress = (validAddress1 && validAddress2 && validPostcode);
    const validDescription =
      this.state.description.length > 0
      && this.state.description.length <= 300;
    const validFeatured = this.state.featured.length < featuredModuleLimit;
    let validFacebook = true;
    let validTwitter = true;
    let validGooglePlus = true;
    let validLinkedin = true;

    if (this.state.facebook !== null) {
      if (this.state.facebook === '') {
        validFacebook = true;
      } else {
        const facebookRegex = /^(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/ // eslint-disable-line
        validFacebook = facebookRegex.test(this.state.facebook);
      }
    }

    if (this.state.twitter !== null) {
      if (this.state.twitter === '') {
        validFacebook = true;
      } else {
        const twitterRegex = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/ // eslint-disable-line
        validTwitter = twitterRegex.test(this.state.twitter);
      }
    }

    if (this.state.googlePlus !== null) {
      if (this.state.googlePlus === '') {
        validFacebook = true;
      } else {
        const googlePlusRegex = /((http|https):\/\/)?(www[.])?plus\.google\.com\/.?\/?.?\/?([0-9]*)/;
        validGooglePlus = googlePlusRegex.test(this.state.googlePlus);
      }
    }

    if (this.state.linkedin !== null) {
      if (this.state.linkedin === '') {
        validFacebook = true;
      } else {
        const linkedinRegex = /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/; // eslint-disable-line
        validLinkedin = linkedinRegex.test(this.state.linkedin);
      }
    }

    const validSocial = (validFacebook && validTwitter && validGooglePlus && validLinkedin);

    this.setState({
      validName,
      validPhoneNumber,
      validWebsite,
      validAddress,
      validDescription,
      validFacebook,
      validTwitter,
      validGooglePlus,
      validLinkedin,
      validSocial,
    });

    const validation =
      (validSocial
        && validName
        && validPhoneNumber
        && validWebsite
        && validAddress
        && validDescription
        && validSocial
        && validFeatured);
    return validation;
  }
  renderActivities() {
    return this.state.chosenActivities.map((id) => {
      const a = this.state.activities.find(i => i.id === id);
      return (
        <div key={id} className="selection-container">
          <div className="selection-text">{a.description}</div>
          <div className="selection-icon">
            <i
              id={id}
              className="fa fa-times fa"
              aria-hidden="true"
              onClick={e => this.removeActivityClick(e)}
            />
          </div>
        </div>
      );
    });
  }

  renderTargetGroups() {
    return this.state.chosenTargetGroups.map((id) => {
      const tg = this.state.targetGroups.find(i => i.id === id);
      return (
        <div key={id} className="selection-container">
          <div className="selection-text">{tg.description}</div>
          <div className="selection-icon">
            <i
              id={id}
              className="fa fa-times fa"
              aria-hidden="true"
              onClick={e => this.removeTargetGroupClick(e)}
            />
          </div>
        </div>
      );
    });
  }

  renderChosenGoals() {
    return this.state.chosenGoals.map((id) => {
      const goal = this.state.goalOptions.find(i => i.id === id);
      return (
        <div key={id} className="selection-container">
          <div className="selection-text">{goal.description}</div>
          <div className="selection-icon">
            <i
              id={id}
              className="fa fa-times fa"
              aria-hidden="true"
              onClick={e => this.removeGoalClick(e)}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    const goals = this.state.goalOptions.filter(i =>
      !this.state.chosenGoals.includes(i.id));
    const activities = this.state.activities.filter(i =>
      !this.state.chosenActivities.includes(i.id));
    const tagetGroups = this.state.targetGroups.filter(i =>
      !this.state.chosenTargetGroups.includes(i.id));
    return (
      <div className="settings-container">
        <Row>
          <Col sm={6}>
            <FormGroup className="form" controlId="name" validationState={!this.state.validName ? 'error' : null}>
              <ControlLabel>Hub Name</ControlLabel>
              <FormControl
                autoFocus
                className="form-input"
                type="text"
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
              />
              {!this.state.validName && <HelpBlock>Must Enter Hub Name</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="description" validationState={!this.state.validDescription ? 'error' : null}>
              <ControlLabel>Hub Description</ControlLabel>
              <div className="character-count">{300 - this.state.description.length}/300</div>
              <FormControl
                className="form-text-area"
                componentClass="textarea"
                value={this.state.description}
                maxLength={300}
                onChange={e => this.setState({ description: e.target.value })}
              />
              {!this.state.validDescription && <HelpBlock>Must Enter Hub Description</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="phoneNumber" validationState={!this.state.validPhoneNumber ? 'error' : null}>
              <ControlLabel>Phone Number</ControlLabel>
              <FormControl
                className="form-input"
                type="text"
                value={this.state.phoneNumber}
                onChange={e => this.setState({ phoneNumber: e.target.value })}
              />
              {!this.state.validPhoneNumber && <HelpBlock>Must Enter Hub Phone Number</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="website" validationState={!this.state.validWebsite ? 'error' : null}>
              <ControlLabel>Website</ControlLabel>
              <FormControl
                className="form-input"
                type="text"
                value={this.state.website}
                onChange={e => this.setState({ website: e.target.value })}
              />
              {!this.state.validWebsite && <HelpBlock>Must Enter Hub Website</HelpBlock>}
            </FormGroup>
            <FormGroup className="form" controlId="address" validationState={!this.state.validAddress ? 'error' : null}>
              <ControlLabel>Address</ControlLabel>
              <FormControl
                className="form-input-address"
                type="text"
                value={this.state.address1}
                onChange={e => this.setState({ address1: e.target.value })}
              />
              <FormControl
                className="form-input-address"
                type="text"
                value={this.state.address2}
                onChange={e => this.setState({ address2: e.target.value })}
              />
              <FormControl
                className="form-input-address"
                type="text"
                value={this.state.postcode}
                onChange={e => this.setState({ postcode: e.target.value })}
              />
              {!this.state.validWebsite && <HelpBlock>Must Enter Valid Address</HelpBlock>}
            </FormGroup>
            <FormGroup className="form-group" controlId="location">
              <ControlLabel>Local Authority Area Covered</ControlLabel>
              <div className='form-input'>
                <FormControl
                  className="form-input targetGroup-select"
                  componentClass="select"
                  onChange={e => this.setState({ location: e.target.value })}
                  value={this.state.location}>
                  {locations}
                </FormControl>
                <FormControl.Feedback />
              </div>
            </FormGroup>
          </Col>

          <Col sm={6}>
            <FormGroup className="form" controlId="activity">
              <ControlLabel className="block-label">Activities</ControlLabel>
              <FormControl
                className="multi-select"
                componentClass="select"
                onChange={e => this.setState({ selectedActivity: e.target.value }, () => this.addActivity())}
              >
                <option value="select">Please choose an activity</option>
                {activities.map(activity => (
                  <option key={activity.id} value={activity.id}>{activity.description}</option>
                ))}
              </FormControl>
              <div className="chosen-goals-container">
                {this.renderActivities()}
              </div>
            </FormGroup>
            <FormGroup className="form" controlId="target">
              <ControlLabel className="block-label">Target Group</ControlLabel>
              <FormControl
                className="multi-select"
                componentClass="select"
                onChange={e => this.setState({ selectedTargetGroup: e.target.value }, () => this.addTargetGroup())}
              >
                <option value="select">Please choose target groups</option>
                {tagetGroups.map(target => (
                  <option key={target.id} value={target.id}>{target.description}</option>
                ))}
              </FormControl>
              <div className="chosen-goals-container">
                {this.renderTargetGroups()}
              </div>
            </FormGroup>
            <FormGroup className="form" controlId="formGoals">
              <ControlLabel className="block-label">Goals</ControlLabel>
              <FormControl
                className="multi-select"
                componentClass="select"
                onChange={e => this.setState({ selectedGoal: e.target.value }, () => this.addGoal())}
              >
                <option value="select">Please enter your goals</option>
                {goals.map(goal => (
                  <option key={goal.id} value={goal.id}>{goal.description}</option>
                ))}
              </FormControl>
              <div className="chosen-goals-container">
                {this.renderChosenGoals()}
              </div>
            </FormGroup>
            <FormGroup className="form-group" controlId="physicalActivity">
              <ControlLabel>Physical Activity Level</ControlLabel>
              <div className='form-input'>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  {physicalActivitylist.map(t => (
                    <Target
                      key={t}
                      target={t}
                      active={t === this.state.targetPhysicalActivity}
                      onClick={() => this.setState({ targetPhysicalActivity: t })}
                    />
                  ))}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group" controlId="target">
              <ControlLabel>Target Skill Level</ControlLabel>
              <div className='form-input'>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  {targetlist.map(t => (
                    <Target
                      key={t}
                      target={t}
                      active={t === this.state.targetSkill}
                      onClick={() => this.setState({ targetSkill: t })}
                    />
                  ))}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group" controlId="target">
              <ControlLabel>Target Confidence Level</ControlLabel>
              <div className='form-input'>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                  {confidenceLevelList.map(t => (
                    <Target
                      key={t}
                      target={t}
                      active={t === this.state.targetConfidence}
                      onClick={() => this.setState({ targetConfidence: t })}
                    />
                  ))}
                </div>
              </div>
            </FormGroup>


            <FormGroup className="form-followers">
              <div style={{ display: 'flex' }}>
                <ControlLabel className="block-header">Show Follower Count</ControlLabel>
                <input style={{ marginRight: '10px', transform: 'scale(1.5)', filter: 'invert(70%) hue-rotate(358deg) brightness(1.7)' }} type="checkbox" defaultChecked={this.state.showFollowers} onChange={(e) => this.handleFollowerChange(this.state.showFollowers)} />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <FormGroup className="form image-group" controlId="logo">
            <ControlLabel className="block-header">Update Hub Logo</ControlLabel>
            <FormControl
              className="form-input-upload"
              type="text"
              value={this.state.logoTitle}
              disabled
            />
            <div className='logo-preview'>
              <div className='logo-container'>
                <img id='logo-preview' src='data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=' alt='' width='0' height='0' />
              </div>
              <div className="button-container">
                <Button className="browse-button" onClick={() => this.logoFileInput.click()}>Browse</Button>
              </div>
            </div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="form" controlId="banner">
            <ControlLabel className="block-header">Update Hub Banner Image</ControlLabel>
            <FormControl
              className="form-input-upload"
              type="text"
              value={this.state.bannerTitle}
              disabled
            />
            <div className='banner-preview'>
              <div className='banner-container'>
                <img id='banner-preview' src='data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=' alt='' width='0' height='0' />
              </div>
              <div className="button-container">
                <Button className="browse-button" onClick={() => this.bannerFileInput.click()}>Browse</Button>
              </div>
            </div>
          </FormGroup>
        </Row>

        <FormGroup className="form" controlId="social" validationState={!this.state.validSocial ? 'error' : null}>
          <ControlLabel className="block-header" style={{ color: "#14B4C8", marginTop: "15px" }}>Social Links</ControlLabel>

          <Row>
            <Col lg={15}>
              <div className="social-link-container">
                <div className="social-icon-container social-tw">
                  <i className="fa fa-twitter fa social social-tw fa-3x" aria-hidden="true" />
                </div>
                <FormControl
                  className="form-input-social"
                  type="text"
                  value={this.state.twitter === null ? '' : this.state.twitter}
                  onChange={e => this.setState({ twitter: e.target.value })}
                />
                {!this.state.validTwitter && <HelpBlock>Must Enter Valid Twitter URL</HelpBlock>}
              </div>

              <div className="social-link-container">
                <div className="social-icon-container social-fb">
                  <i className="fa fa-facebook fa social social-fb fa-3x" aria-hidden="true" />
                </div>
                <FormControl
                  className="form-input-social"
                  type="text"
                  value={this.state.facebook === null ? '' : this.state.facebook}
                  onChange={e => this.setState({ facebook: e.target.value })}
                />
                {!this.state.validFacebook && <HelpBlock>Must Enter Valid Facebook URL</HelpBlock>}
              </div>


              <div className="social-link-container">
                <div className="social-icon-container social-g">
                  <i className="fa fa-google-plus fa social social-g fa-3x" aria-hidden="true" />
                </div>
                <FormControl
                  className="form-input-social"
                  type="text"
                  value={this.state.googlePlus === null ? '' : this.state.googlePlus}
                  onChange={e => this.setState({ googlePlus: e.target.value })}
                />
                {!this.state.validGooglePlus && <HelpBlock>Must Enter Valid Google+ URL</HelpBlock>}
              </div>

              <div className="social-link-container">
                <div className="social-icon-container social-ln">
                  <i className="fa fa-linkedin fa social social-ln fa-3x" aria-hidden="true" />
                </div>
                <FormControl
                  className="form-input-social"
                  type="text"
                  value={this.state.linkedin === null ? '' : this.state.linkedin}
                  onChange={e => this.setState({ linkedin: e.target.value })}
                />
                {!this.state.validLinkedin && <HelpBlock>Must Enter Valid Linkedin URL</HelpBlock>}
              </div>
            </Col>
          </Row>
        </FormGroup>

        <div className='feature-group' id="manage-featured">
          <SelectFeaturedModules
            featured={this.state.featured}
            modules={this.props.modules}
            handleModuleSearch={query => this.handleSearch(query, 'featured')}
            handleChange={this.handleFeaturedChange}
          />
        </div>

        <div>{this.state.selectedFeaturedModuleLength > 8 ?
          (<p className='error-message'>A maximum of only eight modules can be set as featured modules</p>)
          :
          ("")}</div>

        <div className='feature-group'>
          <SelectFeaturedFolders
            featured={this.state.featuredFolders}
            folders={this.props.plans}
            hub={this.props.hub}s
            handleChange={this.handleFeaturedFolderChange}
          />
        </div>

        <div>{this.state.selectedFeaturedFolderLength > 8 ?
          (<p className='error-message'>A maximum of only eight folders can be set as featured folders</p>)
          :
          ("")}</div>


        <div style={{ display: 'none' }}>
          <input
            ref={(f) => { this.bannerFileInput = f; }}
            className="form-input logo"
            type="file"
            onChange={e => this.onChangeBannerFile(e)}
            accept="image/jpeg"
          />

          <input
            ref={(f) => { this.logoFileInput = f; }}
            className="form-input logo"
            type="file"
            onChange={e => this.onChangeLogoFile(e)}
            accept="image/jpeg"
          />
        </div>
        <Row className="button-row">
          <Button className="save-settings-button" onClick={() => this.onSaveChangesClick()}>Save Changes</Button>
        </Row>
      </div>
    );
  }
}

export default SettingsTab;
