import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import ItemTypes from './ItemTypes';

const wordSource = {
  beginDrag(props) {
    return {
      word: props.word,
      textIndex: props.textIndex,
    };
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class Word extends Component {
  render() {
    const {
      word,
      connectDragSource,
      isDragging, // eslint-disable-line
      isDropped,
    } = this.props;
    return (
      connectDragSource(
        <div className="draggable">
          {isDropped ? <s>{word}</s> : word}
        </div>,
      )
    );
  }
}

Word.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  isDropped: PropTypes.bool.isRequired,
  word: PropTypes.string.isRequired,
};

export default DragSource(ItemTypes.BOX, wordSource, collect)(Word);
