import { connect } from 'react-redux';
import SignUp from './SignUpNew';
import { setUser, toggleLogin } from '../../../store/actions';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  setUser: (user) => {
    dispatch(setUser(user));
    dispatch(toggleLogin(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
