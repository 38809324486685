import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import Conversation from './Conversation';
import MessageUser from './MessageUser';
import UserDetails from './UserDetails';
import config from '../../constant/config';
import './MessageTab.css';
import mailIcon1 from '../../resources/img/mail_icon_1.svg';
import mailIcon2 from '../../resources/img/mail_icon_2.svg';

const fetchConversations = async () => {
  const res = await fetch(`${config.apiURL}/message/conversations`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (!res.status === 200) {
    throw new Error('unable to fetch user messages');
  }
  const messages = await res.json();
  return messages;
};

const fetchConversation = async (conversationId) => {

  const res = await fetch(`${config.apiURL}/message/conversation/${conversationId}`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (!res.status === 200) {
    throw new Error('unable to fetch user messages');
  }
  const messages = await res.json();
  return messages;
};

class MessageTab extends Component {

  constructor(props) {
    super(props);
    this.state = {
      conversations: [],
      showModal: false,
      user: null,
      userOptions: [],
      userUpdated: false,
      messageError: '',
      conversation: null,
      messages: [],
    };
    this.toggle = false;
    this.onClickRespond = this.onClickRespond.bind(this);
  }

  componentDidMount() {
    this.getConversations();
  }

  onCloseModal() {
    this.setState({ showModal: false });
    this.getConversations();
  }

  onUserSelect(user) {
    this.setState({ user });
  }

  handleUserSearch(query) {
    if (query) {
      fetch(`${config.apiURL}/user/search/${query}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      })
        .then(resp => resp.json())
        .then(json => this.setState({ userOptions: json, userUpdated: false }));
    }
  }

  onUserClick(user) {
    if (user[0]) {
      this.setState({
        showModal: true,
        modalContent: <UserDetails admin={false} adminHubs={[]} modalUser={user[0]} />,
      });
    } else {
      this.setState({ messageError: "Could not find user."})
    }
  }

  async onViewConversation(conversationId) {
    const conversations = await fetchConversations();
    conversations.sort((a, b) => (moment(a.latestMessage.createdAt).isAfter(moment(b.latestMessage.createdAt)) ? -1 : 1));
    this.setState({
      conversations,
    });

    const  conversation  = await fetchConversation(conversationId);
    conversations[conversations.findIndex(convo => convo.id === conversationId)].latestMessage.readAt = moment().format();
    let messages = conversation.messages;
    this.setState({
      conversations,
      conversation,
      messages
    });
  }

  onClickRespond(user) {
    this.setState({
      modalContent: <MessageUser recipient={user} />,
      showModal: true,
    });
  }

  async getConversations() {
    const conversations = await fetchConversations();
    conversations.sort((a, b) => (moment(a.latestMessage.createdAt).isAfter(moment(b.latestMessage.createdAt)) ? -1 : 1));
    this.setState({
      conversations,
    });
  }

  getStyle(conversation) {
    return { opacity: conversation.latestMessage.readAt || conversation.latestMessage.senderId === this.props.user.id ? 0.7 : 1 };
  }

  accountType() {
    if (this.props.accountType === 1) {
      return (
        <span style={{ height: "13px", width: "13px", backgroundColor: '#D3F5FA', borderRadius: "50%", display: "inline-block"}} />
      ); }
      else if (this.props.accountType === 2) {
        return (
          <span style={{ height: "13px", width: "13px", backgroundColor: '#FFEFBC', borderRadius: "50%", display: "inline-block"}} />
        )
      }
      return (
        <span>
          <span style={{ height: "13px", width: "13px", backgroundColor: '#D3F5FA', borderRadius: "50%", display: "inline-block"}} />
          <span style={{ height: "13px", width: "13px", backgroundColor: '#FFEFBC', borderRadius: "50%", display: "inline-block"}} />
        </span>
      )
  }


  renderConversationRows() {
    return this.state.conversations.map(conversation => (
      <div key={conversation.id} className="messageRow" onClick={() => this.onclick(conversation.id)}>
        <div style={this.getStyle(conversation)}>
          <div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${conversation.user.avatarId}.thumbnail)`}} />
        </div>
        <div className="inline_display">
          <div className="name-row" style={this.getStyle(conversation)}>
            {`${conversation.user.firstName} ${conversation.user.surname}`}
          </div>
          <div style={this.getStyle(conversation)}>
            <div className="message-content">
              {conversation.latestMessage.content}
            </div>
          </div>
        </div>
        <div className="date-row" style={this.getStyle(conversation)}>
          {moment(conversation.latestMessage.createdAt).format('DD-MM-YYYY')}
        </div>
      </div>
    ));
  }

  Toggle(val) {
    this.toggle = val;
    if(!this.toggle){
      this.setState({});
    }
    return this.toggle;
  }
  onclick(conversationId) {
    this.Toggle(true);
    this.onViewConversation(conversationId);
    this.props.showConversation(null);
  }

  render() {
    const {activeRole } = this.props;
    const {conversationId} = this.props;
    const {conversation, messages, conversations } = this.state;

    if (conversationId){
      this.onclick(conversationId);
    }

    const TOGGLE = () => {
      if (this.toggle && conversation && conversations && messages){
        return (
          <div>
            <div className='mobile-conversation-header'>
              <div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${conversation.user.avatarId}.thumbnail)`}} />
              <div className='name-row'> {`${conversation.user.firstName} ${conversation.user.surname}`} </div>
              <Button className='back-button' onClick={()=> this.Toggle(false)}>Back</Button>
            </div>
            <Conversation conversation={conversation} activeRole={activeRole} addMessage={m => this.setState({messages:[...messages , m]})} messages={messages} onClickRespond={this.onClickRespond} user={this.props.user} />
          </div>
        )
      }
        return (
          <div className='message-image-title'>
            <div className='message-image'>
              {!activeRole ? <img src={mailIcon2} alt="Mail_icon" /> : <img src={mailIcon1} alt="Mail_icon" /> }
            </div>
            <p className='click-on-title'>
              Click on a message to read
            </p>
          </div>
        )
    }

    return (
      <div className='main-container'>
        <div className={this.toggle ? 'message-tab toggle' : 'message-tab no-toggle'} style={{paddingRight:'18px', borderRight: '0.5px solid whiteSmoke'}}>
          <h3 className="title">Messages</h3>
          <div className="add-user">
            <AsyncTypeahead
              labelKey={option => `${option.firstName} ${option.surname}`}
              filterBy={['firstName', 'surname']}
              onSearch={query => this.handleUserSearch(query)}
              searchText="Searching..."
              emptyLabel="No Users"
              minLength={2}
              options={this.state.userOptions}
              onChange={e => this.onUserSelect(e)}
              useCache={false}
              renderMenuItemChildren={this.renderMenuItemChildren}
              className="user-search"
              placeholder="Enter name here..."
            />
            <div className="view-message-container">
              <Button onClick={() => this.onUserClick(this.state.user)} style={{borderRadius: '25px'}}>Message</Button>
              <Modal show={this.state.showModal} onHide={() => this.onCloseModal()}>
                <Modal.Header closeButton />
                {this.state.modalContent}
              </Modal>

            </div>
          </div>
          {this.state.messageError &&
            <p>{this.state.messageError}</p>
          }
          <div className='heading1'>
            <h3 className='heading'>Chat list</h3>
          </div>
          {this.state.conversations.length > 0 &&
            <div className="messages-container">
              <table className="messages-table">
                <tbody>
                  {this.renderConversationRows()}
                </tbody>
              </table>
            </div>
          }
          {this.state.conversations.length === 0 &&
            <p className='no-messages-text' style={{paddingTop: "20px"}}>It appears that you don't have any active conversations with other users, get chatting by using the input above!</p>
          }
        </div>
        <div className='message-tab2'>
          { TOGGLE()}
        </div>
      </div>
    )
  }

}

export default withRouter(MessageTab);
