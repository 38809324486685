import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import config from '../../constant/config';
import './ThemePills.css';

class ThemePills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: [],
      callback: () => {},
    };
  }

  componentDidMount() {
    this.getThemes();
  }

  async getThemes() {
    try {
      // query the custom modules - temp solution for themes
      const res = await fetch(`${config.apiURL}/module/custom`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const customModules = await res.json();
        let themes = [];
        // extract all the title of valid modules
        for (let i = 0; i < customModules.length; i++) {
          // make sure it's a valid module, and that it has a title
          if (customModules[i]) {
            if (customModules[i].title) {
              themes.push(customModules[i].title);
            }
          }
        }
        this.setState({ themes });
      }
    } catch (err) {
      console.log(err);
    }
  }

  onLinkClick(e, query) {
    e.preventDefault();
    this.props.history.push({ pathname: `/result/${query.replace('#', '%23')}`, query: query });
  }

  render() {
    if (this.state.themes.length > 0) {
      return this.state.themes.map(theme => (
        <Button className="theme-btn" onClick={(e) => this.onLinkClick(e, theme)}>
          {theme}
        </Button>
      ));
    } else {
      return (
        <p>Currently there is no themes.</p>
      );
    }
  }
}

export default withRouter(ThemePills);
