import React from 'react';
import {
  Button, Modal, HelpBlock,
} from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import config from '../../constant/config';
import './ManageFeaturedModules.css';

class ManageFeaturedModules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModules: [],
      showModal: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  renderTableRows() {
    return this.props.featured.map(module => (
      <tr key={module.id}>
        <td><Link to={`/module/${module.id}`} >{module.title}</Link></td>
        <td>{module.category.description}</td>
        <td>{module.hub.name}</td>
        <td>{module.hub.organisation}</td>
        <td>
          <div className="warning-button-container">
            <Button className="warning-button" onClick={() => this.props.removeFeaturedClick(module.id)}>Remove</Button>
          </div>
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <div className="manage-featured-container">
        <h4>Featured Modules</h4>
        <table className="modules-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Category</th>
              <th>Hub</th>
              <th>Organisation</th>
              <th className="delete-heading">Remove from Featured</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>

        </table>
        {/* { (this.props.nonFeaturedModules && this.props.nonFeaturedModules.length > 0) && */}
          <div className="add-button-container">
            <Button
              className="add-button"
              onClick={() => { this.setState({ showModal: true }); }}
              disabled={this.props.featured.length >= config.featuredModuleLimit}
            >
              Add New Featured
            </Button>
          </div>
        {/* } */}
        { (this.props.featured && this.props.featured.length >= config.featuredModuleLimit) &&
          <p className="limit-text">You have reached the limit for Featured Modules</p>
        }
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          <div className="featured-module-modal">
            <div className="heading-container">
              <h4>
              Manage Featured Modules
              </h4>
            </div>
            <div className="typeahead-container">
              <AsyncTypeahead
                isLoading={false}
                autoFocus
                multiple
                labelKey={option => `${option.title}: ${option.hub.name}`}
                onSearch={query => this.props.handleModuleSearch(query)}
                filterBy={(option, text) => (`${option.title}: ${option.hub.name}`.toLowerCase().includes(text.toLowerCase()))}
                searchText="Searching..."
                emptyLabel="No Modules"
                minLength={2}
                options={this.props.modules.filter(m => !this.state.selectedModules.find(s => s.id === m.id))}
                onChange={selection => this.setState({ selectedModules: selection })}
                useCache={false}
                renderMenuItemChildren={this.renderMenuItemChildren}
              />
            </div>
            <div className="button-container">
              <Button
                className="add-button"
                onClick={() => this.props.addFeaturedClick(this.state.selectedModules.map(m => m.id)) && this.setState({ showModal: false })}
                disabled={this.props.featured.length + this.state.selectedModules.length > config.featuredModuleLimit}
              >
                Add
              </Button>
              { this.props.featured.length + this.state.selectedModules.length > config.featuredModuleLimit && <HelpBlock className="limit-text">Your selection will exceed the module limit</HelpBlock>}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ManageFeaturedModules;
