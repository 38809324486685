import React, { Component } from 'react';
import './SupportBlock.css';

class SupportBlock extends Component {
  render() {
    const colour = this.props.colour;
    // choose the class name based on the colour prop
    const className = `block block-${colour.toLowerCase()}`;

    return (
      <div className={className}>
        <div className='block-title'>
          {this.props.title}
        </div>
        <div className='block-text'>
          {this.props.text}
        </div>
      </div>
    );
  }
}

// 315x212
export default SupportBlock;
