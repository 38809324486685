import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import './FreeTextQuestion.css';

class FreeTextQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerText: '',
      correct: null,
    };
  }

  componentDidMount() {
    if (this.props.answer) {
      this.setAnswer(this.props.answer);
    }
  }

  onChangeAnswerText(e) {
    e.preventDefault();
    this.setState({ answerText: e.target.value });
    if (this.props.onChangeAnswer) {
      this.props.onChangeAnswer(e.target.value);
    }
  }

  onReviewQuestion(correct) {
    this.setState({
      correct,
    });
    this.props.onReviewQuestion(correct);
  }

  setAnswer(answer) {
    this.setState({
      answerText: answer.answerValue,
      correct: answer.isCorrect,
    });
  }

  getStyle() {
    if ((this.props.review || this.props.answer) && this.state.correct !== null) {
      return {
        color: this.state.correct ? 'green' : '#ca3e25',
        borderColor: this.state.correct ? 'green' : '#ca3e25',
        border: '1px',
        borderStyle: 'solid',
      };
    }
    return null;
  }

  render() {
    return (
      <div className="free-text-question-container" style={this.getStyle()}>
        <h3 className="question-text-container">
          {this.props.questionNumber ?
            <ol start={this.props.questionNumber}>
              <li>{this.props.questionText}</li>
            </ol> : this.props.questionText}
        </h3>
        { this.props.deletable &&
          <div className="icon-container">
            <span className="edit-quiz" onClick={() => this.props.editQuestion()}>EDIT</span>
            <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.props.removeQuestion()} />
          </div>
        }
        {(this.props.review || this.props.answer) && this.state.correct !== null &&
          <div className="icon-container">
            {this.state.correct ?
              <i className="fa fa-check fa-2x" aria-hidden="true" /> :
              <i className="fa fa-times fa-2x" aria-hidden="true" />
            }
          </div>
        }
        <div className="answers-container">
          <FormGroup className="form" controlId="answer-text" style={{display:"block"}}>
            {this.props.answerLabel && <ControlLabel>{this.props.answerLabel}</ControlLabel>}
            <FormControl
              disabled={this.props.disabled}
              className="form-input"
              componentClass="textarea"
              value={this.state.answerText}
              onChange={e => this.onChangeAnswerText(e)}
              style={{width:"96%"}}
            />
          </FormGroup>
        </div>
        {this.props.review &&
        <div className="review-container">
          <Button className="correct-button" onClick={() => this.onReviewQuestion(true)}>Correct</Button>
          <Button className="incorrect-button" onClick={() => this.onReviewQuestion(false)}>Incorrect</Button>
        </div>}
      </div>
    );
  }
}

export default FreeTextQuestion;
