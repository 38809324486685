import { connect } from 'react-redux';
import MessageTab from './MessageTab';

const mapStateToProps = state => ({
  user: state.user,
  activeRole : state.activeRole,
  conversationId: state.conversationToShow
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MessageTab);
