import React from 'react';
import { Button, Row, Col, Tab, Tabs} from 'react-bootstrap';
import config, { hubAccessLevels } from '../../constant/config';
import HomeTab from '../common/HomeTab';
import UsersTab from '../common/UsersTab';
import checkStatus from '../common/checkStatus';
import CreateModule from '../common/CreateModuleContainer';
import CreatePlanSession from '../common/CreatePlanSessionContainer';
import ModulesTab from '../common/ModulesTab';
import FoldersTab from '../common/FoldersTab';
import AnalyticsTab from '../common/AnalyticsTab';
import SettingsTab from '../common/SettingsTab';
import Error404 from '../common/Error404';
import './HubHome.css';
import { FaCheckCircle} from 'react-icons/fa';
import { IconContext } from "react-icons";
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

class HubHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hub: null,
      activeTab: 0,
      tabs: [],
      modules: [],
      plans: [],
      featuredModules: [],
      featuredFolders: [],
      followersCount: 0,
      notfound: false,
      siteOwnerHublevel: null,
      hubPathname: this.props.match.params,
      isExpanded1: true,
      isExpanded2: true,
      isExpanded3: true,
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    this.getHub();
  }

  async onFollowHub(e) {
    e.preventDefault();
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.state.hub.id}/follow`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status !== 200) {
        throw new Error('unable to follow hub');
      }
      const newHub = await res.json();
      this.props.onFollowHub(newHub);
      this.getHub();
    } catch (err) {
      console.error(err.message);
    }
  }

  async onUnfollowHub(e) {
    e.preventDefault();
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.state.hub.id}/user`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: this.props.user.id,
        }),
      });
      if (res.status !== 200) {
        throw new Error('unable to unfollow hub');
      }
      this.props.onUnfollowHub(this.state.hub.id);
      this.getHub();
    } catch (err) {
      console.error(err.message);
    }
  }

  async onLeaveHub(e) {
    e.preventDefault();
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.state.hub.id}/user`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: this.props.user.id,
        }),
      });
      if (res.status !== 200) {
        throw new Error('unable to follow hub');
      }
      this.getHub();
    } catch (err) {
      console.error(err.message);
    }
  }

  onLogoClick = () => {
    this.setState({ activeTab: 0 });
  }

  async getModules(hubid) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubid}/modules`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const modules = await res.json();
        this.setState({ modules: modules, });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getFeaturedModules(hubId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubId}/modules/featured`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const featuredModules = await res.json();
        await this.setState({ featuredModules: featuredModules });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getFeaturedFolders(hubId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubId}/plans/featured`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const featuredFolders = await res.json();
        await this.setState({ featuredFolders: featuredFolders });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getFollowers(hubId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubId}/followers`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const hub = await res.json();
        await this.setState({ followersCount: hub.followerCount });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getHub() {
    const { pathname } = this.state.hubPathname;
    try {
      const res = await fetch(`${config.apiURL}/hub/name/${pathname}/`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const hub = await res.json();
        await this.getModules(hub.id);
        await this.getFeaturedModules(hub.id);
        await this.getFeaturedFolders(hub.id);
        await this.getFollowers(hub.id);
        await this.setState({ hub });
        await this.getPlans();
        if (hub.userAccessLevel === hubAccessLevels.SITE_OWNER) {
          this.getSiteOwnerActionButtonLevel();
        }
        this.getTabs();
      } else {
        throw new Error('not found');
      }
    } catch (err) {
      this.setState({ notfound: true });
    }
  }

  async getPlans() {
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.state.hub.id}/plans`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const plans = await res.json();
        this.setState({ plans });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  getTabs() {
    let tabs = [
      {
        title: 'Home',
        component: <HomeTab modules={this.state.modules} hub={this.state.hub} plans={this.state.plans} featuredModules={this.state.featuredModules} featuredFolders={this.state.featuredFolders} changeTab={this.handleSelect} user={this.props.user} />,
      },
      {
        title: 'Modules',
        component: <ModulesTab modules={this.state.modules} hub={this.state.hub} changeTab={this.handleSelect} user={this.props.user} />,
      },
      {
        title: 'Folders',
        component: <FoldersTab plans={this.state.plans} hub={this.state.hub} changeTab={this.handleSelect} user={this.props.user} />,
      }
    ];
    if (this.props.user) {
      tabs = [...tabs, { title: 'Hub Users', component: <UsersTab accessLevel={this.state.hub.userAccessLevel} hubId={this.state.hub.id} users={this.state.hub.users} modules={this.state.modules} /> }];
    }
    if (this.state.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      tabs = [...tabs,
        { title: 'Create Module', component: <CreateModule hubId={this.state.hub.id} approved={this.state.hub.approved} user={this.props.user}/> },
        { title: 'Create Folders/Session', component: <CreatePlanSession hubId={this.state.hub.id} modules={this.state.modules} users={this.state.hub.users} approved={this.state.hub.approved} /> },
        { title: 'Analytics', component: <AnalyticsTab hubId={this.state.hub.id} /> },
      ];
    }

    if (this.state.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      tabs = [...tabs,
        { title: 'Settings', component: <SettingsTab editHub={editedHub => this.saveHubChanges(editedHub)} hub={this.state.hub} plans={this.state.plans} modules={this.state.modules} featured={this.state.featuredModules} featuredFolders={this.state.featuredFolders}/> },
      ];
    }

    this.setState({ tabs });
  }

  async getSiteOwnerActionButtonLevel() {
    try {
      const res = await fetch(`${config.apiURL}/siteowner/${this.state.hub.id}/hublevel`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const accessLevel = await res.json();
        this.setState({
          siteOwnerHublevel: accessLevel,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  getHubWebsiteURL(url) {
    const httpPrefix = 'http';
    if (url.substr(0, httpPrefix.length) !== httpPrefix) {
      const prefixedURL = `${httpPrefix}://${url}`;
      return prefixedURL;
    }
    return url;
  }

  async saveHubChanges(editedHub) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${editedHub.id}`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: editedHub.id,
          name: editedHub.name,
          organisation: editedHub.organisation,
          phoneNumber: editedHub.phoneNumber,
          website: editedHub.website,
          address1: editedHub.address1,
          address2: editedHub.address2,
          postcode: editedHub.postcode,
          description: editedHub.description,
          activities: editedHub.activities,
          targetGroups: editedHub.targetGroups,
          goals: editedHub.goals,
          logoId: editedHub.logoId,
          bannerId: editedHub.bannerId,
          twitter: editedHub.twitter,
          facebook: editedHub.facebook,
          googlePlus: editedHub.googlePlus,
          linkedin: editedHub.linkedin,
          featured: editedHub.featured,
          featuredFolders: editedHub.featuredFolders,
          location: editedHub.location,
          targetConfidence: editedHub.targetConfidence,
          target: editedHub.target,
          physicalActivity: editedHub.physicalActivity,
          showFollowers: editedHub.showFollowers
        }),
      });
      if (res.status === 200) {
        this.setState({activeTab:0});
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleSelect(key) {
    if (this.state.activeTab !== 0 && key === 0) {
      await this.getHub();
      this.getTabs();
    }
    await this.setState({ activeTab: key });
  }

  renderTabs() {
    return this.state.tabs.map((tab, index) => <Tab key={tab.title} eventKey={index} title={tab.title}>{tab.component}</Tab>);
  }

  renderActionButton() {
    if (this.state.hub.userAccessLevel === 0 || this.state.siteOwnerHublevel === 0) {
      return (
        <Button className="action-button" onClick={e => this.onFollowHub(e)}>Follow  <IconContext.Provider value={{ style: { verticalAlign: '-10%',} }}><FaCheckCircle /></IconContext.Provider></Button>
      );
    }
    if (this.state.hub.userAccessLevel === hubAccessLevels.HUB_FOLLOWER || this.state.siteOwnerHublevel === hubAccessLevels.HUB_FOLLOWER) {
      return (
        <Button className="action-button-following" onClick={e => this.onUnfollowHub(e)}>Unfollow  <IconContext.Provider value={{ style: { verticalAlign: '-10%',} }}><FaCheckCircle /></IconContext.Provider></Button>
      );
    }
    if (this.state.hub.userAccessLevel === hubAccessLevels.HUB_MEMBER || this.state.siteOwnerHublevel === hubAccessLevels.HUB_MEMBER) {
      return (
        <Button className="action-button" onClick={e => this.onLeaveHub(e)}>Leave Hub</Button>
      );
    }
    if (this.state.hub.userAccessLevel === hubAccessLevels.HUB_ADMIN || this.state.siteOwnerHublevel === hubAccessLevels.HUB_ADMIN) {
      return (
        <div className="access-lvl">Hub Admin</div>
      );
    }
    if (this.state.hub.userAccessLevel === hubAccessLevels.HUB_OWNER || this.state.siteOwnerHublevel === hubAccessLevels.HUB_OWNER) {
      return (
        <div className="access-lvl">Owner</div>
      );
    }
  }

  socialMedia() {
    const { hub } = this.state;
    if (hub.twitter || hub.facebook || hub.googlePlus || hub.linkedin){
      return(
      <Col md={3} className="social-container">
        <div className="social-icons-container">
          {hub.twitter && <div onClick={() => window.open(hub.twitter)} className="social-icon-container social-tw"><i className="fa fa-twitter fa social social-tw" aria-hidden="true" /></div>}
          {hub.facebook && <div onClick={() => window.open(hub.facebook)} className="social-icon-container social-fb"><i className="fa fa-facebook fa social social-fb" aria-hidden="true" /></div>}
          {hub.googlePlus && <div onClick={() => window.open(hub.googlePlus)} className="social-icon-container social-g"><i className="fa fa-google-plus fa social social-g" aria-hidden="true" /></div>}
          {hub.linkedin && <div onClick={() => window.open(hub.linkedin)} className="social-icon-container social-ln"><i className="fa fa-linkedin fa social social-ln" aria-hidden="true" /></div>}
        </div>
      </Col>)
    }
    return;
  }

  onClickExpand1() {
    const { isExpanded1 } = this.state;
    this.setState({ isExpanded1: !isExpanded1 });
  }

  onClickExpand2() {
    const { isExpanded2 } = this.state;
    this.setState({ isExpanded2: !isExpanded2 });
  }
  onClickExpand3() {
    const { isExpanded3 } = this.state;
    this.setState({ isExpanded3: !isExpanded3 });
  }
  render() {
    const { hub } = this.state;
    const { isExpanded1, isExpanded2, isExpanded3 } = this.state;
    return (
      this.state.notfound ? <Error404 /> :
        this.state.hub &&
        <div className="hub-home-container">
          <div className="banner-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${hub.bannerId})` }}>
          {this.socialMedia()}
          </div>
          <div className="content-container">
            <Row className="header-container">
              <Row md={2} className="header-image-container">
                <div className="hub-logo" onClick={this.onLogoClick} style={{ backgroundImage: `url(${config.spacesURL}/${hub.logoId})` }} />
                {this.props.user && this.renderActionButton()}
              </Row>
              <Row md={7} className="hub-details-container">
              <h1 className="hub-name">{hub.name}</h1>
              { hub.showFollowers===1 && <p className="followers"> {this.state.followersCount} followers</p>}
                <p className="hub-description">{hub.description}</p>
                <div className="contact-details-container">

                  <div className="contact" style={{borderRight: "1px solid #646464"}}>
                    <div style={{display: "inline"}}>
                      <i className="fa fa-map-marker fa social-tz" aria-hidden="true" /><p style={{display: "inline", padding: "0px 10px 10px 0px"}}>Location</p>
                        <Button onClick={() => this.onClickExpand1()}>
                          <IconContext.Provider value={{ style: { color: "var(--color-background-darkest-role)", size: "150px"} }}>
                            {isExpanded1 ? <FaCaretDown /> : <FaCaretUp /> }
                          </IconContext.Provider>
                        </Button>
                    </div>
                    <div style={{paddingTop: "15px"}}>
                      {!isExpanded1 ? <p className="contact-info">{`${hub.address1}, ${hub.address2}, ${hub.postcode}`}</p> : <p className="contact-info"></p>}
                    </div>
                  </div>

                  <div className="contact" style={{borderRight: "1px solid #646464"}}>
                    <div style={{display: "inline"}}>
                      <i className="fa fa-phone fa social-tz" aria-hidden="true" /><p  style={{display: "inline", padding: "0px 10px 10px 0px"}}>Phone Number</p>
                        <Button onClick={() => this.onClickExpand2()}>
                          <IconContext.Provider value={{ style: { color: "var(--color-background-darkest-role)", size: "150px"} }}>
                            {isExpanded2 ? <FaCaretDown /> : <FaCaretUp /> }
                          </IconContext.Provider>
                        </Button>
                    </div>
                    <div style={{paddingTop: "15px"}}>
                      {!isExpanded2 ? <p className="contact-info">{hub.phoneNumber}</p> : <p className="contact-info"></p>}

                    </div>
                  </div>

                  <div className="contact">
                    <div style={{display: "inline"}}>
                      <i className="fa fa-window-maximize fa social-tz" aria-hidden="true" /><p  style={{display: "inline", padding: "0px 10px 10px 0px"}}>Website</p>
                        <Button onClick={() => this.onClickExpand3()}>
                          <IconContext.Provider value={{ style: { color: "var(--color-background-darkest-role)", size: "150px"} }}>
                            {isExpanded3 ? <FaCaretDown /> : <FaCaretUp /> }
                          </IconContext.Provider>
                        </Button>
                    </div>
                    {!isExpanded3 ? <div style={{paddingTop: "15px"}}>
                      <a
                        rel="noopener"
                        // target="_blank"ß
                        href={hub.website ? this.getHubWebsiteURL(hub.website) : ''}
                        className="contact-info"
                      >
                        {hub.website}
                      </a>
                    </div> : <div style={{paddingTop: "15px"}}> <p className='contact-info'></p></div>}
                  </div>

                </div>
              </Row>
            </Row>
          </div>
          <div className="tab-container">
            <Tabs activeKey={this.state.activeTab} onSelect={this.handleSelect} id="controlled-tab-example">
                {this.renderTabs()}
            </Tabs>
          </div>
        </div>
    );
  }
}

export default checkStatus(HubHome);
