import React, { Component } from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  HelpBlock,
  Checkbox,
} from 'react-bootstrap';
import { emailValid, passwordValid } from '../../../lib/validation';

import logo from '../../../resources/img/actify_logo.svg';
import personIcon from '../../../resources/img/person_icon.svg';
import mailIcon from '../../../resources/img/mail_icon.svg';
import lockIcon from '../../../resources/img/lock_icon.svg';

import config from '../../../constant/config';
import './SignUpNew.css';

class PageOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideBanner: window.innerWidth <= 992,
    };
    this.checkWindowWidth = this.checkWindowWidth.bind(this);
  }

  checkWindowWidth() {
    this.setState({ hideBanner: window.innerWidth <= 992 });
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkWindowWidth);
  }

  checkPassword(type) {
      const { password } = this.props;
      if (type === "length") {
          return password.length >= 8
      }
      if (type === "upper") {
          return /[A-Z]/.test(password)
      }
      if (type === "lower") {
          return /[a-z]/.test(password)
      }
      if (type === "number") {
          return /[0-9]/.test(password)
      }
      if (type === "special") {
          return /[^A-Za-z0-9]/.test(password)
      }
  }

  async checkUniqueEmail() {
    const { email, setUniqueEmail } = this.props;

    if (email.length > 0 && emailValid(email)) {
      const res = await fetch(`${config.apiURL}/user/email`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ email: email }),
      });
      if (res.status === 200) {
        const emailTaken = await res.json();
        if (emailTaken) {
          setUniqueEmail(false);
          console.log('taken')
          return false;
        } else {
          setUniqueEmail(true);
          return true;
        }
      }
    } else {
      return true;
    }
  }

  async validate(type, value) {
    const {
      setValidForename,
      setValidSurname,
      setValidEmail,
      setValidPassword,
      setPasswordHelpText,
      uniqueEmail
    } = this.props;

    if (type === 'fname') {
      const validForename = value.length > 0 && value.length < 255;
      setValidForename(validForename);
      return;
    }
    if (type === 'sname') {
      const validSurname = value.length > 0 && value.length < 255;
      setValidSurname(validSurname);
      return;
    }
    if (type === 'email') {
      const validEmail = emailValid(value) && value.length > 0 && value.length < 255;
      setValidEmail(validEmail && uniqueEmail);
      return;
    }
    if (type === 'pword') {
      const checkPassword = passwordValid(value);
      const passwordHelpText = value === '' ? 'Password required' : checkPassword.errorMessage;
      setValidPassword(checkPassword.isValid);
      setPasswordHelpText(passwordHelpText);
      return;
    }
  }

  render() {
    const {
      handleButtonClick,
      email,
      validEmail,
      uniqueEmail,
      confirmEmail,
      password,
      validPassword,
      forename,
      validForename,
      surname,
      validSurname,
      passwordHelpText,
      setForename,
      setSurname,
      setEmail,
      setConfirmEmail,
      setPassword,
      acceptedTerms,
      setAcceptedTerms,
      setShowTerms,
      setShowDataPolicy,
    } = this.props;

    const { skipValidation, login } = this.props;

    const { hideBanner } = this.state;

    const valid = validForename && validSurname && validEmail && uniqueEmail && validPassword && (email === confirmEmail);
    const empty = forename.length === 0 || surname.length === 0 || email.length === 0 || password.length === 0;

    return (
      <div className="row flexbox">
        { !hideBanner &&
          <div className="col-md-6 infographic run-hill position-relative">
            <div className="welcome-message">
              <span className="welcome-big">
                Welcome to
                <br />
                <span className="underline">
                  Actify!
                  <hr />
                </span>
              </span>
              <br />
              <span className="welcome-small">Connecting Scotland's physical activity community</span>
            </div>
            <div className="intro-message">
              <div style={{marginBottom: '8px'}}>The Actify online platform is a product of Actify Training and Technology CIC, created with the support of the Scottish Government. To see how your data is handled and kept secure, please read our&nbsp;
              <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setShowDataPolicy(true)}>data collection policy</span>.</div>
            </div>
          </div>
        }
        <div className={!hideBanner ? "col-md-6" : ""}>
          <div className="form-padding">
            <div className="modal-form-container">
              <div className="logo-container">
                <img
                  alt="actify"
                  className="img-fluid"
                  src={logo}
                />
              </div>

              <p className="already-have-acc">
                Already have an account?{' '}
                <Button onClick={() => login()} className="blue-link">
                  Sign in here
                </Button>
              </p>

              <div className="input-icon-group">
                <FormGroup
                  className="form"
                  validationState={!validForename ? 'error' : null}
                >
                  <ControlLabel className="input-label">First name*</ControlLabel>
                  <FormControl
                    autoFocus
                    className="form-input orange-input"
                    placeholder="First name"
                    type="text"
                    name="firstName"
                    value={forename}
                    onChange={(e) => {
                      setForename(e.target.value);
                      this.validate('fname', e.target.value);
                    }}
                  />
                  {!validForename && <HelpBlock>Please enter first name</HelpBlock>}
                </FormGroup>
                <img
                  class="input-icon"
                  src={personIcon}
                  alt="person icon"
                />
              </div>

              <div className="input-icon-group">
                <FormGroup
                  className="form"
                  validationState={!validSurname ? 'error' : null}
                >
                  <ControlLabel className="input-label">Last name*</ControlLabel>
                  <FormControl
                    className="form-input orange-input"
                    placeholder="Last name"
                    type="text"
                    name="secondName"
                    value={surname}
                    onChange={(e) => {
                      setSurname(e.target.value);
                      this.validate('sname', e.target.value);
                    }}
                  />
                  {!validSurname && <HelpBlock>Please enter last name</HelpBlock>}
                </FormGroup>
                <img
                  class="input-icon"
                  src={personIcon}
                  alt="person icon"
                />
              </div>

              <div className="input-icon-group">
                <FormGroup
                  className="form"
                  controlId="formEmail"
                  validationState={(!validEmail || !uniqueEmail) ? 'error' : null}
                >
                  <ControlLabel className="input-label">Email*</ControlLabel>
                  <FormControl
                    className="form-input orange-input"
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      this.validate('email', e.target.value);
                    }}
                    onBlur={() => this.checkUniqueEmail()}
                  />
                  {(!validEmail || !uniqueEmail) &&
                    <HelpBlock>
                      {!uniqueEmail ? 'Email address already in use' : 'Please enter a valid email'}
                    </HelpBlock>
                  }
                </FormGroup>
                <img
                  class="input-icon"
                  src={mailIcon}
                  alt="mail icon"
                />
              </div>
              <div className="input-icon-group">
              <FormGroup
                  controlId="formConfirmEmail"
                  validationState={(validEmail && uniqueEmail) && (email !== confirmEmail) ? 'error' : null}
                >
                  <ControlLabel className="input-label">Confirm Email*</ControlLabel>
                  <FormControl
                    className="form-input orange-input"
                    type="email"
                    placeholder="Confirm Email address"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                  />
                  {(validEmail && uniqueEmail) && (email !== confirmEmail) && (email !== confirmEmail) &&
                    <HelpBlock>
                      Email addresses do not match
                    </HelpBlock>
                  }
                </FormGroup>
                <img
                  class="input-icon"
                  src={mailIcon}
                  alt="mail icon"
                />
              </div>

              <div className="input-icon-group">
                <FormGroup
                  className="form"
                  controlId="formPassword"
                  validationState={!validPassword ? 'error' : null}
                >
                  <ControlLabel className="input-label">Password*</ControlLabel>
                  <FormControl
                    className="form-input orange-input"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        this.validate('pword', e.target.value);
                    }}
                  />
                  {!validPassword &&
                    <HelpBlock>
                      {passwordHelpText}
                    </HelpBlock>
                  }
                </FormGroup>
                <img
                  class="input-icon"
                  src={lockIcon}
                  alt="padlock icon"
                />
              </div>

              <div>
                <p className="password-requirements">
                  <strong>Password requirements:</strong>
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul className="designed-ul">
                      <li className={this.checkPassword("length") ? "checked" : ""}>
                        At least 8 characters
                      </li>
                      <li className={this.checkPassword("upper") ? "checked" : ""}>
                        1 upper-case character
                      </li>
                      <li className={this.checkPassword("lower") ? "checked" : ""}>
                        1 lower-case character
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="designed-ul">
                      <li className={this.checkPassword("number") ? "checked" : ""}>
                        1 number
                      </li>
                      <li className={this.checkPassword("special") ? "checked" : ""}>
                        1 special character
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <FormGroup className="form-checkbox" controlId="formTerms">
                <Checkbox
                  id="terms-conditions"
                  className="terms-checkbox"
                  onClick={e => setAcceptedTerms(e.target.checked)}
                  checked={acceptedTerms}
                  disabled={!valid || empty}
                />
                <ControlLabel className="inline-label">
                  I accept the
                  <Button className="link-button" onClick={() => setShowTerms(true)}>
                    Actify terms and conditions
                  </Button>
                </ControlLabel>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mb-20">
              <div className="modal-button-container">
                {((valid && acceptedTerms) || skipValidation) &&
                  <Button
                    className="blue-button pull-right"
                    onClick={() => handleButtonClick()}
                  >
                    Get started →
                  </Button>
                }
              </div>
            </div>
          </div>

          {hideBanner && <div className='intro-message-mobile'>
            <div style={{ marginBottom: '8px' }}>The Actify online platform is a product of Actify Training and Technology CIC, created with the support of the Scottish Government. To see how your data is handled and kept secure, please read our&nbsp;
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowDataPolicy(true)}>data collection policy</span>.</div>
          </div>}

        </div>

        </div>
    );
  }
}

export default PageOne;
