import React, { Component } from 'react';
import { FormGroup, ControlLabel, Radio } from 'react-bootstrap';
import './RadioQuestion.css';

class RadioQuestion extends Component {
  getStyle() {
    if (this.props.answer) {
      return {
        color: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        borderColor: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        border: '1px',
        borderStyle: 'solid',
      };
    }
    return null;
  }

  renderLabel(option) {
    if (!this.props.disabled) {
      return (
        <p>{option.answerText}</p>
      );
    }
    if (this.props.answer && option.id === parseInt(this.props.answer.correctAnswer, 10)) {
      return (
        <p className="correct">{option.answerText}</p>
      );
    }
    if (this.props.answer && option.id === parseInt(this.props.answer.answerValue, 10)) {
      return (
        <p className="incorrect">{option.answerText}</p>
      );
    }
    return (
      <p>{option.answerText}</p>
    );
  }

  render() {
    return (
      <div className="radio-question-container" style={this.getStyle()}>
        <div >
          <h3 className="question-text-container">
            {this.props.questionNumber ?
              <ol start={this.props.questionNumber}>
                <li>{this.props.questionText}</li>
              </ol> : this.props.questionText}
          </h3>

          { this.props.deletable &&
            <div className="icon-container">
              <span className="edit-quiz" onClick={() => this.props.editQuestion()}>EDIT</span>
              <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.props.removeQuestion()} />
            </div>
          }
          {this.props.answer &&
            <div className="icon-container">
              {this.props.answer.isCorrect ?
                <i className="fa fa-check fa-2x" aria-hidden="true" /> :
                <i className="fa fa-times fa-2x" aria-hidden="true" />
              }
            </div>
          }
        </div>
        <div className="answers-container">
          <FormGroup className="form" controlId="formRadio" style={{display:"block"}}>
            {this.props.answerLabel && <ControlLabel>{this.props.answerLabel}</ControlLabel>}
            {this.props.possibleAnswers.map((answer, index) => {
                if (answer.id) {
                  return (
                    <Radio
                      disabled={this.props.disabled}
                      key={`correct-answer-${index}`}
                      id={answer.id}
                      name={`radio-quiz-${this.props.questionNumber}`}
                      onClick={() => this.props.onClickAnswer(answer.id)}
                      checked={this.props.answer ? parseInt(this.props.answer.answerValue, 10) === answer.id : null}
                    >
                      {this.renderLabel(answer)}
                    </Radio>) } // eslint-disable-line
                return (<Radio disabled={this.props.disabled} key={`correct-answer-${index}`} id={index} name="radioQuiz" onClick={() => this.props.onClickAnswer(index)}>{answer.answerText}</Radio>);
              })
            }
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default RadioQuestion;
