import React from 'react';
import { Button } from 'react-bootstrap';
import config from '../../constant/config';
import "./Quizzes.css";
import infoIcon from '../../resources/img/info-icon.png';
import InfoBox from './InfoBox';

class Quizzes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfo : false,
    };
  }

  onClickMoudle(id) {
    this.props.history.push(`/module/${id}`);
  }

  renderCompletedQuiz() {
    return this.props.quizResults.map(result => (
          <div key={result.moduleId} style={{margin: "0 1.25em 2.5em 1.25em", textAlign: "center", borderRadius: "15px", border: "1px solid #eeeeee "}}>
            <div className="module-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${result.logoId})` }} />
            <div onClick={() => { this.onClickMoudle(result.moduleId); }} style={{padding: "10px 0px 20px 0px", color: "#646464", fontWeight: "bold"}}>{result.title}</div>
            <div style={{padding: "0px 0px 10px 0px", color: "#646464"}}>{result.requiresAdminReview ? 'Awaiting Review' : `${Math.round((result.userScore / result.userAnswers.length) * 100)}%`}</div>
            <div className="button-container">
              <Button className="view-quiz-button" onClick={() => { this.onClickMoudle(result.moduleId); }} >View Quiz</Button>
            </div>
          </div>

    ));
  }

  render() {
    const { quizResults } = this.props;

    const handleEnter = () => {
      this.setState({showingInfo : true})
    };

    const handleLeave = () => {
      this.setState({showingInfo : false})
    };

    return(
      <div className="my-modules-container">
        <span className="title">
          Quizzes
          <img onMouseOver={()=>handleEnter()} onMouseOut={()=>handleLeave()} className='quiz-info-icon' src={infoIcon} alt='help icon' />
        </span>
        { this.state.showingInfo
          ?
          <div className='quiz-info-box'>
            <InfoBox
              headerStyle={{fontSize: '18px', top: '1px'}}
              bodyStyle={{fontSize: '15px', top:'73px'}}
              imgStyle={{width: '350px'}}
              helpHeader="What are quiz results and where do you access the results after notification?"
              helpText="After you take a quiz, you'll see the results and feedback right away. You will also get a notification so you can find your quiz results in the Modules section of your profile at any time."
            />
          </div>
          :
          null }
        <div className="completed-quiz-container">
          {quizResults.length > 0 ?
            <div className="completed-quiz-table-container">
              <h3 style={{ fontWeight: "bold", fontSize: "1.5em", color: "#646464", paddingTop: "10px", marginBottom: "40px" }}>Completed Quizzes</h3>
              <div className="quiz-card-grid">
                {this.renderCompletedQuiz()}
              </div>
            </div> :
            <div className="no-quizes-container">
              You have No Quizzes to Review!
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Quizzes;
