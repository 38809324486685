import React, { Component } from 'react';
import checkStatus from '../common/checkStatus';
import SupportBlock from '../common/SupportBlock';
import Contact from './Contact';
import './Support.css';

import Tier1 from '../../resources/img/support_page/Tier_1.svg';
import Tier2 from '../../resources/img/support_page/Tier_2.svg';
import Tier3 from '../../resources/img/support_page/Tier_3.svg';


class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <div className="support-div">
        <div>
          <div className="support-subtitle">Support</div>
          <div className="support-title">Support Packages</div>
          <div className="support-text">
            Actify began in 2017 as a free tool for the sports and physical activity sector to share resources and learning. Since then we’ve listened and developed the Actify platform based on what our users tell us. Often people have enthusiasm and vision for how they want to use a hub but putting it into practice on their own can slow them down, so that’s where support packages will help!
            <br></br>
            <br></br>
            A new way to get support for your company/organisation by selecting one of our support packages available at three different tiers to suit your business needs or speak to us about creating your own custom package by selecting the support blocks below that you would like.
          </div>
        </div>

        <div className='support-package'>
          <div className="title">Free Support</div>
          <div className="blocks">
            <SupportBlock colour="blue" title="Onboarding Session"
              text="A free 30 minute introductory session to discuss and plan how best to use your hub and create/upload content with one of our support staff." />
            <SupportBlock colour="blue" title="Help Hub"
              text="Full access to our Help Hub on the Actify platform that has several modules, from creating a hub to content planning, to help start your Actify journey." />
            <SupportBlock colour="blue" title="FAQs"
              text="We have an entire page dedicated to Frequently Asked Questions for both account types that can help answer any of your questions in the first instance." />
          </div>
        </div>

        <div className='support-package'>
          <div className="title">Workshops</div>
          <div className="support-text">
            Workshops will cover such topics as reviewing existing approaches and identifying challenges and/or opportunities, mapping the journeys of audiences and developing detailed plans on the use of Actify hubs.
          </div>
          <div className="blocks">
            <SupportBlock colour="yellow" title="Scoping"
              text="The aim of this workshop is to review existing approaches and identifying challenges and/or opportunities." />
            <SupportBlock colour="yellow" title="Workforce Journeys"
              text="The aim of this workshop is to explore the existing journeys of the people within your workforce you want to help and develop a vision of a new and improved version." />
            <SupportBlock colour="yellow" title="Mapping Your Workforce’s Needs"
              text="The aim of this workshop is to investigate what your workforce’s needs are and defining what you can offer in response." />
            <SupportBlock colour="yellow" title="Action Planning"
              text="The aim of this workshop is to make detailed plans on the use of Actify hubs,content and who will do what and by when." />
            <SupportBlock colour="yellow" title="Content Planning"
              text="This training session will cover creating detailed content plans aligned to audience needs and programme outcomes." />
          </div>
        </div>

        <div className='support-package'>
          <div className="title">Training</div>
          <div className="support-text">
            Training opportunities include managing Actify hubs, users and content, training on video production & software that can be integrated into your work, training on the structure, presentation and technical hosting on Actify.
          </div>
          <div className="blocks">
            <SupportBlock colour="orange" title="Content Production"
              text="This session will provide training on the structuring, presentation and technical hosting of training and support content within Actify." />
            <SupportBlock colour="orange" title="Hub Management"
              text="This session will provide training on managing Actify hubs, users and content." />
            <SupportBlock colour="orange" title="Video Production and Editing"
              text="This session will provide training on video production using basic equipment and software that can be integrated into, and support, their existing workflows." />
          </div>
        </div>

        <div className='support-package'>
          <div className="title">1-2-1 Sessions</div>
          <div className="blocks">
            <SupportBlock colour="pink" title="Bespoke Sessions"
              text="1-2-1 Sessions offer time and space for follow up support and progress updates." />
          </div>

        </div>

        <div className='support-package'>
          <div className="title">Support Package Tiers</div>
          <div className="support-text">
            We have three predefined support packages available at three different price points with a 10% discount to suit your/your organisation’s needs. The support blocks included in each of these tiers are detailed below along with the time each session will take to deliver.
          </div>
          <div className='tiers'>
            <div>
              <img
                src={Tier1}
                alt="Tier1"
                style={{}}
              />
              <div className='tier-title'>Tier 1</div>
            </div>

            <div>
              <img
                src={Tier2}
                alt="Tier2"
                style={{}}
              />
              <div className='tier-title'>Tier 2</div>
            </div>

            <div>
              <img
                src={Tier3}
                alt="Tier3"
                style={{}}
              />
              <div className='tier-title'>Tier 3</div>
            </div>
          </div>
          <div className='title' style={{textAlign: 'center', padding: "0 10%"}}>
          For more information on Support Packages and pricing, please enquire via our contact form below.
          </div>
        </div>

        <div className='get-in-touch'>
          <div className="support-title" style={{textAlign: 'center', marginTop: '20px'}}>Get in Touch!</div>
          <div className='support-text'>
          You can also choose to create your own custom support package to suit your business needs. If you would like to discuss Support Packages in more detail then feel free to send us a message using the contact form below and a member of our support team will be in touch.
          </div>
          <Contact />

        </div>

      </div>
    );
  }
}

export default checkStatus(Support);
