import React from 'react';
import config from '../../constant/config';
import auth from '../../lib/auth';
import checkStatus from '../common/checkStatus';
import ModuleHeader from '../common/ModuleHeader';
import CreateModule from '../common/CreateModuleContainer';
import './EditModule.css';

class EditModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      module: [],
      hubName: '',
      categoryDescription: '',
      actionButton: null,
      isAdmin: true,
      moduleNotFound: false,
    };
  }

  componentWillMount() {
    if(!this.props.user){
      this.props.history.push('/');
    }
    else{
    this.getModule();
    }
  }

  async getModule() {
    const { moduleId } = this.props.match.params;
    try {
      const res = await fetch(`${config.apiURL}/module/${moduleId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const module = await res.json();
        const isAdmin = await auth.isAuthenticatedHubAdmin(module.hub.id);
        const actionButton = this.renderActionButton(isAdmin);
        this.setState({ module, hubName: module.hub.name, categoryDescription: module.category.description, actionButton , isAdmin});
      }
      if (res.status === 404) {
        this.setState({moduleNotFound :  true})
      }
    } catch (err) {
      console.log(err);
    }
  }

  renderActionButton() {
    return;
  }

  render() {
    if(this.state.moduleNotFound){
      return(
        <div><center>Module not found</center></div>
      )
    }
    else{
    return (
      <div className="edit-module">
        <ModuleHeader
          mod={this.state.module}
          hubName={this.state.hubName}
          categoryDescription={this.state.categoryDescription}
          loggedIn={this.props.user}
          actionButton={this.state.actionButton}
        />
        {this.state.isAdmin ? (
          <CreateModule
            edit
            module={this.state.module}
            moduleId={this.props.match.params.moduleId}
            hubId={this.state.module.hubId}
            user={this.props.user}
            approved
          />
        ) : (
          <div>
           <center><p>You do not have access to this page</p></center> 
          </div>
        )}
      </div>
    );
  }
  }
}

export default checkStatus(EditModule);
