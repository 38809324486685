import React from 'react';
import config from '../../constant/config';
import { parseEmbedYouTubeUrl } from '../../lib/video';
import './RTE.css';

import { FormGroup, HelpBlock, ControlLabel } from 'react-bootstrap';
import LoadingIcons from 'react-loading-icons'

import RichTextEditor, {
  getTextAlignClassName,
  Button as RTEButton,
  ButtonGroup,
} from 'react-rte';
import { Modifier, EditorState, ContentState, SelectionState, CompositeDecorator, convertFromHTML } from 'draft-js';
import {ENTITY_TYPE} from 'draft-js-utils';

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_ALIGNMENT_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Strikethrough', style: 'STRIKETHROUGH'},
    {label: 'Monospace', style: 'CODE'},
    {label: 'Underline', style: 'UNDERLINE'},
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    {label: 'Align Left', style: 'ALIGN_LEFT'},
    {label: 'Align Center', style: 'ALIGN_CENTER'},
    {label: 'Align Right', style: 'ALIGN_RIGHT'},
    {label: 'Align Justify', style: 'ALIGN_JUSTIFY'},
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'},
    {label: 'Code Block', style: 'code-block'},
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Blockquote', style: 'blockquote'},
  ]
};

class RTE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      type: null,
      filename: null,
      loading: false,
      popoverOpen: false,
      embedURL: '',
      imageSize: 2,
    };
  }

  onUploadRteImage(e) {
    e.preventDefault();
    const files = this.textContentImage.files;
    this.readContentImage(files[0]);
  }

  async readContentImage(file) {
    const reader = new FileReader();
    reader.onloadend = async () => {
      await this.setState({
        img: reader.result,
        type: file.type,
        filename: file.name,
      });
      await this.uploadImage();
    };
    reader.readAsArrayBuffer(file);
  }

  async uploadImage() {
    const { img, filename, type, imageSize } = this.state;
    if (img) {
      this.setState({ loading: true });
      const payload = new Buffer(img, 'binary').toString('binary');
      const assetRes = await fetch(`${config.apiURL}/asset/`, { // eslint-disable-line
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          title: filename,
          data: payload,
          fileType: type,
          imageSize,
        }),
      });
      const assetId = await assetRes.text(); // eslint-disable-line
      setTimeout(this.handleImageInsert(`${config.spacesURL}/${assetId}`), 2000);
    }
  }

  handleImageInsert(src) {
    const { textContent, onChangeTextContent } = this.props;
    let editorState = textContent.getEditorState();
    let contentState = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    selection = SelectionState.createEmpty(selection.getEndKey());
    contentState = contentState.createEntity(ENTITY_TYPE.IMAGE, 'IMMUTABLE', {src});
    let entityKey = contentState.getLastCreatedEntityKey();
    let newContentState = Modifier.insertText(contentState, selection, ' ', null, entityKey);

    editorState = EditorState.push(editorState, newContentState);
    let newValue = textContent.setEditorState(editorState);
    onChangeTextContent(newValue);
    this.setState({ loading: false, popoverOpen: false });
  }

  showImageUpload() {
    this.textContentImage.click();
  }

  customImageControl() {
    const { popoverOpen, loading, imageSize } = this.state;
    return (
      <ButtonGroup>
        <div className="embed-wrap">
          <RTEButton className="rte-button" onClick={() => this.showPopover()}>
            <span className="icon-image" />
            { loading && <LoadingIcons.ThreeDots fill="black" stroke="black" height="22px" width="22px" />}
          </RTEButton>
          { popoverOpen &&
            <div className="embed-popover">
              <div className="embed-popover-inner">
                <div className="popover-sizes">
                  <button
                    className={imageSize === 1 ? 'selected' : ''}
                    onClick={() => this.setState({ imageSize: 1 })}
                  >
                    S
                  </button>
                  <button
                    className={imageSize === 2 ? 'selected' : ''}
                    onClick={() => this.setState({ imageSize: 2 })}
                  >
                    M
                  </button>
                  <button
                    className={imageSize === 3 ? 'selected' : ''}
                    onClick={() => this.setState({ imageSize: 3 })}
                  >
                    L
                  </button>
                </div>
                <div className="embed-popover-accept-decline">
                  <button id="decline" type="button" onClick={() => this.showImageUpload()}>
                    UPLOAD
                  </button>
                  <button id="upload" type="button" onClick={() => this.setState({ popoverOpen: false })}>
                    CANCEL
                  </button>
                </div>
              </div>
            </div>
          }
      </div>
      </ButtonGroup>
    );
  }

  handleEmbedInsert() {
    const { popoverOpen, embedURL } = this.state;
    if (embedURL !== '') {
      const { valid, url } = this.validateEmbedUrl();
      if (valid) {
        const IFrame = (props) => {
          return (
            <iframe src={props.decoratedText} title={props.decoratedText} className="video-wrapper" frameBorder="0" allowFullScreen />
          );
        };
        const decorator = new CompositeDecorator([
            {
              strategy: this.findIFrameEntities,
              component: IFrame,
            },
          ]);
        const { textContent, onChangeTextContent } = this.props;
        const sampleMarkup = url;

        const blocksFromHTML = convertFromHTML(sampleMarkup);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        const eState = EditorState.createWithContent(state, decorator)
        let newValue = textContent.setEditorState(eState);
        onChangeTextContent(newValue);
        this.setState({
          popoverOpen: !popoverOpen,
          embedURL: '',
        });
      }
    }
  }

  findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  findIFrameEntities(contentBlock, callback, contentState) {
    const regex = /(?:https?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig; //eslint-disable-line
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  validateEmbedUrl() {
    const { embedURL } = this.state;
    const youtubeUrl = parseEmbedYouTubeUrl(embedURL);
    const validYoutube = youtubeUrl.startsWith('https://www.youtube.com/embed/');
    return { valid: validYoutube, url: youtubeUrl };
  }

  showPopover() {
    const { popoverOpen } = this.state;
    this.setState({ popoverOpen: !popoverOpen });
  }

  customEmbedControl() {
    const { popoverOpen, embedURL } = this.state;
    return (
      <ButtonGroup>
        <div className="embed-wrap">
          <RTEButton className="rte-button" onClick={() => this.showPopover()}>
            <span className="embed-icon-image" />
          </RTEButton>
          { popoverOpen &&
            <div className="embed-popover">
              <div className="embed-popover-inner">
                <input
                  type="text"
                  placeholder="https://example.com/"
                  value={embedURL}
                  onChange={(e) => this.setState({ embedURL: e.target.value})}
                />
                <div className="embed-popover-accept-decline">
                  <button id="decline" type="button" onClick={() => this.setState({ popoverOpen: false })}>
                    <span className="decline-icon"/>
                  </button>
                  <button id="accept" type="button" onClick={() => this.handleEmbedInsert()}>
                    <span className="accept-icon"/>
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </ButtonGroup>
    );
  }

  render() {
    const {
      textContent,
      validTextContent,
      onChangeTextContent,
      hideTitle,
    } = this.props;
    return (
      <FormGroup
        className="form"
        controlId="textContent"
        validationState={!validTextContent ? 'error' : null}
        style={{display: "block", marginBottom: "5px"}}
      >
        {!hideTitle && <ControlLabel className="text-area" style={{ marginBottom: "15px"}}>Content:</ControlLabel>}
        <RichTextEditor
          className="rich-editor"
          value={textContent}
          onChange={value => onChangeTextContent(value)}
          blockStyleFn={getTextAlignClassName}
          customControls={[() => this.customImageControl()]}
          toolbarConfig={toolbarConfig}
        />
        {!validTextContent && !hideTitle && <HelpBlock>Must Enter Text Content</HelpBlock>}
        <input
          ref={(f) => { this.textContentImage = f; }}
          className="form-input logo"
          type="file"
          style={{ display: 'none' }}
          onChange={e => {
            this.onUploadRteImage(e);
            e.target.value=null;
          }}
        />
      </FormGroup>
    );
  }
}

export default RTE;
