export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';
export const SET_USER = 'SET_USER';
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';
export const SET_BOOKMARKS = 'SET_BOOKMARKS';
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const ADD_QUIZ_RESULT = 'ADD_QUIZ_RESULT';
export const SET_QUIZ_RESULTS = 'SET_QUIZ_RESULTS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_ACTUAL_NOTIFICATIONS = 'SET_ACTUAL_NOTIFICATIONS';
export const FOLLOW_HUB = 'FOLLOW_HUB';
export const UNFOLLOW_HUB = 'UNFOLLOW_HUB';
export const REMOVE_HUB = 'REMOVE_HUB';
export const ADD_HUB = 'ADD_HUB';
export const SET_ACTIVE_PROFILE_TAB = 'SET_ACTIVE_PROFILE_TAB';
export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE';
export const SET_CONVERSATION_ID = 'SET_CONVERSATION_ID';
