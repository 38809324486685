import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import UserDetails from './UserDetails';
import AddUser from './AddUser';
import RemoveUser from './RemoveUser';
import config from '../../constant/config';
import './ManageUsers.css';

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      error: null,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.error) {
      this.setState({ error: props.error, showModal: true });
    }
  }

  onUserClick(user) {
    this.setState({
      showModal: true,
      modalContent: <UserDetails admin={false} modalUser={user} />,
    });
  }

  onRemoveUserClick(user) {
    this.setState({
      showModal: true,
      modalContent: <RemoveUser
        modalUser={user}
        closeModal={() => this.closeModal()}
        removeUser={() => this.handleRemoveUser(user)}
      />,
    });
  }

  onAddUserClick() {
    this.setState({
      showModal: true, modalContent: <AddUser hubId={this.props.hubId} />,
    });
  }

  closeModal() {
    this.setState({ showModal: false, modalContent: null, error: null });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  handleRemoveUser(user) {
    this.setState({ showModal: false });
    this.props.removeUser(user.id);
  }

  renderTableRows() {
    return this.props.users.map(user => (
      <tr key={user.id}>
        <td>
          <div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${user.avatarId})` }} />
        </td>
        <td><div onClick={() => { this.onUserClick(user); }}>{`${user.firstName} ${user.surname}`}</div></td>
        <td>
          <div className="warning-button-container">
            <Button className="warning-button" onClick={() => this.onRemoveUserClick(user)}>
              Remove User
            </Button>
          </div>
        </td>

      </tr>
    ));
  }

  render() {
    return (
      <div className="manage-users-container">
        <div className="add-user-button-container">
          <Button onClick={() => this.onAddUserClick()}>
            Invite New User
          </Button>
        </div>
        <table className="users-table">
          <thead>
            <tr>
              <th>Avatar</th>
              <th>Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>

        </table>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {!this.state.error && this.state.modalContent}
          {this.state.error &&
          <div className="error-feedback">
            <i className="fa fa-exclamation-circle fa-3x" aria-hidden="true" />
            <p>{this.state.error}</p>
          </div>
        }
        </Modal>
      </div>
    );
  }
}

export default ManageUsers;
