import React from 'react';
import './ManageHubs.css';
import './ManageFaq.css';

import { Button, Modal, FormGroup, FormControl } from 'react-bootstrap';

class ManageFaq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalContent: null,
      error: null,
      curQuestion: null,
      curAnswer: null,
      curType: null,
      showError: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  onAcceptRemoveFaq(id) {
    this.closeModal();
    this.props.removeClick(id);
  }



  onAcceptEditFaq(id, question, answer, type) {
    if (type === "1" || type === "2") {
      this.setState({ showError: false })
      this.closeModal();
      this.props.editClick(id, question, answer, type);
    } else {
      this.setState({ showError: true })
    }
  }

  onAcceptAddFaq(question, answer, type) {
    if (type === "1" || type === "2") {
      this.setState({ showError: false })
      this.closeModal();
      this.props.addClick(question, answer, type);
    } else {
      this.setState({ showError: true })
    }
  }

  onRemoveFAQ(e, id, question) {
    e.preventDefault();
    const modalContent = (
      <div className="remove-hub-modal-container">
        <div>
          <div className="text-container">
            <p>
              You are about to remove: <br /> <b>{question}</b> <br />
              Are you sure?
            </p>
          </div>
          <div className="button-container">
            <Button onClick={() => this.onAcceptRemoveFaq(id)}>Yes</Button>
            <Button onClick={() => this.closeModal()}>No</Button>
          </div>
        </div>
      </div>
    );
    this.setState({
      showModal: true,
      error: null,
      modalContent,
      showError: false,
    });
  }

  onEditFAQ(e, id, question, answer, type) {
    e.preventDefault();
    const modalContent = (
      <div className="remove-hub-modal-container">
        <div>
          <div className="text-container" style={{ textAlign: 'left', marginLeft: '1em', marginRight: '1em' }}>
            <FormGroup controlId="formCategory">
              <FormControl
                defaultValue={question}
                onChange={e => this.setState({ curQuestion: e.target.value })}
              />
              <br />
              <FormControl
                componentClass="textarea"
                defaultValue={answer}
                onChange={e => this.setState({ curAnswer: e.target.value })}
              />
              <br />

              <FormControl
                defaultValue={type}
                onChange={e => this.setState({ curType: e.target.value })}
              />
            </FormGroup>
          </div>
          <div className="button-container">
            <Button onClick={() => this.onAcceptEditFaq(id, this.state.curQuestion, this.state.curAnswer, this.state.curType)}>Save</Button>
            <Button onClick={() => this.closeModal()}>Cancel</Button>
          </div>
        </div>
      </div>
    );
    this.setState({
      showModal: true,
      error: null,
      modalContent,
      curQuestion: question,
      curAnswer: answer,
      curType: type,
      showError: false,
    });

  }

  onAddFAQ(e) {
    e.preventDefault();
    const modalContent = (
      <div className="remove-hub-modal-container">
        <div>
          <div className="text-container" style={{ textAlign: 'left', marginLeft: '1em', marginRight: '1em' }}>
            <h4 style={{ textAlign: 'center' }}>Add a new FAQ</h4>
            <br />
            <FormGroup controlId="formCategory">
              <FormControl
                placeholder="Enter Question"
                onChange={e => this.setState({ curQuestion: e.target.value })}
              />
              <br />
              <FormControl
                componentClass="textarea"
                placeholder="Enter Answer"
                onChange={e => this.setState({ curAnswer: e.target.value })}
              />
              <br />
              <FormControl
                placeholder="Enter User Type (for Practitioner: 2, for Personal Use: 1)"
                onChange={e => this.setState({ curType: e.target.value })}
              />
            </FormGroup>
          </div>
          <div className="button-container">
            <Button onClick={() => this.onAcceptAddFaq(this.state.curQuestion, this.state.curAnswer, this.state.curType)}>Submit</Button>
            <Button onClick={() => this.closeModal()}>Cancel</Button>
          </div>
        </div>
      </div>
    );
    this.setState({
      showModal: true,
      error: null,
      modalContent,
      showError: false,
    });
  }

  renderRows() {
    return this.props.faq.map(faq => (
      <tr key={faq.id}>
        <td>{faq.question}</td>
        <td>{faq.answer.slice(0, 100)} {faq.answer.length > 100 ? '...' : ''}</td>
        <td>{faq.type}</td>
        <td>
          <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <div className="manage-hub-button-container">
              <Button
                className="pending-button"
                style={{ marginRight: '1em' }}
                onClick={e => this.onEditFAQ(e, faq.id, faq.question, faq.answer, faq.type)}
              >
                Edit
              </Button>
            </div>
            <div className="manage-hub-button-container">
              <Button
                className="remove-button"
                onClick={e => this.onRemoveFAQ(e, faq.id, faq.question)}
              >
                Delete
              </Button>
            </div>
          </div>
        </td>
      </tr>
    ));
  }

  render() {
    console.log(this.state.showError)
    return (
      <div
        className="manage-hubs"
        style={{
          marginBottom: (this.props.hubOptions && this.props.hubOptions.length > 0) ? 0 : 30,
        }}
      >
        <div className="pending-table-container">
          <table className="pending-table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer Excerpt</th>
                <th>Type</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {this.renderRows()}
            </tbody>
          </table>
          <div className="add-button-container">
            <Button
              className="add-button"
              onClick={(e) => this.onAddFAQ(e)}
            >
              Add New FAQ
            </Button>
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />

          {!this.state.error && this.state.modalContent}
          {this.state.error &&
            <div className="error-feedback">
              <i className="fa fa-exclamation-circle fa-3x" aria-hidden="true" />
              <p>{this.state.error}</p>
            </div>
          }
          {this.state.showError &&
            <div className="alert" dismissible>
              User Type can only have values of 1 (Personal Use) or 2 (Practitioner)!
            </div>
          }

        </Modal>


      </div>
    );
  }
}

export default ManageFaq;
