import React from 'react';
import config from '../../constant/config';
import { Modal, Button } from 'react-bootstrap';
import { dateCompare } from '../../lib/utilities';
import Comment from './Comment';
import Login from './LoginContainer';
import SignUpNew from '../common/signup/SignUpNewContainer';
import auth from '../../lib/auth';
import './CommentSection.css'


const fetchComments = async (id) => {
  try {
    const res = await fetch(`${config.apiURL}/comment/${id}/`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      },
    });
    const body = await res.json();
    return body;
  } catch (err) {
    console.error(err);
  }
}

const getUserLikedComments = async () => {
  try {
    const res = await fetch(`${config.apiURL}/comment/user/likes/`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      },
    });
    const body = await res.json();
    return body;
  } catch (err) {
    console.error(err);
  }
};

class CommentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      comment: '',
      loading: false,
      error: false,
      usersLikedComments: [],
      refreshReady: true,
      showModal: false,
      modalContent: null,
    };
    this.hubInviteToken = new URLSearchParams(this.props.location.search).get("invite_token");
  }

  componentDidMount() {
    this.getComments();
    if (auth.isAuthenticated() && this.props.user) {
      this.getUsersLikes();
    }
    setInterval(() => {
      this.setState({ refreshReady: true })
    }, 20000);
  }

  async getComments() {
    this.setState({
      loading: true,
    });

    const comments = await fetchComments(this.props.moduleId);

    this.setState({
      comments: comments,
      loading: false,
    });
  }

  async getUsersLikes() {
    this.setState({
      loading: true,
    });

    let likes = await getUserLikedComments();
    likes = likes.map(like => like.commentId);

    this.setState({
      usersLikedComments: likes,
      loading: false,
    });
  }

  async publishComment() {
    this.setState({
      error: false,
    });
    const moduleId = this.props.moduleId;

    const { comments } = this.state;

    try {
      const res = await fetch(`${config.apiURL}/comment/${moduleId}`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          content: this.state.comment,
          moduleId: moduleId,
        }),
      });
      if (res.status === 200) {
        const comment = await res.json();
        comments.unshift(comment);
        this.setState({
          comment: '',
          comments,
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        error: true,
      });
    }
  }

  deleteComment(id) {
    const { comments } = this.state;

    const removed = comments.filter((value, i, arr) => value.id !== id);

    this.setState({
      comments: removed,
    });

    this.forceUpdate()
  }

  refreshComments() {
    const { refreshReady } = this.state;
    if (refreshReady) {
      this.getComments();
      this.setState({ refreshReady: false });
    }
  }

  renderComment(comment) {
    const { usersLikedComments } = this.state;
    return (
      <Comment
        key={comment.id}
        user={this.props.user}
        comment={comment}
        likes={usersLikedComments}
        deleteComment={(id) => this.deleteComment(id)}
        hubId={this.props.hubId}
      />
    );
  }

  onSignInClick(e) {
    e.preventDefault();
    this.setState({
      showModal: true,
      modalContent: <Login handleModalClose={this.closeModal} />,
    });
  }

  onSignUpClick(e) {
    e.preventDefault();
    const login = <Login handleModalClose={this.closeModal} />;
    this.setState({
      showModal: true,
      modalContent: <SignUpNew loginRedirect={() => this.setState({ modalContent: login })} hubInviteToken={this.hubInviteToken} handleModalClose={this.closeModal} />,
    });
  }

  closeModal() {
    this.setState({ showModal: false, modalContent: null });
  }

  /* eslint-disable */
  render() {
    const {
      comment,
      comments,
      loading,
      refreshReady,
      showModal,
      modalContent,
    } = this.state;

    if (loading) {
      return (<h2>Loading comments...</h2>)
    }

    const sortedComments = comments.sort(dateCompare);

    return (
      <div>
        <div className='comment-section-header'>
          Leave a Comment
        </div>
        <div className="comment-section-container">
          <div>
            {/* <h2>
                    Comments { comments.length > 0 && `(${comments.length})`}
                    { ` ` }
                    <OverlayTrigger
                      placement="right"
                      delay={250}
                      overlay={<Tooltip id="refreshComments">Refresh comments every 20 seconds</Tooltip>}
                    >
                      <FontAwesomeIcon
                        icon={faSyncAlt}
                        className={refreshReady ? 'refresh' : 'refresh not-ready'}
                        aria-hidden="true"
                        onClick={() => this.refreshComments()}
                      />
                    </OverlayTrigger>
                </h2> */}
            {this.props.user &&
              <div className="user-comment">
                <div className="user-comment-input">
                  <input
                    className={'comment-box'}
                    value={comment}
                    onChange={(e) => this.setState({ comment: e.target.value })}
                    placeholder="Post a comment..."
                  />
                </div>
                <div className='submit-button'>
                  {comment.length >= 4 &&
                    <Button
                      className="comment-submit"
                      onClick={() => this.publishComment()}
                    >
                      Post
                    </Button>
                  }
                </div>
              </div>
            }
            {
              !this.props.user &&
              <span className="login-prompt">
                <a href="#" onClick={(e) => this.onSignInClick(e)}>Login</a>
                {' '}
                or
                {' '}
                <a href="#" onClick={(e) => this.onSignUpClick(e)}>sign up</a>
                {' '}
                to leave a comment!
              </span>
            }
          </div>
          <br />
          <div className="comments-container">
            {sortedComments.map(comment => this.renderComment(comment))}
          </div>
          <Modal show={showModal} onHide={() => this.closeModal()} backdrop="static">
            <Modal.Header closeButton />
            {modalContent}
          </Modal>
          <div className="padding-line-black" />
        </div>
      </div>

    );
  }
}

export default CommentSection;
