import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';

import transitionIcon from '../../../resources/img/transition_icon.svg';

import './SignUpNew.css';

class PageEleven extends Component {

  render() {
    const {
      handleButtonClick,
      skipQuestionnaire, 
    } = this.props;

    return (
      <div className="no-padding">
        <div className="title-section">
          <div>
            <p className="hlayout-title">
              Thanks for your input! Would you like to answer some questions about how you'd like to help others using your account on Actify?
            </p>
          </div>

          <div className="pers-feedback-image">
            <img src={transitionIcon} alt="transition icon to next questions" />
          </div>

          <div>
            <p className="hlayout-copy" style={{padding:"0 3em"}}>
              You can answer a few more questions, or skip and come back here later using your profile! <br />
            </p>
          </div>
        </div>

        <div className="navigation-buttons no-colour mt-20">
          <div className="back">
            <Button
              className="navigation-button"
              onClick={() => handleButtonClick(true)}
            >
              ← Back
            </Button>
          </div>
          <div className="next">
            <Button
              className="navigation-button"
              onClick={() => handleButtonClick()}
            >
              Next →
            </Button>
            <Button 
              className="blue-button"
              onClick={() => skipQuestionnaire(true)}
            >
              Skip <FaCheck style={{paddingTop: "3px"}}/>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PageEleven;
