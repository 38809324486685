import React from 'react';

const dataCollectionPolicy = (
  <div>
    <h3>1. Introduction</h3>
    <ol>
      <li>
        <p>&nbsp; We are committed to safeguarding the privacy of our website visitors and service users.</p>
      </li>
      <li>
        <p>This policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users; in other words, where we determine the purposes and means of the processing of that personal data.</p>
      </li>
      <li>
        <p>By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>
      </li>
      <li>
        <p>Our website incorporates privacy controls which affect how we will process your personal data. You can access the privacy controls via [<a href="https://www.actify.org.uk/myprofile">https://www.actify.org.uk/myprofile</a>]</p>
      </li>
      <li>
        <p>In this policy, "we", "us" and "our" refer to Agile CIC (For more information about Agile CIC, see Section 8).</p>
      </li>
      <li>
        <p>By visiting this website, you accept any and all practices outlined in the policy.</p>
      </li>
    </ol>
    <h3>2. How we use your personal data</h3>
    <ol>
      <li>
        <p>&nbsp; In this Section 2 we have set out:</p>
        <ol className="ordered-letters">
          <li>
            <p>&nbsp;the general categories of personal data that we may process;</p>
          </li>
          <li>
            <p>&nbsp;in the case of personal data that we did not obtain directly from you, the source and specific categories of that data;</p>
          </li>
          <li>
            <p>&nbsp;the purposes for which we may process personal data; and</p>
          </li>
          <li>
            <p>the legal bases of the processing.</p>
          </li>
        </ol>
      </li>
      <li>
        <p>&nbsp;Regarding each of your visits to the website, we will automatically collect the following information about you: your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views, and navigation. For detailed information on the cookies we use and the purposes for which we use them, a link to our cookies policy can be found at <a href="https://actify.org.uk/about">https://actify.org.uk/about</a></p>
      </li>
      <li>
        <p>&nbsp;We may process your information included in your account data (<strong>"account data"</strong>) or your personal profile on our website (<strong>"profile data"</strong>). The profile data may include your name, address, telephone number, email address, profile pictures, gender, date of birth, interests and hobbies, educational details and employment details. The profile data may be processed for the purposes of analysing, enabling, and monitoring your use of our website and services. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business.</p>
      </li>
      <li>
        <p>We may process your personal data that are provided in the course of the use of our services (<strong>"service data"</strong>) or information that you post for publication on our website (<strong>"publication data"</strong>). The source of the service data is you or your employer. The service data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business.</p>
      </li>



      <li>
        <p>We may process information contained in any enquiry you submit to us regarding goods and/or services (<strong>"enquiry data"</strong>). The enquiry data may be processed for the purposes of sending you relevant notifications/newsletters, or offering, marketing and selling relevant goods and/or services to you. The legal basis for this processing is consent.</p>
      </li>
      <li>
        <p>We may process information relating to transactions, including purchases of goods and services, that you enter into with us and/or through our website (<strong>"transaction data"</strong>). The transaction data may include your contact details, your card details and the transaction details. The transaction data may be processed for the purpose of supplying the purchased goods and services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely our interest in the proper administration of our website and business.</p>
      </li>
      <li>
        <p>We may process information contained in or relating to any communication that you send to us (<strong>"correspondence data"</strong>). The correspondence data may include the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the website contact forms. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.</p>
      </li>
      <li>
        <p>We may process and identify general categories of data. This data may include your profession, goals, groups you wish to work with, and interests. The source of this data is you. This data may be processed for improving your experience on the website. The legal basis for this processing is our legitimate interests, namely recommending relevant content.</p>
      </li>
      <li>
        <p>We may process any of your personal data identified in this policy where necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.</p>
      </li>
      <li>
        <p>We may process any of your personal data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</p>
      </li>
      <li>
        <p>In addition to the specific purposes for which we may process your personal data set out in this Section 2, we may also process any of your personal data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>
      </li>
      <li>
        <p>Please do not supply any other person's personal data to us, unless we prompt you to do so.</p>
      </li>
    </ol>
    <h3>3. Providing your personal data to others</h3>
    <ol>
      <li>
        <p>We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.</p>
      </li>
      <li>
        <p>We may disclose your personal data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>
      </li>

      <li>
        <p>When the user has given explicit consent to do so, we may disclose your enquiry data to one or more of those selected third party suppliers of goods and services identified on our website for the purpose of enabling them to contact you so that they can offer, market and sell to you relevant goods and/or services. Each such third party will act as a data controller in relation to the enquiry data that we supply to it; and upon contacting you, each such third party will supply to you a copy of its own privacy policy, which will govern that third party's use of your personal data.</p>
      </li>
      <li>
        <p>In addition to the specific disclosures of personal data set out in this Section 4, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>
      </li>
    </ol>
    <h3>4. International transfers of your data</h3>
    <ol>
      <li>
        <p>In this Section 4, we provide information about the circumstances in which your personal data may be transferred to countries outside the European Economic Area (EEA).</p>
      </li>
      <li>
        <p>Agile will not hold any personal information outside of the EEA without informing the user beforehand.</p>
      </li>
      <li>
        <p>Transmission of information via the internet is never completely secure, and Agile cannot guarantee the security of any information which is transmitted to the website. You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others.</p>
      </li>
    </ol>
    <h3>5. Retaining and deleting personal data</h3>
    <ol>
      <li>
        <p>This Section 5 sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</p>
      </li>
      <li>
        <p>Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>
      </li>
      <li>
        <p>Your personal data will be retained for the duration of the subscription, and for a maximum period of 3 months following cancellation of membership.</p>
      </li>
      <li>
        <p>Notwithstanding the other provisions of this Section 5, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</p>
      </li>
    </ol>
    <h3>6. Amendments</h3>
    <ol>
      <li>We may update this policy from time to time by publishing a new version on our website.</li>
      <li>You should check this page occasionally to ensure you are happy with any changes to this policy.</li>
      <li>We may notify you of changes to this policy by email or through the private messaging system on our website.</li>
      <li>If any planned changes will materially affect the way your personal data is processed, consent will be sought before implementation.</li>
    </ol>

    <h3>7. Your rights</h3>
    <ol>
      <li>
        <p>In this Section 7, we have summarised the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</p>
      </li>
      <li>
        <p>Your principal rights under data protection law are:</p>
        <ol>
          <li>
            <p>You have the right to access the personal information we hold about you by making a subject access request in accordance with data protection legislation. We may charge a reasonable fee where a request is manifestly unfounded or excessive.</p>
          </li>
          <li>
            <p>You have the right to have your personal information rectified if it is inaccurate or incomplete.</p>
          </li>
          <li>
            <p>You have the right to have your personal information deleted in certain specific circumstances.</p>
          </li>
          <li>
            <p>You have the right to object to the processing of your personal information by us as well as the right to request us to restrict the processing of your personal information, both in certain specific circumstances.</p>
          </li>
          <li>
            <p>You have the right to ask us not to process your personal information for marketing purposes. You can exercise the right at any time (including after giving consent to marketing) by contacting us.</p>
          </li>
          <li>
            <p>Where you have provided consent, you can withdraw your consent at any time by contacting us.</p>
          </li>
          <li>
            <p>You have the right to data portability in certain circumstances.</p>
          </li>
        </ol>
      </li>
    </ol>
    <h3>8. Agile CIC details</h3>
    <ol>
      <li>
        <p>&nbsp;This website is owned and operated by Agile CIC</p>
      </li>
      <li>
        <p>&nbsp;We are registered in Scotland under registration number SC529471, and our registered office is at PO Box Agile, The Hub, Pacific Quay, Pacific Dr, City of Glasgow, G51 1EA</p>
      </li>
      <li>
        <p>&nbsp;Our principal place of business is at PO Box Agile, The Hub, Pacific Quay, Pacific Drive, City of Glasgow, G51 1EA.</p>
      </li>
      <li>
        <p>&nbsp;You can contact us:</p>
        <ol className="ordered-letters">
          <li>
            <p>by post, to the postal address given above;</p>
          </li>
          <li>
            <p>&nbsp;by using our website contact form;</p>
          </li>
          <li>
            <p>by email, using info@agilecic.com</p>
          </li>
        </ol>
      </li>
    </ol>

    <h3>9. Queries in relation to data protection</h3>
    <ol>
      <li>Any queries regarding how we use your data can be directed to Agile CIC,
        <ol className="ordered-letters">
          <li>&nbsp;by post, to the postal address given above;</li>
          <li>&nbsp;by email, using info@agilecic.com</li>
        </ol>
      </li>
    </ol>
  </div>
);

export default dataCollectionPolicy;
