/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-equals-spacing */
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import actifyIcon from '../../resources/img/actify_icon.svg';
import walkingMan from '../../resources/img/walking_man_prac.svg'
import walkingWoman from '../../resources/img/walking_woman.svg'

class UserWalkthroughPageOne extends Component {


  render() {
    const { nextPage, existingUserSelected, existingUser, skipWalkthrough } = this.props;
    let checked = localStorage.getItem("modal-show-again") === null ? false : localStorage.getItem("modal-show-again") === "true"
    const handleShowAgain = () => {
      localStorage.setItem("modal-show-again", !checked);
      checked = !checked
      console.log(localStorage.getItem('modal-show-again'))

    }

    return (
      <div className='walkthrough'>

        <div className='top-section'>
          <img id='logo' src={actifyIcon} alt="Actify Logo" height='75px' />
          <div style={{ width: '55%', textAlign: 'center', margin: '0 auto' }}>
            <p className='heading'> Welcome to the Actify platform - let's get active!</p>
          </div>
        </div>

        <p className='subheading'>
          Curious about how Actify works? Let us take you on a quick tour of our latest features.
        </p>

        <div className='bottom-section'>

          <p className='subheading' style={{ color: '#2D6773', marginTop: '2em' }}>
            Have you used Actify before?
          </p>

          <div className={existingUser === false ? "option selected" : "option"} onClick={() => existingUserSelected(false)}>
            <img src={walkingMan} alt="Walking Man" height='60px' />
            <p style={{ paddingTop: '30px' }}> I'm new to using Actify! </p>
          </div>

          <div className={existingUser === true ? "option selected" : "option"} onClick={() => existingUserSelected(true)}>
            <img src={walkingWoman} alt="Walking Woman" height='60px' style={{ marginLeft: '3em' }} />
            <p style={{ paddingTop: '20px' }}>
              I've used Actify before but would like <br /> to hear about new features
            </p>
          </div>

          <div className='page-one-buttons'>
            <Button className='walkthrough-button skip' onClick={() => skipWalkthrough()}>
              Skip
            </Button>
            <Button className='walkthrough-button' onClick={() => nextPage()}>
              Next →
            </Button>
          </div>

          <div className="show-again">
            <label>
              <input type="checkbox" value={checked} defaultChecked={checked} onChange={handleShowAgain} />
              <span style={{ marginLeft: '5px' }}>Do not show this again</span>
            </label>
          </div>
        </div>

      </div>
    );
  }
}

export default withRouter(UserWalkthroughPageOne);
