/* eslint-disable react/self-closing-comp */
/* eslint-disable import/prefer-default-export */
import React from "react";
import walkthroughTextBubble from '../../resources/img/walkthrough-text-bubble.svg';
import './InfoBox.css';

const InfoBox = (props) => {
  return(
    <div className="info-container" >
      <img className="info-box-image" style={props.imgStyle} src={walkthroughTextBubble} alt="message container" />
      <h1 style={props.headerStyle}>{props.helpHeader}</h1>
      <span style={props.bodyStyle}>{props.helpText}</span>
    </div>
  );
};

export default InfoBox;
