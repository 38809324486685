import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import ItemTypes from './ItemTypes';
import './WordSpace.css';

function getStyle(borderColor) {
  return {
    borderColor,
  };
}

const boxTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem());
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

class WordSpace extends Component {
  render() {
    const {
      isOver,
      connectDropTarget,
      canDrop,
      lastDroppedItem,
    } = this.props;
    const isActive = canDrop && isOver;

    let borderColor = 'black';
    if (isActive) {
      borderColor = '#10b5c6';
    }
    return connectDropTarget(<div className="word-space" style={getStyle(borderColor)}>
      {lastDroppedItem}
    </div>);
  }
}

WordSpace.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
};

export default DropTarget(ItemTypes.BOX, boxTarget, collect)(WordSpace);
