/* eslint-disable react/self-closing-comp */
/* eslint-disable import/prefer-default-export */
import React from "react";
import './NavbarLogo.css';
import icon from '../../resources/img/actify_icon.svg'

export function Logo(){
  return(
    <div className="nav-logo-wrapper">
      <div className="nav-logo-image">
        <a href="/"><img src={icon} alt="logo" width="29" height="29" /></a>
      </div>
    </div>
  )
}
