import { connect } from 'react-redux';
import { setUser, addHub, setActiveProfileTab, setConversation } from '../../store/actions';
import MyProfile from './MyProfile';

const mapStateToProps = state => ({
  user: state.user,
  activeProfileTab: state.activeProfileTab,
  conversationToShow: state.conversationToShow,
});

const mapDispatchToProps = dispatch => ({
  onGetUser: (user) => {
    dispatch(setUser(user));
  },
  setActiveProfileTab: (activeTab) => {
    dispatch(setActiveProfileTab(activeTab));
  },
  addHub: (hub) => {
    dispatch(addHub(hub));
  },
  setConversation: (conversationId) => {
    dispatch(setConversation(conversationId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
