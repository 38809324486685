import React, { Component } from 'react';
import { Button, Modal, FormGroup } from 'react-bootstrap';
import CreateQuizQuestion from './CreateQuizQuestion';
import { shuffle } from '../../../lib/utilities';
import config from '../../../constant/config';
import CheckboxQuestion from './CheckboxQuestion';
import './QuizBuilder.css';
import RadioQuestion from './RadioQuestion';
import FreeTextQiestion from './FreeTextQiestion';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const fetchQuestionTypes = async () => {
  const res = await fetch(`${config.apiURL}/quiz/questionTypes`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch question types');
  }
  const body = await res.json();
  const questionTypes = body.map(qtype =>
    <option key={qtype.id} value={qtype.id}>{qtype.description}</option>);
  return questionTypes;
};

class QuizBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionTypes: [],
      questionToEdit: null,
      questions: this.props.questions,
    };
    this.closeModal = this.closeModal.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.onQuizQuestionsReorder = this.onQuizQuestionsReorder.bind(this);
  }

  componentWillMount() {
    this.getQuestionTypes();
  }

  onCreateQuestion() {
    this.setState({ showModal: true, questionToEdit: null, questionToEditContent: null });
  }

  async getQuestionTypes() {
    try {
      const questionTypes = await fetchQuestionTypes();
      this.setState({
        questionTypes,
      });
    } catch (err) {
      console.error(err.message);
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  addQuestion(question) {
    this.setState({
      questions: [...this.state.questions, question],
    });
    this.props.addQuizQuestion(question);
    this.closeModal();
  }

  onEditQuestion(index) {
    this.setState({ showModal: true, questionToEdit: index,  questionToEditContent: this.props.questions[index]});
  }

  editQuestion(index, question) {
    this.props.editQuizQuestion(index, question);
    this.closeModal();
    if (index < 0 || index >= this.state.questions.length) {
      console.error("Invalid index. Cannot replace question.");
      return;
    }

    const updatedQuestions = [...this.state.questions];

    updatedQuestions[index] = question;

    this.setState({
      questions: updatedQuestions,
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedQuestions = Array.from(this.state.questions);

    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);
    this.onQuizQuestionsReorder(reorderedQuestions);

    
  };

  onQuizQuestionsReorder(reorderedQuestions)
  {
    this.setState({ questions: reorderedQuestions });
    this.props.onQuizQuestionsReorder(reorderedQuestions);
  }

  renderQuestions() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {this.state.questions.map((question, index) => (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {question.questionType === config.questionTypes.CHECKBOX ? (
                        <CheckboxQuestion
                          questionNumber={index + 1}
                          questionText={question.questionText || question.text}
                          answerLabel="Select all that apply:"
                          possibleAnswers={question.possibleAnswers}
                          disabled
                          editQuestion={() => this.onEditQuestion(index)}
                          removeQuestion={() => this.props.removeQuizQuestion(index)}
                          deletable
                        />
                      ) : question.questionType === config.questionTypes.RADIO ? (
                        <RadioQuestion
                          questionNumber={index + 1}
                          questionText={question.questionText || question.text}
                          answerLabel="Select the correct answer below:"
                          possibleAnswers={question.possibleAnswers}
                          disabled
                          editQuestion={() => this.onEditQuestion(index)}
                          removeQuestion={() => this.props.removeQuizQuestion(index)}
                          deletable
                        />
                      ) : question.questionType === config.questionTypes.FREE ? (
                        <FreeTextQiestion
                          disabled
                          questionNumber={index + 1}
                          questionText={question.questionText || question.text}
                          answerLabel="Your answer"
                          editQuestion={() => this.onEditQuestion(index)}
                          removeQuestion={() => this.props.removeQuizQuestion(index)}
                          deletable
                        />
                      ) : question.questionType === config.questionTypes.FILL ? (
                        <div className="checkbox-question-container">
                          <h3 className="question-text-container">
                            <ol start={index + 1}>
                              <li>Fill in the blanks</li>
                            </ol>
                          </h3>
                          <div className="icon-container">
                            <span className="edit-quiz" onClick={() => this.onEditQuestion(index)}>EDIT</span>
                            <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.props.removeQuizQuestion(index)} />
                          </div>
                          <div className="answers-container">
                            <FormGroup className="form" controlId="input" style={{display:"block"}}>
                              {(question.questionText ? question.questionText.split(' ') : question.text.split(' ')).map((word, wordIndex) => (
                                question.editableWords.includes(wordIndex) ? (
                                  <input disabled key={wordIndex} className="blank-input" />
                                ) : (
                                  <div key={wordIndex} className="word-container">{word}</div>
                                )
                              ))}
                            </FormGroup>
                          </div>
                        </div>
                      ) : question.questionType === config.questionTypes.DRAG ? (
                        <div className="checkbox-question-container">
                          <h3 className="question-text-container">
                            <ol start={index + 1}>
                              <li>Drag the words into their correct position</li>
                            </ol>
                          </h3>
                          <div className="icon-container">
                            <span className="edit-quiz" onClick={() => this.onEditQuestion(index)}>EDIT</span>
                            <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.props.removeQuizQuestion(index)} />
                          </div>
                          <div className="answers-container">
                            <FormGroup className="form" controlId="input" style={{display:"block"}}>
                              {(question.questionText ? question.questionText.split(' ') : question.text.split(' ')).map((word, wordIndex) => (
                                question.editableWords.includes(wordIndex) ? (
                                  <div key={wordIndex} className="question-word-space" />
                                ) : (
                                  <div key={wordIndex} className="word-container">{word}</div>
                                )
                              ))}
                              <p className="instruction-text">Draggable words:</p>
                              <div className="words-container">
                                {shuffle(question.editableWords).map(i => (
                                  <div key={i} className="draggable">{(question.questionText ? question.questionText.split(' ') : question.text.split(' '))[i]}</div>
                                ))}
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
  
  render() {
    return (
      <div className="quiz-builder">
        <div className="question-container">
          {this.renderQuestions()}
        </div>
        <Button id="newQuestionButton" className="new-button" onClick={e => this.onCreateQuestion(e)}>Create Quiz</Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          <CreateQuizQuestion
            questionTypes={this.state.questionTypes}
            onCancel={this.closeModal}
            addQuestion={question => this.addQuestion(question)}
            questionToEdit={this.state.questionToEdit}
            closeModal={this.closeModal}
            editQuestion={(index, question) => this.editQuestion(index, question)}
            questionToEditContent={this.state.questionToEditContent}
          />
        </Modal>
      </div>
    );
  }
}

export default QuizBuilder;

