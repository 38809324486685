import React from 'react';
import {
  Button, Modal, FormGroup, ControlLabel, FormControl,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import config from '../../constant/config';
import './ManageFeaturedHubs.css';

class ManageFeaturedHubs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hub: '',
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.hubOptions && props.hubOptions.length > 0) {
      this.setState({ hub: props.hubOptions[0].props.value });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  renderTableRows() {
    return this.props.featured.map(hub => (
      <tr key={hub.id}>
        <td><Link to={`/${hub.pathName}`} >{hub.name}</Link></td>
        <td>{hub.organisation}</td>
        <td>
          <div className="warning-button-container">
            <Button className="warning-button" onClick={() => this.props.removeFeaturedClick(hub)}>Remove</Button>
          </div>
        </td>

      </tr>
    ));
  }
  render() {
    return (
      <div
        className="manage-featured-container"
        style={{
          marginBottom: (this.props.hubOptions && this.props.hubOptions.length > 0) ? 0 : 30,
        }}
      >
        <h4>Featured</h4>
        <table className="hubs-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Organisation</th>
              <th className="delete-heading">Remove from Featured</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>

        </table>
        { (this.props.hubOptions && this.props.hubOptions.length > 0) &&
          <div className="add-button-container">
            <Button
              className="add-button"
              onClick={() => { this.setState({ showModal: true }); }}
              disabled={this.props.featured.length >= config.featuredHubLimit }
            >
              Add New Featured
            </Button>
          </div>
        }
        { (this.props.featured && this.props.featured.length > config.featuredHubLimit) &&
          <p className="limit-text">You have reached the limit for Featured Hubs</p>
        }
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          <div className="featured-hub-modal">
            <div>
              <h4>Featured Hubs</h4>
            </div>
            <div>
              <FormGroup className="form" controlId="formCategory">
                <ControlLabel>Select Hub to add to featured</ControlLabel>
                <FormControl
                  componentClass="select"
                  placeholder="Select Hub"
                  value={this.state.hub}
                  onChange={e => this.setState({ hub: e.target.value })}
                >
                  {this.props.hubOptions}
                </FormControl>
              </FormGroup>
            </div>
            <div className="button-container">
              <Button className="add-button" onClick={() => this.props.addFeaturedClick(this.state.hub) && this.setState({ showModal: false })} >
                Add
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ManageFeaturedHubs;
