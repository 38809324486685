import { connect } from 'react-redux';
import HubHome from './HubHome';
import { followHub, unfollowHub } from '../../store/actions';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onFollowHub: (hub) => {
    dispatch(followHub(hub));
  },
  onUnfollowHub: (hub) => {
    dispatch(unfollowHub(hub));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HubHome);
