import React, { Component } from 'react';
import { Image, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import config from '../../constant/config';
import Quiz from '../common/quiz/Quiz';
import './UserLearningStats.css';

class UserLearningStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      quiz: null,
      viewQuiz: false,
    };
  }

  onClickMoudle(id) {
    this.props.history.push(`/module/${id}`);
  }

  onClickBack() {
    if (this.state.viewQuiz) {
      this.setState({
        quiz: null,
        viewQuiz: false,
      });
    } else {
      this.props.onClickBack();
    }
  }

  async onViewQuiz(userResult) {
    const res = await fetch(`${config.apiURL}/quiz/${userResult.moduleId}`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status !== 200) {
      console.error('unable to fetch module questions');
    }
    const questions = await res.json();
    this.setState({
      quiz: {
        questions,
        moduleId: userResult.moduleId,
        userResult,
      },
      viewQuiz: true,
    });
  }

  renderTableRows() {
    return this.props.userResults.map((userResult) => {
      const mod = this.props.modules.find(m => m.id === userResult.moduleId);
      return (
        <tr key={mod.id}>
          <td>
            <div className="module-image-container">
              <Image className="module-image" src={`${config.spacesURL}/${mod.logoId}`} />
            </div>
          </td>
          <td><div onClick={() => { this.onClickMoudle(mod.id); }}>{mod.title}</div></td>
          <td>{userResult.requiresAdminReview ? <b>Requires Review</b> : `${userResult.userScore} / ${userResult.questionCount}`}</td>
          <td>
            <Button className="view-button" onClick={() => this.onViewQuiz(userResult)}>{userResult.requiresAdminReview ? 'Review' : 'View'}</Button>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="users-stats-container">
        <div className="user-header" onClick={() => this.props.onUserClick(this.props.user)}>
          <div className="avatar-container">
            <Image className="avatar-image" src={`${config.spacesURL}/${this.props.user.avatarId}`} />
          </div>
          <div className="user-name">{`${this.props.user.firstName} ${this.props.user.surname}`}</div>
        </div>
        <table className="users-table">
          <thead>
            <tr>
              <th />
              <th />
              <th />
              <th><div className="add-user-button-container"><Button onClick={() => this.onClickBack()}>Back</Button></div></th>
            </tr>
          </thead>
          <tbody>
            {this.state.viewQuiz ?
              <div className="quiz-container">
                <Quiz
                  questions={this.state.quiz.questions}
                  moduleId={this.state.quiz.moduleId}
                  reviewResult={this.state.quiz.userResult}
                  userId={this.state.user.id}
                />
              </div> :
              this.renderTableRows()
            }
          </tbody>
        </table>
        {!(this.props.userResults.length > 0) && !this.state.viewQuiz &&
          <div className="no-results">
            No learning module results for {`${this.state.user.firstName} ${this.state.user.surname}`}
          </div>
        }
      </div>
    );
  }
}

export default withRouter(UserLearningStats);
