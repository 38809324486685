import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import hubs from '../../resources/img/hubs.svg'

class UserWalkthroughPageFive extends Component {

render() {
    const { nextPage, previousPage } = this.props;

    return (
        <div className = 'walkthrough'>
            <div className='top-section'>
                <div style={{width:'55%', textAlign: 'center', margin: '0 auto'}}>
                    <p className='heading'> Hubs</p>
                </div>
                <div>
                    <p className='subheading'>
                        A Hub is an organisation's own space on the platform. They add videos, pdfs, text and other
                        information here. This is where you can see their resources and connect with other Hub members.
                        Follow a Hub by clicking in the top right corner.
                    </p>
                    <img src={hubs} alt="Hubs" className="walkthrough-img"/>
                </div>
            </div>

            <div className='navigation'>
                <Button className='back-button' onClick={() => previousPage()}>
                    ← Back
                </Button>

                <Button className='next-button' onClick={() => nextPage()}>
                    Next →
                </Button>
            </div>
        </div>
    );
  }
}

export default withRouter(UserWalkthroughPageFive);
