import config, { hubAccessLevels } from '../constant/config';

export const getAccessLevelForHub = async (hubId) => {
  const res = await fetch(`${config.apiURL}/hub/${hubId}/access`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status !== 200) {
    throw new Error('failed to fetch access level for hub');
  }
  const level = await res.json();
  return level;
};

const auth = {
  isAuthenticated: async () => {
    const res = await fetch(`${config.apiURL}/user/status`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    return res.status === 200;
  },
  isAuthenticatedHubFollower: async (hubId) => {
    const accessLevel = await getAccessLevelForHub(hubId);
    return (accessLevel >= hubAccessLevels.HUB_FOLLOWER);
  },
  isAuthenticatedHubMember: async (hubId) => {
    const accessLevel = await getAccessLevelForHub(hubId);
    return (accessLevel >= hubAccessLevels.HUB_MEMBER);
  },
  isAuthenticatedHubAdmin: async (hubId) => {
    const accessLevel = await getAccessLevelForHub(hubId);
    return (accessLevel >= hubAccessLevels.HUB_ADMIN);
  },
  isAuthenticatedHubOwner: async (hubId) => {
    const accessLevel = await getAccessLevelForHub(hubId);
    return (accessLevel >= hubAccessLevels.HUB_OWNER);
  },
  isAuthenticatedSiteOwner: async () => {
    const res = await fetch(`${config.apiURL}/user/siteOwner`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status === 200) {
      return true;
    }
    return false;
  },
};

export default auth;
