import React, { Component } from 'react';
import {
  Button,
  FormGroup,
  FormControl,
  HelpBlock,
} from 'react-bootstrap';
import moment from 'moment';
import config from '../../constant/config';
import './Conversation.css';

const setRead = async (conversationId) => {
  const res = await fetch(`${config.apiURL}/message/read/${conversationId}`, {
    method: 'PUT',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status !== 200) {
    throw new Error('unable to update message read times');
  }
};

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestMessageIndex: 0,
      validContent: true,
      messageContent: '',
    };
  }
  componentDidMount() {
    this.scrollToBottom();
    this.readMessages();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  async readMessages() {
    try {
      await setRead(this.props.conversation.id);
    } catch (err) {
      console.error(err.message);
    }
  }

  scrollToBottom() {
    const { latestMessageIndex } = this.state;
    this.messagesEnd.scrollIntoView({ behavior: 'instant', block: 'nearest' });
    if (latestMessageIndex !== 0) {
      this.messagesContainer.scrollTop -= -50;
    }
  }

  renderMessages(avatarIamge) {
    const { latestMessageIndex } = this.state;
    const { messages } = this.props;
    let updatedMessageIndex = messages.findIndex(m =>
      (m.readAt === null && m.recipientId === this.props.user.id));
    if (updatedMessageIndex === -1) {
      updatedMessageIndex = messages.length - 1;
    }
    if (updatedMessageIndex !== latestMessageIndex) {
      this.setState({ latestMessageIndex: updatedMessageIndex });
    }
    return messages.map((message, index) => {
      if (index === updatedMessageIndex) {
        return (
          <div className={message.senderId === this.props.user.id ? 'message-container rgt' : 'message-container'} style={{backgroundColor: message.activeRole ? "#D3F5FA" : "#FFEFBC"}}>
            <div className="message-header" ref={(r) => { this.messagesEnd = r; }}>
              <div className="date">{moment(message.createdAt).format('DD-MM-YYYY')}</div>
            </div>
            <div style={{display:"flex"}}>
              {message.senderId === this.props.user.id ? "" : avatarIamge }
              <div  style={{margin: "10px 0px 0px 10px"}}>
                {message.content}
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className={message.senderId === this.props.user.id ? 'message-container rgt' : 'message-container'} style={{backgroundColor: message.activeRole ? "#D3F5FA" : "#FFEFBC"}}>
          <div className="message-header">
            <div className="date">{moment(message.createdAt).format('DD-MM-YYYY')}</div>
          </div>
          <div style={{display:"flex"}}>
            {message.senderId === this.props.user.id ? "" : avatarIamge }
            <div  style={{margin: "10px 0px 0px 10px"}}>
              {message.content}
            </div>
          </div>
        </div>
      );
    });
  }

  async onClickSendMessage(user) {
    const res = await fetch(`${config.apiURL}/message/${user.id}/`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        content: this.state.messageContent,
        activeRole: Boolean(this.props.activeRole),
      }),
    });

    if (res.status === 200) {
      res.json()
        .then((message) => {
          message.activeRole = Boolean(this.props.activeRole);
          message.createdAt = moment().toDate();
          this.setState({
            messageContent: ''
          });
          this.props.addMessage(message);
        });
    }
  }

  render() {
    let avatarIamge = <div className="avatar-image" style={{ minHeight: "50px", minWidth: "50px", borderRadius: "50%", border: "1px solid var(--colour-light-extra)", backgroundImage: `url(${config.spacesURL}/${this.props.conversation.user.avatarId})` }} />
    return (
      <div className="conversation">
        <div className="chat-history-container" ref={(r) => { this.messagesContainer = r; }}>
          {this.renderMessages(avatarIamge)}
        </div>
        <div className="send-message-content">
          <FormGroup className="form" controlId="textContent" validationState={!this.state.validContent ? 'error' : null}>
            <FormControl
              className="form-text-area"
              componentClass="textarea"
              placeholder="Type your message here!"
              value={this.state.messageContent}
              onChange={e => this.setState({ messageContent: e.target.value })}
            />
            {!this.state.validContent && <HelpBlock>Please enter your message</HelpBlock>}
          </FormGroup>
          <Button className='button1' style={{ margin : "17px 10px 20px 10px", borderRadius: "15px", width:'15%'}} onClick={() => this.onClickSendMessage(this.props.conversation.user)} >
            <span style={{fontSize:"22px", fontWeight: "bold"}}>→</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default Conversation;
