import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import config from '../../../constant/config';
import './NotificationsTab.css';
import infoIcon from '../../../resources/img/info-icon.png';
import InfoBox from '../InfoBox';

// const fetchCompletedReviews = async () => {
//   const res = await fetch(`${config.apiURL}/user/completedreviews`, {
//     method: 'GET',
//     credentials: 'include',
//     headers: { 'content-type': 'application/json' },
//   });
//   if (!res.status === 200) {
//     throw new Error('unable to fetch completed reviews');
//   }
//   const completedReviews = await res.json();
//   return completedReviews;
// };

const fetchConversations = async () => {
  const res = await fetch(`${config.apiURL}/message/conversations`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (!res.status === 200) {
    throw new Error('unable to fetch user messages');
  }
  const messages = await res.json();
  return messages;
};

const fetchInvitations = async () => {
  const res = await fetch(`${config.apiURL}/user/hubinvites/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchApprovedHubs = async () => {
  const res = await fetch(`${config.apiURL}/user/approvedhubs/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchPendingReviews = async () => {
  const res = await fetch(`${config.apiURL}/user/pendingAdminReview/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchComments = async () => {
  const res = await fetch(`${config.apiURL}/user/notifications/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body.notifications.comments;
};

class NotificationsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: [],
      invitations: [],
      pendingReviews: [],
      approvedHubs: [],
      comments: [],
      showingInfo : false,
    };
  }

  componentWillMount() {
    this.getNotificationContent();
  }

  async getNotificationContent() {
    const conversations = await fetchConversations();
    const invitations = await fetchInvitations();
    const pendingReviews = await fetchPendingReviews();
    const approvedHubs = await fetchApprovedHubs();
    const comments = await fetchComments();

    this.setState({
      conversations: conversations.sort((a, b) => (moment(a.latestMessage.createdAt).isAfter(moment(b.latestMessage.createdAt)) ? -1 : 1)),
      invitations,
      pendingReviews,
      approvedHubs,
      comments
    });
  }

  async onHubInvitationClick(id, accepted) {
    const res = await fetch(`${config.apiURL}/user/hubinvites`, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id,
        accepted,
      }),
    });
    if (res.status === 200) {
      const invitations = await fetchInvitations();
      this.setState({ invitations });
      const body = await res.json();
      if (body.accepted && body.hub) {
        this.props.addHub(body.hub);
      }
    }
  }

  async onViewQuiz(review) {
    try {
      const res = await fetch(`${config.apiURL}/quiz/${review.hubId}/${review.userId}`, {
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
      });
      if (res.status !== 200) {
        throw new Error('unable to fetch results');
      }
      const userResults = await res.json();
      const userResult = userResults.find(r => r.moduleId === review.moduleId);
      this.props.history.push(`/module/${review.moduleId}`, { userResult, userId: review.userId });
    } catch (err) {
      console.error(err.message);
    }
  }

  async onDismissHub(id) {
    if (id) {
      // make a request which will delete the notification
      try {
        const res = await fetch(`${config.apiURL}/user/notifications/clearhub/${id}`, {
          method: 'GET',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
        });
        if (res.status !== 200) {
          await res.json();
        }
      } catch (err) {
        console.log(err);
      }
      await this.getNotificationContent();
    }
  }

  async onViewModule(moduleId) {
    try {
      this.props.history.push(`/module/${moduleId}`);
    } catch (err) {
      console.error(err.message);
    }
  }

  renderMessages() {
    const { conversations } = this.state;

    let messages = conversations.filter(message => {
      // console.log(message)
      // message has been read do not show
      if (!!message.latestMessage.readAt) {
        return false;
      }
      // message belongs to user do not show
      if (message.latestMessage.senderId === this.props.user.id) {
        return false;
      }
      return true;
    });

    messages = messages.map(message => (
      <div key={message.id} className='message'>
        <div
          className='message-picture'
          style={{ backgroundImage: `url(${config.spacesURL}/${message.user.avatarId}.thumbnail)` }}
        />
        <div className='message-content'>
          <span><b>{message.user.firstName} {message.user.surname}</b></span>
          <br />
          <span>{message.latestMessage.content.substring(0, 75) + (message.latestMessage.content.length > 75 ? '...' : '')}</span>
        </div>
        <div className='message-date'>
          {moment(message.latestMessage.createdAt).format('Do MMM YYYY')}
        </div>
        <button className='message-button' onClick={() => { this.props.showConversation(message.id); this.props.changeTab(4);}}>
          View Message
        </button>
      </div>
    ));

    return (
      <div className='messages'>
        <span className='section-title'>Messages</span>
        {messages.length > 0 ?
          messages
          :
          <div className='no-notifications'>
            You have no new message notifications!
          </div>
        }
      </div>
    )
  }

  renderPendingQuizReviews() {
    const { pendingReviews } = this.state;

    const reviews = pendingReviews.map(review => (
      <div key={review.id} className='invite'>
        <div
          className='hub-picture'
          style={{ backgroundImage: `url(${config.spacesURL}/${review.avatarId}.thumbnail)` }}
        />
        <div className='hub-name'>
          <span><b>{review.firstName} {review.surname}</b></span>
          <br />
          <span><i>{review.moduleTitle}</i></span>
        </div>
        <button className='hub-button' onClick={() => this.onViewQuiz(review)}>
          Review
        </button>
      </div>
    ));


    return (
      <div className='invites'>
        <span className='section-title'>Pending Quiz Reviews</span>
        {reviews.length > 0 ?
          <div className='invites-container'>
            {reviews}
          </div>
          :
          <div className='no-notifications'>
            You have no quizzes to review!
          </div>
        }
      </div>
    )
  }

  renderHubInvites() {
    const { invitations } = this.state;

    const invites = invitations.map(invite => (
      <div key={invite.id} className='invite'>
        <div className='invite-inner'>
          <div
            className='hub-picture'
            style={{ backgroundImage: `url(${config.spacesURL}/${invite.hub.logoId}.thumbnail)` }}
          />
          <div className='hub-name'>
            {invite.hub.name}
          </div>
          <button className='hub-button' onClick={() => this.props.history.push(invite.hub.pathName)}>
            View Hub
          </button>
        </div>
        <div className='hub-accept-decline'>
          <button className='hub-button' onClick={() => this.onHubInvitationClick(invite.id, true)}>
            Accept
          </button>
          <button className='hub-button decline' onClick={() => this.onHubInvitationClick(invite.id, false)}>
            Decline
          </button>
        </div>
      </div>
    ));

    return (
      <div className='invites'>
        <span className='section-title'>Hub Invitations</span>
        {invites.length > 0 ?
          <div className='invites-container'>
            {invites}
          </div>
          :
          <div className='no-notifications'>
            You have no hub invitations!
          </div>
        }
      </div>
    )
  }

  renderApprovedHubs() {
    const { approvedHubs } = this.state;

    const approvals = approvedHubs.map(approval => (
      <div key={approval.id} className='invite'>
        <div className='invite-inner'>
          <div
            className='hub-picture'
            style={{ backgroundImage: `url(${config.spacesURL}/${approval.hub.logoId}.thumbnail)` }}
          />
          <div className='hub-name'>
            {approval.hub.name}
          </div>
          <button className='hub-button' onClick={() => this.props.history.push(approval.hub.pathName)}>
            View Hub
          </button>
          <button className='hub-button'onClick={() => this.onDismissHub(approval.id)}>
            Dismiss
          </button>
        </div>
      </div>
    ));

    return (
      <div className='invites'>
        {approvals.length > 0 ?
          <div>
            <span className='section-title'>Hub Approvals</span>
            <div className='invites-container'>
              {approvals}
            </div>
          </div>
          :
          <div />
        }
      </div>
    )

  }

  renderComments() {
    const { comments } = this.state;

    const commentNotifications = comments.map(comment => (
      <div key={comment.id} className='message'>
        <div className='message-content'>
          <span><b>{comment.module.title}</b></span>
          <br />
          <span>There is a new comment on your module: "{comment.comment.content.substring(0, 75) + (comment.comment.content.length > 75 ? '...' : '')}"</span>
        </div>
        <div className='message-date'>
          {moment(comment.comment.createdAt).format('Do MMM YYYY')}
        </div>
        <button className='message-button' onClick={() => this.onViewModule(comment.moduleId)}>
          View Module
        </button>
      </div>
    ));


    return (
      <div className='messages'>
        {/* {console.log(comments)} */}
        {commentNotifications.length > 0 ?
          <div>
            <span className='section-title'>Comments</span>
              {commentNotifications}
          </div>
          :
          <div />
        }
      </div>
    )
  }

  render() {
    const handleEnter = () => {
      this.setState({showingInfo : true})
    };

    const handleLeave = () => {
      this.setState({showingInfo : false})
    };

    return (
      <div className="notification-tab-container">
        <span className='title'>
          Notifications
          <img onMouseOver={()=>handleEnter()} onMouseOut={()=>handleLeave()} className='notification-info-icon' src={infoIcon} alt='help icon' />
        </span>
        { this.state.showingInfo
          ?
          <div className='notification-info-box' style={{marginLeft:'20px'}}>
            <InfoBox
              headerStyle={{fontSize: '17px', top: '10px'}}
              bodyStyle={{fontSize: '14px', top: '65px'}}
              imgStyle={{width: '400px'}}
              helpHeader="What are notifications, and where do I find them?"
              helpText="Notifications appear and can be accessed by clicking on the bell icon on the right of the top bar next to the profile icon. There will be a red dot on the bell when you have an unread notification.
              Notifications will appear for unread messages, quiz results, hub requests and much more!"
            />
          </div>
          :
          null }
        {this.renderMessages()}
        {this.renderPendingQuizReviews()}
        {this.renderHubInvites()}
        {this.renderApprovedHubs()}
        {this.renderComments()}
      </div>
    );
  }

}
export default withRouter(NotificationsTab);
