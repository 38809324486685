import React from 'react';
import Section from '../common/Section';
import CarouselSection from '../common/CarouselSection';
import ThemePills from '../common/ThemePills';
import config from '../../constant/config';
import checkStatus from '../common/checkStatus';
import loadingAnimation from '../../resources/img/loading_animation.gif'
import './Home.css';
import './Explore.css';

class Explore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredModules: [],
      featuredHubs: [],
      customModules: [],
      customTitle: '',
      allModules: [],
      trendingModules: [],
      trendingHubs: [],
      latestModules: [],
      recommendedModules: [],
      isInfiniteLoading: false,
      allModulePageSize: 20,
      maxModulesReached: false,
      showModal: false,
      personalUse: false,
    };
  }

  componentWillMount() {
    if (this.props.user) {
      this.getRecommendedModules();
    }
    this.getModules();
    this.getFeaturedModules();
    this.getCustomTitle();
    this.getCustomModules();
    this.getFeaturedHubs();
    this.getTrendingModules();
    this.getLatestModules();
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (this.state.isInfiniteLoading || this.state.maxModulesReached) {
        return;
      }
      this.handleInfiniteLoad();
    });
  }

  async getModules() {
    try {
      const res = await fetch(`${config.apiURL}/module/`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          startIndex: this.state.allModules.length,
          endIndex: this.state.allModules.length + this.state.allModulePageSize,
        }),
      });
      if (res.status === 200) {
        const allModules = await res.json();
        const currentModules = this.state.allModules;
        currentModules.push(...allModules);
        await this.setState({
          allModules: currentModules,
          maxModulesReached: !(allModules.length > 0),
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getRecommendedModules() {
    try {
      const res = await fetch(`${config.apiURL}/module/recommended`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const recommendedModules = await res.json();
        this.setState({ recommendedModules });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getFeaturedModules() {
    try {
      const res = await fetch(`${config.apiURL}/module/featured`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const featuredModules = await res.json();
        this.setState({ featuredModules });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getCustomModules() {
    try {
      const res = await fetch(`${config.apiURL}/custom`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const custom = await res.json();
        this.setState({ customTitle: custom.title });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getCustomTitle() {
    try {
      const res = await fetch(`${config.apiURL}/module/custom`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const customModules = await res.json();
        this.setState({ customModules });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getFeaturedHubs() {
    try {
      const res = await fetch(`${config.apiURL}/hub/featured`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const featuredHubs = await res.json();
        this.setState({ featuredHubs });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getTrendingModules() {
    try {
      const res = await fetch(`${config.apiURL}/module/trending`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const trendingModules = await res.json();
        this.setState({ trendingModules });
        await this.getTrendingHubs();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getLatestModules() {
    try {
      const res = await fetch(`${config.apiURL}/module/latest`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const latestModules = await res.json();
        this.setState({ latestModules });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  async handleInfiniteLoad() {
    if (this.state.isInfiniteLoading) {
      return;
    }
    const h = document.documentElement;
    const b = document.body;
    const st = 'scrollTop';
    const sh = 'scrollHeight';
    const currentScrollPercent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
    if (currentScrollPercent > 85) {
      this.setState({ isInfiniteLoading: true });
      await this.getModules();
      this.setState({
        isInfiniteLoading: false,
      });
    }
  }

  async getTrendingHubs() {
    const ids = this.state.trendingModules.map(x => x.hubId);

    try {
      const res = await fetch(`${config.apiURL}/hub/trending`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          ids: ids,
        }),
      });
      if (res.status === 200) {
        const trendingHubs = await res.json();
        this.setState({ trendingHubs: trendingHubs });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    let { recommendedModules } = this.state;
    const { featuredModules } = this.state;
    if (recommendedModules.length < 8) {
      recommendedModules = [...recommendedModules, ...featuredModules].slice(0, 8);
    }
    return (
      <div >
        <div>
          <div className="home-cont" >
            <div className='what-we-do'>
              <div className="page-heading-container">
                <span className='top-text'>
                  EXPLORE
                </span>

                <div className="page-title-container">
                  <h1 className="page-title"> In the Spotlight!</h1>
                  <img className='explore-visual-mobile' alt="" />
                </div>


                <p className="page-description">Check out our featured modules and hubs or search popular themes to find content from a range of sports and physical activity organisations.</p>
              </div>
              <div className='image-container'>
                <img className="explore-visual" alt="" />
              </div>
            </div>
          </div>

          <div className='popular-theme-container-personal'>
            <p className='theme-text-sub-heading'>What's Trending?</p>
            <p className='theme-text'>Each month, we highlight a specific theme or topic that's grabbing attention within the sector and showcase the most relevant modules. Click on each one to explore.</p>
            <div className="home-containers">
              <div className="flex-container theme-pills-container">
                <ThemePills />
              </div>
            </div>
          </div>

          {this.state.featuredModules.length > 0 &&
            <div className="home-containers">
              <CarouselSection
                id="featureModules"
                context="modules"
                tiles={this.state.featuredModules}
                title="Featured Modules"
                infinite={true}
              />
            </div>}
          {this.state.featuredHubs.length > 0 &&
            <div className="home-containers second">
              <CarouselSection
                id="featuredHubs"
                context="hubs"
                tiles={this.state.featuredHubs}
                title="Featured Hubs"
                infinite={true}
                viewAllLink="/result/all"
                viewAllText="View all"
              />
            </div>}

          {this.state.trendingModules.length > 0 &&
            <div className="home-containers">
              <CarouselSection
                id="popularModules"
                context="modules"
                tiles={this.state.trendingModules}
                title="Popular Modules This Month"
                infinite={true}
              />
            </div>
          }
          {/* {this.state.trendingHubs.length > 0 &&
            <div className="home-containers second">
              <CarouselSection
                id="popularHubs"
                context="hubs"
                tiles={this.state.trendingHubs}
                title="Popular Hubs This Month"
                infinite={true}
                viewAllLink="/result/all"
                viewAllText="View all Hubs"
              />
            </div>} */}


          {this.state.allModules.length > 0 &&
            <div className="home-containers">
              <Section
                ref={(c) => { this.allSection = c; }}
                id="allModules"
                context="modules"
                tiles={this.state.allModules}
                title="All Modules"
                small={true}
              />

            </div>
          }
          {this.state.isInfiniteLoading &&
            <div className="">
              <img className='gif' src={loadingAnimation} alt="loading" />
            </div>
          }
        </div>
      </div>
    );
  }
}

export default checkStatus(Explore);
