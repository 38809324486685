import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import UserWalkthroughPageOne from './UserWalkthroughPageOne';
import UserWalkthroughPageTwo from './UserWalkthroughPageTwo';
import UserWalkthroughPageThree from './UserWalkthroughPageThree';
import UserWalkthroughPageFour from './UserWalkthroughPageFour';
import UserWalkthroughPageFive from './UserWalkthroughPageFive';
import UserWalkthroughPageSix from './UserWalkthroughPageSix';
import UserWalkthroughPageSeven from './UserWalkthroughPageSeven';

class UserWalkthrough extends Component {
    constructor(props) {
      super(props);

      this.state = {
        currentPage: 1,
        existingUserSelected: null,
      };
    }

    async nextPage() {
        const { currentPage, existingUserSelected } = this.state;

        if ((currentPage < 7 && !existingUserSelected) || (currentPage < 5 && existingUserSelected)) {
            this.setState({ currentPage: currentPage + 1 });
        }
        return;
    }

    async previousPage() {
        const { currentPage } = this.state;

        if (currentPage > 1) {
            this.setState({ currentPage: currentPage - 1 });
            return;
        }
    }

    async skipWalkthrough() {
        const { existingUserSelected } = this.state;

        if (existingUserSelected) {
            this.setState({ currentPage: 5 });
            return;
        }

        else {
            this.setState({ currentPage: 7 });
            return;
        }
    }

    renderWalkthroughPageOne() {
        const { existingUserSelected } = this.state;

        return(
            <UserWalkthroughPageOne
                nextPage={() => this.nextPage()}
                existingUserSelected={(v) => this.setState({ existingUserSelected: v })}
                existingUser = { existingUserSelected }
                skipWalkthrough={() => this.skipWalkthrough()}
            />
        );
    }

    renderWalkthroughPageTwo() {
        const { existingUserSelected } = this.state;

        return(
            <UserWalkthroughPageTwo
                existingUserSelected={ existingUserSelected }
                nextPage={() => this.nextPage()}
                previousPage={() => this.previousPage()}
            />
        );
    }

    renderWalkthroughPageThree() {
        const { existingUserSelected } = this.state;

        return(
            <UserWalkthroughPageThree
                existingUserSelected={ existingUserSelected }
                nextPage={() => this.nextPage()}
                previousPage={() => this.previousPage()}
            />
        );
    }

    renderWalkthroughPageFour() {
        const { existingUserSelected } = this.state;

        return(
            <UserWalkthroughPageFour
                existingUserSelected={ existingUserSelected }
                nextPage={() => this.nextPage()}
                previousPage={() => this.previousPage()}
            />
        );
    }

    renderWalkthroughPageFive() {
        return(
            <UserWalkthroughPageFive
                nextPage={() => this.nextPage()}
                previousPage={() => this.previousPage()}
            />
        );
    }

    renderWalkthroughPageSix() {
        return(
            <UserWalkthroughPageSix
                nextPage={() => this.nextPage()}
                previousPage={() => this.previousPage()}
            />
        );
    }

    renderWalkthroughPageSeven() {
        return(
            <UserWalkthroughPageSeven />
        );
    }

    render() {
        const { currentPage, existingUserSelected, walkthroughSkipped } = this.state;

        return (
            <div>
                {currentPage === 1 && this.renderWalkthroughPageOne()}
                {currentPage === 2 && this.renderWalkthroughPageTwo()}
                {currentPage === 3 && this.renderWalkthroughPageThree()}
                {currentPage === 4 && this.renderWalkthroughPageFour()}
                {currentPage === 5 && !existingUserSelected && this.renderWalkthroughPageFive()}
                {currentPage === 6 && !existingUserSelected && this.renderWalkthroughPageSix()}
                {currentPage === 7 && !existingUserSelected && this.renderWalkthroughPageSeven()}
                {currentPage === 5 && existingUserSelected && this.renderWalkthroughPageSeven()}
            </div>
        );
    }
}

export default withRouter(UserWalkthrough);
