import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { BarChart, Bar, Tooltip, XAxis } from 'recharts';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import config from '../../constant/config';
import './AnalyticsTab.css';

const fetchModuleData = async (hubId) => {
  const res = await fetch(`${config.apiURL}/analytics/${hubId}/modules`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchSessionData = async (hubId) => {
  const res = await fetch(`${config.apiURL}/analytics/${hubId}/sessions`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

const fetchPlanData = async (hubId) => {
  const res = await fetch(`${config.apiURL}/analytics/${hubId}/plans`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  const body = await res.json();
  return body;
};

class AnalyticsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleData: null,
      planData: null,
      sessionData: null,
    };
  }

  componentWillMount() {
    this.getModuleData();
    this.getSessionData();
    this.getPlanData();
  }

  async getModuleData() {
    const moduleData = await fetchModuleData(this.props.hubId);
    this.setState({
      moduleData,
    });
  }

  async getSessionData() {
    const sessionData = await fetchSessionData(this.props.hubId);
    this.setState({
      sessionData,
    });
  }

  async getPlanData() {
    const planData = await fetchPlanData(this.props.hubId);
    this.setState({
      planData,
    });
  }

  getFeedbackColumn(row) {
    if (row.feedback) {
      return <Button className="view-button" onClick={() => this.props.history.push(`/session/${row.id}/feedback`)}>View</Button>;
    }
    const now = moment();
    const session = moment(row.sessionDate);
    return moment(now).isAfter(session, 'day') ? 'No' : '-';
  }

  renderModules() {
    const { moduleData } = this.state;
    const barData = moduleData.filter(m => (m.viewCount > 0 && m.bookmarkCount > 0)).map((row) => {
      if (!(row.viewCount > 0)) {
        return ({
          title: row.title,
          Bookmarks: row.bookmarkCount,
        });
      } else if (!(row.bookmarkCount > 0)) {
        return ({
          title: row.title,
          Views: row.viewCount,
        });
      }
      return ({
        title: row.title,
        Views: row.viewCount,
        Bookmarks: row.bookmarkCount,
      });
    });
    barData.sort((a, b) => ((a.viewCount + a.bookmarkCount) - (b.viewCount + b.bookmarkCount)));
    const sortedBarData = barData.slice(0, 10);
    return (
      <Row className="block-container">
        <Col className="analytics-block">
          <Row>
            <Col sm={6}>
              <div className="block-title">Modules</div>
              <div className="module-totals">
                <div className="total-count">{
                  moduleData.reduce((a, b) => (a + b.viewCount), 0)
                }
                </div>
                <span className="total-title">TOTAL VIEWS</span>
              </div>
            </Col>
            <Col sm={6}>
              <div className="graphs-container">
                {!(barData.length > 0) &&
                  <div className="module-totals">
                    <div className="total-count">{
                      moduleData.reduce((a, b) => (a + b.bookmarkCount), 0)
                    }
                    </div>
                    <span className="total-title">BOOKMARKS</span>
                  </div>}
                {sortedBarData.length > 0 &&
                  <BarChart
                    className="bar-chart"
                    width={300}
                    height={220}
                    barCategoryGap={10}
                    data={sortedBarData}
                  >
                    <XAxis dataKey="title" hide />
                    <Tooltip label="title" />
                    <Bar dataKey="Views" fill="#56c4cd" />
                    <Bar dataKey="Bookmarks" fill="#fe4846" />
                  </BarChart>}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="analytics-block">
          <Row className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Views</th>
                  <th>Bookmarks</th>
                  <th>Plans Part Of</th>
                </tr>
              </thead>
              <tbody>
                {moduleData.map(row => (
                  <tr key={row.id}>
                    <td>{row.title}</td>
                    <td>{row.viewCount}</td>
                    <td>{row.bookmarkCount}</td>
                    <td>{row.planCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </Col>
      </Row>
    );
  }

  renderSessions() {
    const { sessionData } = this.state;
    return (
      <Row className="block-container">
        <Col className="analytics-block">
          <Row>
            <Col sm={6}>
              <div className="block-title">Sessions</div>
              <div className="session-totals">
                <div className="total-count">{sessionData.length}</div>
                <span className="total-title">TOTAL SESSIONS</span>
              </div>
            </Col>
            <Col sm={6}>
              <div className="graphs-container">
                <div className="session-totals">
                  <div className="total-count">{sessionData.reduce((a, b) => {
                    const delivered = !!b.feedback || moment(moment()).isAfter(moment(b.sessionDate), 'day');
                    return a + (delivered ? 1 : 0);
                  }, 0)}
                  </div>
                  <span className="total-title">TOTAL DELIVERED</span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="analytics-block">
          <Row className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Session Date</th>
                  <th>Feedback Delivered</th>
                </tr>
              </thead>
              <tbody>
                {sessionData.map(row => (
                  <tr key={row.id}>
                    <td>{row.planTitle}</td>
                    <td>{moment(row.sessionDate).format('Do MMM YYYY')}</td>
                    <td>{this.getFeedbackColumn(row)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </Col>
      </Row>
    );
  }

  renderPlans() {
    const { planData } = this.state;
    const plansWithoutSession = planData.reduce((a, b) => (a + (b.sessionCount < 1 ? 1 : 0)), 0);
    return (
      <Row className="block-container">
        <Col className="analytics-block">
          <Row>
            <Col sm={6}>
              <div className="block-title">Plans</div>
              <div className="plan-totals">
                <div className="total-count">{planData.length}</div>
                <span className="total-title">TOTAL PLANS</span>
              </div>
            </Col>
            <Col sm={6}>
              <div className="graphs-container">
                <div className="plan-totals">
                  <div className="total-count">
                    {plansWithoutSession}
                  </div>
                  <span className="total-title">PLAN{plansWithoutSession !== 1 ? 'S' : null} WITHOUT A SESSION</span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="analytics-block">
          <Row className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>No. Modules</th>
                  <th>Sessions part of</th>
                </tr>
              </thead>
              <tbody>
                {planData.map(row => (
                  <tr key={row.id}>
                    <td>{row.planTitle}</td>
                    <td>{row.moduleCount}</td>
                    <td>{row.sessionCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    const { moduleData, sessionData, planData } = this.state;
    return (
      // <div className="analytics-tab-container">
      //   {moduleData && this.renderModules()}
      //   {sessionData && this.renderSessions()}
      //   {planData && this.renderPlans()}
      // </div>
      <div className='analytics-placeholder'>
        We're currently updating how we record and show hub analytics data. For details on your hub's analytics please contact support@actify.org.uk
      </div>
    );
  }
}

export default withRouter(AnalyticsTab);
