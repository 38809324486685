import { connect } from 'react-redux';
import Login from './Login';
import { setUser, setQuizResults, toggleLogin } from '../../store/actions';

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onLogin: (user) => {
    dispatch(setUser(user));
    dispatch(toggleLogin(true));
  },
  setQuizResults: (results) => {
    dispatch(setQuizResults(results));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
