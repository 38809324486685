import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './UserWalkthrough.css';
import connect from '../../resources/img/connect.svg'

class UserWalkthroughPageSix extends Component {

render() {
    const { nextPage, previousPage } = this.props;

    return (
        <div className = 'walkthrough'>
            <div className='top-section'>
                <div style={{width:'55%', textAlign: 'center', margin: '0 auto'}}>
                    <p className='heading'> Connect </p>
                </div>
                <div>
                    <p className='subheading'>
                        You can get to know other community members by commenting on module content and
                        sending direct messages to other members about their interests.
                    </p>
                    <img src={connect} alt="Connect" className="walkthrough-img"/>
                </div>
            </div>

            <div className='navigation'>
                <Button className='back-button' onClick={() => previousPage()}>
                    ← Back
                </Button>

                <Button className='next-button' onClick={() => nextPage()}>
                    Next →
                </Button>
            </div>
        </div>
    );
  }
}

export default withRouter(UserWalkthroughPageSix);
