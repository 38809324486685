import React from 'react';
import { FormGroup, ControlLabel, FormControl, Button, Row, Col, Checkbox, HelpBlock } from 'react-bootstrap';
// import { withRouter } from 'react-router';
import moment from 'moment';
import config from '../../constant/config';
import checkStatus from '../common/checkStatus';
import './SessionFeedback.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

class SessionFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      session: '',
      hub: '',
      usersSelected: [],
      attendees: 0,
      delivered: null,
      validDelivered: true,
      feedback: '',
      validFeedback: true,
      disabled: false,
      loaded: false,
      feedbackUserName: '',
      sessionUsers: [],
    };
  }

  componentWillMount() {
    this.getSession();
    this.getSessionFeedback();
  }

  componentDidUpdate(props) {
    if (props.user === null) {
      this.props.history.push('/home');
    }
  }

  onAttendeeBlur() {
    if (this.state.attendees < 0) {
      this.setState({ attendees: 0 });
    }
  }

  async getSession() {
    try {
      const res = await fetch(`${config.apiURL}/session/${this.props.session_id}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const session = await res.json();
        await this.setState({ session });
        this.getHub();
        this.getSessionUsers();
      }
      if (res.status === 401) {
        this.props.history.push('/');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getSessionUsers() {
    try {
      const res = await fetch(`${config.apiURL}/session/${this.props.session_id}/users`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const sessionUsers = await res.json();
        await this.setState({ sessionUsers });
      }
      if (res.status === 401) {
        this.props.history.push('/');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getSessionFeedback() {
    try {
      const res = await fetch(`${config.apiURL}/session/${this.props.session_id}/feedback`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const feedback = await res.json();
        if (!feedback) {
          return;
        }
        const feedbackUser = feedback.users.filter(user => user.id === feedback.feedbackUserId);
        if (!feedbackUser[0]) {
          feedbackUser.push(this.props.user);
        }
        const feedbackUserName = `${feedbackUser[0].firstName} ${feedbackUser[0].surname}`;
        const usersSelected = feedback.users.map(user => user.id);
        this.setState({
          disabled: true,
          feedback: feedback.feedback,
          attendees: feedback.numberOfAttendees,
          delivered: feedback.sessionDelivered === 1,
          usersSelected,
          feedbackUserName,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getHub() {
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.state.session.hubId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const hub = await res.json();
        await this.setState({ hub, loaded: true });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleUserCheckboxClick(e) {
    const index = this.state.usersSelected.indexOf(parseInt(e, 10));
    if (index === -1) {
      const usersSelected = [...this.state.usersSelected, parseInt(e, 10)];
      await this.setState({ usersSelected });
    } else {
      const { usersSelected } = this.state;
      usersSelected.splice(index, 1);
      await this.setState({ usersSelected });
    }
  }

  async submitFeedback() {
    try {
      const res = await fetch(`${config.apiURL}/session/${this.state.session.id}/feedback`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          sessionId: this.state.session.id,
          feedbackUserId: this.props.user.id,
          attendees: parseInt(this.state.attendees, 10),
          sessionDelivered: this.state.delivered,
          feedback: this.state.feedback,
          usersSelected: this.state.usersSelected,
        }),
      });
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  }

  renderUserCheckboxes() {
    return (
      <Row className='user-rows'>
        {console.log(this.state.sessionUsers)}
        {this.state.hub.users.map((user) => {
          const userInSession = this.state.sessionUsers.includes(user.id);
          if (userInSession) {
            return (
              <Col key={user.id} md={2} xs={12}>
                <Checkbox
                  className="users-check"
                  key={user.id}
                  id={user.id}
                  onChange={(e) => this.handleUserCheckboxClick(e.target.id)}
                  checked={this.state.usersSelected.includes(user.id)}
                  disabled={this.state.disabled}
                >
                  <p className="checkbox-title">{`${user.firstName} ${user.surname}`}</p>
                </Checkbox>
              </Col>
            );
          }
          // Return null if the user is not in the list of sessionUsers
          return null;
        })}
      </Row>
    );
  }

  render() {
    console.log(this.props)
    console.log(this.state)
    if (!this.state.loaded) {
      return null;
    }
    // const title = this.state.session;
    const sessionDate = moment(this.state.session.dueDate).format('Do MMMM YYYY');
    return (
      <div className="session-feedback-container">
        <div className="heading-container">
          <h4 className="title" >Session Feedback Form</h4>
          <div className="session-description">{this.props.title}</div>
          <div className="session-description">{sessionDate}</div>
        </div>
        <div>
          <FormGroup className="form-inline" controlId="name" >
            <ControlLabel>Name:</ControlLabel>
            <FormControl
              className="form-input inline"
              type="text"
              disabled
              value={this.state.disabled ? this.state.feedbackUserName : `${this.props.user.firstName} ${this.props.user.surname}`}
            />
          </FormGroup>
          <div>
            <p className="category-title">Please confirm practitioners that attended session:</p>
          </div>
          {this.state.hub && this.renderUserCheckboxes()}
          <FormGroup className="form-inline attendees" controlId="attendees" >
            <ControlLabel>Number of Attendees:</ControlLabel>
            <FormControl
              className="form-input inline"
              type="number"
              disabled={this.state.disabled}
              min="0"
              value={this.state.attendees}
              onChange={e => this.setState({ attendees: e.target.value })}
              onBlur={() => this.onAttendeeBlur()}
            />
          </FormGroup>
          <FormGroup className="form-inline" controlId="formRadio" validationState={!this.state.validDelivered ? 'error' : null}>
            <ControlLabel>Session Delivered:</ControlLabel>
            <div className='radio-group-container'>
              <Checkbox name="radioGroup" disabled={this.state.disabled} checked={this.state.delivered === true} onChange={() => this.setState({ delivered: true })}>
                Yes
              </Checkbox>
              <Checkbox name="radioGroup" disabled={this.state.disabled} checked={this.state.delivered === false} onChange={() => this.setState({ delivered: false })}>
                No
              </Checkbox>
            </div>
            {!this.state.validDelivered && <HelpBlock>Please let us know if session was completed</HelpBlock>}
          </FormGroup>
          <FormGroup className="form" controlId="textContent" validationState={!this.state.validFeedback ? 'error' : null}>
            <ControlLabel className="text-area">General Feedback:</ControlLabel>
            <FormControl
              className="form-input"
              disabled={this.state.disabled}
              componentClass="textarea"
              value={this.state.feedback}
              onChange={(e) => { this.setState({ feedback: e.target.value }); }}
            />
            {!this.state.validFeedback && <HelpBlock>Must Enter Text Content</HelpBlock>}
          </FormGroup>
        </div>
        {!this.state.disabled &&
          <div className="button-container">
            <Button onClick={() => this.submitFeedback()} >Submit Feedback <FontAwesomeIcon icon={faFlag} /></Button>
          </div>}
      </div>
    );
  }
}

export default checkStatus(SessionFeedback);
