import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import config from '../../constant/config';
import { emailValid } from '../../lib/validation';
import './Login.css';
import './signup/SignUpNew.css';

import logo from '../../resources/img/actify_logo.svg';
import mailIcon from '../../resources/img/mail_icon.svg';
import lockIcon from '../../resources/img/lock_icon.svg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showLoginFeedback: false,
      feedback: '',
      showReset: false,
      showResetConfirmed: false,
      hideBanner: window.innerWidth <= 992,
    };
    this.checkWindowWidth = this.checkWindowWidth.bind(this);
  }

  checkWindowWidth() {
    this.setState({ hideBanner: window.innerWidth <= 992 });
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkWindowWidth);
  }

  async getQuizAnswers() {
    try {
      const res = await fetch(`${config.apiURL}/quiz/answers`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const body = await res.json();
        this.props.setQuizResults(body);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleButtonClick() {
    const { email, password } = this.state;
    if (!emailValid(email) || email.length < 1) {
      this.setState({ showLoginFeedback: true, feedback: 'Please enter valid email address' });
      return;
    }
    if (this.state.showReset) {
      try {
        const res = await fetch(`${config.apiURL}/user/reset`, {
          credentials: 'include',
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ email }),
        });
        if (res.status !== 200) {
          throw new Error();
        }
        this.setState({ showResetConfirmed: true, feedback: '' });
      } catch (err) {
        this.setState({ showLoginFeedback: true, feedback: 'Unable to send email. Please try again later.' });
      }
    } else {
      if (password.length < 1) {
        this.setState({ showLoginFeedback: true, feedback: 'Please enter valid password' });
      }
      const user = {
        email,
        password,
      };
      try {
        const res = await fetch(`${config.apiURL}/user/login`, {
          credentials: 'include',
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(user),
        });
        if (res.status === 200) {
          const body = await res.json();
          this.props.onLogin(body);
          await this.getQuizAnswers();
          this.setState({ showLoginFeedback: false, feedback: '' });
          this.props.handleModalClose({}, true);
        }
        if (res.status === 401) {
          this.setState({ showLoginFeedback: true, feedback: 'Incorrect email or password' });
        }
      } catch (err) {
        this.setState({ showLoginFeedback: true, feedback: 'Unable to login. Please try again later.' });
      }
    }
  }

  resetPasswordClick() {
    this.setState({
      showReset: true,
      showLoginFeedback: false,
      email: '',
      password: '',
      feedback: '',
    });
  }

  backClick() {
    this.setState({
      showReset: false,
      showLoginFeedback: false,
      email: '',
      password: '',
      feedback: '',
    });
  }

  render() {
    const {
      email,
      password,
      hideBanner,
      showReset,
      showResetConfirmed,
      showLoginFeedback,
      feedback
    } = this.state;

    const { signupRedirect } = this.props;

    const validEmail = emailValid(email) && email.length < 255;

    return (
      <div className="new-sign-up">
        <div className="row flexbox">
          { !hideBanner &&
            <div className="col-md-6 infographic sit-ups position-relative">
              <div className="welcome-message">
                <span className="welcome-big">
                  Welcome
                  <br />
                  <span className="underline">
                    Back!
                    <hr />
                  </span>
                </span>
                <br />
              </div>
              <div className="intro-message" style={{display:'none'}} />
            </div>
          }
          <div className={!hideBanner ? "col-md-6" : ""}>
            <div className="form-padding" style={{paddingBottom:"60px"}}>
              <div className="modal-form-container">
                <div className="logo-container">
                  <img
                    alt="actify"
                    className="img-fluid"
                    src={logo}
                  />
                </div>

                <p className="already-have-acc">
                  New to Actify?{' '}
                  <Button onClick={() => signupRedirect()} className="blue-link">
                    Sign up here
                  </Button>
                </p>

                <br />

                <h3 className="welcome-back" style={{marginBottom: '18%'}}>Sign in to your account here.</h3>

                <div className="login-error-container">
                  {showLoginFeedback && <p className="feedback">{feedback}</p>}
                </div>

                { showReset ?
                  <div>
                    { showResetConfirmed ?
                      <div className="reset-confirmed-header">
                        Check <b>{email}</b> for a link to reset your password.
                      </div>
                      :
                      <div>
                        <div className="reset-help-header">
                          To reset your password, enter the email address you use to sign in to your Actify account.
                        </div>
                        <br />
                        <div className="input-icon-group">
                          <FormGroup
                            className="form"
                            validationState={!validEmail ? 'error' : null}
                          >
                            <ControlLabel className="input-label">Email*</ControlLabel>
                            <FormControl
                              autoFocus
                              className="form-input orange-input"
                              placeholder="Email address"
                              type="text"
                              name="email"
                              value={email}
                              onChange={(e) => this.setState({ email: e.target.value })}
                            />
                          </FormGroup>
                          <img
                            class="input-icon"
                            src={mailIcon}
                            alt="mail icon"
                          />
                        </div>
                      </div>
                    }
                  </div>
                  :
                  <div>
                    <div className="input-icon-group">
                      <FormGroup
                        className="form"
                        validationState={!validEmail ? 'error' : null}
                      >
                        <ControlLabel className="input-label">Email*</ControlLabel>
                        <FormControl
                          autoFocus
                          className="form-input orange-input"
                          placeholder="Email address"
                          type="text"
                          name="email"
                          value={email}
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                      </FormGroup>
                      <img
                        class="input-icon"
                        src={mailIcon}
                        alt="mail icon"
                      />
                    </div>

                    <div className="input-icon-group">
                      <FormGroup
                        className="form"
                        controlId="formPassword"
                      >
                        <ControlLabel className="input-label">Password*</ControlLabel>
                        <FormControl
                          className="form-input orange-input"
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </FormGroup>
                      <img
                        class="input-icon"
                        src={lockIcon}
                        alt="padlock icon"
                      />
                    </div>

                    <Button onClick={() => this.resetPasswordClick()} className="blue-link" style={{marginLeft: '13px'}}>
                      Forgot password?
                    </Button>
                  </div>
                }
              </div>
            </div>

            <br /><br />

            <div className="row">
              <div className="col-md-12 mb-20">
                <div className="modal-button-container">
                  <Button
                    className="blue-button pull-right"
                    onClick={() => this.handleButtonClick()}
                  >
                    {this.state.showReset ? 'Send Email' : 'Sign in →'}
                  </Button>
                  {this.state.showReset &&
                    <div>
                      <Button
                        className="blue-button pull-right"
                        style={{ marginRight: '1em' }}
                        onClick={() => this.backClick()}
                      >
                        Back
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
