import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Faq from '../common/Faq';
import './Faq.css';

export default function FaqPage() {
  const helpingOthersRef = useRef()
  const personalUseRef = useRef()

  return (
    <div className="faq-page-container">
      <div className='top-container'>
        <div className="top-text">
          HOW CAN WE HELP?
        </div>
        <div className="title-text">
          Frequently Asked Questions
        </div>
        <div className="main-text">
          Got a question that's not answered here? <Link to="/contact"> <u>Contact us</u></Link> and we'll be happy to help. You'll also find get-started guides in the <Link to="/help"> <u>Help Hub</u></Link>.
        </div>
        <div className='personas' >
          <div onClick={() => window.scrollTo({ behavior: 'smooth', top: helpingOthersRef.current.offsetTop })}>
            <img className="helping-others-image" alt="helping_others" />
          </div>
          <div onClick={() => window.scrollTo({ behavior: 'smooth', top: personalUseRef.current.offsetTop })}>
            <img className="personal-use-image" alt="personal_use" />
          </div>
        </div>
      </div>

      <div className="firstDiv" ref={helpingOthersRef}>
        <div className="top-text-grey" >
          HELPING OTHERS
        </div>
        <div className="right-col">
          <img className='practitioner' alt="" />
        </div>
        <div className="main-text-faq">
          I have questions about how to use Actify to help others achieve their goals.
        </div>
        <Faq type="2" />
        <div className="blue-button-container">
          <Button
            className="blue-button"
            onClick={() => window.scrollTo({ behavior: 'smooth', top: (0, 0) })}
          >Back to top ↑
          </Button>
        </div>
      </div>

      <div className="firstDiv" ref={personalUseRef}>
        <div className="top-text-grey">
          PERSONAL USE
        </div>
        <div className="right-col">
          <img className="user-icon" alt="" />
        </div>
        <div className="main-text-faq">
          I have questions about how to use Actify to learn about physical activity and how I can personally improve.
        </div>
        <Faq type="1" />
        <div className="blue-button-container">
          <Button
            className="blue-button"
            onClick={() => window.scrollTo({ behavior: 'smooth', top: (0, 0) })}
          >Back to top ↑
          </Button>
        </div>
      </div>
    </div >
  )
}
