import React from 'react';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RatingControls from '../common/RatingControls';
import config from '../../constant/config';
import { privacySelector } from '../../lib/utilities';
import './ModuleHeader.css';

const updateRating = async (moduleId, ratingType) => {
  const res = await fetch(`${config.apiURL}/module/${moduleId}/rating`, {
    method: 'PUT',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      type: ratingType,
    }),
  });
  if (res.status !== 200) {
    throw new Error('unable to update rating');
  }
  const newRating = await res.json();
  return newRating;
};

const deleteRating = async (moduleId) => {
  const res = await fetch(`${config.apiURL}/module/${moduleId}/rating`, {
    method: 'DELETE',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status !== 200) {
    throw new Error('unable to delete rating');
  }
  const newRating = await res.json();
  return newRating;
};

const fetchUserRating = async (moduleId) => {
  const res = await fetch(`${config.apiURL}/user/rating/${moduleId}/`, {
    method: 'GET',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  if (res.status !== 200) {
    throw new Error('unable to delete rating');
  }
  const userRating = await res.json();
  return userRating;
};

class ModuleHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upRatingSelected: false,
      upRatingCount: 0,
      downRatingSelected: false,
      downRatingCount: 0,
      likingUsers: []
    };
  }

  componentWillMount() {
    if (this.props.mod) {
      this.getUserRating();
    }
  }

  componentWillReceiveProps(props) {
    console.log(props)
    if (props.mod) {
      this.getUserRating();
    }
    if (props.mod.ratings) {
      let userHasLikedModule = false;
      if(props.loggedIn){
        userHasLikedModule = props.mod.ratings.likingUsers.find(({ id }) => id === this.props.loggedIn.id) ? true : false
      }

      this.setState({
        upRatingSelected: userHasLikedModule,
        upRatingCount: parseInt(props.mod.ratings.points.positive, 10),
        downRatingCount: parseInt(props.mod.ratings.points.negative, 10),
        likingUsers: props.mod.ratings.likingUsers,
      });
    }
  }

  async onClickRatingUp(e) {
    e.preventDefault();
    try {
      const newRating = this.state.upRatingSelected ?
        await deleteRating(this.props.mod.id) :
        await updateRating(this.props.mod.id, config.ratingTypes.POSITIVE);
      if (!newRating) {
        return;
      }

      let updatedLikingUsers;
      // Upvoting
      if (this.state.upRatingSelected) {
        updatedLikingUsers = this.state.likingUsers.filter(({ id }) => id !== this.props.loggedIn.id);
      }
      // De-upvoting
      else if (!this.state.upRatingSelected) {
        const info = this.props.loggedIn
        const userData = {
          id: info.id,
          firstName: info.firstName,
          surname: info.surname
        }
        updatedLikingUsers = [...this.state.likingUsers, userData];
      }

      this.setState({
        upRatingSelected: !this.state.upRatingSelected,
        downRatingSelected: false,
        upRatingCount: parseInt(newRating.positive, 10),
        downRatingCount: parseInt(newRating.negative, 10),
        likingUsers: updatedLikingUsers
      });
    } catch (err) {
      console.error(err.message);
    }
  }

  async onClickRatingDown(e) {
    e.preventDefault();
    try {
      const newRating = this.state.downRatingSelected ?
        await deleteRating(this.props.mod.id) :
        await updateRating(this.props.mod.id, config.ratingTypes.NEGATIVE);

      if (!newRating) {
        return;
      }
      this.setState({
        upRatingSelected: false,
        downRatingSelected: !this.state.downRatingSelected,
        upRatingCount: parseInt(newRating.positive, 10),
        downRatingCount: parseInt(newRating.negative, 10),
      });
    } catch (err) {
      console.error(err.message);
    }
  }

  async getUserRating() {
    if (!this.props.mod.id) {
      return;
    }
    try {
      const userRating = await fetchUserRating(this.props.mod.id);
      if (!userRating) {
        throw new Error('unable to get user rating');
      }
      if (userRating[0]) {
        this.setState({
          upRatingSelected: userRating[0].typeId === config.ratingTypes.POSITIVE,
          downRatingSelected: userRating[0].typeId === config.ratingTypes.NEGATIVE,
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  render() {
    if (!this.props.mod.hub) {
      return null;
    }
    return (
      <div className="module-header-container">
        {config.showModuleBannerImage && <div className="banner-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.mod.logoId})` }} />}
        <div className="header-container">
          <div className="header-image-container">
            <Link to={`/${this.props.mod.hub.pathName}`} className="module-name">
              <div className="module-logo" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.mod.hub.logoId})` }} />
            </Link>


          </div>
          <div className="module-details-container">
            <Row>
              <h3>{privacySelector(this.props.mod.accessId, true)}{this.props.mod.title}</h3>
              <h4>{`${this.props.hubName} | ${this.props.categoryDescription}`}</h4>
              <p>{this.props.mod.description}</p>
              <p>Written by: {`${this.props.mod.writtenBy.firstName} ${this.props.mod.writtenBy.surname}`}</p>
            </Row>
            <Row className="rating-and-author-container">
              <div className="rating-controls-container">
                {/* Only show ratings when logged in */}
                {this.props.loggedIn && <RatingControls
                  upSelected={this.state.upRatingSelected}
                  onClickUp={e => this.onClickRatingUp(e)}
                  upCount={this.state.upRatingCount}
                  downSelected={this.state.downRatingSelected}
                  onClickDown={e => this.onClickRatingDown(e)}
                  downCount={this.state.downRatingCount}
                  likingUsers={this.state.likingUsers}
                />}
              </div>
              <div>
                <p></p>
                <p></p>
                {/* <p>Written by: {`${this.props.mod.writtenBy.firstName} ${this.props.mod.writtenBy.surname}`}</p> */}
              </div>
            </Row>
          </div>
            {this.props.loggedIn && this.props.actionButton}
        </div>
      </div>
    );
  }
}

export default ModuleHeader;
