import React from 'react';
import config from '../../constant/config';
import './ModuleMembers.css';
import {Button, Modal, FormControl}  from 'react-bootstrap';
import './CreateModule.css';

class ModuleMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchResults: false,
      searchPending: false,
      searchResults: [],
      showModal: false,
      userList: '',
      userListParsed: [],
      notFoundUsers: []
    };
    this.searchTimeout = null;
    this.searchDelay = 500; //
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  findUserByEmail = async (email) => {
    try {
      const response = await fetch(`${config.apiURL}/search/${email}/`, {
        credentials: 'include',
        method: 'GET',
        headers: {
         'content-type': 'application/json'
        },
      });
      if (response.ok){
        const body = await response.json();
        return body;
      }
    } catch (err) {
      console.error(err);
    }
  }

  search = (query) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    if (query) {
      this.setState({
        searchPending: true
      });
      this.searchTimeout = setTimeout(async () => {
        const response = await fetch(`${config.apiURL}/search`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            query
          })
        });
        if (response.ok) {
          const body = await response.json();
          this.setState({
            searchResults: body.users,
            searchPending: false
          });
        } else {
          this.setState({
            searchResults: [],
            searchPending: false
          });
        }
      }, this.searchDelay);
    } else {
      this.setState({
        searchResults: [],
        searchPending: false
      });
    }
  };

  hideSearchResultsOnBlur = (event) => {
    this.setState({
      showSearchResults:
        event.relatedTarget !== null &&
        (event.relatedTarget.className === 'chat-members-search-result-item' ||
          event.relatedTarget.id === 'members-search')
    });
  };

  addMember = (userAdd) => {
    if (userAdd.id === this.props.user.id)
    {
      return;
    }
    let ids = this.props.members.map(m => m.id);
    if (!ids.includes(userAdd.id))
    {
      this.props.addMemberBound(userAdd);
    }
  };

  removeMember = (user) => {
    this.props.removeMemberBound(user);
  };

  saveUserList() {
    const { userList } = this.state;
    const userListParsed = userList.split(',').map(item => item.trim());
    this.setState({ notFoundUsers: [] });

     // Find each user in userListParsed by email  and add them
    userListParsed.forEach(async email => {
      try {
        const user = await this.findUserByEmail(email);
        if (user) {
          this.addMember(user);
        } else {
          // Handle the case when the user is not found
          this.setState(prevState => ({
            notFoundUsers: [...prevState.notFoundUsers, email], // Add the user to the userList state variable
          }));
        }
      } catch (error) {
        console.log(error) // Handle any errors that occur during the asynchronous operation
      }
    });
    this.closeModal()
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  renderModalContent() {
    return (
      <div className='bulk-add-modal'>
        <div>
          <h3>Add users from a list</h3>
          <p>Please enter the list of user emails seperated by commas, e.g. james.bond@gmail.com, mary.poppins@gmail.com</p>
        </div>
        <div className='form-container'>
        <FormControl
                className="form-input"
                type="text"
                value={this.state.userList}
                onChange={e => this.setState({ userList: e.target.value })}
              />
        </div>
        <div className="button-container">
          <Button className="button" onClick={() => this.saveUserList()}>Save</Button>
        </div>
      </div>
    );
}

  render() {
    const members = this.props.members;
    // filter results to only show hub members and users not already in members
    const filteredSearchResults = this.state.searchResults
      .filter(
        (result) =>
          !this.props.members.map(x => x.id).includes(result.id) && this.props.hubMembers.some((hubMember) => hubMember.id === result.id)
      );
    var showSearchResults =
      this.state.showSearchResults && filteredSearchResults.length > 0;

    return (
      <div className="module-members">
        <input
          id="members-search"
          type="text"
          placeholder="Search for users..."
          onChange={(event) => {
            this.search(event.target.value);
          }}
          onFocus={() =>
            this.setState({
              showSearchResults: true
            })
          }
          onBlur={this.hideSearchResultsOnBlur}
        />
         <Button
        className="bulk-add-button"
        onClick={() => this.openModal()}
      >
        Add from a list
        </Button>
        {this.state.searchPending ? (
          <i id="members-search-pending" className="fa fa-spinner fa-2x" />
        ) : (
          <i id="members-search-icon" className="fa fa-search fa-2x" />
        )}
        {showSearchResults && (
          <div className="chat-members-search-results" id="member-search-results" style={{display:'default'}}>
            {filteredSearchResults.map((result) => (
              <button
                className="chat-members-search-result-item"
                key={`sr-${result.id}`}
                onClick={() => {
                  this.addMember(
                    result
                  );
                  document.getElementById('members-search').focus();
                  document.getElementById('member-search-results').setAttribute('style', 'display:none;');
                }}
                onBlur={this.hideSearchResultsOnBlur}
              >
                {result.firstName} {result.surname}
              </button>
            ))}
          </div>
        )}
        <div className="chat-members-list">
          {members.map((member) => {
            if (member.id !== this.props.user.id) {
              return (
                <div className="chat-members-list-item" key={`li-${member.id}`}>
                  <span className="chat-members-list-item-name">
                    {member.firstName} {member.surname}
                  </span>

                    <button
                      className="chat-members-list-item-remove"
                      onClick={() => this.removeMember(member)}
                    >
                      <i className="fa fa-minus-circle fa-2x" />
                    </button>
                </div>);
              }
            return ( <div /> );
          })}
        </div>
        {/* show what users have not been found */}
        {this.state.notFoundUsers.length >0  && <div className='not-found-users'>Users {this.state.notFoundUsers.join(', ')} not found!</div>}
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          <div className="plan-modal-container">
            {this.renderModalContent()}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ModuleMembers;
