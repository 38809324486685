// import config from '../../constant/config';

// export default function googleAnalyticsScript() {
//   const googleAnalyticsLink = `https://googletagmanager.com/gtag/js?id=${config.GA_TRACKING_ID}`;
//   const googleAnalyticsElement = document.createElement('script');
//   googleAnalyticsElement.src = googleAnalyticsLink;
//   googleAnalyticsElement.setAttribute('async', true);
//   document.body.appendChild(googleAnalyticsElement);
// }
// googleAnalyticsScript.js
export default function googleAnalyticsScript(trackingID) {
  // if (!window.gtag) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      if (!window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
      }
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', trackingID, { 'send_page_view': false });
     };
  // }
}
