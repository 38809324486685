import React, { Component } from 'react';
import {
  Row, Col, Button
} from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import Tile from './Tile';
import HubTile from './HubTile';
import UserTile from './UserTile';
import PlanTile from './PlanTile';
import Section from './Section';
import { Link } from 'react-router-dom';
import './CarouselSection.css';
import 'react-multi-carousel/lib/styles.css';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { IconContext } from "react-icons";
import infoIcon from '../../resources/img/info-icon.png';
import InfoBox from './InfoBox';

/*
  ### PROPS
  context
    - the type of tile
    - modules, hubs, users, plans
  title
    - heading of carousel section
  tiles
    - list of tiles to be rendered
  infinite
    - infinite scroll (cyclic)
  small
    - boolean that sets the carousel to deal with smaller elements
    - useful for hubs, users, and small module/plan tiles
  extraClasses
    - additional classes to be specified when rendering the carousel
    - classes can be defined in the component that carousel is used in
*/

class CarouselSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      showingInfo: false,
    };
  }

  getSize() {
    if (this.props.small) {
      return {
        8: {
          breakpoint: { max: 3000, min: 1819 },
          items: 8
        },
        7: {
          breakpoint: { max: 1819, min: 1603 },
          items: 7
        },
        6: {
          breakpoint: { max: 1603, min: 1388 },
          items: 6
        },
        5: {
          breakpoint: { max: 1388, min: 1171 },
          items: 5
        },
        4: {
          breakpoint: { max: 1171, min: 956 },
          items: 4
        },
        3: {
          breakpoint: { max: 956, min: 740 },
          items: 3
        },
        2: {
          breakpoint: { max: 740, min: 524 },
          items: 2
        },
        1: {
          breakpoint: { max: 524, min: 0 },
          items: 1
        },
      };

    }
    return {
      superDuperLargeDesktop: {
        breakpoint: { max: 3000, min: 2608 },
        items: 7,
        slidesToSlide: 2
      },
      superLargeDesktop: {
        breakpoint: { max: 2608, min: 2248 },
        items: 6,
        slidesToSlide: 2
      },
      normalDesktop: {
        breakpoint: { max: 2248, min: 1891 },
        items: 5,
        slidesToSlide: 2
      },
      smallDesktop: {
        breakpoint: { max: 1891, min: 1531 },
        items: 4,
        slidesToSlide: 2
      },
      bigTablet: {
        breakpoint: { max: 1531, min: 1000 },
        items: 3,
        slidesToSlide: 2
      },
      tablet: {
        breakpoint: { max: 1000, min: 600 },
        items: 2,
        slidesToSlide: 1
      },
      smallTablet: {
        breakpoint: { max: 600, min: 464 },
        items: 1,
        slidesToSlide: 1,
        partialVisibilityGutter: 30
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
      }
    };
  }

  renderTiles(tile, index) {
    return (
      <Tile key={index} hubPathName={tile.hub.pathName} hubId={tile.hubId} id={tile.id} moduleTitle={tile.title} category={tile.category.description} hubName={tile.hub.name} hubImage={tile.hub.logoId} image={tile.logoId} accessId={tile.accessId} createdAt={tile.createdAt} carousel={true} />
    );
  }
  renderPlanTiles(tile, index, hub) {
    return (
      <PlanTile key={index} hubPathName={hub.pathName} hubId={tile.hubId} id={tile.id} moduleTitle={tile.title}  accessId={tile.accessId} category="Folder" hubName={hub.name} image={tile.logoId} small={this.props.small} carousel={true} hubImage={hub.logoId}/>
    );
  }

  renderHubTiles(tile, index) {
    return (
      <HubTile addHub={this.props.addHub ? this.props.addHub : null} hubPathName={tile.pathName} id={tile.id} selectable={this.props.selectable} key={index} hubName={tile.name} image={tile.logoId} />
    );
  }

  renderUserTiles(tile, index) {
    const fullName = `${tile.firstName} ${tile.surname}`;
    return (
      <UserTile id={tile.id} key={index} hubName={fullName} image={tile.avatarId} userClick={this.props.userClick} user={tile} selectable={this.props.selectable} onCheckboxClick={this.props.onCheckboxClick} />
    );
  }

  onClickExpand() {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  renderTitle() {
    const {
      small,
      title,
      noExpand,
      viewAllLink,
      viewAllText,
      infoHeader,
      infoText
    } = this.props;
    const { isExpanded } = this.state;
    const handleEnter = () => {
      this.setState({showingInfo : true})
    };

    const handleLeave = () => {
      this.setState({showingInfo : false})
    };

    return (
      <div className='carousel-title'>
        <h3 className={`${small ? "section-title small" : "section-title"} full-width-mobile-view`}>{title}</h3>
        { infoHeader && infoText && <img onMouseOver={()=>handleEnter()} onMouseOut={()=>handleLeave()} className='info-icon' src={infoIcon} alt='help icon' />}
        { this.state.showingInfo
          ?
          <div className='info-box' style={{marginLeft: "87px"}}>
            <InfoBox
              headerStyle={{fontSize: '18px'}}
              bodyStyle={{fontSize: '15px'}}
              imgStyle={{width: '350px'}}
              helpHeader={infoHeader}
              helpText={infoText}
            />
          </div>
          :
          null }
        {!noExpand &&
          <Button className="section-link top-link" onClick={() => this.onClickExpand()}>
            <IconContext.Provider value={{ style: { verticalAlign: '-10%', } }}>
              {isExpanded ? 'Show Less' : 'Explore More'} {isExpanded ? <FaMinusCircle /> : <FaPlusCircle />}
            </IconContext.Provider>
          </Button>
        }
        {viewAllLink && viewAllText &&
          <Link to={viewAllLink} className="section-link top-link top-link-view-all">{viewAllText}</Link>
        }
      </div>
    );
  }

  render() {
    const {
      context,
      extraClasses,
      infinite,
      emptyText,
      hub,
      tiles,
      noTitle,
      expanded
    } = this.props;
    const { isExpanded } = this.state;
    const responsive = this.getSize();

    return (
      <Row className={extraClasses ? `section-container ${extraClasses}` : "section-container"}>
        <Col className="content-container">
          {!noTitle && this.renderTitle()}
          {(isExpanded || expanded) || tiles.length === 1 ?
            <Section
              context={context}
              tiles={tiles}
              hub={hub}
            />
            :
            <Carousel swipeable partialVisible={true} infinite={infinite} responsive={responsive} containerClass={'carousel-container'}>
              {context === 'modules' && tiles.map((tile, index) => this.renderTiles(tile, index))}
              {context === 'hubs' && tiles.map((tile, index) => this.renderHubTiles(tile, index))}
              {context === 'users' && tiles.map((tile, index) => this.renderUserTiles(tile, index))}
              {context === 'plans' && tiles.map((tile, index) => this.renderPlanTiles(tile, index, hub))}
              {tiles.length < 1 &&
                <div className="empty-text-container">{emptyText}</div>
              }
            </Carousel>
          }
        </Col >
      </Row>
    );
  }
}

export default CarouselSection;
