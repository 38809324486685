import React, { Component } from 'react';
import { Col, Row, Popover, OverlayTrigger } from 'react-bootstrap';
import './RatingControls.css';

class RatingControls extends Component {
  render() {
    return (
      <div className="rating-controls">
        <Row>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={
              <Popover id="popover-trigger-hover-focus" title="People who have liked this">
                {this.props.likingUsers.map(({ firstName, surname }, idx) => (
                  <div key={idx}>{firstName} {surname}</div>
                ))}
              </Popover>
            }
            positionLeft={200}
            positionTop={50}
          >
            <Col sm={6} className="thumbs-col up-col" style={{ color: this.props.upSelected ? 'black' : '#A0A0A0' }} onClick={this.props.onClickUp}>
              <div className="icon">
                <i className="clickable fa fa-thumbs-up fa-2x" aria-hidden="true" />
              </div>
              <div className="count">{this.props.upCount || 0}</div>
            </Col>
          </OverlayTrigger>
        </Row>
      </div>
    );
  }
}

export default RatingControls;

