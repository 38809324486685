import React, { Component } from 'react';
import { Button, Radio } from 'react-bootstrap';
import './EditPermissions.css';

class EditPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [] || this.props.accessLevels,
      selectedRole: '' || this.props.currentAccess,
    };
  }

  getFeedback() {
    if (this.props.messageState === 'success') {
      return (
        <p className="success">User permissions successfully updated</p>
      );
    }
    if (this.props.messageState === 'error') {
      return (
        <p className="error">Error saving permissions</p>
      );
    }
    return null;
  }

  handleCheckboxClick(e) {
    this.setState({ selectedRole: parseInt(e, 10) });
  }

  render() {
    console.log(this.state.selectedRole)
    return (
      <div className="edit-permissions-container">
        <div className="heading-container">
          <h3>Edit Permissions</h3>
        </div>
        <div className="content-container">
          <div className="radio-container">
            {this.props.accessLevels.map(level =>
              <Radio checked={this.state.selectedRole === level.id} key={level.id} id={level.id} onChange={e => this.handleCheckboxClick(e.target.id)}>{level.role}</Radio>)}
          </div>
        </div>
        <div className="feedback-container">
          {this.props.showMessage && this.getFeedback()}
        </div>
        <div className="button-container">
          <Button onClick={() => this.props.handleSavePermissions(this.props.userToEdit, this.state.selectedRole)}>Save</Button>
        </div>

      </div>
    );
  }
}

export default EditPermissions;
