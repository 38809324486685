import React, { Component } from 'react';
import { FormControl, FormGroup, ControlLabel, Button, HelpBlock, Radio } from 'react-bootstrap';
import config from '../../../constant/config';
import RadioQuestion from './RadioQuestion';
import './CreateRadioQuestion.css';

class CreateRadioQuestion extends Component {
  constructor(props) {
    super(props);
    let initialState = {
      validQuestionText: true,
      questionText: '',
      validPossibleAnswers: true,
      possibleAnswers: [],
      validAnswerText: true,
      newAnswer: '',
      validCorrectAnswer: true,
      correctAnswer: null,
      activePage: 1,
    };
    if (props.questionToEditContent){
      initialState.questionText = props.questionToEditContent.text || props.questionToEditContent.questionText;
      initialState.possibleAnswers = props.questionToEditContent.possibleAnswers;
    }
    this.state = initialState;
    this.selectCorrectAnswer = this.selectCorrectAnswer.bind(this);
  }

  async onClickNext() {
    if (this.state.activePage === 1) {
      if (!this.validPageOne()) {
        return;
      }
      this.setState({
        activePage: 2,
      });
    } else {
      if (!this.validPageTwo()) {
        return;
      }
      const { questionText, possibleAnswers, correctAnswer } = this.state;
      const question = {
        questionText,
        questionType: config.questionTypes.RADIO,
        possibleAnswers: possibleAnswers.map(ans => ({ answerText: ans.answerText })),
        correctAnswers: [parseInt(correctAnswer, 10)],
      };
      if (this.props.editIndex == null) {
        this.props.addQuestion(question);
      }else{
        this.props.editQuestion(this.props.editIndex, question);
      }
    }
  }

  addAnswer(e) {
    e.preventDefault();
    const { newAnswer } = this.state;
    const trimmedAnswer = newAnswer.trim().split(' ').filter(w => !!w && w !== ' ').join(' ');
    if (trimmedAnswer === '') {
      this.setState({
        validAnswerText: false,
      });
      return;
    }
    this.setState((prevState) => ({
      newAnswer: '',
      possibleAnswers: [...prevState.possibleAnswers,  {answerText: trimmedAnswer}],
      validPossibleAnswers: true,
    }));
  }

  deleteAnswer(index) {
    const updatedAnswers = [...this.state.possibleAnswers];
    updatedAnswers.splice(index, 1);
    this.setState({
      possibleAnswers: updatedAnswers,
      validPossibleAnswers: true,
    });
  }

  selectCorrectAnswer(id) {
    this.setState({ correctAnswer: id, validCorrectAnswer: true });
  }

  validPageOne() {
    const { questionText, possibleAnswers } = this.state;
    const trimmedQuestionText = questionText.trim().split(' ').filter(w => !!w && w !== ' ').join(' ');
    const validQuestionText = !!trimmedQuestionText;
    const validPossibleAnswers = possibleAnswers.length > 0;
    this.setState({
      validQuestionText,
      validPossibleAnswers,
      questionText: trimmedQuestionText,
    });
    return (validQuestionText && validPossibleAnswers);
  }

  validPageTwo() {
    const { correctAnswer } = this.state;
    const validCorrectAnswer = correctAnswer !== null;
    this.setState({
      validCorrectAnswer,
    });
    return validCorrectAnswer;
  }

  renderPageOne() {
    return (
      <div className="page-1-container">
        <FormGroup className="form" controlId="title" validationState={!this.state.validQuestionText ? 'error' : null}>
          <ControlLabel>Question Text</ControlLabel>
          <FormControl
            className="form-input"
            componentClass="textarea"
            value={this.state.questionText}
            onChange={e => this.setState({ questionText: e.target.value, validQuestionText: true })}
          />
          {!this.state.validQuestionText && <HelpBlock>Must enter question text</HelpBlock>}
        </FormGroup>
        {this.state.possibleAnswers.length > 0 &&
          <FormGroup className="form" controlId="formRadio">
            <ControlLabel>Possible Answers</ControlLabel>
            {this.state.possibleAnswers.map((answer, index) => (
            <div key={`answer-${index}`} className="answer-container" style={{display: 'flex', gap: '20px'}}>
              <Radio disabled>{answer.answerText || answer}</Radio>
              <i className="clickable fa fa-times" aria-hidden="true" onClick={() => this.deleteAnswer(index)} style={{color: 'red'}}/>
            </div>
          ))}
          </FormGroup>
        }
        <FormGroup className="form" controlId="answer-input" validationState={!this.state.validAnswerText || !this.state.validPossibleAnswers ? 'error' : null}>
          <ControlLabel className="inline-label">Add answer</ControlLabel>
          <FormControl
            className="form-input answer-text"
            type="text"
            value={this.state.newAnswer}
            onChange={e => this.setState({ newAnswer: e.target.value, validAnswerText: true })}
          />
          <Button
            className="add-answer-button"
            onClick={e => this.addAnswer(e)}
            disabled={this.state.possibleAnswers.length >= config.questionAnswerLimit}
          >
            Add
          </Button>
          {!this.state.validAnswerText && <HelpBlock>Answer text can not be empty</HelpBlock>}
          {!this.state.validPossibleAnswers && <HelpBlock>There must be at least one answer choice</HelpBlock>}
        </FormGroup>
      </div>);
  }

  renderPageTwo() {
    return (
      <div className="page-2-container">
        <FormGroup validationState={!this.state.validCorrectAnswer ? 'error' : null}>
          <RadioQuestion
            questionText={this.state.questionText}
            answerLabel="Mark the correct answer from the options below"
            possibleAnswers={this.state.possibleAnswers.map(ans => ({ answerText: ans.answerText }))}
            onClickAnswer={this.selectCorrectAnswer}
          />
          {!this.state.validCorrectAnswer && <HelpBlock>Please select the correct answer</HelpBlock>}
        </FormGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="create-checkbox-question">
        {this.state.activePage === 1 &&
          this.renderPageOne()
        }
        {this.state.activePage === 2 &&
          this.renderPageTwo()
        }
        <div className="modal-controls">
          <Button className="modal-control modal-button" id="done" onClick={e => this.onClickNext(e)}>{this.state.activePage === 1 ? 'Next' : 'Done'}</Button>
        </div>
      </div>
    );
  }
}

export default CreateRadioQuestion;
