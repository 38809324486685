import React, { Component } from 'react';
import { FormGroup } from 'react-bootstrap';
import update from 'immutability-helper';
import './FillQuestion.css';

class FillQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      words: props.questionWords.map((word, index) => (
        {
          text: props.editableWords.includes(index) ? null : word,
          originalOrder: index,
          isEditable: props.editableWords.includes(index),
        }
      )),
    };
  }

  async onChangeAnswer(answerText, id) {
    await this.setState(update(this.state, {
      words: {
        [id]: {
          text: {
            $set: answerText.trim(),
          },
        },
      },
    }));
    const newAnswerText = this.state.words.map(word => word.text).join(' ');
    this.props.onChangeAnswer(newAnswerText);
  }

  getStyle() {
    if (this.props.answer) {
      return {
        color: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        borderColor: this.props.answer.isCorrect ? 'green' : '#ca3e25',
        border: '1px',
        borderStyle: 'solid',
      };
    }
    return null;
  }

  render() {
    return (
      <div className="fill-question-container" style={this.getStyle()}>
        <h3 className="question-text-container">
          <ol start={this.props.questionNumber}>
            <li>Fill in the blanks</li>
          </ol>
        </h3>
        {this.props.answer &&
          <div className="icon-container">
            {this.props.answer.isCorrect ?
              <i className="fa fa-check fa-2x" aria-hidden="true" /> :
              <i className="fa fa-times fa-2x" aria-hidden="true" />
            }
          </div>
        }
        <div className="answers-container">
          <FormGroup className="form" controlId="input">
            {this.state.words.map((word) => {
              if (word.isEditable) {
                if (this.props.disabled) {
                  const answerWords = this.props.answer.answerValue.split(' ');
                  const correctAnswerWords = this.props.answer.correctAnswer.split(' ');
                  if (answerWords[word.originalOrder] === correctAnswerWords[word.originalOrder]) {
                    return (
                      <input className="correct blank-input" disabled={this.props.disabled} value={answerWords[word.originalOrder]} key={word.originalOrder} />
                    );
                  }
                  return (
                    <input disabled={this.props.disabled} value={answerWords[word.originalOrder]} key={word.originalOrder} className="incorrect blank-input" />
                  );
                }
                return (
                  <input key={word.originalOrder} className="blank-input" onChange={e => this.onChangeAnswer(e.target.value, word.originalOrder)} />
                );
              }
              return (
                <div key={word.originalOrder} className="word-container">{word.text}</div>
              );
            })
            }
            {this.props.answer &&
              <div className="correct-answer-container">
                <p className="correct-answer-heading">Correct Answer:</p>
                <p className="correct-answer-text">{this.props.answer.correctAnswer}</p>
              </div>}
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default FillQuestion;
