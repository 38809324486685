import React from 'react';
import { Link } from 'react-router-dom';
import { dateCompare, privacySelector } from '../../lib/utilities';
import './ManageFeaturedModules.css';

class SelectFeaturedModules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFolders: [],
      showModal: false,
    };
  }

  renderTableRows() {
    let ids;
    try
    {
      ids = this.props.featured.map(x => x.id);
    }
    catch (err)
    {
      console.log(err);
      return <p>Hub has no modules! Create a module to feature it.</p>
    }

    return this.props.modules.sort(dateCompare).map(module => (
      <tr key={module.id}>
        <td><Link to={`/module/${module.id}`} >{privacySelector(module.accessId)}{module.title}</Link></td>
        <td>{module.category.description}</td>
        <td>
          <div className="warning-button-container">
            <input type="checkbox" defaultChecked={ids.includes(module.id)} onChange={(e) => this.props.handleChange(module)}/>
          </div>
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <div className="manage-featured-container">
        <h4>Featured Modules</h4>
        <table className="modules-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Category</th>
              <th className="delete-heading">Set Featured</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableRows()}
          </tbody>

        </table>
      </div>
    );
  }
}

export default SelectFeaturedModules;
