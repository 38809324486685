import React from 'react';

const cookiesPolicy = (
  <div>
    <h3>About Cookies</h3>
    <ol>
      <li>A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.</li>
      <li>Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.</li>
      <li> Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.</li>
    </ol>
    <h3>Cookies that we use</h3>
    <ol>
      <li>We use cookies for the following purposes:
        <ol className="ordered-letters">
          <li>authentication - we use cookies to identify you when you visit and as you navigate Actify;</li>
          <li>status - we use cookies to help us to determine if you are logged into Actify;</li>
          <li>personalisation - we use cookies to store information about your preferences and to personalise Actify for you;</li>
          <li>security - we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect Actify and services generally;</li>
          <li>advertising - we use cookies to help us to display advertisements that will be relevant to you;</li>
          <li>analysis - we use cookies to help us to analyse the use and performance of Actify and services;</li>
          <li>cookie consent - we use cookies to store your preferences in relation to the use of cookies more generally</li>
        </ol>
      </li>
    </ol>
    <h3>Cookies used by our service providers</h3>
    <ol>
      <li>Our service providers use cookies and those cookies may be stored on your computer when you visit Actify.</li>
      <li>We use Google Analytics to analyse the use of Actify. Google Analytics gathers information about Actify use by means of cookies. The information gathered relating to Actify is used to create reports about the use of Actify. Google's privacy policy is available at: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</li>
    </ol>

    <h3>Managing cookies</h3>
    <ol>
      <li>Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:
        <ol className="ordered-letters">
          <li><a href="https://support.google.com/chrome/answer/25647?hl=en">https://support.google.com/chrome/answer/25647?hl=en</a> (Chrome);</li>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> (Firefox);</li>
          <li><a href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a> (Opera);</li>
          <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a> (Internet Explorer);</li>
          <li><a href="https://support.apple.com/kb/PH2144">https://support.apple.com/kb/PH2144</a> (Safari); and</li>
          <li><a href="https://privacy.microsoft.com/en-us/windows-3-microsoft-edge-and-privacy">https://privacy.microsoft.com/en-us/windows-3-microsoft-edge-and-privacy</a> (Edge).</li>
        </ol>
      </li>
      <li> Blocking all cookies will have a negative impact upon the usability of many websites, webapps and platforms.</li>
      <li>If you block cookies, you will not be able to use all the features on Actify.</li>
    </ol>

  </div>
);

export default cookiesPolicy;
